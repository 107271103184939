import backendURLs from "../utils/backendURL";
import { joinBaseUrlWithParams, makeActionRequest } from "./utils";
import * as pagesContentActionsTypes from '../constants/pagesContent-actions-types';

export const getPageContentForPage = (pageName, lang) => dispatch => {
  const url = joinBaseUrlWithParams(
    `${backendURLs.PAGE_CONTENT.GET_CONTENT_OF_PAGE}`,
    [
      { param: "pageName", value: pageName },
      { param: "lang", value: lang },
    ]
  );

   makeActionRequest("get", url, null, dispatch, pagesContentActionsTypes.FETCH_PAGE_CONTENT, {});
};

export const getCommodityPrices = () => dispatch => {
   makeActionRequest("get", backendURLs.PAGE_CONTENT.GET_COMMODITY_PRICES, null, dispatch, pagesContentActionsTypes.FETCH_COMMODITY_PRICE, {});
};
