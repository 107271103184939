import Doc from './DocService';
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import CountryManager from '@helpers/CountryManager';
import { formatToUTCDate } from '@helpers/helpers';


export const PurchasePdf = (props) => {
    const { t } = props;
    const { currentRfq, setPurchasePdfBase64, generate } = props;
    const current_date = formatToUTCDate(currentRfq.quotation.createdAt, 'fr', 'MMM Do YYYY');

    const product_code = currentRfq ? currentRfq.quotation.product.name.toUpperCase() : "";
    const description_goods = currentRfq ? currentRfq.quotation.product.description.toUpperCase() : "";
    const unity_type = currentRfq ? currentRfq.quotation.product.type.toUpperCase() : "";

    const createPdf1 = (html) => Doc.createPdfForConfirmPoceed(html, setPurchasePdfBase64);

    const bodyPurchaseRef = React.createRef();
    const [langSelected, setLangSelected] = useState("en");
    const authUser = useSelector(state => state.authUser);

    useEffect(() => {
        if (authUser.authUser) {
            setLangSelected(authUser.authUser.language.toLowerCase());
        }
    }, [authUser]);

    useEffect(() => {
        if (generate) {
            createPdf1(bodyPurchaseRef.current);
        }

    }, [props.generate])

    return (
        <>
            {/* <section>
            <button onClick={createPdf}>Create Purchase Pdf</button>
        </section> */}
            <div className="pdfPruchase">

                <section ref={bodyPurchaseRef}>

                    <h2 style={{ textAlign: 'center' }}>{t('documents.purchase')}</h2>
                    {currentRfq ? (<div className="container" style={{ color: "black", border: 'solid black 1px' }} >
                        <div className="row">
                            <div className="col-md-6" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px', borderRight: 'solid black 0px' }}>
                                <p>{t('documents.from')}</p>

                                <p>{currentRfq.baseQuotation.user.companyName}<br />
                                    {currentRfq.baseQuotation.user.streetAddress}<br />
                                    {currentRfq.baseQuotation.user.city}, {currentRfq.baseQuotation.user.province} {currentRfq.baseQuotation.user.postal} <br />
                                    {CountryManager.getNameFromId(currentRfq.baseQuotation.user.country)}<br />
                                    TEL: {currentRfq.baseQuotation.user.phone}<br />
                                    {currentRfq.baseQuotation.user.keyContactName}<br /></p>

                            </div>
                            <div className="col-md-6">
                                <div className="row" style={{ border: 'solid black 1px' }}>
                                    <div className="col-md-12" >
                                        <div style={{ float: 'right' }}>
                                            <p></p><br />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px', borderRight: 'solid black 0px' }}>
                                        <p>Quotation No <br /><span style={{ fontWeight: 'bold' }}>{currentRfq.quotation.name}</span></p><br />
                                    </div>
                                    <div className="col-md-6" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px' }}>
                                        <p>Date <br /> <span style={{ fontWeight: 'bold' }}>{current_date}</span></p><br />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px' }}>
                                        <p>Reference <br /></p><br />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-6" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px', borderRight: 'solid black 0px' }}>
                                <p>{t('documents.to')}</p>

                                <p>{currentRfq.user.companyName}<br />
                                    {currentRfq.user.streetAddress}<br />
                                    {currentRfq.user.city}, {currentRfq.user.province} {currentRfq.user.postal} <br />
                                    {CountryManager.getNameFromId(currentRfq.user.country)}<br />
                                    TEL: {currentRfq.user.phone}<br />
                                    {currentRfq.user.keyContactName}<br /></p>

                                <div className="row">
                                    <div className="col-md-6" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px', borderRight: 'solid black 0px', borderLeft: 'solid black 0px' }}>
                                        <p>{t('documents.dispatch')} <br /><span style={{ fontWeight: 'bold' }}>{currentRfq.quotation.methodOfShipment}</span></p><br />
                                    </div>
                                    <div className="col-md-6" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px', borderRight: 'solid black 0px' }}>
                                        <p>{t('documents.shipment')}<br /> <span style={{ fontWeight: 'bold' }}>{currentRfq.quotation.typeOfShipment ? currentRfq.quotation.typeOfShipment.name : 'undefined'}</span></p><br />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{ border: 'solid black 1px', borderLeft: 'solid black 0px', borderRight: 'solid black 0px' }}>
                                        <p>{t('documents.discharge')}<br />  <span style={{ fontWeight: 'bold' }}>{currentRfq.quotation.port ? currentRfq.quotation.port.name : currentRfq.quotation.arrival} </span> </p><br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ border: 'solid black 1px' }}>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px', borderTop: 'solid black 0px', borderRight: 'solid black 0px' }}>
                                        <p>{t('documents.code')}</p>
                                    </div>
                                    <div className="col-md-8" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px', borderTop: 'solid black 0px', borderRight: 'solid black 0px' }}>
                                        <p>{t('documents.description')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-3" style={{ border: 'solid black 1px', borderTop: 'solid black 0px', borderBottom: 'solid black 0px', borderRight: 'solid black 0px' }}>
                                        <p>{t('documents.unit_q')}</p>
                                    </div>
                                    <div className="col-md-3" style={{ border: 'solid black 1px', borderTop: 'solid black 0px', borderBottom: 'solid black 0px', borderRight: 'solid black 0px' }}>
                                        <p>{t('documents.unit_t')}</p>
                                    </div>
                                    <div className="col-md-3" style={{ border: 'solid black 1px', textAlign: 'right', borderTop: 'solid black 0px', borderBottom: 'solid black 0px', borderRight: 'solid black 0px' }}>
                                        <p>{t('documents.price')}</p>
                                    </div>
                                    <div className="col-md-3" style={{ border: 'solid black 1px', textAlign: 'right', borderTop: 'solid black 0px', borderBottom: 'solid black 0px' }}>
                                        <p>{t('documents.amount')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ fontWeight: 'bold', border: 'solid black 1px', borderBottom: 'solid black 0px' }}>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4">
                                        <p>{product_code}</p>
                                    </div>
                                    <div className="col-md-8">
                                        <p>{description_goods} </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-3">
                                        <p>{currentRfq.quotation.quantity}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p>{currentRfq.quotation.quantityUnit[langSelected]}</p>
                                    </div>
                                    <div className="col-md-3" style={{ textAlign: 'right' }}>
                                        <p>{currentRfq.quotation.price}</p>
                                    </div>
                                    <div className="col-md-3" style={{ textAlign: 'right' }}>
                                        <p>{currentRfq.quotation.quantity * currentRfq.quotation.price}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px' }}>
                            <div className="col-md-6" style={{ textAlign: 'right' }}>
                                <p>Total</p>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-5" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                                        <p>{currentRfq.quotation.quantity}</p>
                                    </div>
                                    <div className="col-md-7" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                        <p>{currentRfq.quotation.quantity * currentRfq.quotation.price}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px' }}>
                            <div className="col-md-6" style={{ textAlign: 'right' }}>
                                <p>{t('documents.total')}</p>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-5" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                                        <p>{currentRfq.quotation.quantity}</p>
                                    </div>
                                    <div className="col-md-7" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                        <p>{currentRfq.quotation.quantity * currentRfq.quotation.price}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6" style={{ border: 'solid black 1px', borderRight: 'solid black 0px', borderBottom: 'solid black 0px' }}>

                                <p style={{ fontWeight: 'bold' }}>  </p>
                            </div>
                            <div className="col-md-6">
                                {/* <div className="row" style={{ border: 'solid black 1px', borderBottom:'solid black 0px'}}>
                                        <div className="col-md-6">
                                            <p style={{ fontWeight: 'bold'}}>{t('documents.charges')}</p>
                                        </div>
                                        <div className="col-md-6" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                            <p>00.00</p><br />
                                        </div>
                                    </div> */}
                                <div className="row" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px' }}>
                                    <div className="col-md-12">
                                        <div style={{ float: 'left' }}>
                                            <p style={{ marginBottom: '0px' }}>{t('documents.incoterms')}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-5" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                                {currentRfq.quotation.incoterm.name}
                                            </div>
                                            <div className="col-md-2" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                                {currentRfq.quotation.currency}
                                            </div>
                                            <div className="col-md-3" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                                {currentRfq.quotation.quantity * currentRfq.quotation.price}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px' }}>
                                    <div className="col-md-6">
                                        {t('documents.place')}<br />
                                        <span style={{ fontWeight: 'bold' }}>{CountryManager.getNameFromId(currentRfq.baseQuotation.user.country)}</span>
                                    </div>
                                    <div className="col-md-6" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                        <p style={{ fontWeight: 'bold' }}><br />{current_date}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px' }}>
                                        {t('documents.signatory')}<br />
                                        <span style={{ fontWeight: 'bold' }}>  {currentRfq.baseQuotation.user.companyName}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px' }}>
                                        {t('documents.signer')}<br />
                                        <span style={{ fontWeight: 'bold' }}> {currentRfq.baseQuotation.user.keyContactName}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{ border: 'solid black 1px', borderBottom: 'solid black 0px' }}>
                                        <p>Signature</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row" style={{ border: 'solid black 1px', borderTop: 'none' }}>
                            <div className="col-md-12" style={{ height: '40px' }}>
                                <p></p>
                            </div>
                        </div>

                    </div>) : null}
                </section>
            </div>
        </>
    )
}

export default withTranslation()(React.memo(withRouter(PurchasePdf)));

