import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { paginate } from "@helpers/helpers";
import TransactionItem from './TransactionItem';
import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { getTransactionsOfUser } from '@action/docs-actions';

const TransactionList = (props) => {

    const authUser = useSelector((state) => state.authUser);

    const { t } = props;

    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [errorData, setErrorData] = useState(null);

    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const filterFromSearchText = (item) => {
        try {
            return item.status.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
        } catch (err) {
            return false;
        }
    }

    let loader = null;
    let errorDisplay = null;

    const initialiseState = () => {
        setLoadingData(true);
        setErrorData(null);
        setData([]);
    };

    if (loadingData) {
        loader = (
            <div className="loader text-center">
                <div className="bounce-loader">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
        );
    }

    if (errorData) {
        errorDisplay = (
            <>
                <br />
                <div className="alert alert-danger">Error: {errorData.message}</div>
                <br />
            </>
        );
    }

    const getTransactions = (id) => {
        initialiseState();
        getTransactionsOfUser(id)
            .then(data => {
                setData(data.sort((a, b) => a.id > b.id ? -1 : 1));
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoadingData(false);
            });
    };


    useEffect(() => {
        if (authUser.authUser) {
            getTransactions(authUser.authUser.id);
        }
    }, [authUser]);

    return (
        <>
            <Helmet>
                <title>Sooko - Checkout Page </title>
            </Helmet>

            <div className="main">

                <div className="container">

                    <h2>{t('documents.transactions')}</h2>

                    {data.length > 0 ? <div className="row">
                        <div className={"col-md-5 header-search " + "header-search-inline"} style={{ width: "100% !important" }}>
                            <div className="header-search-wrapper">
                                <input type="search" className="form-control" name="q" placeholder={t('common.search')} style={{ width: "100%" }} required
                                    value={searchText} onChange={(ev) => setSearchText(ev.target.value)} />
                            </div>
                        </div>
                    </div> : null}

                    {loader}
                    {errorDisplay}


                    {data.length > 0 ? <div className="row" style={{ marginTop: 30, marginBottom: 20 }}>
                        <table className="col-lg-12 col-sm-12 rfq-table-scroll">
                            <thead>
                                <th>{t('documents.transaction_type')}</th>
                                <th> {t('documents.status')} </th>
                                <th> {t('documents.amount')} </th>
                                <th> {t('documents.date')} </th>
                                <th>{t('documents.action')}</th>
                            </thead>
                            <br />
                            <tbody>
                                {paginate(data.filter(filterFromSearchText), page, pageSize).map((item, index) => <TransactionItem key={index} item={item} />)}
                            </tbody>
                        </table>
                    </div> : null}
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        count={Math.ceil(data.filter(filterFromSearchText).length / pageSize)}
                        showFirstButton
                        showLastButton
                        onChange={(__, selectedPage) => setPage(selectedPage)}
                    />

                    {!loadingData && data.length <= 0 ?
                        (
                            <div className="mb-5 text-center">
                                <img src="assets/images/nothing-here.png" width="500" style={{ margin: "0 auto" }} />
                                <h2 className="text-muted font-weight-light"> {t('documents.no_transaction')} </h2>
                            </div>
                        ) : null
                    }

                </div>

                <div className="mb-6"></div>
            </div>
        </>
    )
}


export default withTranslation()(React.memo(withRouter(TransactionList)))
