import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { joinBaseUrlWithParams } from '@action/utils';
import { monthTypeOne, monthTypeTwo } from '@mockData/data';
import { DISCUSSION_POST, MARKET_INTELLIGENCE_DETAILS } from '@routes/urls';

function BlogTypeOne(props) {
    const { blog, addClass, discussion } = props;
    let date = new Date(blog.createdAt);
    useEffect(() => {
    }, [])
    return (
        <div>
            <div className="skel-post"></div>
            <article className={`post ${addClass}`}>
                <div className="post-media">
                    {
                        blog.image &&
                        (
                            <Link to={discussion ? joinBaseUrlWithParams(DISCUSSION_POST, [{param: 'id', value: blog.id}]) : joinBaseUrlWithParams(MARKET_INTELLIGENCE_DETAILS, [{param: 'id', value: blog.id}])}>
                                <figure>
                                    <img src={`${process.env.BACKEND_BASE_URL}${blog.image}`} width={props.width} height={props.height} alt="discussion image" />
                                </figure>
                            </Link>
                        )
                    }
                </div>
                <div className="post-body">
                    <div className="post-date">
                        <span className="day">{date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}</span>
                        <span className="month">{monthTypeOne[date.getMonth()]}</span>
                    </div>

                    <h2 className="post-title">
                        <Link to={discussion ? joinBaseUrlWithParams(DISCUSSION_POST, [{param: 'id', value: blog.id}]) : joinBaseUrlWithParams(MARKET_INTELLIGENCE_DETAILS, [{param: 'id', value: blog.id}])}>{blog.title}</Link>
                    </h2>

                    <div className="post-meta">
                        <span><i className="icon-calendar"></i>{` ${monthTypeTwo[date.getMonth()]} ${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}, ${date.getYear() + 1900}`}</span>
                        <span><i className="icon-folder-open"></i>
                            {
                                [blog.category].map((category, index) => (
                                    index === ([blog.category].length - 1) ?
                                        <Link to="#" key={"BlogCategory" + index}>{category.name}</Link>
                                        : <Link to="#" key={"BlogCategory" + index}>{category.name}, </Link>
                                ))
                            }
                        </span>
                    </div>

                    <div className="post-content">
                        <p className="mt-2 mb-2">{blog.content}</p>

                        <Link to={discussion ? joinBaseUrlWithParams(DISCUSSION_POST, [{param: 'id', value: blog.id}]) : joinBaseUrlWithParams(MARKET_INTELLIGENCE_DETAILS, [{param: 'id', value: blog.id}])} className="read-more float-left">Read More <i className="icon-angle-double-right"></i></Link>
                    </div>
                </div>
            </article>
        </div >
    )
}

export default BlogTypeOne;