import {
    UPDATE_COMPANY_INFORMATIONS_USER_BASE, UPDATE_COMPANY_INFORMATIONS_USER_SUCCESS, UPDATE_COMPANY_INFORMATIONS_USER_ERROR,
    UPDATE_COMPANY_INFORMATIONS_USER_CLEAR
} from '../../../constants/auth-action-types'

const initialState = {

    data: null,

    loading: false,
    error: null,
    userCompanyUpdate: false
}

const updateUserCompanyInformationReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case UPDATE_COMPANY_INFORMATIONS_USER_BASE:
            return { ...state, loading: true, error: null, userCompanyUpdate: false, data: null};

        case UPDATE_COMPANY_INFORMATIONS_USER_SUCCESS:
            return { ...state, error: null, loading: false, userCompanyUpdate: true, data: action.payload };

        case UPDATE_COMPANY_INFORMATIONS_USER_ERROR:
            return { ...state, data: null, loading: false, userCompanyUpdate: false, error: action.payload };

        
        case UPDATE_COMPANY_INFORMATIONS_USER_CLEAR:
            return initialState

        default:
            return state;
    }
};

/* const persistConfig = {
    keyPrefix: "porto-",
    key: "products",
    storage
}
 */
export default updateUserCompanyInformationReducer;