import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getPrice, findIndex } from '../../../utils';
import { addToCart, addToWishList, showQuickView } from '../../../action';
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function ProductTypeTwo( props ) {
    let isInWishlist = props.product ? ( findIndex( props.wishlist, props.product.id ) ? true : false ) : false;
    let { addClass, link = "default", product, hasWishIcon = true, showQuickView, addToCart } = props;
    let priceMax, priceMin = 0;

    if ( product.variants ) {
        priceMax = getPrice( product.variants );
        priceMin = getPrice( product.variants, 'min' );
    }

    const onWishlistClick = ( e ) => {
        if ( !isInWishlist ) {
            e.preventDefault();
            props.addToWishList( product );
        }
    }

    const { authUser } = useSelector(state => state.authUser);
    const [langSelected, setLangSelected] = useState("en");
  
    useEffect(() => {
      if (authUser != null) {
        setLangSelected(authUser.language.toLowerCase());
      }
    }, [authUser])

    const {t} = props;

    return (
        <>
            <div className={ `product-default ${ addClass }` }>
            <figure>
                <Link to={ `${ process.env.PUBLIC_URL }/products/${ product.id }/details` } >
                    <span>
                        <img src={ `${process.env.BACKEND_BASE_URL}${product.mainImagePath}` } className="first-image" alt="product" />
                    </span>
                    {
                        product.mainImagePath ?
                            <span className="product-image-hover">
                                <img src={ `${process.env.BACKEND_BASE_URL}${product.mainImagePath}` } className="last-image" alt="product" />
                            </span> : ""

                    }
                </Link>

                {/* <div className="btn-icon-group">
                    <button className="btn-icon btn-add-cart" data-toggle="modal" data-target="#addCartModal" onClick={ () => addToCart( product, 1 ) } title="Add to Cart">
                        <i className="icon-bag"></i>
                    </button>
                </div> */}
                <Link to={ `${ process.env.PUBLIC_URL }/products/${ product.id }/details` } className="btn-quickview" title="Quick View" >{t("top_banner.go_to_detail")}</Link>
            </figure>

            <div className="product-details">
                <div className="category-wrap">
                    <div className="category-list">
                        <Link to={`${process.env.PUBLIC_URL}/categories/full-width`} className="product-category">{ langSelected == 'fr' ? product.productCategory.fr : product.productCategory.en } </Link>
                        {/* {
                            product.productCategory.map( ( category, index ) => (
                                index === ( product.category.length - 1 ) ?
                                    <Link to={ `${ process.env.PUBLIC_URL }/categories/full-width` } className="product-category" key={ "category" + index }>{ category }</Link>
                                    : <Link to={ `${ process.env.PUBLIC_URL }/categories/full-width` } className="product-category" key={ "category" + index }>{ category }, </Link>
                            ) )
                        } */}
                    </div>
                    {/* <Link to={ `${ process.env.PUBLIC_URL }/pages/wishlist` } className={ `btn-icon-wish ${ isInWishlist ? 'checked' : '' } ${ hasWishIcon ? '' : 'd-none' }` } onClick={ onWishlistClick } title={ `${ isInWishlist ? 'Go to Wishlist' : 'Add to Wishlist' }` }><i className="icon-heart"></i></Link> */}
                </div>

                <h3 className="product-title">
                    <Link to={ `${ process.env.PUBLIC_URL }/products/${ product.id }/details` }>{ product.name }</Link>
                </h3>
                <div className="price-box">
                    <span className="product-price">{product.price.toFixed(2)} {product.currency}</span>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = ( state, props ) => {
    return {
        wishlist: state.wishlist.list ? state.wishlist.list : []
    }
}

export default withTranslation()(connect( mapStateToProps, { addToCart, addToWishList, showQuickView } )( ProductTypeTwo ));

