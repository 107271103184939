import backendURLs from "../utils/backendURL";
import { joinBaseUrlWithParams, makeRequest, replaceIdParamInUrlFormat } from "./utils"

export const getDocumentsOfUser = () => {

    let url = `${backendURLs.DOCS.GET_ALL_DOCS_FOR_USER}`;

    return makeRequest('get', url, null, {});
}

export const getTransactionsOfUser = (id) => {

    let url = replaceIdParamInUrlFormat(`${backendURLs.GET_ALL_TRANSACTIONS}`, id);

    return makeRequest('get', url, null, {});
}


export const markDocumentAsRead = (docId) => {

    let url = replaceIdParamInUrlFormat(`${backendURLs.DOCS.MARK_AS_READ}`, docId);

    return makeRequest('put', url, null, {shouldSkipDataParsing: true});
}


export const getDocumentFromSinRequest = (uuid) => {

    let url = joinBaseUrlWithParams(`${backendURLs.DOCS.GET_ALL_DOC_FROM_SIGN_REQUEST}`, [
        {param: 'uuid', value: uuid}
    ]);

    return makeRequest('get', url, null, {shouldSkipDataParsing: true});
}