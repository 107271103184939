
// FETCH SELLER PRODUCTS
export const FETCH_SELLER_PRODUCTS_BASE = 'FETCH_SELLER_PRODUCTS'
export const FETCH_SELLER_PRODUCTS_SUCCESS = 'FETCH_SELLER_PRODUCTS_SUCCESS'
export const FETCH_SELLER_PRODUCTS_ERROR = 'FETCH_SELLER_PRODUCTS_ERROR'
export const FETCH_SELLER_PRODUCTS_CLEAR = 'FETCH_SELLER_PRODUCTS_CLEAR'


// CREATE SELLER PRODUCT **************************************************
export const CREATE_SELLER_PRODUCT= 'CREATE_SELLER_PRODUCT'
export const CREATE_SELLER_PRODUCT_SUCCESS = 'CREATE_SELLER_PRODUCT_SUCCESS'
export const CREATE_SELLER_PRODUCT_ERROR = 'CREATE_SELLER_PRODUCT_ERROR'
export const CREATE_SELLER_PRODUCT_CLEAR = 'CREATE_SELLER_PRODUCT_CLEAR'


// UPDATE SELLER PRODUCT **************************************************
export const UPDATE_SELLER_PRODUCT= 'UPDATE_SELLER_PRODUCT'
export const UPDATE_SELLER_PRODUCT_SUCCESS = 'UPDATE_SELLER_PRODUCT_SUCCESS'
export const UPDATE_SELLER_PRODUCT_ERROR = 'UPDATE_SELLER_PRODUCT_ERROR'
export const UPDATE_SELLER_PRODUCT_CLEAR = 'UPDATE_SELLER_PRODUCT_CLEAR'


// DELETE SELLER PRODUCT **************************************************
export const DELETE_SELLER_PRODUCT= 'DELETE_SELLER_PRODUCT'
export const DELETE_SELLER_PRODUCT_SUCCESS = 'DELETE_SELLER_PRODUCT_SUCCESS'
export const DELETE_SELLER_PRODUCT_ERROR = 'DELETE_SELLER_PRODUCT_ERROR'
export const DELETE_SELLER_PRODUCT_CLEAR = 'DELETE_SELLER_PRODUCT_CLEAR'
