import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import Breadcrumb from '../../common/breadcrumb';
import React, { useEffect, useState } from 'react';
import BlogSingle from '../../features/blog/blog-single';
import SidebarToggle from '../products/common/sidebars/sidebar-toggle';
import { stickyContentHandle, setStickyValues } from '../../../utils';
import { getOneArticle, getAllCategories } from '../../../action/blogActions';
import { NOT_FOUND } from '@routes/urls';

function Single(props) {
    const [categories, setCategories] = useState([]);
    const [article, setArticle] = useState(null);

    useEffect(() => {
        setStickyValues(120);
        window.addEventListener('scroll', stickyContentHandle, { passive: true });

        return () => {
            window.removeEventListener('scroll', stickyContentHandle);
        }
    })

    useEffect(() => {
        getCategories();
        getArticle();
    }, [])


    const getCategories = () => {
        getAllCategories('BLOG').then(data => {
            setCategories(data);
        }).catch(err => {
        });
    };


    const getArticle = () => {
        console.log(props.match.params.id)
        getOneArticle(props.match.params.id).then(data => {
            setArticle(data);
        }).catch(err => {
            window.location = NOT_FOUND;
        });
    };

    return (
        <>
            <Helmet>
                <title>Sooko - Blog Page </title>
            </Helmet>

            <h1 className="d-none">Sooko - Blog Page</h1>

            <div className="main">
                <Breadcrumb current="Blog Post" parent="pages" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            {
                                article ?
                                    <BlogSingle blog={article} /> : null
                            }
                        </div>
                        <SidebarToggle />
                        <aside className="sidebar col-lg-3 mobile-sidebar">
                            <StickyBox className="sidebar-wrapper sticky-sidebar">
                                <div className="widget widget-search">
                                    <form role="search" method="get" className="search-form" action="#">
                                        <input type="search" className="form-control" placeholder="Search posts here..." name="s" required />
                                        <button type="submit" className="search-submit" title="Search">
                                            <i className="icon-search"></i>
                                            <span className="sr-only">Search</span>
                                        </button>
                                    </form>
                                </div>

                                <div className="widget widget-categories">
                                    <h4 className="widget-title">All Categories</h4>

                                    <ul className="list">
                                        {
                                            categories.length <= 0 ?
                                                <li>Nothing to show</li> :
                                                categories.map(category => (
                                                    <li><Link to="#">{category.name}</Link></li>
                                                ))
                                        }
                                    </ul>
                                </div>
                            </StickyBox>
                        </aside>
                    </div>
                </div>
                <div className="mb-6"></div>
            </div>
        </>
    )
}

export default Single;