import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import React, { useEffect, useState } from "react";
import classes from "../productCreate.module.scss";

function ProductCreateStep1(props) {
  const { setDataStep1, productsCatConfig, defaultDataStep1, productToUpdate } = props;

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: { ...defaultDataStep1 }
  });

  const { authUser } = useSelector(state => state.authUser);
  const [langSelected, setLangSelected] = useState("en");
  const [isCountryError, setIsCountryError] = useState(false);

  useEffect(() => {
    if (authUser != null) {
      setLangSelected(authUser.language.toLowerCase());
    }
  }, [authUser])


  const [countrySelected, setCountrySelected] = useState(productToUpdate ? productToUpdate.countryOfOrigin : '');


  const onSubmitForm = (data) => {
    if(!countrySelected) {
      setIsCountryError(true);
      return;
    }
    setDataStep1({ ...data, country: countrySelected });
  };

  const { t } = props;
  
  return (
    <div className="main">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 order-lg-first">
            <div className="checkout-payment">

              <div id="new-checkout-address" className="show">
                <form name="productForm mt-2" className={classes.productForm}>
                  <div className="showStepOfStepper">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row mb-2">
                          <div className="required-field col-md-6">
                            <label> {t("products.product_category")} </label>
                            <select
                              required
                              className="form-control"
                              name="productCategoryId"
                              ref={register({ required: true })}
                            >
                              <option value=""> </option>
                              {productsCatConfig.sort((a, b) => (a.isUnknown === b.isUnknown) ? 0 : a.isUnknown ? -1 : 1).map((item, __) => (
                                <option key={item.id} value={Number(item.id)}>
                                  { langSelected == 'fr' ? item.fr : item.en }
                                </option>
                              ))}
                            </select>
                            {errors.productCategoryId && <div className="text-danger mt-1">{t("form.categoryRequired")}</div>}
                          </div>

                          <div className="required-field col-md-6">
                            <label> {t("products.product_name")} </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              ref={register({ required: true })}
                              required
                            />
                            {errors.name && <div className="text-danger mt-1">{t("form.nameRequired")}</div>}
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="form-group required-field  col-md-6">
                            <label> {t("products.product_country")} </label>
                            <ReactFlagsSelect
                              searchable
                              placeholder={t("form.selectCountry")}
                              selected={countrySelected}
                              onSelect={code => {
                                setIsCountryError(false);
                                setCountrySelected(code);
                              }} 
                              className="react-flag-select" 
                            />
                            {isCountryError && <div className="text-danger mt-1">{t("form.countryRequired")}</div>}
                          </div>

                          <div className="required-field col-sm-6">
                            <label> {t("products.product_type")} </label>
                            <select
                              className="form-control"
                              name="type"
                              ref={register({ required: true })}
                              required
                            >
                              <option value=""> </option>
                              <option value={"RAW_MATERIAL"}>{t("notes.raw")}</option>
                              <option value={"FINISHED_GOOD"}>{t("notes.finish")}</option>
                            </select>
                            {errors.type && <div className="text-danger mt-1">{t("form.typeRequired")}</div>}
                          </div>
                        </div>

                        <div className="row">
                          <div className="required-field col-md-12">
                            <label> {t("products.product_quality")} </label>
                            <textarea
                              cols="5"
                              className="form-control"
                              name="quality"
                              ref={register({ required: true })}
                              required
                              spellcheck='false'
                            ></textarea>
                            {errors.quality && <div className="text-danger mt-1">{t("form.specificationRequired")}</div>}
                          </div>
                        </div>

                        <div className="row">
                          <div className="required-field col-md-12">
                            <label> {t("products.product_description")} </label>
                            <textarea
                              cols="5"
                              className="form-control"
                              name="description"
                              spellcheck='false'
                              ref={register({ required: true })}
                              required
                            ></textarea>
                            {errors.description && <div className="text-danger mt-1">{t("form.descriptionRequired")}</div>}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>

                <div className="col-md-12">
                  <button
                    onClick={handleSubmit(onSubmitForm)}
                    className="btn btn-primary float-right px-5"
                  >
                    {t("products.next")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6"></div>
    </div>
  );
}

export default withTranslation()(React.memo(ProductCreateStep1));