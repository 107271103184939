import './style.css'
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import 'react-phone-input-2/lib/bootstrap.css'
import "react-phone-input-2/lib/bootstrap.css";
import { withTranslation } from "react-i18next";
import Breadcrumb from '../../common/breadcrumb';
import SuccessSignUpModal from './successSignUpModal';
import "file-upload-with-preview/dist/file-upload-with-preview.min.css";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { signInUser, signUpUser, googleSignIn, facebookSignIn, linkedinSignIn, checkUserEmail } from "@action/auth";

const emailValidation = (email) => {
  if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return null;
  }
  if (email.trim() === "") {
    return "Email is required";
  }
  return "Please enter a valid email";
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",

      fullName: "",
      phone: "",
      email: "",
      lang: "En",
      hasReferral: new URLSearchParams(this.props.location.search).get("referral") != null,
      passwordRegistration: "",
      referralCode: new URLSearchParams(this.props.location.search).get("referral"),
      passwordConfirm: "",

      touchOneRegisterField: false,
      tryToSignUp: false,
      accept: false,

      errorLoginDialogClosed: false,
      errorRegisterDialogClosed: false,

      showLoginPassword: false,
      showRegistrationPassword: false,

      showModalRegistrationSucess: false,

      upload: null
    };

    this.inputPasswordLoginRef = React.createRef();
    this.inputPasswordRgistrationRef = React.createRef();
    this.inputPasswordConfirmationRef = React.createRef();
  }


  onSubmitLogin = ($event) => {
    $event.preventDefault();
    const data = {
      username: this.state.username,
      password: this.state.password,
    };
    this.props.signInUser(data, this.props.history, this.props.t('common.login_success'), this.props.t('common.email_not_verified'));
  };

  setShowLoginPassword = (value) => {
    this.setState({ showLoginPassword: value }, () => {
      if (value == true)
        this.inputPasswordLoginRef.current.type = 'text'
      else
        this.inputPasswordLoginRef.current.type = 'password'
    })
  }

  setShowRegistrationPassword = (value) => {
    this.setState({ showRegistrationPassword: value }, () => {
      if (value == true) {
        this.inputPasswordRgistrationRef.current.type = 'text';
        this.inputPasswordConfirmationRef.current.type = 'text';
      } else {
        this.inputPasswordRgistrationRef.current.type = 'password';
        this.inputPasswordConfirmationRef.current.type = 'password';
      }
    })
  }

  validateRegister = () => {

    return !emailValidation(this.state.email) && this.validatePasswordAndConfirmedPassword()
  }

  setShowModalRegistrationSucess = (value) => {
    this.setState({ showModalRegistrationSucess: value });
  }

  _onGoogleSignIn = (data) => {
    this.props.googleSignIn(data.tokenObj.id_token, this.props.history);
  }

  _onFaceBookSignIn = (data) => {
    console.log('_onFaceBookSignIn', data);
    this.props.facebookSignIn(data.accessToken, this.props.history);
  }

  _onLinkedinSignIn = (data) => {
    console.log('_onLinkedinSignIn', data);
    //this.props.linkedinSignIn(data.accessToken, this.props.history);
  }

  onSubmitRegister = (type) => {
    //$event.preventDefault();

    this.setState({ tryToSignUp: true, errorRegisterDialogClosed: false }, () => {

      const data = {
        name: this.state.fullName,
        //phone: this.state.phone,
        email: this.state.email,
        language: this.state.lang,
        password: this.state.passwordRegistration,
        userType: type,
      };

      if(this.state.hasReferral && this.state.referralCode) {
        data.referralCode = this.state.referralCode;
      }

      if (!this.validateRegister())
        return

      if (!this.validatePasswordRegex())
      return

      this.props.signUpUser(data).then((result) => {

        this.setState({ showModalRegistrationSucess: true });
      }).catch((err) => {

      });
    });
  };

  onBlurRegisterField = () => {
    if (!this.state.touchOneRegisterField) {
      this.setState({ touchOneRegisterField: true });
    }
  }

  validatePasswordAndConfirmedPassword = () => {
     return this.state.passwordRegistration.toLocaleLowerCase().trim() ===
      this.state.passwordConfirm.toLocaleLowerCase().trim()

  };


  validatePasswordRegex = () =>{
    const decimal =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/;
    return this.state.passwordRegistration.match(decimal)
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token')
    if (token) {
      checkUserEmail(token);
    }
  }

  onInputHandler = ($event) => {
    this.setState({ [$event.target.name]: $event.target.value });
  };

  onChangePhoneHandler = (val) => {
    this.setState({ phone: val });
  }

  testAuth = () => {
    this.setState({ tryToSignUp: false, errorLoginDialogClosed: false });
    const data = {
      username: this.state.username,
      password: this.state.password,
    }
    this.props.signInUser(data, this.props.history, this.props.t('common.login_success'), this.props.t('common.email_not_verified'));
  };

  handleFailureLinkedin = () => {

  }

  render() {
    const { t, accountCreated } = this.props;
    //const countryWithNumberAndFlag = CountryManager.countryWithNumberAndFlag();

    let signInError = null;
    let signUpError = null;

    let errorPasswordMatch = null;
    let errorPasswordRegex = null;
    let accountCreatedSuccessfully = null;

    if (this.props.error && !this.state.errorLoginDialogClosed) {
      signInError = (
        <div className="alert alert-danger">
          <strong>Sign error: </strong> {t("login.signin_error")}
          <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => this.setState({ errorLoginDialogClosed: true })}>×</button>
        </div>
      );
    }

    if (this.props.errorSignUp && !this.state.errorRegisterDialogClosed) {
      signUpError = (
        <div className="alert alert-danger">
          <strong>SignUp error: </strong> {t("login.signup_error")}
          <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => this.setState({ errorRegisterDialogClosed: true })}>×</button>
        </div>
      );
    }


    if (!this.validatePasswordRegex()) {
      errorPasswordRegex= (
        <div className="alert alert-danger">
          <strong>Password format error: </strong> {t("login.confirm_error")}
        </div>
      );
    }

    if (!this.validatePasswordAndConfirmedPassword()) {
      errorPasswordMatch = (
        <div className="alert alert-danger">
          <strong>Password  match error: </strong> {t("login.password_error")}
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <title>Sooko - Login Page </title>
        </Helmet>

        <h1 className="d-none">Sooko - Login Page</h1>

        <div className="main" style={{ marginTop: "1%" }}>

          <Breadcrumb current={t("login.login_title")} />

          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="heading">
                  <h2 className="title">{t("login.title")}</h2>
                  <p>{t("login.message")}</p>
                </div>

                {signInError}

                <div style={{

                  marginBottom: '4%',
                  height: 'auto',
                  width: '100%'
                }}>
                  <GoogleLogin
                    clientId="631785752296-26dcjnpcnjma16s630fcvhivhi8qsdg6.apps.googleusercontent.com"
                    buttonText="Sign in with Google"
                    render={renderProps => (
                      <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="loginBox__btn" style={{ height: 'auto' }}><img src={`${process.env.PUBLIC_URL}/assets/images/demo/google.png`} alt="" /><span>{t("login.sign_in_with_google")}</span></button>
                    )}
                    onSuccess={(data) => this._onGoogleSignIn(data)}
                    onFailure={(error) => console.log('ERROR GOOGLE LOGIN ==> ', error)} />

                  <FacebookLogin
                    appId="420243212577079"
                    fields="name,email,picture"
                    callback={this._onFaceBookSignIn}
                    onFailure={(error) => console.log('ERROR FACEBOOK LOGIN ==> ', error)}
                    render={renderProps => (
                      <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="loginBox__btn" style={{ height: 'auto' }}><img src={`${process.env.PUBLIC_URL}/assets/images/demo/facebook.png`} alt="" /><span>{t("login.sign_in_with_facebook")}</span></button>
                    )} />

                </div>

                <form>
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t("login.email_address")}
                    name="username"
                    value={this.state.username}
                    onChange={this.onInputHandler}
                  />
                  <div className="password-content">
                    <input
                      type="password"
                      className="form-control"
                      placeholder={t("login.password")}
                      name="password"
                      ref={this.inputPasswordLoginRef}
                      value={this.state.password}
                      onChange={this.onInputHandler}
                    />
                    {this.state.showLoginPassword ? <i className="far fa-eye" onClick={() => this.setShowLoginPassword(false)}></i>
                      : <i className="far fa-eye-slash" onClick={() => this.setShowLoginPassword(true)}></i>}
                  </div>

                  <div className="form-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.testAuth}
                      disabled={this.props.loading}
                      style={{ borderRadius: '5px' }}
                    >
                      {this.props.loading ? <span><i className="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> : null}
                      {t("login.login_title")}
                    </button>
                    <Link
                      to={`${process.env.PUBLIC_URL}/forgot-password`}
                      className="forget-pass"
                    >
                      {" "}
                      {t("login.forgot_password")}
                    </Link>
                  </div>
                </form>
              </div>

              <div className="col-md-6">
                <div className="heading">
                  <h2 className="title"> {t("login.registration_title")}</h2>
                  <p>
                    {t("login.registration_message")}
                  </p>
                </div>

                {signUpError}
                {accountCreatedSuccessfully}
                <SuccessSignUpModal shouldShowSuccessModal={this.state.showModalRegistrationSucess}
                  setShowModalRegistrationSucess={this.setShowModalRegistrationSucess} />

                <form>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("login.name")}
                    name="fullName"
                    required
                    value={this.state.fullName}
                    onChange={this.onInputHandler}
                    onBlur={this.onBlurRegisterField}
                  />
                  <input
                    type="email"
                    autoComplete="false"
                    className="form-control"
                    placeholder={t("login.email_address")}
                    name="email"
                    required
                    value={this.state.email}
                    onChange={this.onInputHandler}
                    onBlur={this.onBlurRegisterField}
                  />
                  {this.state.tryToSignUp && emailValidation(this.state.email) != null ? <div className="text-danger" style={{ marginBottom: "10px" }}>{emailValidation(this.state.email)}</div> : null}
                  <div className="row" style={{ marginBottom: "2.5%" }}>
                    <div className="col-md-4">
                      <label
                        className="form-check-label"
                        style={{ fontWeight: "lighter" }}
                      >
                        {t("login.french")}
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="lang"
                        style={{ marginLeft: "5%" }}
                        value="Fr"
                        onClick={(e) => this.setState({ lang: e.target.value })}
                      />
                    </div>
                    <div className="col-md-4">
                      <label
                        className="form-check-label"
                        style={{ fontWeight: "lighter" }}
                      >
                        {t("login.english")}
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="lang"
                        defaultChecked={true}
                        style={{ marginLeft: "5%" }}
                        value="En"
                        onClick={(e) => this.setState({ lang: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className="password-content">
                    <input
                      type="password"
                      autoComplete="false"
                      className="form-control"
                      name="passwordRegistration"
                      placeholder={t("login.password")}
                      required
                      value={this.state.passwordRegistration}
                      onChange={this.onInputHandler}
                      onBlur={this.onBlurRegisterField}
                      ref={this.inputPasswordRgistrationRef}
                    />
                    {this.state.showRegistrationPassword ? <i className="far fa-eye" onClick={() => this.setShowRegistrationPassword(false)}></i>
                      : <i className="far fa-eye-slash" onClick={() => this.setShowRegistrationPassword(true)}></i>}
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    name="passwordConfirm"
                    placeholder={t("login.confirm")}
                    required
                    value={this.state.passwordConfirm}
                    onChange={this.onInputHandler}
                    onBlur={this.onBlurRegisterField}
                    ref={this.inputPasswordConfirmationRef}
                  />

                  <div style={{ marginBottom: "2.5%" }}>
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="referral-checkbox" checked={this.state.hasReferral} onChange={(e) => this.setState({ hasReferral: e.target.checked })}/>
                      <label className="custom-control-label" htmlFor="referral-checkbox" style={{ fontWeight: 'normal' }}>{t("board.got_referral_code")}</label>
                    </div>
                    { this.state.hasReferral && (
                      <input
                        type="text"
                        className="form-control"
                        name="referralCode"
                        placeholder={t("board.referral_code")}
                        value={this.state.referralCode}
                        onChange={this.onInputHandler}
                        onBlur={this.onBlurRegisterField}
                      />
                    )}
                  </div>

                  {this.state.tryToSignUp && errorPasswordMatch}
                  
                  {this.state.tryToSignUp && errorPasswordRegex}

                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="change-pass-checkbox" value="1" onChange={(e) => this.setState({ accept: e.target.checked })}/>
                    <label className="custom-control-label" htmlFor="change-pass-checkbox" style={{ fontWeight: 'normal' }}>{t("login.term1")} <Link to={ `${ process.env.PUBLIC_URL }/terms-and-conditions` }>{t("login.term4")}</Link> {t("login.term2")} <Link to={ `${ process.env.PUBLIC_URL }/privacy-policy` }>{t("login.term5")}</Link> {t("login.term3")}</label>
                  </div>

                  <div className="row" style={{ marginTop: "4%" }}>
                    <div
                      className="col-xl-6 col-lg-12 col-md-12 col-sm-6"
                      style={{ marginBottom: "2.5%", width: "100%" }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#00c805", color: "white", borderRadius: '5px' }}
                        onClick={() => this.onSubmitRegister("BUYER")}
                        disabled={this.props.loadingSignUp || !this.state.accept}
                      >
                        {this.props.loadingSignUp ? <span><i class="fa fa-spinner fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> : null}
                        {t("login.buyer_registration")}
                      </button>
                    </div>
                    <div
                      className="col-xl-6 col-lg-12 col-md-12 col-sm-6"
                      style={{ marginBottom: "2.5%", width: "100%" }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#00c805", color: "white", borderRadius: '5px' }}
                        onClick={() => this.onSubmitRegister("SELLER")}
                        disabled={this.props.loadingSignUp || !this.state.accept}
                      >
                        {this.props.loadingSignUp ? <span><i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> : null}
                        {t("login.seller_account")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ authUser, signUpUser }) => ({
  isAuth: authUser.isAuth,
  error: authUser.error,
  loading: authUser.loading,
  loadingSignUp: signUpUser.loading,
  errorSignUp: signUpUser.error,
  accountCreated: signUpUser.accountCreated,
});

export default withTranslation()(
  connect(mapStateToProps, { signInUser, signUpUser, googleSignIn, facebookSignIn, linkedinSignIn })(Login)
);
