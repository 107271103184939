import './header.css';
import { isIEBrowser } from '@utils';
import { Link } from 'react-router-dom';
import Marquee from "react-marquee-slider";
import MainMenu from './partials/main-menu';
import { getSettings } from '@action/contract';
import { useTranslation } from 'react-i18next';
import { withTranslation } from "react-i18next";
import SearchForm from './partials/search-form';
import React, { useEffect, useState } from 'react';
import LoginModal from '../features/modal/login-modal';
import { useDispatch, useSelector } from "react-redux";
import { getCommodityPrices } from '@action/pagesContents-actions';
import { logout, updateUserLanguage, setAuthUser } from '@action/auth';
import { LOGIN } from '@routes/urls';

function Header(props) {

    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [langSelected, setLangSelected] = useState("en");
    const { isAuth } = useSelector(state => state.authUser);
    const { authUser } = useSelector(state => state.authUser);
    const commodityPrices = useSelector(state => state.commodityPrices);

    useEffect(() => {
        dispatch(getCommodityPrices());
    }, [])

    function handleClick(e) {
        e.preventDefault();
        document.querySelector("body").classList.toggle("mmenu-active");
        e.currentTarget.classList.toggle("active");
        if (isIEBrowser() && document.querySelector("body").classList.contains("mmenu-active")) {
            document.querySelector(".mmenu-active .mobile-menu-container").style.transform = "translateX(0)";
        }
    }

    useEffect(() => {
        if (authUser != null) {
            i18n.changeLanguage(authUser.language.toLowerCase());
            dispatch({ type: 'CHANGE_LANGUAGE', payload: authUser.language.toLowerCase() });
            setLangSelected(authUser.language.toLowerCase());
        }
    }, [authUser])

    useEffect(() => {
        getSettings().then((result) => {
            setData(result);
        }).catch((err) => {
            // toast(`Error to get fees app: ${err.message}`, { className: "toast-error" });
        });
    }, [])

    const changeLang = (newlang) => {
        i18n.changeLanguage(newlang);
        dispatch({ type: 'CHANGE_LANGUAGE', payload: newlang });
        setLangSelected(newlang);
        if (authUser != null)
            updateUserLanguage(newlang.charAt(0).toUpperCase() + newlang.slice(1).toLowerCase(), authUser.id).then(data => {
                dispatch(setAuthUser())
            })
    }

    const { t } = props;

    return (
        <header className="header" style={{ marginBottom: "4%" }}>
            <div className="header-top">
                <div className="container">
                    <div className="header-left header-dropdowns">
                        <div className="header-dropdown ">
                            {langSelected === "en" ? <Link to="#" className="pl-0"><img src={`${process.env.PUBLIC_URL}/assets/images/flags/en.png`} alt="England flag" />ENG</Link> : null}
                            {langSelected === "fr" ? <Link to="#" className="pl-0"><img src={`${process.env.PUBLIC_URL}/assets/images/flags/fr.png`} alt="England flag" />FR</Link> : null}
                            <div className="header-menu">
                                <ul>
                                    <li onClick={() => { changeLang('en') }} style={{ cursor: "pointer" }}><img src={`${process.env.PUBLIC_URL}/assets/images/flags/en.png`} alt="England flag" />ENG</li>
                                    <li onClick={() => { changeLang('fr') }} style={{ cursor: "pointer" }}><img src={`${process.env.PUBLIC_URL}/assets/images/flags/fr.png`} alt="France flag" />FRA</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="header-right">
                        <p className="top-message text-uppercase d-none d-sm-block mr-4">{t("header.title")}</p>

                        <span className="separator"></span>

                        <div className="header-dropdown dropdown-expanded mx-2 px-1">
                            <Link to="#">Links</Link>
                            <div className="header-menu">
                                <ul>
                                    <li className="menu-item">
                                        {!isAuth ? <Link className="login" to={LOGIN}>{t("header.log_in")}</Link> : null}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <span className="separator"></span>

                        <div className="social-icons">
                            <Link to="#" className="social-icon social-facebook icon-facebook" target="_blank"></Link>
                            <Link to="#" className="social-icon social-twitter icon-twitter ml-2" target="_blank"></Link>
                            <Link to="#" className="social-icon social-instagram icon-instagram ml-2" target="_blank"></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-middle">
                <div className="container">
                    <div className="header-left w-lg-max ml-auto ml-lg-0">
                        <SearchForm addClass="header-icon header-search-inline header-search-category" iconClass="icon-search-3" />
                    </div>

                    <div className="header-center order-first order-lg-0 ml-0 ml-lg-auto">
                        <button className="mobile-menu-toggler" type="button" onClick={handleClick}>
                            <i className="icon-menu"></i>
                        </button>
                        <Link to={`${process.env.PUBLIC_URL}/`} className="logo">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/demo/Sooko-Logo-PNG.png`} alt="Porto Logo" />
                        </Link>
                    </div>

                    <div className="header-right w-lg-max ml-0 ml-lg-auto">
                        <div className="header-contact d-none d-lg-flex align-items-center ml-auto pr-xl-4 mr-4">
                            <i className="icon-phone-2"></i>
                            <h6 className="pt-1 line-height-1 pr-2">{t("header.call_us_now")}<Link to="#" className="d-block text-dark pt-1 font1">{data.length > 0 ? data.find(d => d.key == 'PHONE')[i18n.language == 'en' ? 'valueEn' : 'valueFr'] : ''}</Link></h6>
                        </div>
                        {isAuth ?
                            <nav className={` ${''}`}>
                                <ul className={`menu sf-arrows ${''}`}>
                                    <li>

                                        {authUser ?
                                            <Link to={`${process.env.PUBLIC_URL}/account/dashboard/board`} className="header-icon pl-1 d-flex">
                                                <img src={authUser && authUser.avatar.startsWith('https://encryp') ? authUser.avatar : `${process.env.BACKEND_BASE_URL}${authUser.avatar}`} className="img-user-connected" />
                                                <span style={{ paddingLeft: 5, paddingTop: 5 }}>
                                                    <p>{authUser.name}</p>
                                                    <p>{authUser.userType}</p>
                                                </span>
                                            </Link> : <Link to="#" className="header-icon pl-1"><i className="icon-user-2"></i></Link>}

                                        <ul>
                                            <li><Link to={`${process.env.PUBLIC_URL}/account/dashboard/board`}>{t("dashboard.side_bar_tilte")}</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/account/dashboard/rfqs`}>{t("dashboard.rfq")}</Link></li>
                                            <li><button style={{ paddingLeft: "20px" }} className="btn btn-link text-danger text-left" onClick={() => dispatch(logout())}>{t("dashboard.logout")}</button></li>
                                        </ul>
                                    </li></ul></nav> : null}

                        {/* <Link to={ `${ process.env.PUBLIC_URL }/pages/wishlist` } className="header-icon pl-1"><i className="icon-wishlist-2"></i></Link> */}

                        {/* <CartMenu btnClass="btn-dark" /> */}
                    </div>
                </div>
            </div>
            <div className="header-bottom sticky-header" style={{ background: '#00c805' }}>
                <div className="container">
                    <Link to={`${process.env.PUBLIC_URL}/`} className="logo logo-right">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/demo/logo.png`} alt="Porto-Logo" />
                    </Link>
                    {/* <CartMenu addClass="d-none" btnClass="btn-dark"/> */}
                    <MainMenu active={0} btnAClass="p-0" addClass="d-lg-flex w-lg-max justify-content-center" subClass="sf-js-enabled" />
                    <SearchForm searchId="stickySearch" addClass="header-icon header-search header-search-category ml-auto d-none" iconClass="icon-search-3" />
                    <LoginModal addClass="header-icon pl-1 d-none" is_icon="true" />

                </div>
            </div>
            {commodityPrices.data && (
                <Marquee key={1} velocity={2} scatterRandomly={false} minScale={0.7}>
                    {
                        commodityPrices.data.map((cp, index) => (
                            <div style={{ marginLeft: 40 }} key={`commodity-${index}`}>
                                <div className='marquee-item'>
                                    <p className='marquee-name'>{cp.name}</p>
                                    <p className='marquee-price'>${cp.close}</p>
                                </div>
                                <p className='marquee-date'>{cp.date}</p>
                            </div>
                        ))
                    }
                </Marquee>
            )}
        </header>
    )
}

export default withTranslation()(Header);