
export const FETCH_MESSAGES_WITH_USER = 'FETCH_MESSAGES_WITH_USER';
export const FETCH_MESSAGES_WITH_USER_SUCCESS = 'FETCH_MESSAGES_WITH_USER_SUCCESS';
export const FETCH_MESSAGES_WITH_USER_ERROR = 'FETCH_MESSAGES_WITH_USER_ERROR';
export const FETCH_MESSAGES_WITH_USER_CLEAR = 'FETCH_MESSAGES_WITH_USER_CLEAR';


export const APPEND_NEW_MESSAGES = 'APPEND_NEW_MESSAGES';
export const APPEND_NEW_MESSAGES_SUCCESS = 'APPEND_NEW_MESSAGES_SUCCESS';
export const APPEND_NEW_MESSAGES_ERROR = 'APPEND_NEW_MESSAGES_ERROR';
export const APPEND_NEW_MESSAGES_CLEAR = 'APPEND_NEW_MESSAGES_CLEAR';
