import backendURLs from "../utils/backendURL";
import { makeRequest, replaceIdParamInUrlFormat } from "./utils"

export const getAllArticles = () => {
    return makeRequest('get', backendURLs.BLOG.GET_ALL_ARTICLES, null, {});
}

export const getOneArticle = (id) => {
    let url = replaceIdParamInUrlFormat(backendURLs.BLOG.GET_ONE_ARTICLE, id)
    return makeRequest('get', url, null, {});
}

export const getAllArticlesByCategory = (category_id) => {
    const url = replaceIdParamInUrlFormat(`${backendURLs.BLOG.GET_ALL_ARTICLES_BY_CATEGORY}`, category_id);
    return makeRequest('get', url, null, {});
}

export const getAllCategories = (type) => {
    return makeRequest('get', `${backendURLs.BLOG.GET_ALL_CATEGORY}?type=${type}`, null, {});
}

export const getAllArticleComments = (article_id) => {
    const url = replaceIdParamInUrlFormat(`${backendURLs.BLOG.GET_ALL_COMMENTS}`, article_id);
    return makeRequest('get', url, null, {});
}

export const postComment = (article_id, data) => {
    const url = replaceIdParamInUrlFormat(`${backendURLs.BLOG.CREATE_COMMENT}`, article_id);
    return makeRequest('post', url, data, {});
}