import axios from '../api';
import { toast } from 'react-toastify';
import backendURLs from '../utils/backendURL';
import { saveAuthToken } from "../helpers/tokens";
import * as authActionsTypes from '../constants/auth-action-types';
import { getFullAuthorisationRequestConfig } from '../helpers/helpers';
import { makeActionRequest, makeRequest, replaceIdParamInUrlFormat, joinBaseUrlWithParams, joinBaseUrlWithRequestParams } from './utils';

export const signInUser = (dataRequest, history, successMessage = "", errorMessage= "") => (dispatch) => {

    dispatch({ type: authActionsTypes.SIGNIN_USER_BASE });

    const url = `${backendURLs.AUTH.SIGNIN}`;

    const config = getFullAuthorisationRequestConfig();

    const _data = { ...dataRequest };
    _data['grantType'] = process.env.OAUTH.grandType;
    //_data['clientId'] =  process.env.OAUTH.clientId;

    let data = new FormData();
    Object.keys(_data).forEach(key => data.append(key, _data[key]));
    //formData.append('client_id', _data['clientId']);
    //formData.append('scope', 'read');

    return axios
        .post(url, _data, config)
        .then((response) => {
            const token = response.data;
            const access = response.data;

            localStorage.setItem("access", JSON.stringify(access));

            const data = {
                accessToken: token.accessToken,
                tokenType: token.tokenType,
                expiresIn: token.expiresIn,
                refreshToken: token.refreshToken,
            };

            // Persist data into localstorage
            saveAuthToken(data.accessToken, data.tokenType, data.expiresIn, data.refreshToken);

            // Fetch user data
            
            dispatch({ type: authActionsTypes.SIGNIN_USER_SUCCESS, payload: data });
            dispatch(setAuthUser(errorMessage, () => {
                toast(successMessage, { className: "toast-success" });
                history.push('/account/dashboard/board');
            }));
            return Promise.resolve();
        })
        .catch((error) => {
            dispatch({ type: authActionsTypes.SIGNIN_USER_ERROR, payload: error });
            //errorDisplay(error);
            return Promise.reject();
        });
}

export const setAuthUser = (errorMessage = "", callback = null) => (dispatch) => {

    const url = `${backendURLs.AUTH.ME}`;

    dispatch({ type: authActionsTypes.FETCH_AHTH_USER_BASE, payload: null });

    axios.get(url, { shouldSkipDataParsing: true }).then(response => {
        if (response.data.emailVerified) {
            dispatch({ type: authActionsTypes.FETCH_AHTH_USER_SUCCESS, payload: response.data });
            if (callback)
                callback();
            return Promise.resolve();
        } else {
            toast(errorMessage, { className: "toast-error" });
            return Promise.resolve();
        }
    }).catch(error => {
        dispatch({ type: authActionsTypes.FETCH_AHTH_USER_ERROR, payload: error });
        return Promise.reject();
    })
}



export const signUpUser = (data) => (dispatch) => {

    dispatch({ type: authActionsTypes.SIGNUP_USER_BASE });
    const headers = { 'Content-Type': 'application/json' };

    const url = `${backendURLs.AUTH.SIGNUP}`;

    return new Promise((resolve, reject) => {

        axios.post(url, data, { headers, shouldSkipDataParsing: true }).then(response => {

            dispatch({ type: authActionsTypes.SIGNUP_USER_SUCCESS, payload: response.data });
            resolve(response);

        }).catch(error => {

            dispatch({ type: authActionsTypes.SIGNUP_USER_ERROR, payload: error });
            reject(error);
        })
    });

}



export const updateUserInfo = (data, userId, callback = null) => (dispatch) => {

    const url = replaceIdParamInUrlFormat(backendURLs.AUTH.USER.UPADATE.INFORMATIONS, userId);
    makeActionRequest('put', url, data, dispatch, authActionsTypes.UPDATE_INFORMATIONS_USER_BASE, {
        shouldFetchAuthUserAfterSuccess: true,
        shouldClearReducerState: true,
        callback
    });
}

export const googleSignIn = (id_token, history) => (dispatch) => {
    let data = { "id_token": id_token }
    return axios.post(backendURLs.AUTH.GOOGLE, data).then(response => {
        const token = response.data;
        const access = response.data;

        localStorage.setItem("access", JSON.stringify(access));

        const data = {
            accessToken: token.accessToken,
            tokenType: token.tokenType,
            expiresIn: token.expiresIn,
            refreshToken: token.refreshToken,
        };

        // Persist data into localstorage
        saveAuthToken(data.accessToken, data.tokenType, data.expiresIn, data.refreshToken);

        // Fetch user data
        dispatch(setAuthUser());

        // Persist data into store
        dispatch({ type: authActionsTypes.SIGNIN_USER_SUCCESS, payload: data });
        toast("Welcome, You have Logged in!", { className: "toast-success" });

        history.push('/account/dashboard/board');
        // NotificationManager.success('User Login Successfully!');
        return Promise.resolve();
    })
        .catch((error) => {
            dispatch({ type: authActionsTypes.SIGNIN_USER_ERROR, payload: error });
            //errorDisplay(error);
            return Promise.reject();
        });
}


export const facebookSignIn = (accessToken, history) => (dispatch) => {
    let data = { "access_token": accessToken }
    return axios.post(backendURLs.AUTH.FACEBOOK, data).then(response => {
        const token = response.data;
        const access = response.data;

        localStorage.setItem("access", JSON.stringify(access));

        const data = {
            accessToken: token.accessToken,
            tokenType: token.tokenType,
            expiresIn: token.expiresIn,
            refreshToken: token.refreshToken,
        };

        // Persist data into localstorage
        saveAuthToken(data.accessToken, data.tokenType, data.expiresIn, data.refreshToken);

        // Fetch user data
        dispatch(setAuthUser());

        // Persist data into store
        dispatch({ type: authActionsTypes.SIGNIN_USER_SUCCESS, payload: data });
        toast("Welcome, You have Logged in!", { className: "toast-success" });

        history.push('/account/dashboard/board');
        // NotificationManager.success('User Login Successfully!');
        return Promise.resolve();
    })
        .catch((error) => {
            dispatch({ type: authActionsTypes.SIGNIN_USER_ERROR, payload: error });
            //errorDisplay(error);
            return Promise.reject();
        });
}


export const linkedinSignIn = (accessToken, history) => (dispatch) => {
    let data = { "access_token": accessToken }
    return axios.post(backendURLs.AUTH.LINKEDIN, data).then(response => {
        const token = response.data;
        const access = response.data;

        localStorage.setItem("access", JSON.stringify(access));

        const data = {
            accessToken: token.accessToken,
            tokenType: token.tokenType,
            expiresIn: token.expiresIn,
            refreshToken: token.refreshToken,
        };

        // Persist data into localstorage
        saveAuthToken(data.accessToken, data.tokenType, data.expiresIn, data.refreshToken);

        // Fetch user data
        dispatch(setAuthUser());

        // Persist data into store
        dispatch({ type: authActionsTypes.SIGNIN_USER_SUCCESS, payload: data });
        toast("Welcome, You have Logged in!", { className: "toast-success" });

        history.push('/account/dashboard/board');
        // NotificationManager.success('User Login Successfully!');
        return Promise.resolve();
    })
        .catch((error) => {
            dispatch({ type: authActionsTypes.SIGNIN_USER_ERROR, payload: error });
            //errorDisplay(error);
            return Promise.reject();
        });
}


export const updateUserPassWord = (data, userId) => {

    //const url = replaceIdParamInUrlFormat(backendURLs.AUTH.USER.UPADATE.PASSWORD, userId);
    const url = joinBaseUrlWithRequestParams(joinBaseUrlWithParams(backendURLs.AUTH.USER.UPADATE.PASSWORD, [
        {
            param: 'id',
            value: userId
        }
    ]), [{
        param: 'old_password',
        value: data.oldPassword
    },
    {
        param: 'new_password',
        value: data.newPassword
    },
    {
        param: 'confirmed_password',
        value: data.confirmNewPassword
    },


    ]);

    return makeRequest('put', url, data, {});
}

export const updateUserLanguage = (lang, userId) => {

    //const url = replaceIdParamInUrlFormat(backendURLs.AUTH.USER.UPADATE.PASSWORD, userId);
    const url = joinBaseUrlWithRequestParams(joinBaseUrlWithParams(backendURLs.AUTH.USER.UPADATE.LANGUAGE, [
        {
            param: 'id',
            value: userId
        }
        ]), [{
            param: 'lang',
            value: lang
        } ]);

    return makeRequest('put', url, null, {});
}

export const checkUserEmail = (token) => {

    const url = joinBaseUrlWithParams(backendURLs.AUTH.CHECK_EMAIL, [
        {
            param: 'token',
            value: token
        }
    ]);

    makeRequest('get', url, null, {});
}

export const updateUserCompanyInfo = (data, userId, callback = null) => (dispatch) => {
    const url = joinBaseUrlWithRequestParams(joinBaseUrlWithParams(backendURLs.AUTH.USER.UPADATE.COMPANY, [
        {
            param: 'id',
            value: userId
        }
    ]), [{
        param: 'fax',
        value: data.fax
    },
    {
        param: 'business_registration_number',
        value: data.businessRegistrationNumber
    },
    /*{
        param: 'company_business_registration',
        value: data.companyBusinessRegistration
    },*/
    {
        param: 'company_creation_date',
        value: data.companyCreationDate
    },
    {
        param: 'company_name',
        value: data.companyName
    },
    {
        param: 'key_contact_name',
        value: data.keyContactName
    },
    {
        param: 'country',
        value: data.country
    },
    {
        param: 'street_address',
        value: data.streetAddress
    },
    {
        param: 'city',
        value: data.city
    },
    {
        param: 'province',
        value: data.province
    },
    {
        param: 'postal',
        value: data.postal
    },
    {
        param: 'website_url',
        value: data.websiteUrl
    },

    ]);


    return makeActionRequest('put', url, data, dispatch, authActionsTypes.UPDATE_COMPANY_INFORMATIONS_USER_BASE, {
        shouldFetchAuthUserAfterSuccess: true,
        shouldClearReducerState: true,
        multipart: true,
        shouldSkipDataParsing: false,
        fileData: ['companyBusinessRegistration'],
        callback
    });

};

export const updateUserAvatr = (data, userId, callback = null) => (dispatch) => {

    const url = replaceIdParamInUrlFormat(backendURLs.AUTH.USER.UPADATE.AVATAR, userId);

    makeActionRequest('post', url, data, dispatch, authActionsTypes.UPDATE_AVATAR_USER_BASE, {
        shouldFetchAuthUserAfterSuccess: true,
        shouldClearReducerState: true,
        multipart: true,
        shouldSkipDataParsing: false,
        fileData: ['avatar'],
        callback
    });
}

export const updateUserLicense = (data, userId, callback = null) => (dispatch) => {

    const url = replaceIdParamInUrlFormat(backendURLs.AUTH.USER.UPADATE.LICENCE, userId);

    makeActionRequest('post', url, data, dispatch, authActionsTypes.UPDATE_LICENCE_USER_BASE, {
        shouldFetchAuthUserAfterSuccess: true,
        shouldClearReducerState: true,
        multipart: true,
        shouldSkipDataParsing: false,
        fileData: ['licenceFile'],
        callback
    });
}

export const updateUserRegistration = (data, Id, callback = null) => {

    const url = replaceIdParamInUrlFormat(backendURLs.AUTH.USER.UPADATE.LICENCE, Id);

    return makeRequest('post', url, data, {
        shouldFetchAuthUserAfterSuccess: true,
        multipart: true,
        shouldSkipDataParsing: false,
        fileData: ['businessFile'],
        callback
    });
}

export const resetPasswordCode = (email) => {
    const url = joinBaseUrlWithRequestParams(`${backendURLs.AUTH.USER.RESET_PASSWORD}`, [{ param: 'email', value: email }]);
    return makeRequest('get', url, null, {});
}

export const resetPassword = (data) => {

    const url = replaceIdParamInUrlFormat(`${backendURLs.AUTH.USER.RESET_PASSWORD}`);

    return makeRequest('post', url, data, {shouldSkipDataParsing: false, multipart: true});
}

export const getNotifs = (id, page = 1, size = 10) => {
    const url = replaceIdParamInUrlFormat(`${backendURLs.AUTH.USER.GET_NOTIFS}`, id);
    return makeRequest('get', `${url}?page=${page}&size=${size}`, null, {});
}

export const countNotifs = (id) => {
    const url = replaceIdParamInUrlFormat(`${backendURLs.AUTH.USER.COUNT_NOTIFS}`, id );
    return makeRequest('get', url, null, {});
}

export const setNotif = (id) => {
    const url = replaceIdParamInUrlFormat(`${backendURLs.AUTH.USER.SET_NOTIFS}`, id);
    return makeRequest('put', url, null, {});
}

export const logout = () => (dispatch) => {
    dispatch({ type: authActionsTypes.LOGOUT, payload: null });
    if(!window.location.pathname.includes("home") && !window.location.pathname.includes("login"))
        window.location = "/home"
}

export const generateReferral = (code) => {
    return new Promise((resolve, reject) => {
        axios.post(backendURLs.REFERRAL.GENERATE, {code: code}, {}).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error);
        })
    });

}

export const getReferrers = () => {
    return makeRequest('get', backendURLs.REFERRAL.USERS, null, {});
}