import { drawDOM, exportPDF } from '@progress/kendo-drawing';

class DocService {
  createPdf = (html, callback) => {
    drawDOM(html, { 
      paperSize: 'A3',
      forcePageBreak: '.page-break',
      fileName: 'form.pdf',
      margin: 50,
      height: '1200px',
      padding: 16,
    }).then((group) => {
      return exportPDF(group);
  }).then((dataUri) => {
      //console.log(encodeURIComponent(dataUri.split(';base64,')[1]));
      callback(dataUri.split(';base64,')[1]);
  });;
  }
}

const Doc = new DocService();
export default Doc;