import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ProductTypeFour from '../../../../features/product/product-type-four';
import Carousel from '../../../../features/carousel';

import { productFilter } from '../../../../../utils';

function FeaturedProductsTwo(props) {
    const { data } = props;

    return (
        <div className="widget widget-featured">
            <div className="col-sm-12">
                <h2 className="mb-1 font-weight-light">SELLERS</h2>
                <hr style={{ margin: "15px 0px" }} />
            </div>

            <div className="widget-body">
                {/* <Carousel addClass="widget-featured-products" isTheme={false} settings={{ nav: true }}> */}
                    {
                        data.map((item, index) => (
                            <div className="featured-col">
                                {
                                    item.user ?
                                        <ProductTypeFour addClass="left-details product-widget" product={item} key={'product-type'+index} />
                                    : <></>
                                }
                            </div>
                        ))
                    }
                {/* </Carousel> */}
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        products: state.data.products ? state.data.products : []
    }
}

export default connect(mapStateToProps, {})(FeaturedProductsTwo);