import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
//Import Utils
import { isIEBrowser } from '@utils';
import { CONTACT, DISCUSSION, LOGIN } from '@routes/urls';
import { MARKETPLACE, MARKET_INTELLIGENCE, ROOT } from '@routes/urls';

function MobileMenu(props) {
    const [ path, setPath ] = useState( "" );
    const { authUser, isAuth } = useSelector(state => state.authUser);


    useEffect( () => {
        setPath( window.location.pathname );
    } )

    useEffect( () => {
        document.querySelector( ".mobile-menu-overlay" ).addEventListener( "click", closeMobileMenu );
        document.querySelector( ".mobile-menu-close" ).addEventListener( "click", closeMobileMenu );

        return () => {
            if ( document.querySelector( "body" ).classList.contains( "mmenu-active" ) ) {
                document.querySelector( "body" ).classList.remove( "mmenu-active" );
            }
        }
    } )

    const closeMobileMenu = ( e ) => {
        e.preventDefault();
        document.querySelector( "body" ).classList.remove( "mmenu-active" );
        if ( document.querySelector( ".menu-toggler" ) ) {
            document.querySelector( ".menu-toggler" ).classList.remove( "active" );
        }

        if ( isIEBrowser() && !document.querySelector( "body" ).classList.contains( "mmenu-active" ) ) {
            document.querySelector( ".mobile-menu-container" ).style.transform = "translateX(-100%)";
        }
    }

    const {t} = props;

    return (
        <>
            <div className="mobile-menu-overlay"></div>
            <div className="mobile-menu-container">
                <div className="mobile-menu-wrapper">
                    <span className="mobile-menu-close"><i className="icon-cancel"></i></span>
                    <nav className="mobile-nav">
                        <ul className="mobile-menu">
                            <li className={path.indexOf("home") > -1 || path.indexOf('/') == -1 ? 'active' : ''}><Link to={ROOT}> {t("header.home")} </Link></li>
                            <li className={path.indexOf("market-intelligence") > -1 ? 'active' : ''}><Link to={MARKET_INTELLIGENCE}>{t("header.marketi")}</Link></li>
                            <li className={path.indexOf("marketplace") > -1 ? 'active' : ''}>
                                <Link to={MARKETPLACE}><i className="fa fa-store text-white" style={{fontSize: "16px"}} aria-hidden="true"></i> &nbsp; {t("header.market")}</Link>
                            </li>
                            <li><Link to={ROOT}>{t("header.about_us")}</Link></li>                
                            <li className={path.indexOf("contact") > -1 ? 'active' : ''}><Link to={CONTACT}>Contact</Link></li>
                            <li className={path.indexOf("discussion") > -1 ? 'active' : ''}><Link to={DISCUSSION}>Discussions</Link></li>
                            {!isAuth ? <li className={path.indexOf("login") > -1 ? 'active' : ''}><Link to={LOGIN}>{t("header.sign_in")}</Link></li> : null}
                        </ul>
                    </nav>

                    
                </div>
            </div>
        </>
    )
}

export default  withTranslation()(MobileMenu);