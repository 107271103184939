import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react'
import CountryManager from '@helpers/CountryManager';
import HorizontalThumbnail from '@components/pages/products/common/thumbnails/horizontal-thumbnail';

Modal.setAppElement('#root');
const customStylesModal = {
  content: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'relative',
      maxWidth: '870px',
      marginRight: '2rem',
      padding: '.5rem',
      backgroundColor: '#fff',
      minHeight: "400px",
      maxHeight: "800px"
  }
};

const ProductDetatilsModal = (props) => {

    const [open, setOpen] = useState(false);

    /* const openModal = (e) => {
        e.preventDefault();
        setOpen(true);
    } */
    const selectGroup = (e) => {
        e.preventDefault();
        if (props.noSelect === undefined)
            document.querySelector(".product-single-gallery .owl-item.active img").setAttribute("src", e.currentTarget.getAttribute("data-src"));

        e.currentTarget.parentElement.parentElement.querySelector(".active") && e.currentTarget.parentElement.parentElement.querySelector(".active").classList.remove("active");
        e.currentTarget.parentElement && e.currentTarget.parentElement.classList.add("active");
    }
  
    const { authUser } = useSelector(state => state.authUser);
    const [langSelected, setLangSelected] = useState("en");
  
    useEffect(() => {
      if (authUser != null) {
        setLangSelected(authUser.language.toLowerCase());
      }
    }, [authUser])

    const closeModal = () => {
        //props.clearUpdateStateAfterUpdate();
        setOpen(false);
    }

    const { item, showItemDetail, closeDetailModal, t } = props;

    return (
        <>
            <Modal
                shouldCloseOnOverlayClick={false}
                isOpen={showItemDetail}
                onRequestClose={closeModal}
                contentLabel="product-created Modal"
                className="product-created-popup"
                id="product-created-popup-form"
                overlayClassName="cart-modal-overlay"
                style={customStylesModal}
            >
                <div className="modal-wrapper" style={{ height: "100%" }}>
                        {item ? <div className="row" style={{padding:'15px'}}> 
                                    {/*<div className="col-md-5">*/}
                                        <HorizontalThumbnail addClass="col-lg-5 col-md-6" product={ item } />
                                    {/*</div>*/}
                                    <div className="col-md-7">
                                        <div className="product-single-details">
                                            <h1 className="product-title">{item.name}</h1>
                                            <p>{item.productCategory[langSelected == 'fr' ? 'fr' : 'en']}</p>
                                            <div className="ratings-container">
                                            </div>

                                            <hr className="short-divider" />
                                            <div className="price-box">
                                                <span className="product-price"> {item.price.toFixed(2)}&nbsp; {item.currency} / {item.pricePerUnit.name} </span>
                                            </div>
                                            <div className="product-desc">
                                                <p>{item.description}</p>
                                                <ul>
                                                    <li><i className="icon-ok"></i>&nbsp; <strong>{t('products.product_country')}:</strong> {CountryManager.getNameFromId(item.countryOfOrigin)}</li>
                                                    <li><i className="icon-ok"></i>&nbsp; <strong>{t('products.min_price')}:</strong> {item.minPrice} {item.currency} &nbsp;&nbsp; <strong>{t('products.max_price')}:</strong> {item.maxPrice} {item.currency}</li>
                                                    <li><i className="icon-ok"></i>&nbsp; <strong>{t('products.port_of_departure')}:</strong> {item.port.name}</li>
                                                </ul>
                                                
                                                
                                            </div>
                                            {
                                                item.variants ?
                                                    <div className="product-filters-container">
                                                        {
                                                            item.variants.map((variant, index) => (
                                                                <div className="product-single-filter" key={"varient" + index}>
                                                                    <label>{variant.name}:</label>
                                                                    {
                                                                        variant.name === "size" ?
                                                                            <ul className="config-size-list">
                                                                                {
                                                                                    variant.type.map((item, i) => (
                                                                                        <li className={i === 0 ? "active" : ""} key={"size" + i} >
                                                                                            <Link to="#" onClick={selectGroup} data-src={item.pictures[0]}>{item.size}</Link>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                            : <ul className="config-swatch-list">
                                                                                {
                                                                                    variant.type.map((item, i) => (
                                                                                        <li className={i === 0 ? "active" : ""} key={"color" + i} >
                                                                                            {
                                                                                                item.color ?
                                                                                                    <Link to="#" onClick={selectGroup} data-src={item.pictures[0]} style={{ backgroundColor: item.color }}></Link>
                                                                                                    : <Link to="#" data-src={item.pictures[0]} onClick={selectGroup} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/${item.pictures[0]})` }}></Link>
                                                                                            }
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    : ""
                                            }

                                            <div className="product-action">
                                            </div>

                                            <hr className="divider mb-1" />

                                            <div className="product-single-share">
                                                <div className="social-icons mr-2">
                                                    <Link to="#" className="social-icon social-facebook icon-facebook" target="_blank"></Link>
                                                    <Link to="#" className="social-icon social-twitter icon-twitter" target="_blank"></Link>
                                                    <Link to="#" className="social-icon social-linkedin fab fa-linkedin-in" target="_blank"></Link>
                                                    <Link to="#" className="social-icon social-gplus fab fa-google-plus-g" target="_blank"></Link>
                                                    <Link to="#" className="social-icon social-mail icon-mail-alt" target="_blank"></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        </div> : null}


                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={closeDetailModal}>X</button>
                </div>
            </Modal>
            {/* Pdf Generation */}
           
        </>
    )
}

// export default withTranslation()(ProductDetatilsModal)

export default withTranslation()(React.memo(ProductDetatilsModal));