import backendURLs from "../utils/backendURL";
import { makeRequest, replaceIdParamInUrlFormat } from "./utils"
import * as configProductsProductsActionsType from '../constants/config-actions';

export const getAllProductsConfig = () => dispatch => {

    const url_getAllUnits = replaceIdParamInUrlFormat(`${backendURLs.CONFIG.GET_ALL_UNITS}`);
    const url_getAllPorts = replaceIdParamInUrlFormat(`${backendURLs.CONFIG.GET_ALL_PORTS}`);
    const url_getAllIncoTerms = replaceIdParamInUrlFormat(`${backendURLs.CONFIG.GET_ALL_INCO_TERMS}`);
    const url_getAllProductsCat = replaceIdParamInUrlFormat(`${backendURLs.CONFIG.GET_ALL_PRODUCT_CATEGORY}`);
    const url_getAllTpesOfShipments = replaceIdParamInUrlFormat(`${backendURLs.CONFIG.GET_ALL_TYPE_OF_SHIPMENTS}`);
    const url_getAllProductsCatUsed = replaceIdParamInUrlFormat(`${backendURLs.CONFIG.GET_ALL_PRODUCT_CATEGORY_USED}`);
    
    //const url_getAllDeliveryEngine = replaceIdParamInUrlFormat(`${backendURLs.CONFIG.GET_ALL_DELIVERY_ENGINE}`);
    //const url_getAllProductsTyp = replaceIdParamInUrlFormat(`${backendURLs.CONFIG.GET_ALL_PRODUCT_TYPE}`);

    dispatch({type: configProductsProductsActionsType.GET_ALL_PRODUCT_CONFIG});

    Promise.all([

        makeRequest('get', url_getAllUnits, null, {}),
        makeRequest('get', url_getAllPorts, null, {}),
        makeRequest('get', url_getAllIncoTerms, null, {}),
        makeRequest('get', url_getAllProductsCat, null, {}),
        makeRequest('get', url_getAllProductsCatUsed, null, {}),
        makeRequest('get', url_getAllTpesOfShipments, null, {}),
        //makeRequest('get', url_getAllProductsTyp, null, {}),

    ]).then((values) => {

        dispatch({type: configProductsProductsActionsType.GET_ALL_PRODUCT_CONFIG_SUCCESS, payload: values});
    }).catch(err => {

        dispatch({type: configProductsProductsActionsType.GET_ALL_PRODUCT_CONFIG_ERROR, payload: err});
    });
}
