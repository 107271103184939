import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { findIndex } from '../../../utils';
import { withTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';
import { addToCart, addToWishList, showQuickView } from '../../../action';

function ProductTypeThree(props) {
    let isInWishlist = props.product ? (findIndex(props.wishlist, props.product.id) ? true : false) : false;
    let { addClass, product, showQuickView, addToCart } = props;

    const onWishlistClick = (e) => {
        if (!isInWishlist) {
            e.preventDefault();
            props.addToWishList(product);
        }
    }
    
    const { authUser } = useSelector(state => state.authUser);
    const [langSelected, setLangSelected] = useState("en");
  
    useEffect(() => {
      if (authUser != null) {
        setLangSelected(authUser.language.toLowerCase());
      }
    }, [authUser])

    const { t } = props;

    return (
        <div className={`product-default ${addClass}`}>
            <figure className="col-md-3 col-sm-4 p-0">
                <Link to={`${process.env.PUBLIC_URL}/products/${product.id}/details`} >
                    <img src={ `${process.env.BACKEND_BASE_URL}${product.mainImagePath}` } className="first-image" alt="product" />

                    {
                        product.mainImagePath ?
                            <img src={ `${process.env.BACKEND_BASE_URL}${product.mainImagePath}` } className="last-image" alt="product" /> : ""
                    }
                </Link>
                {
                    product.sale === true ?
                        <div className="label-group">
                            <span className="product-label label-sale">{product.discount}%</span>
                        </div>
                        : ""
                }
            </figure>

            <div className="product-details col-md-9 col-sm-8">
                <div className="category-list">
                <Link to={`${process.env.PUBLIC_URL}/categories/full-width`} className="product-category">{ langSelected == 'fr' ? product.productCategory.fr : product.productCategory.en } </Link>
                    {/* {
                        product.productCategory.map((category, index) => (
                            index === (product.category.length - 1) ?
                                <Link to={`${process.env.PUBLIC_URL}/categories/full-width`} className="product-category" key={"category" + index}>{category}</Link>
                                : <Link to={`${process.env.PUBLIC_URL}/categories/full-width`} className="product-category" key={"category" + index}>{category}, </Link>
                        ))
                    } */}
                </div>
                <h2 className="product-title">
                    <Link to={`${process.env.PUBLIC_URL}/products/${product.id}/details`}>{product.name}</Link>
                </h2>
                {/* <div className="ratings-container">
                    <div className="product-ratings">
                        <span className="ratings" style={ { width: 20 * product.rating + '%' } }></span>
                        <span className="tooltiptext tooltip-top">{ product.rating.toFixed( 2 ) }</span>
                    </div>
                </div> */}
                <p className="product-description">
                    {product.description}
                </p>
                <div className="price-box">
                    <span className="product-price">{ product.currency }{ product.price.toFixed( 2 ) }</span>
                </div>
                <div className="product-action">

                    <Link to={`${process.env.PUBLIC_URL}/products/${product.id}/details`} title="Details View" ><button className="btn-icon btn-add-cart"><i className="icon-bag"></i>{t('home.details')}</button></Link>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        wishlist: state.wishlist.list ? state.wishlist.list : []
    }
}

export default withTranslation()(connect(mapStateToProps, { addToCart, addToWishList, showQuickView })(ProductTypeThree));

