import { Helmet } from "react-helmet";
import imagesLoaded from "imagesloaded";
import ToolBox from "./components/tool-box";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../common/breadcrumb";
import React, { useEffect, useState } from "react";
import ShopSidebar from "./components/shop-sidebar";
import InfiniteProduct from "./components/infinite-product";
import { connect, useDispatch, useSelector } from "react-redux";
import SidebarToggle from "../products/common/sidebars/sidebar-toggle";
import { countAllProducts, countAllProductsInCategorySelected } from "../../../action/products-actions";

function Marketplace(props) {

  const products = useSelector(state => state.products);
  const [layout, setLayout] = useState("grid");
  const [displayCount, setDisplayCount] = useState(12);
  const search = useLocation().search;
  const q = new URLSearchParams(search).get('q');

  const dispatch = useDispatch();

  useEffect(() => {
    let imgLoad = imagesLoaded(".product-group");

    if (document.querySelector(".skeleton-body")) {
      document.querySelector(".skeleton-body").classList.remove("loaded");
      imgLoad.on("done", function() {
        document.querySelector(".skeleton-body") &&
          document.querySelector(".skeleton-body").classList.add("loaded");
      });
    }
  });

  useEffect(() => {
    if (products.categorySelected) {
      dispatch(countAllProductsInCategorySelected(products.categorySelected.id, q));
    }else {
      dispatch(countAllProducts(q));
    }   
  }, [products.categorySelected])


  const gridType = (layoutParam) => {
    if (layout !== layoutParam) {
      setLayout(layoutParam);
    }
  };

  const onChangeDisplayCount = (countParam) => {
    if (displayCount !== countParam) {
      setDisplayCount(countParam);
    }
  };

  const {t} = props;

  return (
    <>
      <Helmet>
        <title>Sooko - Market Place</title>
      </Helmet>

      <h1 className="d-none">Sooko - Product Category Page</h1>

      <div className="main">
        <Breadcrumb current="Market place" path="products" />
        <div className="container">
          <div className="row">
            <div className="col-lg-9 skeleton-body skel-shop-products" style={{background: 'white', padding: "30px", borderRadius: "10px"}}>
              <ToolBox
                gridType={gridType}
                changeDisplay={onChangeDisplayCount}
                displayCount={displayCount}
              />

              <h3 className="text-muted font-weight-light mt-3"> {t("infinitescroll.all_products")} </h3>
              <hr style={{margin: "0px", marginBottom: "15px"}}/>
              {products.dataInfinite.length <= 0 ? (
                  <div className="mb-5 text-center">
                    <img
                        src="assets/images/nothing-here.png"
                        width="500"
                        style={{ margin: "0 auto" }}
                    />
                    <h2 className="text-muted font-weight-light">
                      {t("infinitescroll.nothing_here")}
                    </h2>
                  </div>
              ) : null}
              <InfiniteProduct
                type={layout}
              />
            </div>
            <SidebarToggle />
            <aside className="sidebar-shop col-lg-3 order-lg-first mobile-sidebar">
              <ShopSidebar style={{background: 'white'}}/>
            </aside>
          </div>
        </div>
        <div className="mb-5"></div>
      </div>
    </>
  );
}

const mapStateToProps = (state, props) => ({
  filter: state.filter ? state.filter : [],
});

export default withTranslation()(connect(mapStateToProps)(Marketplace));
