import React, { useEffect, useState } from "react";

//import Breadcrumb from '../../../common/breadcrumb';
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import classes from "../rfqCreate.module.scss";
import { Popup } from 'react-chat-elements';
import { withTranslation } from "react-i18next";

function PRfqCreateStep1(props) {
  const {
    setDataStep1,
    unitsConfigQuantity,
    incoTermsConfig,
    defaultDataStep1,
    rfqToUpdate,
    product,
    deactivateWhenSellerUpdate
  } = props;

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: { ...defaultDataStep1 },
  });

  const { t } = props;

  const authUser = useSelector((state) => state.authUser);

  const [langSelected, setLangSelected] = useState("en");

  useEffect(() => {
    if (authUser.authUser != null) {
      setLangSelected(authUser.authUser.language.toLowerCase());
    }
  }, [authUser])

  const [file, setFile] = useState(null);
  const [fileToSend, setFileToSend] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const onSubmitForm = (data, force = false) => {

    if (!force && data.price > product.price) {
      setShowPopup(true);
      return
    }

    setDataStep1({ ...data, image: fileToSend });
  };

  const disableIfSellerWantToUpdate = rfqToUpdate && authUser.authUser && authUser.authUser.userType === 'SELLER';

  return (
    <>
      <div className="main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 order-lg-first">
              <div className="checkout-payment">
                <div id="new-checkout-address" className="show">
                  <form name="rfqForm mt-2" className={classes.rfqForm}>

                    <Popup
                      show={showPopup}
                      header={t("common.warning")}
                      headerButtons={[{
                        type: 'transparent',
                        color: 'black',
                        text: 'close',
                        onClick: () => { setShowPopup(false) }
                      }]}
                      text={t("rfq.price_exeeded")}
                      footerButtons={[{
                        color: 'white',
                        backgroundColor: '#ff5e3e',
                        text: t("rfq.change_price"),
                        onClick: () => { setShowPopup(false) }
                      }, {
                        color: 'white',
                        backgroundColor: 'lightgreen',
                        text: t("common.continue"),
                        onClick: handleSubmit((data) => onSubmitForm(data, true))
                      }]} />

                    <div className="showStepOfStepper">
                      {
                        !authUser.authUser ?
                          <div className="row">
                            <div className="form-group required-field col-md-12" style={{ maxWidth: '100%' }}>
                              <label> Email Address </label>
                              <input
                                type="email"
                                className="form-control full-width"
                                name="email"
                                ref={register({ required: true })}
                                required
                              />
                              {errors.email && <div className="text-danger mt-1">Email address is required</div>}
                            </div>
                          </div> : null}
                      <div className="row">
                        <div className="form-group required-field col-md-6">
                          <label> {t("rfq.price")} </label>
                          <input
                            type="number"
                            className="form-control"
                            name="price"
                            ref={register({ required: true })}
                            required
                          />
                          {errors.price && <div className="text-danger mt-1">Price is required</div>}
                        </div>

                        <div className="form-group required-field col-md-6">
                          <label> {t("rfq.currency")} </label>
                          {disableIfSellerWantToUpdate ? <>
                            <input type="text" className="form-control" value={rfqToUpdate.currency} disabled />
                          </> :
                            <input type="text" name="currency" ref={register({ required: true })} className="form-control" value={product ? product.currency : 'EUR'} disabled />
                          }
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group required-field col-md-4">
                          <label> {t("rfq.quantity")} </label>
                          {disableIfSellerWantToUpdate ? <>
                            <input type="text" className="form-control" value={rfqToUpdate.quantity} disabled />
                          </> : <>
                              <input
                                type="number"
                                className="form-control"
                                name="quantity"
                                ref={register({ required: true })}
                                required
                              />
                              {errors.quantity && <div className="text-danger mt-1">Quantity is required</div>}
                            </>
                          }
                        </div>
                        <div className="form-group required-field col-md-4">
                          <label> {t("rfq.perunit")} </label>
                          {disableIfSellerWantToUpdate ? <>
                            <input type="text" className="form-control" value={unitsConfigQuantity.find(item => item.id == rfqToUpdate.quantityUnit.id)[langSelected == 'fr' ? 'fr' : 'en']} disabled />
                          </> : <>

                              <input type="text" name="quantityUnitId" ref={register({ required: true })} className="form-control" value={product ? product.pricePerUnit[langSelected == 'fr' ? 'fr' : 'en'] : null} disabled />
                            </>}
                        </div>

                        <div className="form-group required-field  col-md-4">
                          <label> {t("rfq.incoterm")} </label>
                          {disableIfSellerWantToUpdate ? <>
                            <input type="text" className="form-control" value={incoTermsConfig.find(item => item.id == rfqToUpdate.incoterm.id).name} disabled />
                          </> : <>
                              <select
                                className="form-control"
                                name="incotermId"
                                ref={register({ required: true })}
                                required
                              >
                                <option value=""> </option>
                                {incoTermsConfig.sort((i1, i2) => i1.name.localeCompare(i2.name)).map((item, index) => (
                                  <option key={index} value={Number(item.id)}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              {errors.incotermId && <div className="text-danger mt-1">Incoterm is required</div>}
                            </>}
                        </div>
                      </div>

                      <div className="row">
                        <div className="required-field col-md-12">
                          <label> {t("rfq.conditions")} </label>
                          <textarea
                            className="form-control"
                            cols="5"
                            name="condition"
                            spellcheck='false'
                            ref={register({ required: true })}
                            required
                          ></textarea>
                          {errors.condition && <div className="text-danger mt-1">Condition is required</div>}
                        </div>
                      </div>
                      <div className="row">
                        <div className="required-field col-md-12">
                          <label> {t("rfq.quality")} </label>
                          <textarea
                            className="form-control"
                            cols="5"
                            name="qualitySpecification"
                            spellcheck='false'
                            ref={register({ required: true })}
                            required
                          ></textarea>
                          {errors.qualitySpecification && <div className="text-danger mt-1">Quality specification is required</div>}
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="col-md-12">
                    <button
                      onClick={handleSubmit((data) => onSubmitForm(data))}
                      className="btn btn-primary float-right px-5"
                    >
                      {t("rfq.next")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(React.memo(PRfqCreateStep1));