import { useSelector } from 'react-redux';
import { withTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';


function RfqListItem(props) {
    const next = (e) => {
        document.querySelector("#shipping-form") && document.querySelector("#shipping-form").submit();
    }

    const authUser = useSelector(state => state.authUser)
    const isBuyer = authUser.authUser && authUser.authUser.userType === 'BUYER';
    const isSeller = authUser.authUser && authUser.authUser.userType === 'SELLER';
    const { item, showDetail, goToUpdate, showProgression } = props;
    const { t } = props;
    const [langSelected, setLangSelected] = useState("en");

    useEffect(() => {
        if (authUser.authUser) {
            setLangSelected(authUser.authUser.language.toLowerCase());
        }
    }, [authUser]);

    return (

        <tr>
            {
                isBuyer ?

                    <>
                        <td className="rfq-item-image" style={{ display: 'flex', alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>
                            <img className="" src={`${process.env.BACKEND_BASE_URL}${item.baseQuotation.product.mainImagePath}`} />
                            <span style={{ paddingLeft: '7%' }}>{item.baseQuotation.product.name}</span>
                        </td>
                        <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <span>{item.baseQuotation.product.productCategory[langSelected == 'fr' ? 'fr' : 'en']}</span>
                        </td>
                        <td style={{ paddingTop: 10, paddingBottom: 10 }}> {item.baseQuotation.price} {item.baseQuotation.product.currency} / {item.quotation.quantityUnit[langSelected == 'fr' ? 'fr' : 'en']} </td>
                    </>
                    :
                    <>
                        <td className="rfq-item-image" style={{ display: 'flex', alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>
                            <img className="" src={`${process.env.BACKEND_BASE_URL}${item.quotation.product.mainImagePath}`} />
                            <span style={{ paddingLeft: '7%' }}>{item.quotation.product.name}</span>
                        </td>
                        <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <span>{item.baseQuotation.product.productCategory[langSelected == 'fr' ? 'fr' : 'en']}</span>
                        </td>
                        <td style={{ paddingTop: 10, paddingBottom: 10 }}> {item.quotation.price} {item.quotation.product.currency} / {item.quotation.quantityUnit[langSelected == 'fr' ? 'fr' : 'en']} </td>
                    </>
            }
            <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                <button
                    onClick={() => showProgression(item)}
                    className="btn btn-primary py-2"
                >
                    {t('notes.progression_view')}
                </button>
            </td>
        </tr>
    )
}

export default withTranslation()(React.memo(RfqListItem));