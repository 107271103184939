import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { formatDate } from '@helpers/helpers';
import { withTranslation, useTranslation } from "react-i18next";


function RfqListItem(props) {

    const authUser = useSelector(state => state.authUser)
    const isBuyer = authUser.authUser && authUser.authUser.userType === 'BUYER';
    const isSeller = authUser.authUser && authUser.authUser.userType === 'SELLER';
    const { item, showProgression } = props;
    const { t } = props;
    const { i18n } = useTranslation();

    const [langSelected, setLangSelected] = useState("en");

    useEffect(() => {
        if (authUser.authUser) {
            setLangSelected(authUser.authUser.language.toLowerCase());
        }
    }, [authUser]);

    return (

        <tr>
            {
                isBuyer ?

                    <>
                        <td className="rfq-item-image" style={{ display: 'flex', alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>
                            <img className="" src={`${process.env.BACKEND_BASE_URL}${item.baseQuotation.product.mainImagePath}`} />
                            <span style={{ paddingLeft: '7%' }}>{item.baseQuotation.product.name}</span>
                        </td>
                        <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <span>{item.baseQuotation.product.productCategory[langSelected == 'fr' ? 'fr' : 'en']}</span>
                        </td>
                        <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                            {
                                isSeller && !item.status ?
                                    <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: '#ff0000a1', fontWeight: "bold", padding: 5 }}> {t("common.new")} </div>
                                    :
                                    isBuyer && item.lastReviewer == 'SELLER' ?
                                        <div className="user-button-action-2 d-inline mt-3" style={{ color: 'green', fontWeight: "bold", padding: 5 }}> {t("common.edited")} </div>
                                        :
                                        isSeller && item.lastReviewer == 'BUYER' ?
                                            <div className="user-button-action-2 d-inline mt-3" style={{ color: 'green', fontWeight: "bold", padding: 5 }}> {t("common.edited")} </div>
                                            :
                                            <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: 'rgb(0 139 243 / 66%)', fontWeight: "bold", padding: 5 }}> {t("common.processing")} </div>
                            }
                        </td>
                        <td style={{ paddingTop: 10, paddingBottom: 10 }}> {item.baseQuotation.price} {item.baseQuotation.product.currency} </td>
                        <td style={{ paddingTop: 10, paddingBottom: 10 }}> {item.baseQuotation.quantity} {item.quotation.quantityUnit[langSelected == 'fr' ? 'fr' : 'en']}</td>
                    </>
                    :
                    <>
                        <td className="rfq-item-image" style={{ display: 'flex', alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>
                            <img className="" src={`${process.env.BACKEND_BASE_URL}${item.quotation.product.mainImagePath}`} />
                            <span style={{ paddingLeft: '7%' }}>{item.quotation.product.name}</span>
                        </td>
                        <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <span>{item.baseQuotation.product.productCategory[langSelected == 'fr' ? 'fr' : 'en']}</span>
                        </td>
                        <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                            {
                                !isBuyer && !item.status ?
                                    <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: '#ff0000a1', fontWeight: "bold", padding: 5 }}> {t("common.new")} </div>
                                    :
                                    isBuyer && item.lastReviewer == 'SELLER' ?
                                        <div className="user-button-action-2 d-inline mt-3" style={{ color: 'green', fontWeight: "bold", padding: 5 }}> {t("common.edited")} </div>
                                        :
                                        isSeller && item.lastReviewer == 'BUYER' ?
                                            <div className="user-button-action-2 d-inline mt-3" style={{ color: 'green', fontWeight: "bold", padding: 5 }}> {t("common.edited")} </div>
                                            :
                                            <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: 'rgb(0 139 243 / 66%)', fontWeight: "bold", padding: 5 }}> {t("common.processing")} </div>
                            }
                        </td>
                        <td style={{ paddingTop: 10, paddingBottom: 10 }}> {item.quotation.price} {item.quotation.product.currency} </td>
                        <td style={{ paddingTop: 10, paddingBottom: 10 }}> {item.quotation.quantity} {item.quotation.quantityUnit[langSelected]}</td>
                    </>
            }
            <td style={{ paddingTop: 10, paddingBottom: 10 }}> {formatDate(item.createdAt, i18n.language)}</td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div className="row">
                    <div className="rfq-item-icons" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Link to={`${process.env.PUBLIC_URL}/account/dashboard/rfqs/${item.id}/details`}><span className="rfq-item-icons-read input-tooltip" data-toggle="tooltip" title={t('common.view')} data-placement="right" style={{ cursor: "pointer" }}><i className="fa fa-eye" aria-hidden="true"></i></span>&nbsp;</Link>
                        {/* {isBuyer ? <Link to={`${process.env.PUBLIC_URL}/account/dashboard/rfqsUpdate/products/${item.baseQuotation.product.id}/rfq/update`}><span className="rfq-item-icons-edit" style={{ color: "#e4c318", cursor: "pointer" }}><i className="fa fa-pencil-alt" aria-hidden="true"></i></span>&nbsp;</Link> : null} */}
                        {/* {isBuyer ? <><span className="rfq-item-icons-edit" onClick={() => goToUpdate(item.baseQuotation)} style={{ color: "#e4c318", cursor: "pointer" }}><i className="fa fa-pencil-alt" aria-hidden="true"></i></span>&nbsp;</> : null} */}
                        {item.hasChat ? <Link to={`${process.env.PUBLIC_URL}/account/dashboard/rfqsChat/quote/${item.id}/chat`}><span className="rfq-item-icons-chat rfq-item-icons-read input-tooltip" data-toggle="tooltip" title={t('common.chat')} data-placement="right"><i class="fa fa-comments" style={{ cursor: "pointer", color: "#1dabf1" }} aria-hidden="true"></i></span></Link> : <span className="rfq-item-icons-chat-disable" style={{ backgroundColor: '#80808033' }}><i class="fa fa-comments" style={{ cursor: "pointer", color: '#c6baba' }} aria-hidden="true"></i></span>}
                        <span onClick={() => showProgression(item)} className="rfq-item-icons-edit input-tooltip" data-toggle="tooltip" title={t('common.progression')} data-placement="right" style={{ cursor: "pointer", color: "#e4c318" }}><i className="fa fa-tasks" aria-hidden="true"></i></span>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default withTranslation()(React.memo(RfqListItem));