import React from 'react';
import { connect } from 'react-redux';
import Carousel from '../../features/carousel';
import { owlSetting3 } from '../../../utils/settings';
import ProductTypeFive from '../../features/product/product-type-five';

function ProductCollection(props) {
    let productSlideSetting = { ...owlSetting3 };
    delete (productSlideSetting.responsive["1200"]);

    return (
        <div className=" col-md-8 col-sm-12 pt-5 pt-md-0 offset-md-2">

            <Carousel className="tab-products-carousel quantity-inputs show-nav-hover nav-outer nav-image-center" settings={productSlideSetting}>
                {
                    props.interestingProducts.map((item, index) => (

                        <ProductTypeFive product={item} key={"filter"} />

                    ))
                }
            </Carousel>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        products: state.data.products ? state.data.products : []
    }
}

export default connect(mapStateToProps, {})(ProductCollection);