import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import classes from "./rfqDetails.module.css";
import UpdateRfqModal from './UpdateRfqModal';
import { withTranslation } from "react-i18next";
import ReactFlagsSelect from 'react-flags-select';
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { createChat } from "@action/chat-actions";
import CountryManager from "@helpers/CountryManager";
import SuccessModal from '@components/pages/succesModal';
import { updateRFQSeller, getOneRfqOfUser, markRFQasRead, getRfqChild } from "@action/rfqs-actions";
import FeaturedProductsTwo from "@components/pages/products/common/product-groups/featured-products-two";

const RfqDetatils = (props) => {
  const {
    notShowBtnAction,
  } = props;

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {},
  });

  const { t } = props;

  const methodOfShipmentData = [
    { name: t("common.air"), value: 'AIR' },
    { name: t("common.sea"), value: 'SEA' },
    { name: t("common.road"), value: 'ROAD' },
    { name: t("common.courriel"), value: 'COURRIEL' },
    { name: t("common.rail"), value: 'RAIL' }
  ];

  const authUser = useSelector(state => state.authUser);
  const isSeller = () => this.props.authUser.authUser && this.props.authUser.authUser.userType === 'SELLER';
  const isBuyer = () => this.props.authUser.authUser && this.props.authUser.authUser.userType === 'BUYER';

  const [child, setChild] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generate, setGenerate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [rfqItemSelected, setRfqItemSelected] = useState(null);
  const [buyerUpdateProcess, setBuyerUpdateProcess] = useState(false);
  const [showModalRfqDetail, setShowModalRfqDetail] = useState(false);
  const [cancelProceed, setCancelProceed] = useState(false);
  const [confirmProcesModal, setConfirmProcesModal] = useState(false);

  const setConfirmProceedModal = (value, toCancelProceed = false) => {
    setConfirmProcesModal(value);
    setCancelProceed(toCancelProceed);
  };

  const rfqItemSelectedQuote = rfqItemSelected && rfqItemSelected.baseQuotation
    ? rfqItemSelected.baseQuotation
    : rfqItemSelected;

  useEffect(() => {
    if (authUser.authUser && rfqItemSelected)
      onGetRfqChild()
  }, [authUser, rfqItemSelected]);

  const [langSelected, setLangSelected] = useState("en");

  const disableConfirmProceedSeller = () => {
    return (isSeller() && rfqItemSelected && rfqItemSelected.chat.sellerStatus);
  }

  const disableConfirmProceedBuyer = () => {
    return (isBuyer() && rfqItemSelected && rfqItemSelected.chat.buyerStatus);
  }

  useEffect(() => {
    if (authUser.authUser) {
      setLangSelected(authUser.authUser.language.toLowerCase());
    }
  }, [authUser]);

  const createRFQChat = () => {
    setLoading(true);

    createChat({
      quotationId: rfqItemSelectedQuote.id,
      sellerUserId: authUser.authUser.id,
      buyerUserId: rfqItemSelectedQuote.user.id,
    })
      .then((result) => {
        //showChatForRfq(rfqItemSelectedQuote);
        toast(t('common.rfq_accepted'), { className: "toast-success" });
        props.history.push(
          `${process.env.PUBLIC_URL}/account/dashboard/chats?id=${result.id}`
        );
      })
      .catch((err) => {
        toast(`Failed to create chat for this RFQ: ${err.message}`, {
          className: "toast-error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sellerUpdateRFQ = (data) => {

    setLoadingUpdate(true);

    if (authUser.authUser.userType === 'SELLER' && buyerUpdateProcess) {
      updateRFQSeller({
        price: data.price, packagingDetails: data.packagingDetails,
        condition: data.condition, qualitySpecification: data.qualitySpecification, sellerId: authUser.authUser.id
      }, rfqItemSelectedQuote.id)
        .then(data => {
          toast(t("board.update_ok"), { className: "toast-success" });
          setBuyerUpdateProcess(false);
        })
        .catch(err => {
          toast(t("board.update_failed"), { className: "toast-error" });
        })
        .finally(() => {
          setLoadingUpdate(false);
        });
    }
  }

  const onGetRfqChild = (data) => {
    if (authUser.authUser.userType === 'BUYER' && !buyerUpdateProcess) {
      getRfqChild(rfqItemSelected.baseQuotation.id)
        .then(data => {
          console.log(data)
          setChild(data);
        })
        .catch(err => {
          setChild([]);
        });
    }
  }

  const goToUpdate = () => {
    props.history.push(
      `${process.env.PUBLIC_URL}/account/dashboard/rfqsUpdate/products/${rfqItemSelectedQuote.product.id}/rfq/update`,
      {
        rfqToUpdate: rfqItemSelectedQuote,
      }
    );
  };

  let loader = (null);

  useEffect(() => {
    const rfqId = props.match.params.rfqId;

    getOneRfqOfUser(rfqId).then((result) => {
      setRfqItemSelected(result);
    }).catch((err) => {
      toast(`Error to get RFQ: ${err.message}`, { className: "toast-error" });
    });
  }, [props.match.params.rfqId])

  if (!rfqItemSelectedQuote) {
    loader = (
      <div className="loader text-center">
        <div className="bounce-loader">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    );
  }

  useEffect(() => {

    if (authUser.authUser && rfqItemSelected && !rfqItemSelected.owner) {

      markRFQasRead(rfqItemSelected.id, authUser.authUser.id, authUser.authUser.userType).then((result) => {

        props.getCountRFQUnread(authUser.authUser.id, authUser.authUser.userType);

      }).catch((err) => {

      });

    }
  }, [authUser, rfqItemSelected])


  return (
    <>

      {loader}
      <h2>{t("rfq.rfq_details")}</h2>
      {rfqItemSelectedQuote ? (
        <div className="row">
          {rfqItemSelected && !rfqItemSelected.chat && authUser.authUser && authUser.authUser.userType == "SELLER" ?
            <p style={{ marginTop: 10, paddingLeft: 10 }}>
              <strong style={{ marginRight: 10 }}>
                Note:
              </strong>
              {t('notes.seller_rfq_details1').replace('{submit}', "'" + t("rfq.negotiation") + "'")}
            </p> : null}
          {rfqItemSelected && rfqItemSelected.chat && authUser.authUser ?
            <p style={{ marginTop: 10, paddingLeft: 10 }}>
              <strong style={{ marginRight: 10 }}>
                Note:
              </strong>
              {t('notes.seller_rfq_details2').replace('{submit}', "'" + t("rfq.submit_offer") + "'").replace('{chat}', "'" + t("rfq.chat") + "'")}
            </p> : null}
          {rfqItemSelected && rfqItemSelected.owner && !rfqItemSelected.chat && authUser.authUser && authUser.authUser.userType == "BUYER" ?
            <p style={{ marginTop: 10, paddingLeft: 10 }}>
              <strong style={{ marginRight: 10 }}>
                Note:
              </strong>
              {t('notes.buyer_rfq_details').replace('{submit}', "'" + t("board.update") + "'")}
            </p> : null}
          <div className={authUser.authUser.userType === 'BUYER' && rfqItemSelected.owner && child.length > 0 ? "col-md-9" : "col-md-12"}>
            <div className="col-md-12 mt-3 row">
              <div className={(authUser.authUser && authUser.authUser.userType == "BUYER" && !buyerUpdateProcess && rfqItemSelected && !rfqItemSelected.owner) ? "col-md-6" : "col-md-12"}>
                <h2 className="mb-1 font-weight-light" style={{ backgroundColor: '#00000026', padding: '10px' }}>{t("rfq.product_details")}</h2>
                <p className="mt-3">
                  <strong>{t("products.product_name")}:</strong> {rfqItemSelectedQuote.product.name}
                </p>

                <p>
                  <strong>{t("products.product_category")}:</strong>{" "}
                  {rfqItemSelectedQuote.product.productCategory[langSelected == 'fr' ? 'fr' : 'en']}
                </p>

                <p>
                  <strong>{t("rfq.asking_price")}:</strong> {rfqItemSelectedQuote.product.price}{" "}
                  {rfqItemSelectedQuote.product.currency}
                </p>

                <p>
                  <strong>{t("products.product_country")}:</strong>{" "}
                  {CountryManager.getNameFromId(rfqItemSelectedQuote.product.countryOfOrigin)}
                </p>
              </div>
              {
                (authUser.authUser && authUser.authUser.userType === "BUYER" && !buyerUpdateProcess && rfqItemSelected && !rfqItemSelected.owner) ?

                  <div className="col-md-6">
                    <h2 className="mb-1 font-weight-light" style={{ backgroundColor: '#00000026', padding: '10px' }}>{t("rfq.seller_details")}</h2>
                    <p className="mt-3">
                      <strong>{t("board.account_name")}:</strong> {rfqItemSelected.user.name}
                    </p>

                    <p>
                      <strong>{t("board.company_name")}:</strong>
                      {rfqItemSelected.user.companyName ? rfqItemSelected.user.companyName : '-'}
                    </p>

                    <p>
                      <strong>{t("board.account_country")}:</strong>
                      {rfqItemSelected.user.country ? <ReactFlagsSelect disabled selected={rfqItemSelected.user.country} className={classes.countryFlag} /> : '-'}
                    </p>
                  </div> : null}
            </div>

            <div className="col-md-12 mt-3">
              <h2 className="mb-1 font-weight-light" style={{ backgroundColor: '#00000026', padding: '10px' }}>{t("rfq.rfq_details")}</h2>
              <p className="mt-3">
                <strong>{t("rfq.price")}:</strong> {rfqItemSelected.quotation.price}{" "}
                {rfqItemSelectedQuote.product.currency} /{" "}
                {rfqItemSelectedQuote.product.pricePerUnit[langSelected]}
                {
                  authUser.authUser && authUser.authUser.userType != rfqItemSelected.lastReviewer && rfqItemSelected.quotation.editedFields.includes('PRICE') ?
                    <div className="user-button-action-2 d-inline mt-3" style={{ color: 'green', fontWeight: "bold", padding: 5, marginLeft: 10 }}> {t("common.edited")} </div>
                    : null
                }
              </p>
              <p>
                <strong>{t("rfq.quantity")}:</strong> {rfqItemSelected.quotation.quantity} {rfqItemSelectedQuote.quantityUnit[langSelected]}
                {
                  authUser.authUser && authUser.authUser.userType != rfqItemSelected.lastReviewer && rfqItemSelected.quotation.editedFields.includes('QUANTITY') ?
                    <div className="user-button-action-2 d-inline mt-3" style={{ color: 'green', fontWeight: "bold", padding: 5, marginLeft: 10 }}> {t("common.edited")} </div>
                    : null
                }
              </p>
              <p>
                <strong>{t("rfq.incoterm")}:</strong> {rfqItemSelectedQuote.incoterm.name}
              </p>
              <p>
                <strong>{t("rfq.conditions")}:</strong>
                {
                  authUser.authUser && authUser.authUser.userType != rfqItemSelected.lastReviewer && rfqItemSelected.quotation.editedFields.includes('CONDITION') ?
                    <div className="user-button-action-2 d-inline mt-3" style={{ color: 'green', fontWeight: "bold", padding: 5, marginLeft: 10 }}> {t("common.edited")} </div>
                    : null
                }              </p>
              <p>
                {rfqItemSelected.quotation.condition}
              </p>
              <p>
                <strong>{t("rfq.quality")}:</strong>
                {
                  authUser.authUser && authUser.authUser.userType != rfqItemSelected.lastReviewer && rfqItemSelected.quotation.editedFields.includes('QUALITY_SPECIFICATION') ?
                    <div className="user-button-action-2 d-inline mt-3" style={{ color: 'green', fontWeight: "bold", padding: 5, marginLeft: 10 }}> {t("common.edited")} </div>
                    : null
                }              </p>
              <p>
                {rfqItemSelected.quotation.qualitySpecification}
              </p>
            </div>

            <div className="col-md-12 mt-3">
              <h2 className="mb-1 font-weight-light" style={{ backgroundColor: '#00000026', padding: '10px' }}>RFQ LOGISTIC</h2>
              <p className="mt-3">
                <strong>{t("rfq.method")}:</strong>{" "}

                {methodOfShipmentData.find(ms => ms.value === rfqItemSelectedQuote.methodOfShipment)
                  ? methodOfShipmentData.find(ms => ms.value === rfqItemSelectedQuote.methodOfShipment).name
                  : methodOfShipmentData.find(ms => ms.value === 'ROAD').name
                }
              </p>

              {rfqItemSelectedQuote.typeOfShipment ? (
                <p>
                  <strong>{t("rfq.type")} :</strong>{" "}
                  {rfqItemSelectedQuote.typeOfShipment.name}
                </p>
              ) : null}

              {rfqItemSelectedQuote.port ? (
                <p>
                  <strong>{t("rfq.port")} :</strong>{" "}
                  {rfqItemSelectedQuote.port.name}
                </p>
              ) : null}

              {
                rfqItemSelectedQuote.arrival ?

                  <p>
                    <strong>{t("rfq.arrival")} :</strong> {rfqItemSelectedQuote.arrival}
                  </p> : null
              }
              <p>
                <strong>{t("rfq.packaging")}:</strong>
                {
                  authUser.authUser && authUser.authUser.userType != rfqItemSelected.lastReviewer && rfqItemSelected.quotation.editedFields.includes('PACKAGING_DETAILS') ?
                    <div className="user-button-action-2 d-inline mt-3" style={{ color: 'green', fontWeight: "bold", padding: 5, marginLeft: 10 }}> Edited </div>
                    : null
                }
              </p>
              <p>
                {rfqItemSelected.quotation.packagingDetails}
              </p>
            </div>
          </div >
          <div className="col-md-3 mt-3" style={{ display: authUser.authUser.userType === 'BUYER' && rfqItemSelected.owner ? "visible" : "none" }}>
            <FeaturedProductsTwo data={child} />
          </div>
        </div >
      ) : null
      }

      {
        buyerUpdateProcess ? <div>
          <form>
            <div className="row">
              <div className="form-group required-field col-md-12">
                <label> {t("rfq.price")} </label>
                <input
                  type="number"
                  className="form-control"
                  name="price"
                  ref={register({ required: true })}
                  required
                />
                {errors.price && (
                  <div className="text-danger mt-1">{t("rfq.price_required")}</div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group required-field col-md-12">
                <label> {t("rfq.conditions")} </label>
                <textarea
                  className="form-control"
                  cols="4"
                  name="condition"
                  ref={register({ required: true })}
                  required
                ></textarea>
                {errors.condition && (
                  <div className="text-danger mt-1">{t("rfq.condition_required")}</div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group required-field col-md-12">
                <label> {t("rfq.quality")} </label>
                <textarea
                  className="form-control"
                  cols="4"
                  name="qualitySpecification"
                  ref={register({ required: true })}
                  required
                ></textarea>
                {errors.qualitySpecification && (
                  <div className="text-danger mt-1">
                    {t("rfq.quality_required")}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-4 text-center">
              <button onClick={handleSubmit(sellerUpdateRFQ)} className="btn btn-primary py-4">
                {loadingUpdate ? (
                  <span>
                    <i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>
                    &nbsp;&nbsp;
                  </span>
                ) : null}
                {t("board.update")}
              </button>
              &nbsp;&nbsp;&nbsp;
              <button onClick={() => setBuyerUpdateProcess(false)} className="btn btn-danger py-4">
                {t("board.cancel")}
              </button>
            </div>
          </form>
        </div> : null}

      {!notShowBtnAction ? (
        <div className="mt-3 text-center" style={{ display: 'flex', justifyContent: 'space-between' }}>


          {(authUser.authUser && authUser.authUser.userType === "SELLER" && !buyerUpdateProcess && rfqItemSelected && !rfqItemSelected.owner && rfqItemSelected.hasChat && !rfqItemSelected.baseQuotation.processing) || (authUser.authUser && authUser.authUser.userType === "BUYER" && !buyerUpdateProcess && rfqItemSelected && !rfqItemSelected.owner && rfqItemSelected.hasChat && !rfqItemSelected.baseQuotation.processing) ? (
            // <button className="btn btn-primary py-4" style={{ color: 'white' }}>
            <UpdateRfqModal rfq={rfqItemSelected} authUser={authUser.authUser} refreshRfq={() => getOneRfqOfUser(rfqItemSelected.id).then((result) => {
              setRfqItemSelected(result);
              setShowModalRfqDetail(true)
            })} />
            // </button>
          ) : null}

          {rfqItemSelected && !rfqItemSelected.owner && rfqItemSelected.hasChat && !buyerUpdateProcess ? (
            <Link
              to={`${process.env.PUBLIC_URL}/account/dashboard/rfqsChat/quote/${rfqItemSelected.id}/chat`}
              className="btn btn-primary py-4"
            >
              <i
                class="fa fa-comments"
                style={{ cursor: "pointer", fontSize: "18px", color: "white" }}
                aria-hidden="true"
              ></i>{" "}
              &nbsp; {t("rfq.chat")}
            </Link>
          ) : null}

          {/* {
            rfqItemSelected ? !rfqItemSelected.baseQuotation.processing && authUser.authUser ?
              <div>
                {disableConfirmProceedSeller() || disableConfirmProceedBuyer() ?
                  <button 
                    onClick={() => setConfirmProceedModal(true, true)} 
                    className="btn-cancel-rfq float-right" 
                    style={{ display: rfqItemSelected ? 'block' : 'none', fontWeight: "bold", margin: "0px" }}
                  > 
                    {this.props.t('chat.cancel')}
                  </button> :
                  <button 
                    onClick={() => setConfirmProceedModal(true)} 
                    className="btn-confirm-rfq float-right" 
                    style={{ display: rfqItemSelected ? 'block' : 'none', fontWeight: "bold", margin: "0px" }}
                  > 
                    {this.isBuyer() ? this.props.t('chat.confirm1_seller') : this.props.t('chat.confirm1_buyer')} {this.props.t('chat.confirm2')} 
                  </button>
                }
              </div> : null : null
          } */}

          {authUser.authUser && authUser.authUser.userType === "BUYER" && rfqItemSelected && rfqItemSelected.owner && child.length <= 0 && !rfqItemSelected.hasChat ? (
            <button onClick={goToUpdate} className="btn btn-primary py-4">
              {t("board.update")}
            </button>
          ) : null}

          {rfqItemSelected && !rfqItemSelected.chat && authUser.authUser && authUser.authUser.userType === "SELLER" ? (
            <button
              onClick={createRFQChat}
              className="btn btn-primary py-4"
              disabled={loading}
            >
              {loading ? (
                <span>
                  <i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>
                  &nbsp;&nbsp;
                </span>
              ) : null}
              {t("rfq.negotiation")}
            </button>
          ) : null}

        </div>
      ) : null}

      <SuccessModal
        shouldShowModal={showModalRfqDetail}
        close={() => setShowModalRfqDetail(false)}
        title={t('notes.contract_sign')}
        subtitle={t('notes.will_reply')}
        button={t('notes.go_to_rfq')}
        to={`${process.env.PUBLIC_URL}/account/dashboard/rfqs`}
      />
    </>
  );
};

export default withTranslation()(React.memo(withRouter(RfqDetatils)));
