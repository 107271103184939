import backendURLs from "../utils/backendURL";
import { joinBaseUrlWithRequestParams, makeRequest, replaceIdParamInUrlFormat } from "./utils"

export const makeStripePayement = (data) => {

    let url = joinBaseUrlWithRequestParams(`${backendURLs.CONTRACT.STRIPE_PAYMENT}`, [
        {param: 'token', value: data.tokenToSend},
        {param: 'amount', value: data.amount},
        {param: 'currency', value: data.currency},
        {param: 'qus_id', value: data.qusId}    
    ]);

    return makeRequest('post', url, null, {});
}

export const makeBankPayement = (data) => {

    let url = joinBaseUrlWithRequestParams(`${backendURLs.CONTRACT.BANK_PAYMENT}`, [
        {param: 'qus_id', value: data.qusId},
        {param: 'currency', value: data.currency},    
    ]);

    return makeRequest('post', url, data, {
        multipart: true,
        shouldSkipDataParsing: false,
        fileData: ['file'],});
}

export const paypalLinkPayment = (id, amount) => {
    let url = replaceIdParamInUrlFormat(`${backendURLs.CONTRACT.PAYPAL_LINK}`, id);
    return makeRequest('post', url+`?amount=${amount}&client_url=${window.location.href}`, null, {});
}

export const paypalPayment = (id, paymentId, payerId) => {
    let url = replaceIdParamInUrlFormat(`${backendURLs.CONTRACT.PAYPAL_PAYMENT}`, id);
    return makeRequest('post', url+`?payment_id=${paymentId}&payer_id=${payerId}`, null, {});
}

export const getFees = () => {

    let url = `${backendURLs.CONTRACT.GET_FEES}`;

    return makeRequest('get', url, null, {});
}

export const mobilePayment = (id, amount) => {
    let url = replaceIdParamInUrlFormat(`${backendURLs.CONTRACT.MOBILE_PAYMENT}`, id);
    return makeRequest('post', url+'?amount='+amount+'&url=https://sookotrade.com/account/dashboard/notifications', null, {});
}

// export const makeMobilePayment = (signature) => {
//     let url = backendURLs.CONTRACT.MOBILE_PAYMENT_PAY.replace('{signature}', signature);
//     return makeRequest('post', url, null, {});
// }

export const getSettings = () => {

    let url = `${backendURLs.SETTINGS}`;
    return makeRequest('get', url, null, {});
}

export const getAmountPaid = (id) => {
    let url = replaceIdParamInUrlFormat(`${backendURLs.AMOUNT_TO_PAY}`, id);
    return makeRequest('get', url, null, {});
}


export const createContract = (docId) => {

    let url = joinBaseUrlWithRequestParams(`${backendURLs.CONTRACT.CREATE_CONTRACT}`, [
        {param: 'id', value: docId},
    ]);

    return makeRequest('post', url, null, {});
}

export const markContractAsRead = (docId) => {

    let url = replaceIdParamInUrlFormat(`${backendURLs.CONTRACT.MARK_AS_READ}`, docId);

    return makeRequest('put', url, null, {shouldSkipDataParsing: true});
}

export const sendFirstDocToBuyerBase64 = (qusId, base64File, type) => {

    let url = replaceIdParamInUrlFormat(`${backendURLs.CONTRACT.SIGN_CONTRACT_DOCS}`, qusId);

    return makeRequest('post', url, {base64File: base64File}, {});
}

export const sendFirstDocToBuyerURL = (qusId, file_url, type) => {

    let url = replaceIdParamInUrlFormat(`${backendURLs.CONTRACT.SIGN_CONTRACT_DOCS_URL}`, qusId);

    return makeRequest('post', url+'?url='+file_url, null, {});
}



export const updateContrat = (data, base64File, contractId) => {
    
    const dataFormated = [];

    Object.entries(data).forEach(([key, val]) => dataFormated.push( {param:key, value: val } ) );
    
    let url = joinBaseUrlWithRequestParams(replaceIdParamInUrlFormat(`${backendURLs.CONTRACT.UPDATE_CONTRACT}`, contractId), [
        ...dataFormated
    ]);

    return makeRequest('put', url, {base64File: base64File}, {shouldSkipDataParsing: true});
}