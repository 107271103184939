import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProductTypeFour(props) {

    let { addClass, product } = props;
    const { authUser } = useSelector(state => state.authUser);
    console.log('Je suis dans la proposition: ', product)
    return (
        <div className={`product-default ${addClass}`}>
            <Link className={`product-default ${addClass}`} to={`${process.env.PUBLIC_URL}/account/dashboard/rfqs/${product.id}/details`}>
                <figure>
                    <img
                        src={product.user.avatar.startsWith('https://encryp') ? product.user.avatar : `${process.env.BACKEND_BASE_URL}${product.user.avatar}`}
                    />
                </figure>

                <div className="product-details">
                    <h2 className="product-title">
                        {product.user.name}
                    </h2>

                    <div className="price-box">
                        <p><span className="product-price">{product.quotation.price.toFixed(2)} {product.baseQuotation.product.currency} </span></p>
                    </div><div style={{
                        right: 0,
                        bottom: 0,
                        paddingRight: '30px',

                    }}>
                        {
                            product.lastReviewer == 'SELLER' ?

                                <div className="user-button-action-2 d-inline mt-3" style={{ color: 'green', fontWeight: "bold", padding: 3 }}> Edited </div> :
                                <div className="user-button-action-2 d-inline mt-3" style={{ backgroundColor: 'white', fontWeight: "bold", padding: 3, color: 'white' }}> Nothing </div>
                        }
                    </div>
                </div>

            </Link>
        </div>
    )
}

export default ProductTypeFour;