import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { getSettings } from '@action/contract';
import { useTranslation } from 'react-i18next';
import { ABOUT, CONTACT, LOGIN, PRIVACY_POLICY, TERM_AND_CONDITIONS } from '@routes/urls';

function Footer(props) {
    const { t } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        getSettings().then((result) => {
            setData(result);
        }).catch((err) => {
            // toast(`Error to get fees app: ${err.message}`, { className: "toast-error" });
        });
    }, [])

    const { i18n } = useTranslation();
    
    return (
        <footer className="footer">
            <div className="footer-middle">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4">
                            <div className="widget">
                                <h4 className="widget-title">{t("footer.contact_info")}</h4>
                                <ul className="contact-info mb-2">
                                    <li>
                                        <span className="contact-info-label">{t("footer.address")}:</span>{data.length > 0 ? data.find(d => d.key == 'ADDRESS')[i18n.language == 'en' ? 'valueEn' : 'valueFr'] : ''}
                                </li>
                                    <li>
                                        <span className="contact-info-label">{t("footer.phone")}:</span><Link to="tel:">{data.length > 0 ? data.find(d => d.key == 'PHONE')[i18n.language == 'en' ? 'valueEn' : 'valueFr'] : ''}</Link>
                                    </li>
                                    <li>
                                        <span className="contact-info-label">{t("footer.email")}:</span> <Link to="mailto:mail@example.com">{data.length > 0 ? data.find(d => d.key == 'EMAIL')[i18n.language == 'en' ? 'valueEn' : 'valueFr'] : ''}</Link>
                                    </li>
                                </ul>

                                <div className="social-icons">
                                    <Link to="#" className="social-icon social-instagram icon-instagram" target="_blank" title="Instagram"></Link>
                                    <Link to="#" className="social-icon social-twitter icon-twitter ml-3" target="_blank" title="Twitter"></Link>
                                    <Link to="#" className="social-icon social-facebook icon-facebook ml-3" target="_blank" title="Facebook"></Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-9 col-md-8">
                            {/* <div className="widget widget-newsletter">
                                <h4 className="widget-title m-b-1 pb-2">{t("footer.subscribe_newsletter")}</h4>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <p className="pt-2 mb-lg-0">{t("footer.get_all_info")}</p>
                                    </div>

                                    <div className="col-lg-6">
                                        <form action="#" className="d-flex mb-0 w-100">
                                            <input type="email" style={{borderColor: "#dfdfdf !important"}} className="form-control mb-0" placeholder="Email address" required="" />

                                            <input type="submit" className="btn btn-primary shadow-none" value={t("footer.subscribe")} />
                                        </form>
                                    </div>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col-sm-6 col-lg-7">
                                    <div className="widget">
                                        <h4 className="widget-title">{t("footer.customer_service")}</h4>

                                        <ul className="links link-parts row mb-0">
                                            <div className="link-part col-sm-6">
                                                <li><Link to={ABOUT }>{t("footer.about_us")}</Link></li>
                                                <li><Link to={CONTACT}>{t("footer.contact_us")}</Link></li>
                                            </div>
                                            <div className="link-part col-sm-6">
                                                <li><Link to={PRIVACY_POLICY}>{t("footer.privacy")}</Link></li>
                                                <li><Link to={TERM_AND_CONDITIONS}>{t("footer.terms")}</Link></li>
                                                <li><Link to={LOGIN} className="login-link">{t("footer.login")}</Link></li>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="container d-flex justify-content-between align-items-center flex-wrap">
                    <p className="footer-copyright py-3 pr-4 mb-0">© {t("footer.copyright")}</p>

                    <img src={ process.env.PUBLIC_URL + "/assets/images/demo/payments.png" } alt="payment methods" className="footer-payments py-3" />
                </div>
            </div>
        </footer>
    )
}

export default withTranslation()(React.memo( Footer ));