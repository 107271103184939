import backendURLs from "../utils/backendURL";
import { joinBaseUrlWithParams,joinBaseUrlWithRequestParams, joinBaseUrlWithSearchParams, makeActionRequest, makeRequest, replaceIdParamInUrlFormat } from "./utils"
import * as sellerProductsActionsType from '../constants/sellerProducts-action-types';

export const getProductsOfSeller = (seller_id) => {

    const url = replaceIdParamInUrlFormat(`${backendURLs.SELLER.PRODUCTS.FETCH}`, seller_id);

    return makeRequest('get', url, null, {});
}

export const createProductsOfSeller = (data) => {

    const url = replaceIdParamInUrlFormat(`${backendURLs.SELLER.PRODUCTS.CREATE}`);

    //const url = joinBaseUrlWithSearchParams(`${backendURLs.SELLER.PRODUCTS.CREATE}`,  [{param: 'image', value: data.image}]);

    return makeRequest('post', url, data, {shouldSkipDataParsing: false, multipart: true, fileData: ['image']});
}

export const updateProductsOfSeller = (data, productId) => {

    const url = replaceIdParamInUrlFormat(`${backendURLs.SELLER.PRODUCTS.UPDATE}`, productId);

    //const url = joinBaseUrlWithSearchParams(`${backendURLs.SELLER.PRODUCTS.CREATE}`,  [{param: 'image', value: data.image}]);

    return makeRequest('post', url, data, {shouldSkipDataParsing: false, multipart: true, fileData: ['image']});
}

export const deleteProductsOfSeller = (sellerId, productId) => {

    //const url = replaceIdParamInUrlFormat(`${backendURLs.SELLER.PRODUCTS.DELETE_ONE}`, productId);

    const url = joinBaseUrlWithRequestParams(replaceIdParamInUrlFormat(`${backendURLs.SELLER.PRODUCTS.DELETE_ONE}`, productId),  [
        {param: 'seller_id', value: sellerId}
    ]);
    //const url = joinBaseUrlWithSearchParams(`${backendURLs.SELLER.PRODUCTS.CREATE}`,  [{param: 'image', value: data.image}]);

    return makeRequest('delete', url, null, {shouldSkipDataParsing: false});
}