import './docs.scss';
import DocsList  from './DocsList';
import { connect } from 'react-redux';
import React, { Component } from 'react';

class index extends Component {
    
    render() {

        const {getCountDocsUnread} = this.props;

        return (
            <div className="row"> 
                <div className="col-md-12 col-xs-12">
                    <DocsList getCountDocsUnread={getCountDocsUnread} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({authUser}) => ({
    authUser
})

export default connect(mapStateToProps, null)(index);
