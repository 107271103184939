import backendURLs from "../utils/backendURL";
import { joinBaseUrlWithParams, joinBaseUrlWithSearchParams,joinBaseUrlWithRequestParams, makeActionRequest, makeRequest, replaceIdParamInUrlFormat } from "./utils"

export const getContractsOfUser = () => {

    let url = `${backendURLs.CONTRACT.GET_ALL_CONTRACTS_FOR_USER}`;

    return makeRequest('get', url, null, {});
}


export const getCountUnreadedContracts = () => {

    let url = `${backendURLs.CONTRACT.GET_UNREAD_CONTRACTS_USER}`

    return makeRequest('get', url, null, {});
}

export const getContractAttachedDocuments = (id) => {
    let url = replaceIdParamInUrlFormat(`${backendURLs.CONTRACT.GET_ALL_CONTRACTS_DOCUMENTS}`, id);
    return makeRequest('get', url, null, {});
}

export const confirmContract = (id, password, data, callback = null) => {

    const url = joinBaseUrlWithParams(`${backendURLs.CONTRACT.CONFIRM_SHOP}`, [
        { param: 'id', value: id },
        { param: 'password', value: password },
    ]);

    return makeRequest('post', url, data, {
        shouldFetchAuthUserAfterSuccess: true,
        multipart: true,
        shouldSkipDataParsing: false,
        fileData: ['file'],
        callback
    });
}

export const sendNewRatings = (id, data, callback = null) => {

    const url = joinBaseUrlWithParams(`${backendURLs.CONTRACT.SEND_RATING}`, [
        { param: 'id', value: id }
    ]);

    return makeRequest('post', url, data, {
        shouldFetchAuthUserAfterSuccess: true,
        multipart: true,
        shouldSkipDataParsing: false,
        fileData: ['file'],
        callback
    });
}

export const updateContractAttachedFile = (data, callback = null) => {

    const url = replaceIdParamInUrlFormat(`${backendURLs.CONTRACT.UPDATE_ATTACHED_FILE}`);

    return makeRequest('post', url, data, {
        shouldFetchAuthUserAfterSuccess: true,
        multipart: true,
        shouldSkipDataParsing: false,
        fileData: ['file'],
        callback
    });
}
