import './style.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../common/breadcrumb';
import React, { useState, useEffect } from 'react';
import { getSettings } from '@action/contract';
import { USER_GUIDE } from '@routes/urls';


function Privacy() {

    const [data, setData] = useState([]);
    const { i18n } = useTranslation();

    useEffect(() => {
        getSettings().then((result) => {
            setData(result);
        }).catch((err) => {
            // toast(`Error to get fees app: ${err.message}`, { className: "toast-error" });
        });
    }, [])

    return (
        <>
            <Helmet>
                <title>Sooko - Privacy Policy </title>
            </Helmet>

            <h1 className="d-none">Sooko - Privacy Policy</h1>

            <div className="main">
                <Breadcrumb current="Privacy Policy" parent="Pages" />

                <div className="container">
                    {i18n.language == 'en' ?
                        <div className="row">
                            <div className="col-md-12" style={{ marginTop: '5%' }}>
                                <h1 className="light-title mb-5"><strong>Privacy Statement</strong></h1>
                                <p className="title">Last updated: October 30, 2021.</p>
                                <p className="p mt-5">
                                    We know that you care about your information and personal data, as well as your company’s,
                                    provided for  business reasons, and we are sensitive to the trust you place in us in this regard.
                                    This Privacy Statement describes how  Sookotrade.com and its affiliates (collectively  "Sooko")
                                    collect,  process  and use your online information that links to this Privacy Statement
                                    (collectively the  "Sooko  Services"). By using the Sooko Services, you consent to the practices
                                    described in this Privacy Statement.
                                </p>
                                <ul className="ul">
                                    <li className="li font-weight-bold">What information Sookotrade.com its customers collect?</li>
                                    <li className="li font-weight-bold">For what purposes does Sooko use your information?</li>
                                    <li className="li font-weight-bold">What is the use of navigation cookies and other identifiers?</li>
                                    <li className="li font-weight-bold">Does Sooko share your information?</li>
                                    <li className="li font-weight-bold">Is your information protected?</li>
                                    <li className="li font-weight-bold">What about advertising?</li>
                                    <li className="li font-weight-bold">What information can I access?</li>
                                    <li className="li font-weight-bold">What options are available to me?</li>
                                    <li className="li font-weight-bold">Are children allowed to use the Sooko Services?</li>
                                    <li className="li font-weight-bold">What are the terms of use, notices and periodic reviews?</li>
                                    <li className="li font-weight-bold">What information and practical actions relate to this statement?</li>
                                    <li className="li font-weight-bold">What are the examples of information collected?</li>
                                </ul>
                                <h3 className="mb-3 mt-4">What information sookotrade.com its customers collect?</h3>
                                <p className="p">
                                    We collect your personal and business information in order to provide and continually improve
                                    our services. The following types of information we collect include:
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        <b>Information provided by you.</b> We receive and store all information you provide through the
                                        Sooko Services. <Link to={USER_GUIDE}>Click here</Link>  to see some examples of the information we collect. You may
                                        choose not to provide certain information, but in doing so you may limit your access to
                                        many of the  Sooko Services.
                                    </li>
                                    <li className="li">
                                        <b>Information Collected Automatically.</b> We automatically collect and store certain types of information
                                        regarding your use of  the SookoServices, including information about your interaction with the content and
                                        services offered through the  SookoServices. Like many other websites, we use browser cookies and other unique
                                        identifiers, and obtain certain types of information when your web browser or device accesses  the Sooko
                                        Services and other content served by or on behalf of Sooko  on other websites.
                                    </li>
                                    <li className="li">
                                        <b>Information from other sources.</b> We may receive information about you from other sources,
                                        such as information regarding the updating of product delivery information or your address
                                        from our  partners  (carriers, freight forwarders, financial institutions, consultants,
                                        etc.). ,  information we use to rectify our records and facilitate your  nexttransactions.
                                        <a href="https://www.amazon.ca/gp/help/customer/display.html/ref=ap_signin_notification_privacy_notice?ie=UTF8&language=fr_CA&nodeId=918814#GUID-39BF3145-BD72-423B-886C-C01B24F96663__SECTION_34632E7569CE4780B6E22533575E8F2E" target="_blank">Click here</a>  for examples of additional information we collect.
                                    </li>
                                </ul>
                                <h3 className="mb-3 mt-4">For what purposes does Sooko use your information?</h3>
                                <p className="p">
                                    We use your personal information to operate, provide, develop and improve the services we
                                    offer to our customers. These objectives include:
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        <b>Purchase, sale,  delivery of products and provision of services.</b> We use your information
                                        for the purposes of processing  your transactions,delivering products and providing services,
                                        processing payments and communicating with you about  your transactions,services and promotional offers.
                                    </li>
                                    <li className="li">
                                        <b>Provision and improvement of the Sooko</b> Services <b>as well as the resolution of problems
                                            related to our services</b>. We use your information to provide you with features, analyze performance,
                                        correct errors, and improve the accessibility and effectiveness of the Sooko Services.
                                    </li>
                                    <li className="li">
                                        <b>Recommendations and customization.</b> We use your information to recommend features, products and
                                        services that may be of interest to you, to identify your preferences, and to personalize your experience
                                        with the Sooko Services.
                                    </li>
                                    <li className="li">
                                        <b>Provision of image and photo services.</b> When you use our services you may  insert  images
                                        and/orphotos; we use your images,  photos and other information to respond to your requests, provide
                                        you with the requested service and improve our services.
                                    </li>
                                    <li className="li">
                                        <b>Comply with legal obligations.</b> In some cases, we have a legal obligation to collect and use your
                                        information. For example, we collect information from you about your place of business and data to
                                        facilitate payments for identity verification or other purposes.
                                    </li>
                                    <li className="li">
                                        <b>Communicate with you.</b> We use your information to communicate with you or have you  to  communicate
                                        between buyer andsupplier,  in connection with the  Sooko Services through various channels (e.g., by phone,
                                        email, or electronic conversation on the website).
                                    </li>
                                    <li className="li">
                                        <b>Advertising.</b> We use your information to display interest-based advertisements for features, products and services that may be of interest to you. We do not use information that personally identifies you to display interest-based advertising.
                                    </li>
                                    <li className="li">
                                        <b>Fraud prevention and credit risk identification.</b> We use your information to prevent and detect
                                        fraud and abuse to protect the safety of our customers  in  Sooko. Our partners andn we may also use
                                        creditworthiness assessment methods to assess and manage payment or   credit risks.
                                    </li>
                                </ul>

                                <h3 className="mb-3 mt-4">What is the use of navigation cookies and other identifiers?</h3>
                                <p className="p">
                                    In order to enable our systems to recognize your browser or device and to provide and improve the SookoServices,
                                    we use browser cookies and other identifiers. To learn more about cookies and how we use them,  please read our
                                    <a href="https://www.amazon.ca/-/fr/gp/help/customer/display.html/?nodeId=201890250" target="_blank">statement on the use of cookies</a>.
                                </p>

                                <h3 className="mb-3 mt-4">Does Sooko share your information?</h3>
                                <p className="p">
                                    Protecting our customers' information is crucial to our business, and we do not sell our customers'
                                    information to third parties. We only disclose this information in the circumstances described below
                                    and only to eSooko subsidiaries or partners facilitating the  closing of your transactions and the delivery
                                    of customer products or services   that have adopted this privacy statement or whose business practices respect
                                    the privacy of their customers at least as strictly.
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        <b>Financial transactions involving third parties.</b> We provide you with services, products, applications
                                        or skills provided by third parties for use on or through the  SookoServices. For example, you may order
                                        products from third parties  in  our marketplaces, download applications from third-party application providers
                                        from our website or applications, and activate  third-party skills through our services. We also offer services
                                        or facilitate the  development of product lines jointly with third-party companies. When a third party participates
                                        in your operations and we share your information with that third party in connection with those transactions,
                                        we report it to you.
                                    </li>
                                    <li className="li">
                                        <b>Third Party Service Providers.</b> We retain the services of companies and individuals to perform certain
                                        tasks on our behalf. For example, processing orders for products or services, delivering  products,sending email
                                        or postal mail, removing redundant data from customer lists, analyzing data, marketing assistance, producing
                                        search results and links (including ads and paid links),  payment processing, content transmission, application
                                        of a rating system, credit risk assessment and management, and customer service delivery. These third-party service
                                        providers have access to the information necessary to perform their tasks, but may not use it for any other purpose.
                                    </li>
                                    <li className="li">
                                        <b>Business transfers.</b> As part of the development of our business, we may sell or acquire other activities
                                        or services. Customer information is generally one of the items transferred to the new acquirer in this type of
                                        transaction, but this information remains subject to any prior to the acquisition or sale privacy statement
                                        (unless, of course, the customer explicitly consents to new terms). Furthermore, in the unlikely event that
                                        Sooko  or a substantial part of its assets were to be acquired, customer information would of course be transferred
                                        with the assets.
                                    </li>
                                    <li className="li">
                                        <b>Protection of Sooko and third parties.</b> We disclose information in our customers' accounts and other
                                        information for the purpose of complying with the law,  enforcing our <Link to={`${process.env.PUBLIC_URL}/terms-and-conditions`}>terms of use</Link> or other agreements, or
                                        protecting the rights,property or safetyofe Sooko,its users or others. This disclosure policy includes the
                                        transmission of information to other businesses or organizations for the purpose of preventing fraud and reducing
                                        credit risk.
                                    </li>
                                </ul>
                                <p className="p">
                                    In all cases other than those mentioned above, you will be notified when your information may be disclosed to third parties,
                                    and you will then have the opportunity to choose not to share this information.
                                </p>

                                <h3 className="mb-3 mt-4">Is my information protected?</h3>
                                <p className="p">
                                    We design our systems with your security and privacy in place.
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        We strive to protect the security of your information during transmission by using appropriate protocols and software.
                                    </li>
                                    <li className="li">
                                        Nos partners and nous follow the Payment Card Industry Data Security Standard (PCI DSS) when processing credit card data.
                                    </li>
                                    <li className="li">
                                        We maintain physical, electronic and procedural safeguards with respect to the collection, storage and disclosure of customer
                                        information. Our security procedures may require us to ask you for proof of your identity before we can provide you with your
                                        information.
                                    </li>
                                    <li className="li">
                                        Our systems have security features that protect them from unauthorized access and data loss. You can control these functions and
                                        configure them according to your needs.
                                    </li>
                                    <li className="li">
                                        It is important that you prevent unauthorized access to your password and your computers, devices, and applications. Be sure to log off
                                        when you have finished using a computer.
                                    </li>
                                </ul>

                                <h3 className="mb-3 mt-4">What about advertising?</h3>
                                <ul className="ul">
                                    <li className="li">
                                        <b>Third Party Advertisers and Links to Other Websites.</b> The  Sooko Services may include advertising from third parties and links
                                        to other websites and applications. Third-party advertisers may collect information when you interact with their content, advertisements
                                        and services.
                                    </li>
                                    <li className="li">
                                        <b>Use of Third Party Advertiser Services.</b> We provide advertisers with information that allows them to serve more useful and
                                        relevant ads on  Sooko  and measure their effectiveness. In this case, we never share your name or other information that directly
                                        identifies you. Instead, we use an advertising identifier such as a browser cookie or other device identifier. For example, if you
                                        have already downloaded one of our apps, we will share your advertising identifier and data about that download so that you do not
                                        receive an advertisement inviting you to download it again. Some advertising companies also use this information to provide you with
                                        relevant ads from other advertisers.
                                    </li>
                                </ul>

                                <h3 className="mb-3 mt-4">What information can I access?</h3>

                                <p className="p">
                                    You have access to your information, including your name, address, payment methods, profile information, business
                                    information, and order history.
                                </p>

                                <h3 className="mb-3 mt-4">What choices are available to me?</h3>

                                <p className="p">
                                    If you have any questions about how we collect and use yourinformation, please contact ourcustomers. Nos Sooko Services
                                    also offers you setting options to choose how your information is used.
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        As mentioned above, you may choose not to provide certain information, but in doing so you may limit your
                                        access to many of the Sooko Services.
                                    </li>
                                    <li className="li">
                                        You can add information or update your information on your profilepages. When you update your information,
                                        we generally keep a copy of the previous version on file.
                                    </li>
                                    <li className="li">
                                        If you do not wish to receive emails or other types of communication from us, please update your communication
                                        preferences.  If you do not want to receive notifications from us when you use our app, please adjust your
                                        notification settings.
                                    </li>
                                    <li className="li">
                                        If you do not want to see interest-based advertising, please adjust your preferences  for advertising campaigns.
                                    </li>
                                    <li className="li">
                                        The help function on most browsers and devices tells you how to prevent your browser or device from accepting new cookies
                                        or other identifiers, how to be notified when you receive a new cookie, or how to block all cookies. However, because cookies
                                        and identifiers allow you to take full advantage of some of the essential functions of the Sooko Services, we recommend that
                                        you do not block them. For example, if you block or otherwise reject our cookies, you will not be able to place an order or
                                        use services that require you to log in to your account.
                                    </li>
                                    <li className="li">
                                        If you wish to browse our websites without linking browsing history to your account, you can do so by logging
                                        out of your account and blocking cookies on your browser.
                                    </li>
                                    <li className="li">
                                        You will also be able to opt out of certain other types of data usage by updating your settings on the sookotrade.com site.
                                        Most devices offer users the ability to change device permissions (e.g., to disable or access location services or contacts).
                                        For most devices, these settings are usually accessible from the device's settings setting menu. If you have any questions
                                        about how to change your device's permissions, we recommend that you contact your mobile service provider or device manufacturer.
                                    </li>
                                </ul>
                                <p className="p">
                                    In addition, to the extent required by applicable law, you may have the right to request access to or correction of your
                                    personal data. If you wish to submit such a request, please go to  <a href="https://www.amazon.ca/-/fr/gp/privacycentral/dsar/preview.html" target="_blank">Request my information</a>  or contact  <a href="https://www.amazon.ca/-/fr/gp/help/contact-us" target="_blank">Customer Service</a>.
                                    Depending on the choice you make in connection with your personal data, your access to certain services may be limited or
                                    withdrawn.
                                </p>

                                <h3 className="mb-3 mt-4">Are children allowed to use the Sooko Services?</h3>

                                <p className="p">
                                    Sooko does not  facilitate the sale of products intended for purchase by minors. The purchase  or sale of products
                                    intended for minors must be carried out by an adult. If you are under the age of 18, you may not use the  Sooko
                                    Services without the participation of a parent or guardian.
                                </p>

                                <h3 className="mb-3 mt-4">Terms of Use, Notices and Revisions</h3>

                                <p className="p">
                                    If you choose to use the SookoServices, your use and any dispute regarding privacy matters are governed by this <Link to={`${process.env.PUBLIC_URL}/terms-and-conditions`}>statement
                                        and  our terms of use</Link>,including limitation of damages, dispute resolution, and enforcement of the laws of the State of
                                    Delaware, USA.
                                </p>
                                <p className="p">
                                    If you have any questions about Sooko's protection of your personal information,  please <a href="https://www.amazon.ca/-/fr/gp/help/contact-us" target="_blank">contact us</a> by sending us a
                                    detailed message and we will do our best to respond satisfactorily.
                                </p>
                                <p className="p">
                                    Our company is constantly evolving, and this privacy statement is also subject to change. Please check our website
                                    frequently for the latest changes. Unless otherwise noted, this Privacy Statement governs all information relating to
                                    you or your account. We keep our promises and will not change our practices and conditions to make them less protective
                                    of our customers' personal information collected in the past without the prior consent of the customers concerned.
                                </p>

                                <h3 className="mb-3 mt-4">Information and practices relating to this statement</h3>

                                <h3 className="mb-3 mt-4">Examples of Information Collected</h3>

                                <h3 className="mb-3 mt-4">Information you provide to us when you use the Sooko Services.</h3>
                                <p className="p">
                                    You provide us with information when you:
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        Search for products or services on our website;
                                    </li>
                                    <li className="li">
                                        Add or remove a product,place an order through the  Sooko Services or use these services;
                                    </li>
                                    <li className="li">
                                        Download, stream, view or use content on a device or through a service or application on a device;
                                    </li>
                                    <li className="li">
                                        Provide information in your account  (you may have more than one account if you have used more than one email address or cell phone number on our site) or inyourdevice;
                                    </li>
                                    <li className="li">
                                        Speak or otherwise interact withthird parties in connection with our services;
                                    </li>
                                    <li className="li">
                                        Import your contacts;
                                    </li>
                                    <li className="li">
                                        Configure your settings, provide data access permissions for or interact with an Amazon device or service;
                                    </li>
                                    <li className="li">
                                        Provide information, goods or services toe Sooko's customers;
                                    </li>
                                    <li className="li">
                                        Offer your products or services on or through the Sooko Services;
                                    </li>
                                    <li className="li">
                                        Contact us by phone, email or otherwise;
                                    </li>
                                    <li className="li">
                                        Fill out a questionnaire, support ticket or contest entry form;
                                    </li>
                                    <li className="li">
                                        Upload or stream images, photos or other files to Sooko or other Sooko Services;
                                    </li>
                                    <li className="li">
                                        Use our services such as Prime Video;
                                    </li>
                                    <li className="li">
                                        Compile product listings;
                                    </li>
                                    <li className="li">
                                        Participate in discussion forums or other community activities;
                                    </li>
                                    <li className="li">
                                        Leave comments and provide ratings;
                                    </li>
                                    <li className="li">
                                        Fixez a reminder for a special occasion; or
                                    </li>
                                    <li className="li">
                                        Use the alerts to notify you of the availability of a given product, such as  emails notifying you that a  product is available for sale again.
                                    </li>
                                </ul>

                                <h3 className="mb-3 mt-4">These actions may provide us with information such as:</h3>

                                <ul className="ul">
                                    <li className="li">
                                        Identifying information such as your name, address and telephone numbers;
                                    </li>
                                    <li className="li">
                                        Your payment information;
                                    </li>
                                    <li className="li">
                                        Your geographic location;
                                    </li>
                                    <li className="li">
                                        Your IP address;
                                    </li>
                                    <li className="li">
                                        People, addresses and telephone numbers added under the heading "Your addresses";
                                    </li>
                                    <li className="li">
                                        The addresses of your contacts;
                                    </li>
                                    <li className="li">
                                        The content of comments and messages  sent to us;
                                    </li>
                                    <li className="li">
                                        The description and photo you have added to your profile;
                                    </li>
                                    <li className="li">
                                        Images and photos collected or stored as part of your use of the  Sooko Services;
                                    </li>
                                    <li className="li">
                                        Corporate and financial data;
                                    </li>
                                    <li className="li">
                                        Information about your credit history;
                                    </li>
                                    <li className="li">
                                        Device log files and configurations, including Wi-Fi IDs, if you choose to sync them automatically.
                                    </li>
                                </ul>

                                <h3 className="mb-3 mt-4">These actions may provide us with information such as:</h3>

                                <p className="p">
                                    Examples of information we collect and analyze include:
                                </p>

                                <ul className="ul">
                                    <li className="li">
                                        The IP address used to connect your computer to the Internet;
                                    </li>
                                    <li className="li">
                                        Username, email address and password;
                                    </li>
                                    <li className="li">
                                        The geographic location of your device or computer;
                                    </li>
                                    <li className="li">
                                        Information relating to your interaction with the Content, such as content downloads, streaming and playback details, including the duration and number of simultaneous streams and downloads, and network details for streaming and download quality, including information about your Internet service provider;
                                    </li>
                                    <li className="li">
                                        Device metric data, such as when a device is being used, app usage, connectivity data, and any errors or failures;
                                    </li>
                                    <li className="li">
                                        Metric data from the Sooko Services (e.g., incidents of technical errors, your interactions with the features and content of the service, your settings preferences and backup information, the location of your device running an application, information about images and downloaded files, such as file name, dates,  the times and location of your images);
                                    </li>
                                    <li className="li">
                                        Version and time zone settings;
                                    </li>
                                    <li className="li">
                                        Purchase/sales  history and usage of content, which we sometimes aggregate with similar information from other customers in order to create features such as  best-selling rankings;
                                    </li>
                                    <li className="li">
                                        The full URL that allows you to know the click path to, through and from our site web, including the date and time, the products and content you have viewed or searched for, page response times, download errors, duration of visits to certain pages and information about page interaction (such as scrolling,  clicks and mouse hovering);
                                    </li>
                                    <li className="li">
                                        Telephone numbers used to call our customer service number; and
                                    </li>
                                    <li className="li">
                                        Images or photos when you search  for products or services using the Sooko Services.
                                    </li>
                                </ul>
                                <p className="p">
                                    We may also use device identifiers, cookies and other technologies on devices, applications and web pagesto collect browsing, usage or other technical information.
                                </p>

                                <h3 className="mb-3 mt-4">Information from other sources</h3>

                                <p className="p">
                                    Examples of information we receive from other sources include:
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        Information from carriers or other third parties regarding the updating of delivery or address information, which we use to rectify our records and deliver your next purchase or communicate with you more easily;
                                    </li>
                                    <li className="li">
                                        Account information, purchases or redemptions, and information about the number of visits to the pages of certain merchants with whom we operate businesses or for which we provide technical, shipping, advertising or other services;
                                    </li>
                                    <li className="li">
                                        Information about your interactions with products and services offered by our affiliates;
                                    </li>
                                    <li className="li">
                                        Search results and links, including paid referencing (such as sponsored links);
                                    </li>
                                    <li className="li">
                                        Information about devices and services connected to the Internet; and
                                    </li>
                                    <li className="li">
                                        Information about your credit history from credit reporting agencies, which we use to prevent and detect fraud and to provide certain credit or financial services to certain customers.
                                    </li>
                                </ul>

                                <h3 className="mb-3 mt-4">Information you have access to</h3>

                                <p className="p">
                                    The following are examples of information that you can access through the Sooko Services:
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        The status of recent orders and sales;
                                    </li>
                                    <li className="li">
                                        The complete history of your orders and sales;
                                    </li>
                                    <li className="li">
                                        Personally identifiable information (including name, email address, password and address book);
                                    </li>
                                    <li className="li">
                                        Payment settings (including payment card information, promotional certificate and gift card balances, etc.);
                                    </li>
                                    <li className="li">
                                        Electronic notification settings (including product availability alerts, deliveries, special occasion reminders and newsletters);
                                    </li>
                                    <li className="li">
                                        Recommendations and products you have recently viewed that form the basis of the recommendations (including the Recommended for You and Refine Our Personalized Tips pages);
                                    </li>
                                    <li className="li">
                                        Shopping or sales lists;
                                    </li>
                                    <li className="li">
                                        Your content, devices, services and related settings, as well as your preferences for communications and personalized advertising;
                                    </li>
                                    <li className="li">
                                        Content you have recently viewed;
                                    </li>
                                    <li className="li">
                                        Records associated with your account;
                                    </li>
                                    <li className="li">
                                        Your profile (including product reviews, recommendations, reminders and personal profile);
                                    </li>
                                </ul>
                            </div>
                        </div>

















                        :























                        <div className="row">
                            <div className="col-md-12" style={{ marginTop: '5%' }}>
                                <h1 className="light-title mb-5"><strong>Déclaration de confidentialité</strong></h1>
                                <p className="title">Dernière mise à jour : 30 octobre 2021.</p>
                                <p className="p mt-5">
                                    Nous savons que vous vous souciez de vos informations et vos données personnelles, de même que celles de votre entreprise, fournies pour raisons d’affaires, et nous sommes sensibles à la confiance que vous placez en nous à cet égard. Cette déclaration de confidentialité décrit comment Sookotrade.com et ses filiales (collectivement « Sooko ») recueillent, traitent et utilisent vos renseignements en ligne qui renvoient à la présente déclaration de confidentialité (collectivement les « Services Sooko »). En utilisant les Services Sooko, vous consentez aux pratiques décrites dans la présente déclaration de confidentialité.
                                </p>
                                <ul className="ul">
                                    <li className="li font-weight-bold">Quels renseignements Sookotrade.com collecte de ses clients?</li>
                                    <li className="li font-weight-bold">A quelles fins Sooko utilise vos informations?</li>
                                    <li className="li font-weight-bold">Quelle utilité ont les témoins de navigation et autres identificateurs?</li>
                                    <li className="li font-weight-bold">Sooko partage-t-elle vos informations?</li>
                                    <li className="li font-weight-bold">Vos renseignements sont-ils protégés? </li>
                                    <li className="li font-weight-bold">Qu’en est-il de la publicité?</li>
                                    <li className="li font-weight-bold">A quels renseignements puis-je avoir accès?</li>
                                    <li className="li font-weight-bold">Quelles options me sont proposées?</li>
                                    <li className="li font-weight-bold">Les enfants sont-ils autorisés à utiliser les Services Sooko?</li>
                                    <li className="li font-weight-bold">Quelles sont les conditions d’utilisation, les avis et révisions périodiques?</li>
                                    <li className="li font-weight-bold">Quelles sont les informations et les actions pratiques se rapportant à la présente déclaration?</li>
                                    <li className="li font-weight-bold">Quelles sont les exemples d’informations recueillies?</li>
                                </ul>
                                <h3 className="mb-3 mt-4">Quels renseignements Sookotrade.com collecte de ses clients?</h3>
                                <p className="p">
                                    Nous recueillons vos renseignements personnels et d’entreprises afin de fournir et améliorer continuellement nos services. Voici les types de renseignements que nous recueillons :
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        <b>Renseignements fournis par vous-même.</b> Nous recevons et stockons tous les renseignements que vous fournissez par l’entremise des Services Sooko.
                                        <Link to={USER_GUIDE}>Cliquez ici</Link> pour consulter quelques exemples de renseignements que nous recueillons. Vous pouvez choisir de ne pas fournir certains renseignements, mais, ce faisant, vous pourriez limiter votre accès à plusieurs des Services Sooko.
                                    </li>
                                    <li className="li">
                                        <b>Renseignements recueillis automatiquement.</b> Nous recueillons et stockons automatiquement certains types de renseignements concernant votre utilisation des services Sooko, y compris des renseignements sur votre interaction avec le contenu et les services offerts par l'entremise des Services Sooko. Comme de nombreux autres sites Web, nous utilisons des témoins de navigation et autres identificateurs uniques, et obtenons certains types d'information lorsque votre navigateur Web ou votre appareil accède aux Services Sooko et à d'autres contenus desservis par Sooko ou en son nom sur d'autres sites Web.
                                    </li>
                                    <li className="li">
                                        <b>Renseignements provenant d'autres sources.</b> Nous pourrions recevoir des renseignements à votre sujet en provenance d'autres sources, comme des renseignements concernant la mise à jour des informations relatives à la livraison de produits ou à votre adresse de la part de nos partenaires (transporteurs, transitaires, institutions financières, consultants, etc.), renseignement que nous utilisons pour rectifier nos dossiers et faciliter vos prochaines transactions.
                                        <a href="https://www.amazon.ca/gp/help/customer/display.html/ref=ap_signin_notification_privacy_notice?ie=UTF8&language=fr_CA&nodeId=918814#GUID-39BF3145-BD72-423B-886C-C01B24F96663__SECTION_34632E7569CE4780B6E22533575E8F2E" target="_blank">Cliquez ici</a>  pour consulter des exemples de renseignements supplémentaires que nous recueillons.
                                    </li>
                                </ul>
                                <h3 className="mb-3 mt-4">A quelles fins Sooko utilise vos informations?</h3>
                                <p className="p">
                                    Nous utilisons vos renseignements personnels pour faire fonctionner, fournir, développer et améliorer les services que nous offrons à nos clients. Ces objectifs incluent :
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        <b>Achat, vente, livraison de produits et prestation de services.</b> Nous utilisons vos renseignements aux fins du traitement de vos transactions, de la livraison de produits et de la prestation de services, du traitement des paiements et de communications avec vous au sujet de vos transactions, de services et d’offres promotionnelles.
                                    </li>
                                    <li className="li">
                                        <b>Prestation et amélioration des Services Sooko ainsi que résolution de problèmes liés à tous nos services</b>. Nous utilisons vos renseignements pour vous fournir des fonctionnalités, analyser les performances, corriger les erreurs et améliorer l’accessibilité et l'efficacité des Services Sooko.
                                    </li>
                                    <li className="li">
                                        <b>Recommandations et personnalisation.</b> Nous utilisons vos renseignements pour recommander des fonctionnalités, des produits et des services qui pourraient vous intéresser, pour identifier vos préférences et pour personnaliser votre expérience avec les Services Sooko.
                                    </li>
                                    <li className="li">
                                        <b>Prestation de services d'image et de photos.</b> Lorsque vous utilisez nos services vous pourrez insérez des images et/ou des photos; nous utilisons vos images, vos photos et d'autres renseignements pour répondre à vos demandes, vous fournir le service demandé et améliorer nos services.
                                    </li>
                                    <li className="li">
                                        <b>Satisfaire aux obligations juridiques.</b> Dans certains cas, nous avons une obligation légale de collecter et d’utiliser vos renseignements. Par exemple, nous recueillons auprès de vous des renseignements concernant votre lieu d'établissement et des données pour faciliter les paiements à des fins de vérification de l'identité ou autres.
                                    </li>
                                    <li className="li">
                                        <b>Communiquer avec vous.</b> Nous utilisons vos renseignements pour communiquer avec vous ou vous aider à communiquer entre acheteur et fournisseur, en lien avec les Services Sooko par différents canaux (p. ex., par téléphone, courriel, ou conversation électronique sur le site web).
                                    </li>
                                    <li className="li">
                                        <b>Publicité.</b> Nous utilisons vos renseignements pour afficher des publicités basées sur vos centres d'intérêt pour des fonctionnalités, des produits et des services qui pourraient vous intéresser. Nous n'utilisons pas les renseignements qui vous identifient personnellement pour afficher des publicités basées sur des centres d'intérêt.
                                    </li>
                                    <li className="li">
                                        <b>Prévention de la fraude et repérage des risques de crédit.</b> Nous utilisons vos renseignements pour prévenir et détecter la fraude et les abus afin de protéger la sécurité de nos clients à Sooko. Nos partenaires et nous pouvons également utiliser des méthodes d’évaluation de la solvabilité pour évaluer et gérer les risques de paiement ou de crédit.
                                    </li>
                                </ul>

                                <h3 className="mb-3 mt-4">Quelle utilité ont les témoins de navigation et autres identificateurs?</h3>
                                <p className="p">
                                    Afin de permettre à nos systèmes de reconnaître votre navigateur ou votre appareil et de fournir et d'améliorer les Services Sooko, nous utilisons des témoins de navigation et d'autres identificateurs. Pour en savoir plus sur les témoins de navigation et sur la façon dont nous les utilisons, veuillez lire
                                    <a href="https://www.amazon.ca/-/fr/gp/help/customer/display.html/?nodeId=201890250" target="_blank">déclaration sur l’utilisation des témoins de navigation</a>.
                                </p>

                                <h3 className="mb-3 mt-4">Sooko partage-t-elle vos informations?</h3>
                                <p className="p">
                                    Il est crucial pour notre entreprise de protéger les renseignements de nos clients, et nous ne vendons pas les renseignements de nos clients à des tiers. Nous ne communiquons ces renseignements que dans les circonstances décrites ci-dessous et uniquement aux filiales de Sooko ou aux partenaires facilitant la clôture de vos transactions et la livraisons de produits ou services clients qui ont adopté la présente déclaration de confidentialité ou dont les pratiques commerciales respectent la confidentialité de leurs clients de façon au moins aussi stricte.
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        <b>Opérations financières impliquant des tiers.</b> Nous mettons à votre disposition des services, des produits, des applications ou des compétences fournis par des tiers pour être utilisés sur ou par l'intermédiaire des Services Sooko. Par exemple, vous pouvez commander des produits auprès de tiers sur nos marchés, télécharger des applications auprès de fournisseurs d'applications tiers depuis notre site web ou nos applications et activer des compétences tierces par l'intermédiaire de nos services. Nous offrons également des services ou facilitons la vente des gammes de produits conjointement avec des entreprises tierces. Lorsqu'un tiers participe à vos opérations et que nous partageons vos renseignements avec ce tiers à l'égard de ces opérations, nous vous le signalons.
                                    </li>
                                    <li className="li">
                                        <b>Prestataires de services tiers.</b> Nous retenons les services d'entreprises et de particuliers pour effectuer certaines tâches en notre nom. Par exemple, le traitement de commandes de produits ou services, la livraison de produits, l’envoi de courrier électronique ou postal, la suppression de données redondantes de listes de clients, l’analyse de données, une assistance en matière de commercialisation, la production de résultats de recherche et de liens (y compris des annonces et des liens payants), le traitement de paiements, la transmission de contenu, l’application d’un système de cotation, l’évaluation et la gestion des risques de crédit, ainsi que la prestation du service à la clientèle. Ces prestataires de services tiers ont accès aux renseignements nécessaires à l’exécution de leurs tâches, mais ne peuvent les utiliser à aucune autre fin.
                                    </li>
                                    <li className="li">
                                        <b>Transferts d'entreprises.</b> Dans le cadre du développement de nos activités, nous pouvons être amenés à vendre ou acquérir d'autres activités ou services. Les renseignements relatifs à la clientèle constituent généralement l'un des éléments transférés au nouvel acquéreur lors de ce type d'opération, mais ces renseignements demeurent sujets à toute déclaration de confidentialité antérieure à l'acquisition ou à la vente (à moins, bien entendu, que le client consente de façon explicite à de nouvelles modalités). Par ailleurs, dans l'éventualité peu probable où Sooko ou une part substantielle de ses actifs devaient faire l'objet d'une acquisition, les renseignements relatifs à la clientèle seraient bien entendu transférés avec les actifs.
                                    </li>
                                    <li className="li">
                                        <b>Protection de Sooko et des tiers.</b> Nous divulguons des renseignements consignés dans les comptes de nos clients et d’autres renseignements dans le but de nous conformer à la loi, de faire appliquer nos <Link to={`${process.env.PUBLIC_URL}/terms-and-conditions`}>conditions d'utilisation </Link> ou autres conventions, ou de protéger les droits, les biens ou la sécurité de Sooko, de ses utilisateurs ou d'autres personnes. Cette politique de divulgation comprend la transmission de renseignements à d'autres entreprises ou organismes dans le but de prévenir la fraude et de réduire le risque de crédit.
                                    </li>
                                </ul>
                                <p className="p">
                                    Dans tous les autres cas que ceux mentionnés ci-dessus, vous serez averti lorsque vos renseignements sont susceptibles d'être communiqués à des tiers, et vous aurez alors la possibilité de choisir de ne pas partager ces renseignements.
                                </p>

                                <h3 className="mb-3 mt-4">Mes renseignements sont-ils protégés?</h3>
                                <p className="p">
                                    Nous concevons nos systèmes en tenant compte du respect de votre sécurité et de votre vie privée.
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        Nous nous efforçons de protéger la sécurité de vos renseignements lors de leur transmission en utilisant des protocoles et des logiciels appropriés.
                                    </li>
                                    <li className="li">
                                        Nos partenaires et nous suivons la norme de sécurité de l’industrie des cartes de paiement PCI DSS (« Payment Card Industry Data Security Standard ») lors du traitement des données de carte de crédit.
                                    </li>
                                    <li className="li">
                                        Nous maintenons des mesures de protection physiques, électroniques et procédurales pour ce qui a trait à la collecte, au stockage et à la divulgation des renseignements des clients. Nos procédures de sécurité peuvent nous amener à vous demander une preuve de votre identité avant de pouvoir vous communiquer vos renseignements.
                                    </li>
                                    <li className="li">
                                        Nos systèmes disposent de fonctions de sécurité qui les protègent contre l'accès non autorisé et la perte de données. Vous pouvez contrôler ces fonctions et les configurer selon vos besoins.
                                    </li>
                                    <li className="li">
                                        Il est important que vous empêchiez l'accès non autorisé à votre mot de passe et à vos ordinateurs, appareils et applications. Assurez-vous de fermer votre session lorsque vous avez terminé l’utilisation d’un ordinateur.
                                    </li>
                                </ul>

                                <h3 className="mb-3 mt-4">Qu'en est-il de la publicité?</h3>
                                <ul className="ul">
                                    <li className="li">
                                        <b>Annonceurs tiers et liens vers d'autres sites Web.</b> Les Services Sooko peuvent inclure de la publicité émanant de tiers et des liens vers d'autres sites Web et applications. Les annonceurs tiers peuvent recueillir des renseignements lorsque vous interagissez avec leur contenu, leurs publicités et leurs services.
                                    </li>
                                    <li className="li">
                                        <b>Utilisation des services d’annonceurs tiers.</b> Nous fournissons aux annonceurs des renseignements qui leur permettent de diffuser des publicités plus utiles et pertinentes sur Sooko et d’en mesurer l’efficacité. En l'occurrence, nous ne communiquons jamais votre nom ou d'autres renseignements qui vous identifient directement. Nous utilisons plutôt un identificateur publicitaire comme un témoin de navigation ou un autre identificateur d'appareil. Par exemple, si vous avez déjà téléchargé l'une de nos applications, nous partagerons votre identificateur publicitaire ainsi que des données relatives à ce téléchargement afin que vous ne receviez pas de publicité vous invitant à la télécharger à nouveau. Certaines agences publicitaires utilisent également ces renseignements pour vous offrir des annonces pertinentes provenant d'autres annonceurs. 
                                    </li>
                                </ul>

                                <h3 className="mb-3 mt-4">À quelles informations puis-je avoir accès?</h3>

                                <p className="p">
                                    Vous avez accès à vos informations, y compris votre nom, votre adresse, vos modes de paiement, vos renseignements de profil, vos renseignements sur votre entreprise et votre historique de commandes.
                                </p>

                                <h3 className="mb-3 mt-4">Quels choix me sont proposés?</h3>

                                <p className="p">
                                    Si vous avez des questions sur la façon dont nous recueillons et utilisons vos renseignements, veuillez communiquer avec notre service clients. Nos Services Sooko vous proposent également des options de paramétrage vous permettant de choisir la façon dont vos renseignements sont utilisés.
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        Comme mentionné ci-dessus, vous pouvez choisir de ne pas fournir certains renseignements, mais, ce faisant, vous pourriez limiter votre accès à plusieurs des Services Sooko.
                                    </li>
                                    <li className="li">
                                        Vous pouvez ajouter des renseignements ou mettre à jour vos renseignements sur les pages de votre profile. Lorsque vous mettez vos renseignements à jour, nous conservons généralement dans nos dossiers une copie de la version antérieure.
                                    </li>
                                    <li className="li">
                                        Si vous ne souhaitez pas recevoir de courriels ou d'autre type de communication de notre part, veuillez mettre à jour vos préférences de communication. Si vous ne voulez pas recevoir de notifications de notre part lorsque vous utilisez notre application, veuillez ajuster vos paramètres de notification.
                                    </li>
                                    <li className="li">
                                        Si vous ne voulez pas voir de publicités basées sur vos centres d'intérêt, veuillez ajuster vos préférences pour les campagnes publicitaires.
                                    </li>
                                    <li className="li">
                                    La fonction d’aide de la plupart des navigateurs et appareils vous indique comment empêcher votre navigateur ou votre appareil d'accepter de nouveaux témoins ou d’autres identificateurs, comment être averti lorsque vous recevez un nouveau témoin ou comment bloquer tous les témoins. Cependant, parce que les témoins de navigation et identificateurs vous permettent de profiter pleinement de certaines des fonctions essentielles des Services Sooko, nous vous recommandons de ne pas les bloquer. Par exemple, si vous bloquez ou rejetez autrement nos témoins, vous ne pourrez pas passer une commande ou utiliser des services qui exigent que vous vous connectiez à votre compte. 
                                    </li>
                                    <li className="li">
                                        Si vous souhaitez naviguer sur nos sites Web sans lier l'historique de navigation à votre compte, vous pouvez le faire en vous deconnectant de votre compte et en bloquant les témoins sur votre navigateur.
                                    </li>
                                    <li className="li">
                                        Vous pourrez également vous désinscrire de certains autres types d'utilisation de données en mettant à jour vos paramètres sur le site Sookotrade.com. La plupart des appareils offrent aux utilisateurs la possibilité de modifier les permissions de l'appareil (p. ex., pour désactiver ou accéder aux services de localisation ou aux contacts). Pour la plupart des appareils, ces paramètres sont généralement accessibles depuis le menu de réglage des paramètres de l'appareil. Si vous avez des questions sur la façon de modifier les permissions de votre appareil, nous vous recommandons de communiquer avec votre fournisseur de services mobiles ou le fabricant de votre appareil.
                                    </li>
                                </ul>
                                <p className="p">
                                    De plus, dans la mesure où la loi applicable l'exige, vous pourriez avoir le droit de demander l'accès à vos données personnelles ou d’en demander la correction. Si vous souhaitez soumettre une telle requête, veuillez accéder à  <a href="https://www.amazon.ca/-/fr/gp/privacycentral/dsar/preview.html" target="_blank">Demander mes renseignements</a>  ou communiquer avec le  <a href="https://www.amazon.ca/-/fr/gp/help/contact-us" target="_blank">Service à la clientèle</a>. En fonction du choix que vous ferez en lien avec vos données à caractère personnel, votre accès à certains services pourrait être limité ou retiré.
                                </p>

                                <h3 className="mb-3 mt-4">Les enfants sont-ils autorisés à utiliser les Services Sooko?</h3>

                                <p className="p">
                                    Sooko ne facilite pas la vente de produits destinés à l’achat par des mineurs. L’achat ou la vente de produits destinés aux mineurs doit être effectué par un adulte. Si vous avez moins de 18 ans, vous ne pouvez pas utiliser les Services Sooko sans la participation d'un parent ou d'un tuteur.
                                </p>

                                <h3 className="mb-3 mt-4">Conditions d'utilisation, avis et révisions</h3>

                                <p className="p">
                                    Si vous choisissez d’utiliser les Services Sooko, votre utilisation de même que tout litige quant aux questions de confidentialité sont régis par la présente déclaration et par nos <Link to={`${process.env.PUBLIC_URL}/terms-and-conditions`}>conditions d'utilisation</Link>, en ce qui a trait notamment à la limite des dommages-intérêts, au règlement des litiges et à l'application des lois de l'État de Delaware, États-Unis.
                                </p>
                                <p className="p">
                                    Pour toute question concernant la protection de vos renseignements personnels par Sooko, veuillez <a href="https://www.amazon.ca/-/fr/gp/help/contact-us" target="_blank">communiquer avec nous</a> en nous envoyant un message détaillé et nous ferons de notre mieux pour y répondre de manière satisfaisante.
                                </p>
                                <p className="p">
                                    Notre entreprise est en constante évolution, et la présente déclaration de confidentialité est également susceptible d'être modifiée. Veuillez consulter notre site Web fréquemment afin de vous informer des changements les plus récents. Sauf avis contraire, la présente déclaration de confidentialité régit tous les renseignements se rapportant à vous-même ou à votre compte. Nous tenons nos promesses et ne modifierons en aucun cas nos pratiques et conditions pour les rendre moins protectrices à l'égard des renseignements personnels de nos clients recueillis par le passé sans avoir préalablement obtenu l'accord des clients concernés.
                                </p>

                                <h3 className="mb-3 mt-4">Renseignements et pratiques se rapportant à cette déclaration</h3>

                                <h3 className="mb-3 mt-4">Exemples de renseignements recueillis</h3>

                                <h3 className="mb-3 mt-4">Renseignements que vous nous fournissez lorsque vous utilisez les Services Sooko.</h3>
                                <p className="p">
                                    Vous nous fournissez des renseignements lorsque vous :
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        Recherchez des produits ou des services sur notre site web;
                                    </li>
                                    <li className="li">
                                        Ajoutez ou retirez un produit, passez une commande par l'intermédiaire des Services Sooko ou utilisez ces services;
                                    </li>
                                    <li className="li">
                                        Téléchargez, diffusez, visionnez ou utilisez du contenu sur un appareil ou au moyen d'un service ou d'une application sur un appareil;
                                    </li>
                                    <li className="li">
                                        Fournissez des renseignements dans votre compte (il est possible que vous possédiez plus d’un compte si vous avez utilisé plus d'une adresse courriel ou d'un numéro de téléphone cellulaire sur notre site) ou dans votre profile;
                                    </li>
                                    <li className="li">
                                        Parlez ou interagissez autrement avec les tiers dans le cadre de nos services;
                                    </li>
                                    <li className="li">
                                        Importez vos contacts;
                                    </li>
                                    <li className="li">
                                        Configurez vos paramètres, fournissez des autorisations d'accès aux données pour un appareil ou un service Amazon ou interagissez avec celui-ci;
                                    </li>
                                    <li className="li">
                                        Fournissez des renseignements, des biens ou des services aux clients de Sooko;
                                    </li>
                                    <li className="li">
                                        Offrez vos produits ou services sur ou par l'entremise des Services Sooko;
                                    </li>
                                    <li className="li">
                                        Communiquez avec nous par téléphone, par courriel ou de toute autre façon;
                                    </li>
                                    <li className="li">
                                        Remplissez un questionnaire, un ticket de soutien ou un formulaire de participation à un concours;
                                    </li>
                                    <li className="li">
                                        Téléchargez ou diffusez en continu des images, des photos ou d'autres fichiers sur Sooko ou d'autres Services Sooko;
                                    </li>
                                    <li className="li">
                                        Utilisez nos services tels que Prime Video;
                                    </li>
                                    <li className="li">
                                        Compilez des listes de produits;
                                    </li>
                                    <li className="li">
                                        Participez à des forums de discussion ou à d'autres activités de la communauté;
                                    </li>
                                    <li className="li">
                                        Laissez des commentaires et fournissez des évaluations;
                                    </li>
                                    <li className="li">
                                        Fixez un rappel pour une occasion spéciale; ou
                                    </li>
                                    <li className="li">
                                        Utilisez les alertes pour vous aviser de la disponibilité d’un produit donné, comme les courriels vous notifiant qu’un produit est de nouveau disponible à la vente.
                                    </li>
                                </ul>

                                <h3 className="mb-3 mt-4">Ces actions pourraient nous fournir des informations telles que :</h3>

                                <ul className="ul">
                                    <li className="li">
                                        Des renseignements d'identification tels que votre nom, votre adresse et vos numéros de téléphone;
                                    </li>
                                    <li className="li">
                                        Vos informations de paiement;
                                    </li>
                                    <li className="li">
                                        Votre localisation géographique;
                                    </li>
                                    <li className="li">
                                        Votre adresse IP;
                                    </li>
                                    <li className="li">
                                        Les personnes, les adresses et les numéros de téléphone ajoutés sous la rubrique «Vos adresses »;
                                    </li>
                                    <li className="li">
                                        Les adresses de vos contacts;
                                    </li>
                                    <li className="li">
                                        Le contenu des commentaires et des messages qui nous sont adressés;
                                    </li>
                                    <li className="li">
                                        La description et la photo que vous aurez ajoutées à votre profile;
                                    </li>
                                    <li className="li">
                                        Les images et les photos recueillies ou stockées dans le cadre de le votre utilisation des Services Sooko;
                                    </li>
                                    <li className="li">
                                        Des données d’entreprise et financières;
                                    </li>
                                    <li className="li">
                                        Des renseignements sur vos antécédents en matière de crédit;
                                    </li>
                                    <li className="li">
                                        Les fichiers journaux de l'appareil ainsi que les configurations, y compris les identifiants Wi-Fi, si vous choisissez de les synchroniser automatiquement.
                                    </li>
                                </ul>

                                <h3 className="mb-3 mt-4">Renseignements recueillis automatiquement</h3>

                                <p className="p">
                                    Voici des exemples de renseignements que nous recueillons et analysons :
                                </p>

                                <ul className="ul">
                                    <li className="li">
                                        L’adresse IP utilisée pour connecter votre ordinateur à l’Internet;
                                    </li>
                                    <li className="li">
                                        Le nom d’utilisateur, l'adresse courriel et le mot de passe;
                                    </li>
                                    <li className="li">
                                        La localisation géographique de votre appareil ou de votre ordinateur;
                                    </li>
                                    <li className="li">
                                        Les informations relatives à votre interaction avec le contenu, telles que les téléchargements de contenu, la diffusion en continu et les détails de lecture, y compris la durée et le nombre de diffusions et de téléchargements simultanés, et les détails du réseau pour la qualité de la diffusion en continu et du téléchargement, y compris les renseignements sur votre fournisseur de services Internet;
                                    </li>
                                    <li className="li">
                                        Les données métriques de l'appareil, comme le moment où un appareil est utilisé, l'utilisation de l'application, les données de connectivité et toute erreur ou défaillance;
                                    </li>
                                    <li className="li">
                                        Les données métriques des Services Sooko (p. ex., les incidents d'erreurs techniques, vos interactions avec les fonctionnalités et le contenu du service, vos préférences en matière de paramètres et les informations de sauvegarde, l'emplacement de votre appareil qui exécute une application, les renseignements sur les images et les fichiers téléchargés, comme le nom du fichier, les dates, les heures et l'emplacement de vos images);
                                    </li>
                                    <li className="li">
                                        Les paramètres de la version et du fuseau horaire;
                                    </li>
                                    <li className="li">
                                        L’historique des achats/ventes et de l'utilisation du contenu, que nous regroupons parfois avec des renseignements similaires provenant d'autres clients afin de créer des fonctions comme le palmares des meilleures ventes;
                                    </li>
                                    <li className="li">
                                        L’adresse URL complète qui permet de connaître le parcours de navigation vers, à travers et à partir de notre site web, y compris la date et l'heure, les produits et le contenu que vous avez consultés ou recherchés, les temps de réponse des pages, les erreurs de téléchargement, la durée des visites à certaines pages et les renseignements sur l'interaction des pages (comme le défilement, les clics et le survol de la souris);
                                    </li>
                                    <li className="li">
                                        Les numéros de téléphone utilisés pour appeler notre numéro du service à la clientèle; et
                                    </li>
                                    <li className="li">
                                        Les images ou les photos lorsque vous faites des recherches de produits ou services utilisant les Services Sooko.
                                    </li>
                                </ul>
                                <p className="p">
                                    Nous pouvons également utiliser des identificateurs d'appareils, des témoins de navigation et d'autres technologies sur des appareils, des applications et des pages web pour recueillir des renseignements de navigation, d'utilisation ou d'autres renseignements techniques.
                                </p>

                                <h3 className="mb-3 mt-4">Renseignements provenant d'autres sources</h3>

                                <p className="p">
                                    Voici des exemples de renseignements que nous recevons d'autres sources :
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        Des renseignements de la part des transporteurs ou autres parties tierces concernant la mise à jour des informations relatives à la livraison ou à votre adresse, que nous utilisons pour rectifier nos dossiers et livrer votre prochain achat ou communiquer avec vous plus facilement;
                                    </li>
                                    <li className="li">
                                        Des renseignements sur le compte, sur les achats ou les rachats et des renseignements sur le nombre de visites sur les pages de certains commerçants avec lesquels nous exploitons des entreprises de comarque ou pour lesquels nous fournissons des services techniques, d'expédition, de publicité ou d'autres;
                                    </li>
                                    <li className="li">
                                        Des renseignements sur vos interactions avec les produits et services offerts par nos filiales;
                                    </li>
                                    <li className="li">
                                        Les résultats de recherche et les liens, y compris les référencements payants (comme les liens commandités);
                                    </li>
                                    <li className="li">
                                        Des renseignements sur les appareils et services connectés à Internet; et
                                    </li>
                                    <li className="li">
                                        Des renseignements sur vos antécédents en matière de crédit provenant d’agences d'évaluation du crédit, que nous utilisons pour prévenir et détecter la fraude et pour offrir certains services de crédit ou financiers à certains clients.
                                    </li>
                                </ul>

                                <h3 className="mb-3 mt-4">Renseignements auxquels vous avez accès</h3>

                                <p className="p">
                                    Voici des exemples d'informations auxquelles vous pouvez accéder par l'entremise des Services Sooko :
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        L’état des commandes et des ventes récentes;
                                    </li>
                                    <li className="li">
                                        L’historique complet de vos commandes et de vos ventes;
                                    </li>
                                    <li className="li">
                                        Les renseignements nominatifs (y compris le nom, l’adresse électronique, le mot de passe et le carnet d'adresses);
                                    </li>
                                    <li className="li">
                                        Les paramètres de paiement (y compris les renseignements sur les cartes de paiement, les soldes des certificats promotionnels et des cartes-cadeaux, etc.);
                                    </li>
                                    <li className="li">
                                        Les paramètres de notification par courrier électronique (y compris les alertes sur la disponibilité des produits, les livraisons, les rappels pour les occasions spéciales et les infolettres);
                                    </li>
                                    <li className="li">
                                        Les recommandations et les produits que vous avez récemment consultés qui constituent la base des recommandations (y compris les pages Recommandées pour vous et Affinez nos conseils personnalisés);
                                    </li>
                                    <li className="li">
                                        Les listes d'achats ou de ventes;
                                    </li>
                                    <li className="li">
                                        Vos contenus, appareils, services et paramètres connexes, ainsi que vos préférences en matière de communications et de publicité personnalisée;
                                    </li>
                                    <li className="li">
                                        Le contenu que vous avez récemment visionné;
                                    </li>
                                    <li className="li">
                                        Les enregistrements associés à votre compte;
                                    </li>
                                    <li className="li">
                                        Votre profil (y compris vos évaluations de produits, vos recommandations, vos rappels et votre profile personnel);
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
                </div>
                <div className="mb-8"></div>
            </div>
        </>
    )
}

export default Privacy;