import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactScroll from 'react-infinite-scroll-component';
import ProductTypeTwo from '../../../features/product/product-type-two';
import ProductTypeThree from '../../../features/product/product-type-three';
import { getProductsFromIdLimit, getProductsInCategoryFromIdLimit } from '../../../../action/products-actions';


function InfiniteProduct(props) {
    const products = useSelector(state => state.products);
    const search = useLocation().search;
    const q = new URLSearchParams(search).get('q');
    const t = products.dataInfinite.length;

    const [infiniteTotal, setInfiniteTotal] = useState(t);
    const dispatch = useDispatch();

    /* let products = products ? products : []; */
    //products = shopFilterProducts( products, filter );
    let total = infiniteTotal;

    //console.log("InfiniteProduct", products.data);

    function fetchData() {
        /* console.log("INFINITE SCROOLL"); */
        if (total < products.totalProducts) {

            const lastProductInList = products.dataInfinite[products.dataInfinite.length - 1];
            if (lastProductInList)
                if (products.categorySelected) {
                    dispatch(getProductsInCategoryFromIdLimit(lastProductInList.id, q, products.categorySelected.id, 6, false, result => {
                        if (result.status == 200) {
                            setInfiniteTotal(total + result.data.length);
                        }
                    }));

                } else {
                    dispatch(getProductsFromIdLimit(lastProductInList.id, 6, q, false, (result) => {
                        if (result.status == 200) {
                            //console.log("infinitooooo all products" , products.dataInfinite.length);
                            setInfiniteTotal(total + result.data.length);
                            document.querySelector('.skel-shop-products').scrollTop = document.querySelector('.skel-shop-products').scrollHeight;
                        }
                    }));
                }
        }
    }

    useEffect(() => {
        setInfiniteTotal();
        if (!products.categorySelected && products.dataInfinite.length <= 0) {
            dispatch(getProductsFromIdLimit(0, 6, q, true, result => {
                if (result.status == 200) {
                    setInfiniteTotal(total + result.data.length);
                }
            }));
        }
    }, [products.categorySelected])


    useEffect(() => {
        if (products.categorySelected) {
            dispatch(getProductsInCategoryFromIdLimit(0, q, products.categorySelected.id, 9, true, result => {
                let tmpTotal = total ? total : 0;
                if (result.status == 200) {
                    setInfiniteTotal(tmpTotal + result.data.length);
                }
            }));
        } else {
            dispatch(getProductsFromIdLimit(0, 9, q, true, result => {
                let tmpTotal = total ? total : 0;
                if (result.status == 200) {
                    setInfiniteTotal(tmpTotal + result.data.length);
                }
            }));
        }
    }, [products.categorySelected])

    return (
        <>
            {/* <ModalTellingLogin show={modalToLogin.status} /> */}
            <ReactScroll
                dataLength={infiniteTotal} //This is important field to render the next data
                next={fetchData}
                hasMore={infiniteTotal < products.totalProducts ? true : false}
                style={{ overflow: 'hidden' }}
                loader={<div className="loader">
                    <div className="bounce-loader">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>}

                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b></b>
                    </p>
                }>
                {products.dataInfinite.filter(p => !p.productCategory.isUnknown) ? <div className={`row row-sm position-relative ${props.type === "list" ? "product-intro" : ""}`}>
                    {
                        props.type === "grid" ?
                            products.dataInfinite.filter(p => !p.productCategory.isUnknown).slice(0, total).map((product, index) => (
                                <div className={`col-6 col-md-4 fadeInDownShorter`} style={{ animationDuration: 1 + 's' }} key={"product" + index}>
                                    <ProductTypeTwo addClass="inner-quickview inner-icon" product={product} />
                                </div>
                            ))
                            :
                            products.dataInfinite.filter(p => !p.productCategory.isUnknown).slice(0, total).map((product, index) => (
                                <div className="col-6 col-sm-12 fadeInDownShorter" style={{ animationDuration: 1 + 's' }} key={"product" + index} >
                                    <ProductTypeThree addClass="left-details product-list mb-4" product={product} />
                                </div>
                            ))
                    }
                </div> : null}
            </ReactScroll>
        </>
    )
}

export default InfiniteProduct;