exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".productLisItem_countryFlag__9tDpi {\n    display: inline-block;\n}\n\n.productLisItem_countryFlag__9tDpi button{\n    border: none;\n    padding: 0px;\n    margin: 0px;\n}\n\n.productLisItem_countryFlag__9tDpi button ::after {\n    content: '';\n}", ""]);

// exports
exports.locals = {
	"countryFlag": "productLisItem_countryFlag__9tDpi"
};