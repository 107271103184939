import Modal from 'react-modal';
import React, { useState } from 'react';
import checkImg from '../../../../../../../public/assets/images/success-green-check-mark.png';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'relative',
        maxWidth: '870px',
        marginRight: '2rem',
        padding: '.5rem',
        backgroundColor: '#fff'
    }
};

Modal.setAppElement('#root');

function infoUpdatedSuccessModal(props) {

    //const {userUpdated} = useSelector(state => state.updateUserInformation);

    const [open, setOpen] = useState(true);

    /* const openModal = (e) => {
        e.preventDefault();
        setOpen(true);
    } */

    const closeModal = () => {
        props.clearUpdateStateAfterUpdate();
        props.closeEditModal(true);
        setOpen(false);
    }


    return (
        <>
            <Modal
                shouldCloseOnOverlayClick={false}
                isOpen={props.haveBeenSuccessfullyUpdated && open}
                onRequestClose={closeModal}
                contentLabel="login Modal"
                className="login-popup"
                id="login-popup-form"
                overlayClassName="cart-modal-overlay"
                style={customStyles}
            >
                <div className="modal-wrapper" style={{ height: "100%" }}>
                    <div className="container">
                        <div className="d-flex flex-column justify-content-center align-items-center">

                            <h2 className="text-center"> <span className="text-success">Personnal informations updated successfuly</span> <br /><br />
                            <div className="text-center"><img src={checkImg} style={{ margin: "0 auto" }} width={250} /></div>
                            <br /></h2>
                            <p>
                                
                            </p>
                        </div>
                    </div>

                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={closeModal}>×</button>
                </div>
            </Modal>
        </>
    )

}

export default infoUpdatedSuccessModal;