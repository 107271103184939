import {
    SIGNIN_USER_BASE, SIGNIN_USER_SUCCESS, SIGNIN_USER_ERROR,
   FETCH_AHTH_USER_BASE, FETCH_AHTH_USER_SUCCESS, FETCH_AHTH_USER_ERROR, 
   LOGOUT
} from '@constants/auth-action-types'
import { removeAuthToken } from '@helpers/tokens';
import { HOME } from '@routes/urls';

const initialState = {

    authUser: null,
    isAuth: false,

    loading: false,
    error: null,

    fetchAuthUserLoaging: false,
    fetchAuthUserError: false,
}

const signInReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case SIGNIN_USER_BASE:
            return { ...state, loading: true, error: null, authUser: null, isAuth: false };

        case SIGNIN_USER_SUCCESS:
            return { ...state, error: null, loading: false, isAuth: true };

        case SIGNIN_USER_ERROR:
            return { ...state, authUser: null, loading: false, isAuth: false, error: action.payload };


        /****************************************************************************************************************** */


        case FETCH_AHTH_USER_BASE:
            return { ...state, fetchAuthUserLoaging: true, fetchAuthUserError: null }; 

        case FETCH_AHTH_USER_SUCCESS:
            return { ...state, fetchAuthUserLoaging: false, fetchAuthUserError: false, isAuth: true, authUser: action.payload }; 

        case FETCH_AHTH_USER_ERROR:
            removeAuthToken();
            window.location = HOME;
            return { ...state, fetchAuthUserLoaging: false, fetchAuthUserError: action.payload };


        /***************************************************************************************************************** */



        case LOGOUT: 

            removeAuthToken();
            // if(!window.location.pathname.includes("login"))
            //     window.location = "/login";
            return { ...state, authUser: null, loading: false, isAuth: false };
            

        default:
            return state;
    }
};

/* const persistConfig = {
    keyPrefix: "porto-",
    key: "products",
    storage
}
 */
export default signInReducer;