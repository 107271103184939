import React from 'react';

function CheckoutProgessBar( props ) {
    const { active = 1 } = props;

    return (
        <div>
            <ul className="checkout-progress-bar">
                <li className={ active === 1 ? 'active' : '' }>
                    <span>Details</span>
                </li>
                <li className={ active === 2 ? 'active' : '' }>
                    <span>Logistic </span>
                </li>
                {/* <li className={ active === 3 ? 'active' : '' }>
                    <span>Products &amp; Infos</span>
                </li> */}
            </ul>
        </div>
    )
}

export default React.memo( CheckoutProgessBar );