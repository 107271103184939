import React from 'react';
import { Helmet } from 'react-helmet';
import RfqListItem from './rfqListItem';
import Breadcrumb from '../../common/breadcrumb';

function RfqList() {

    return (
        <>
            <Helmet>
                <title>Sooko - Checkout Page </title>
            </Helmet>

            <h1 className="d-none">Sooko - Checkout Page</h1>

            <div className="main">
                <Breadcrumb current="Home" parent="RFQs" />

                <div className="container">
                   
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="checkout-step-shipping" >
                                <div className="row rfq-table">
                                    <div className="col-md-1 rfq-item-cell rfq-item-image">
                                        {/*<img className="rounded-circle" src="/assets/images/demo/avatar/cocoa.jpg"/>*/}
                                    </div>
                                    <div className="col-md-2 rfq-item-cell">
                                        Product name
                                    </div>
                                    <div className="col-md-2 rfq-item-cell"> Unit price </div>
                                    <div className="col-md-2 rfq-item-cell"> Shipment </div>
                                    <div className="col-md-3 rfq-item-cell"> Date</div>
                                    <div className="col-md-2 rfq-item-cell" style={{paddingRight: "50px"}}>Action</div>
                                </div>
                                <RfqListItem></RfqListItem>
                                <RfqListItem></RfqListItem>
                                <RfqListItem></RfqListItem>
                                <RfqListItem></RfqListItem>
                                <RfqListItem></RfqListItem>
                            </div>  
                        </div>   
                    </div>

                </div>

                <div className="mb-6"></div>
            </div>
        </>
    )
}

export default RfqList;