import Pdf from './Pdf';
import './contracts.scss';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import ContractPdf from './ContractPdf';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classes from "../../../board.module.css";
import ContractListItem from './ContractListItem';
import React, { useEffect, useState } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from "@material-ui/core/Dialog/Dialog";
import Pagination from '@material-ui/lab/Pagination';
import IconButton from "@material-ui/core/IconButton";
import ModalInfo from '@components/customs/modalInfo';
import { paginate, formatCurrency } from "@helpers/helpers";
import { useTranslation, withTranslation } from 'react-i18next';
import currencies from '@mockData/currencies.json';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import { getFees, makeBankPayement, makeStripePayement, sendFirstDocToBuyerBase64, 
    sendFirstDocToBuyerURL, getSettings, getAmountPaid, mobilePayment, paypalLinkPayment, paypalPayment } from '@action/contract';
import { getContractsOfUser, getContractAttachedDocuments, updateContractAttachedFile, confirmContract, sendNewRatings } from '@action/contracts-actions';

const paypalCurrencies = ["AUD", "BRL", "CAD", "CNY", "CZK", "DKK", "EUR", "HKD", "HUF",
            "ILS", "JPY", "MYR", "MXN", "TWD", "NZD", "NOK", "PHP", "PLN", "GBP", "RUB", "SGD", "SEK", "CHF", "THB",
            "USD"]

const ContractsList = (props) => {

    const authUser = useSelector((state) => state.authUser);

    const { t } = props;

    const [data, setData] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [feesApp, setFeesApp] = useState([]);

    const [loadingData, setLoadingData] = useState(false);
    const [errorData, setErrorData] = useState(null);
    const isSeller = authUser.authUser ? authUser.authUser.userType === 'SELLER' : false;

    const [generate, setGenerate] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [setting, setSetting] = useState(null);
    const [showBox, setShowBox] = useState(false);
    const { i18n } = useTranslation();

    const [itemSelected, setItemSelected] = useState(null);
    const [paymentModalOpen, setPaymentModalOpen] = useState(null);
    const [paymentOptionSelected, setPaymentOptionSelected] = useState(null); //card, mobile, bank

    const [receiptFile, setReceiptFile] = useState(null);
    const [receiptFileToSend, setReceiptFileToSend] = useState(null);
    const [currentContract, setCurrentContract] = useState(null);
    const [loadingReceipt, setLoadingReceipt] = useState(false);
    const [stf, setStf] = useState(0);
    const [sof, setSof] = useState(0);
    const [otf, setOtf] = useState(0);
    const [stfl, setStfl] = useState('');
    const [sofl, setSofl] = useState('');
    const [otfl, setOtfl] = useState('');
    const [password, setPassword] = useState('');
    const [comment, setComment] = useState('');
    const [note, setNote] = useState(0);
    const [paid, setPaid] = useState(0);
    const [amount, setAmount] = useState(0);
    const [errorUploadReceiptFile, setErrorUploadReceiptFile] = useState(false);
    const [registrationLoader, setRegistrationLoader] = useState([]);
    const [registrationToSend, setRegistrationToSend] = useState([]);
    const [langSelected, setLangSelected] = useState("en");
    const [isUpdatingRegistration, setIsUpdatingRegistration] = useState([false, false, false, false, false, false, false, false]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let paymentId = params.get('paymentId');
        let payerId = params.get('PayerID');
        let qusId = params.get('qus_id');
        if (paymentId && payerId && qusId) {
            paypalPayment(qusId, paymentId, payerId).then((response) => {
                console.log(response);
                toast(t('common.paypal_payment_start'), {className: "toast-success",});
            }).catch((err) => {
                console.log(err);
            })
        }
    }, []);

    const filterFromSearchText = (item) => {
        try {
            return item.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
        } catch (err) {
            return false;
        }
    }

    let loader = null;
    let errorDisplay = null;

    const initialiseState = () => {
        setLoadingData(true);
        setErrorData(null);
        setData([]);
    };

    if (loadingData) {
        loader = (
            <div className="loader text-center">
                <div className="bounce-loader">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
        );
    }

    if (errorData) {
        errorDisplay = (
            <>
                <br />
                <div className="alert alert-danger">Error: {errorData.message}</div>
                <br />
            </>
        );
    }

    const getContracts = () => {
        initialiseState();
        getContractsOfUser()
            .then(data => {
                setData(data.sort((a, b) => a.id > b.id ? -1 : 1));
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoadingData(false);
            });
    };

    const makeNewMobilePayment = () => {
        mobilePayment(itemSelected.quotationUserStatus.id, Math.floor(Number(amount)))
            .then(data => {
                toast(t('common.mobile_payment_start'), {className: "toast-success",});
                window.open(data.waveLaunchUrl, 'blank')
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoadingData(false);
            });
    };

    const makeNewPaypalPayment = () => {
        paypalLinkPayment(itemSelected.quotationUserStatus.id, Math.floor(Number(amount)))
            .then(paypalLink => {
                window.location.href = paypalLink;
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoadingData(false);
            });
    };

    const handleReceiptChange = (event) => {
        setReceiptFile(window.URL.createObjectURL(event.target.files[0]));
        setReceiptFileToSend(event.target.files[0]);
    }

    const cancelEditReceipt = () => {
        setReceiptFile(null);
        setReceiptFileToSend(null);
    }

    const openDocument = (name) => {
        let file = documents.filter(d => d.name == name)[0];
        if (file) {
            window.open(`${process.env.BACKEND_BASE_URL}${file.path}`, '_blank')
        }
    }

    const hasDocument = (name) => {
        let file = documents.filter(d => d.name == name)[0];
        return file != null ? file.path != null : false;
    }

    const onUpdateFile = (index, name) => {

        if (registrationToSend) {
            updateContractAttachedFile(
                { file: registrationToSend[index], name: name, contractId: currentContract.id })
                .then((result) => {
                    toast(t('documents.update_ok'), {
                        className: "toast-success",
                    });
                })
                .catch((err) => {
                    toast(t('documents.update_failed'), {
                        className: "toast-error",
                    });
                }).finally(() => {
                    window.location.reload();
                })
        }
    };

    const onSendProof = () => {
        if (registrationToSend) {
            confirmContract(
                currentContract.id, "password",
                { file: registrationToSend[0] })
                .then((result) => {
                    getContracts()
                    toast(t('documents.update_ok'), {
                        className: "toast-success",
                    });
                })
                .catch((err) => {
                    toast(t('documents.update_failed'), {
                        className: "toast-error",
                    });
                }).finally(() => {
                    setShowBox(false);
                    let loaders = registrationLoader;
                    loaders[0] = false;
                    setRegistrationLoader([...loaders]);
                })
        }
    };

    const cancelEditRegistation = (index) => {
        let files = registrationToSend;
        files[index] = null;
        setRegistrationToSend([...files]);
        let table = isUpdatingRegistration;
        table[index] = false;
        setIsUpdatingRegistration([...table]);
        let loaders = registrationLoader;
        loaders[index] = false;
        setRegistrationLoader([...loaders]);
    };

    const onSendReceipt = () => {


        if (amount <= 0) {
            toast(t('documents.correct_amount'), { className: "toast-error" });
            return;
        }

        setLoadingReceipt(true);

        makeBankPayement({ qusId: itemSelected.quotationUserStatus.id, file: receiptFileToSend, amount: amount, currency: itemSelected.quotationUserStatus.quotation.product.currency }).then((result) => {
            toast(t('documents.update_ok'), { className: "toast-success" });
            setPaymentModalOpen(false);
            setReceiptFile(null);
        }).catch((err) => {
            toast(t('documents.updated_failed'), { className: "toast-error" });
        }).finally(() => {

            setLoadingReceipt(false);
        })
    }

    const setContractPdfBase64 = (base64) => {

        if (base64) {
            sendFirstDocToBuyerBase64(itemSelected.quotationUserStatus.id, encodeURIComponent(base64), 'CONTRACTBUYER').then((result) => {

                setGenerate(false);
            }).catch((err) => {
                toast(t('documents.updated_failed'), { className: "toast-error" });
                setGenerate(false);
            });
        }

    }

    const submitFirstContractBuyer = (item, url) => {
        if (url) {
            sendFirstDocToBuyerURL(item.quotationUserStatus.id, encodeURIComponent(url), 'CONTRACTBUYER').then((result) => {
                setGenerate(false);
            }).catch((err) => {
                toast(t('documents.updated_failed'), { className: "toast-error" });
                setGenerate(false);
            });
        }

    }

    useEffect(() => {
        if (authUser.authUser) {
            getContracts();
            setLangSelected(authUser.authUser.language.toLowerCase());
        }
    }, [authUser]);

    useEffect(() => {
        if (paymentModalOpen && itemSelected) {
            getAmountPaid(itemSelected.quotationUserStatus.id).then(data => {
                setPaid(data)
            }).catch(err => {
                console.log(err)
            })
        }
    }, [paymentModalOpen]);

    useEffect(() => {
        getFees().then((result) => {
            result.sort(function (a, b) {
                return a.id - b.id;
            });
            setFeesApp(result);
            setSof(result[0].percentage)
            setStf(result[1].percentage)
            setOtf(result[2].percentage)
            setSofl(result[0].fees)
            setStfl(result[1].fees)
            setOtfl(result[2].fees)
        }).catch((err) => {
            toast(t('documents.updated_failed'), { className: "toast-error" });
        });
        getSettings().then((result) => {
            setSetting(result);
        }).catch((err) => {
            toast(t('documents.updated_failed'), { className: "toast-error" });
        });
    }, [])

    const showDocument = (contract) => {
        getContractAttachedDocuments(contract.id).then(data => {
            setDocuments(data)
        }).catch(err => {
            setDocuments([])
        })
        setCurrentContract(contract);

        setShowBox(true);
    };

    const onStripePayment = (token, address) => {

        const tokenToSend = token.id;
        makeStripePayement({ tokenToSend, amount: currencies[itemSelected.quotationUserStatus.quotation.product.currency].decimal_digits < 0 ? Math.ceil(amount) : Math.ceil((amount * 100)).toFixed(2), qusId: itemSelected.quotationUserStatus.id, currency: itemSelected.quotationUserStatus.quotation.product.currency }).then((result) => {
            toast(t('documents.update_ok'), { className: "toast-success" });
            setPaymentModalOpen(false);
            getContractsOfUser()
                .then(data => {
                    setData(data.reverse());
                })
                .catch(err => {
                    setErrorData(err);
                })
                .finally(() => {
                    setLoadingData(false);
                });
        }).catch((err) => {
            console.log(err);
            toast(t('documents.updated_failed'), { className: "toast-error" });
        });
    }

    const sendRating = () => {
        if (note > 5 || note < 0) {
            toast(t('documents.rating_range'), { className: "toast-error" });
            return;
        }

        sendNewRatings(currentContract.id, { note, comment }).then((result) => {
            toast(t('documents.update_ok'), { className: "toast-success" });
            setShowBox(false);
        }).catch((err) => {
            toast(t('documents.updated_failed'), { className: "toast-error" });
        });
    }

    const handleRegistationChange = (event, index) => {
        let files = registrationToSend;
        files[index] = event.target.files[0];
        setRegistrationToSend([...files]);
        let tables = isUpdatingRegistration;
        tables[index] = true;
        setIsUpdatingRegistration([...tables]);
    };


    const goToDetail = (contractId) => {
        props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/contracts/${contractId}/contract-attached-files`);
    };

    const totalPriceStripeAndSookoFees = (price) => {
        // const p1 = (getTotalToPayStripe(price, true) * stf) / 100;
        const p2 = (price * sof) / 100;
        const p3 = (price * otf) / 100;
        const total = price + p2 + p3 - paid;
        let y = total / (1 - (stf / 100));

        return Math.ceil(y);
    }

    const getTotalToPayStripe = (price, stripe) => {
        const p2 = ((price) * sof) / 100;
        const p3 = ((price) * otf) / 100;
        const total = price + p2 + p3 - paid;
        let y = total / (1 - (stf / 100));
        let stripeFees = y - total;

        if (stripe)
            return Math.ceil(stripeFees);
        else
            return Math.ceil(price + p2 + p3);
    }

    const getBalance = (price) => {
        const p2 = ((price) * sof) / 100;
        const p3 = ((price) * otf) / 100;
        const total = price + p2 + p3 - paid;

        return Math.ceil(total);
    }

    const getTotal = (price, quantity) => {
        return Math.ceil(price * quantity - paid);
    }

    const totalPriceAndSookoFees = (price) => {
        const p1 = (price * sof) / 100;
        const p2 = (price * otf) / 100;

        return Math.ceil(price - paid + p1 + p2);
    }

    return (
        <>
            <Helmet>
                <title>Sooko - Checkout Page </title>
            </Helmet>

            {
                generate ?
                    <>

                        <ContractPdf
                            generate={generate}
                            setContractPdfBase64={setContractPdfBase64}
                        />
                    </> : null
            }

            <ModalInfo
                show={paymentModalOpen}
                closeModal={() => { setPaymentModalOpen(false); setPaymentOptionSelected(null) }}
            >
                <div className="container" style={{ height: '100%' }}>

                    <div className="row" style={{ height: '100%' }}>
                        {(!paymentOptionSelected || paymentOptionSelected === 'card') && ( 
                            <div className="col-md-3 text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                <h4 className="text-center"> {t('documents.card_payment')} </h4>
                                <div>
                                    <img src="assets/images/Credit_card_re_blml.svg" style={{ widt: "100%", height: "150px" }} />
                                </div>

                                <div className="text-center">
                                    {!paymentOptionSelected ? <button onClick={() => setPaymentOptionSelected('card')} className="btn-pay-contract mt-2" style={{ fontWeight: "bold", margin: "0px", marginTop: "-5px" }}> {t('documents.with_card')} </button> :
                                        <button onClick={() => setPaymentOptionSelected(false)} className="btn-pay-contract mt-2" style={{ fontWeight: "bold", margin: "0px", background: "red", color: "white" }}> {t('documents.cancel')} </button>}
                                </div>
                            </div>
                        )}

                        {(!paymentOptionSelected || paymentOptionSelected === 'paypal') && itemSelected && ( 
                            paypalCurrencies.includes(itemSelected.quotationUserStatus.baseQuotation.product.currency.toUpperCase()) && (
                                <div className="col-md-3 text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                    <h4 className="text-center"> {t('documents.paypal_payment')} </h4>
                                    <div>
                                        <img src="assets/images/undraw_transfer_money_re_6o1h.svg" style={{ widt: "100%", height: "150px" }} />
                                    </div>

                                    <div className="text-center">
                                        {!paymentOptionSelected ? <button onClick={() => setPaymentOptionSelected('paypal')} className="btn-pay-contract mt-2" style={{ fontWeight: "bold", margin: "0px", marginTop: "-5px" }}> {t('documents.with_paypal')} </button> :
                                            <button onClick={() => setPaymentOptionSelected(false)} className="btn-pay-contract mt-2" style={{ fontWeight: "bold", margin: "0px", background: "red", color: "white" }}> {t('documents.cancel')} </button>}
                                    </div>
                                </div>
                            )
                        )}

                        {(!paymentOptionSelected || paymentOptionSelected === 'mobile') && itemSelected ?
                            ['XOF', 'XAF'].includes(itemSelected.quotationUserStatus.baseQuotation.product.currency) ?
                                <div className="col-md-3 text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="text-center">
                                        <h4 className="text-center"> {t('documents.mobile_payment')} </h4>
                                        <div>
                                            <img src="assets/images/Mobile_pay_re_sjb8.svg" style={{ widt: "100%", height: "150px" }} />
                                        </div>

                                        <div className="text-center">
                                            {!paymentOptionSelected ? <button onClick={() => setPaymentOptionSelected('mobile')} className="btn-pay-contract mt-2" style={{ fontWeight: "bold", margin: "0px", marginTop: "-5px" }}> {t('documents.with_mobile')} </button> :
                                                <button onClick={() => setPaymentOptionSelected(false)} className="btn-pay-contract mt-2" style={{ fontWeight: "bold", margin: "0px", background: "red", color: "white" }}> {t('documents.cancel')} </button>}
                                        </div>

                                    </div>
                                </div> : null : null}

                        {!paymentOptionSelected || paymentOptionSelected === 'bank' ? <div className="col-md-3 text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="text-center">
                                <h4> {t('documents.bank_payment')} </h4>
                                <div>
                                    <img src="assets/images/sync_files_xb3r.svg" style={{ widt: "100%", height: "150px" }} />
                                </div>

                                <div className="text-center">
                                    {!paymentOptionSelected ? <button onClick={() => setPaymentOptionSelected('bank')} className="btn-pay-contract mt-2" style={{ fontWeight: "bold", margin: "0px", marginTop: "-5px" }}> {t('documents.with_bank')} </button> :
                                        <button onClick={() => setPaymentOptionSelected(false)} className="btn-pay-contract mt-2" style={{ fontWeight: "bold", margin: "0px", background: "red", color: "white" }}> {t('documents.cancel')} </button>}
                                </div>
                            </div>
                        </div> : null}

                        {paymentOptionSelected ? <div className="col-md-8 text-center">

                            {paymentOptionSelected === 'card' ? <div>

                                <h3 className="text-muted"> Details </h3>
                                <hr style={{ margin: "2px" }} />
                                <br />



                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{t('rfq.quantity')}:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{itemSelected.quotationUserStatus.quotation.quantity} {itemSelected.quotationUserStatus.quotation.product.pricePerUnit[langSelected == 'fr' ? 'fr' : 'en']}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{t('products.unit_price')}:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(itemSelected.quotationUserStatus.quotation.price, itemSelected.quotationUserStatus.quotation.product.currency)} / {itemSelected.quotationUserStatus.quotation.product.pricePerUnit[langSelected == 'fr' ? 'fr' : 'en']}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">Total:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity, itemSelected.quotationUserStatus.quotation.product.currency)}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{sofl} {sof}% :</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency((itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity * sof) / 100, itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{otfl} {otf}% :</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency((itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity * otf) / 100, itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{t('documents.already_paid')}:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(paid, itemSelected.quotationUserStatus.quotation.product.currency)}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{t('documents.balance')}:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(getBalance(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity), itemSelected.quotationUserStatus.quotation.product.currency)}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{stfl} {stf}% : </h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency((getTotalToPayStripe(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity, true)), itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                </div>

                                <hr style={{ margin: "0px" }} />
                                <br />

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">Grand Total:</h5></div>
                                    <div className="col-6"><h5 className="text-left text-muted">{formatCurrency(totalPriceStripeAndSookoFees(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity), itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                </div>

                                <input
                                    name="amount"
                                    className="form-control"
                                    type="number"
                                    max={totalPriceStripeAndSookoFees(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity).toFixed(2)}
                                    min={0}
                                    placeholder='Enter the amount to pay'
                                    onChange={(e) => { setAmount(e.target.value) }}
                                />

                                <div className="mt-3">
                                    <StripeCheckout
                                        stripeKey={process.env.REACT_APP_STRIPE_KEY}
                                        token={onStripePayment}
                                        amount={currencies[itemSelected.quotationUserStatus.quotation.product.currency].decimal_digits < 0 ? Math.ceil(amount) : Math.ceil((amount * 100)).toFixed(2)}
                                        currency={itemSelected.quotationUserStatus.quotation.product.currency}
                                        name="Payment RFQ"
                                    />
                                </div>
                            </div> : null}

                            {paymentOptionSelected === 'mobile' ? <div>

                                <h3 className="text-muted"> Details </h3>
                                <hr style={{ margin: "2px" }} />
                                <br />

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{t('rfq.quantity')}:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{itemSelected.quotationUserStatus.quotation.quantity} {itemSelected.quotationUserStatus.quotation.product.pricePerUnit[langSelected == 'fr' ? 'fr' : 'en']}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{t('products.unit_price')}:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(itemSelected.quotationUserStatus.quotation.price, itemSelected.quotationUserStatus.quotation.product.currency)} / {itemSelected.quotationUserStatus.quotation.product.pricePerUnit[langSelected == 'fr' ? 'fr' : 'en']}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">Total:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity, itemSelected.quotationUserStatus.quotation.product.currency)}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{sofl} {sof}% :</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency((itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity * sof) / 100, itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{otfl} {otf}% :</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency((itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity * otf) / 100, itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{t('documents.already_paid')}:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(paid, itemSelected.quotationUserStatus.quotation.product.currency)}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{t('documents.balance')}:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(getBalance(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity), itemSelected.quotationUserStatus.quotation.product.currency)}</h5> </div>
                                </div>

                                <hr style={{ margin: "0px" }} />
                                <br />

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">Grand Total:</h5></div>
                                    <div className="col-6"><h5 className="text-left text-muted">{formatCurrency(totalPriceAndSookoFees(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity), itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                </div>

                                <input
                                    name="amount"
                                    className="form-control"
                                    type="number"
                                    max={totalPriceAndSookoFees(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity).toFixed(2)}
                                    min={0}
                                    placeholder='Enter the amount to pay'
                                    onChange={(e) => { setAmount(e.target.value) }}
                                />

                                <button onClick={() => makeNewMobilePayment()} className="btn-pay-contract mt-2" style={{ fontWeight: "bold", margin: "0px", background: "red", color: "white" }}> {t('notes.pay_mobile')} </button>
                            </div> : null}

                            {paymentOptionSelected === 'paypal' ? <div>

                                <h3 className="text-muted"> Details </h3>
                                <hr style={{ margin: "2px" }} />
                                <br />

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{t('rfq.quantity')}:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{itemSelected.quotationUserStatus.quotation.quantity} {itemSelected.quotationUserStatus.quotation.product.pricePerUnit[langSelected == 'fr' ? 'fr' : 'en']}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{t('products.unit_price')}:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(itemSelected.quotationUserStatus.quotation.price, itemSelected.quotationUserStatus.quotation.product.currency)} / {itemSelected.quotationUserStatus.quotation.product.pricePerUnit[langSelected == 'fr' ? 'fr' : 'en']}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">Total:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity, itemSelected.quotationUserStatus.quotation.product.currency)}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{sofl} {sof}% :</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency((itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity * sof) / 100, itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{otfl} {otf}% :</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency((itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity * otf) / 100, itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{t('documents.already_paid')}:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(paid, itemSelected.quotationUserStatus.quotation.product.currency)}</h5> </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">{t('documents.balance')}:</h5></div>
                                    <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(getBalance(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity), itemSelected.quotationUserStatus.quotation.product.currency)}</h5> </div>
                                </div>

                                <hr style={{ margin: "0px" }} />
                                <br />

                                <div className="row">
                                    <div className="col-6"><h5 className="text-right">Grand Total:</h5></div>
                                    <div className="col-6"><h5 className="text-left text-muted">{formatCurrency(totalPriceAndSookoFees(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity), itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                </div>

                                <input
                                    name="amount"
                                    className="form-control"
                                    type="number"
                                    max={totalPriceAndSookoFees(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity).toFixed(2)}
                                    min={0}
                                    placeholder='Enter the amount to pay'
                                    onChange={(e) => { setAmount(e.target.value) }}
                                />

                                <button onClick={() => makeNewPaypalPayment()} className="btn-pay-contract mt-2" style={{ fontWeight: "bold", margin: "0px", background: "red", color: "white" }}> {t('notes.pay_mobile')} </button>
                            </div> : null}


                            {paymentOptionSelected === 'bank' ? <div id="bank-div">

                                <h3 className="text-muted"> Details </h3>
                                <p style={{ marginTop: 20, fontSize: '1.2em' }}>
                                    <strong style={{ marginRight: 20 }}>
                                        Note:
                                    </strong>
                                    {t('notes.bank_payment')}
                                </p>
                                <p>{t('documents.account_informations')}: {setting.length > 0 ? setting.find(d => d.key == 'ACCOUNT_INFO')[i18n.language == 'en' ? 'valueEn' : 'valueFr'] : ''}</p>
                                <hr style={{ margin: "2px" }} />

                                <div className="mt-3">

                                    <div className="row">
                                        <div className="col-sm-6">

                                            <div className="row">
                                                <div className="col-6"><h5 className="text-right">{t('rfq.quantity')}:</h5></div>
                                                <div className="col-6"> <h5 className="text-left text-muted">{itemSelected.quotationUserStatus.quotation.quantity} {itemSelected.quotationUserStatus.quotation.product.pricePerUnit[langSelected == 'fr' ? 'fr' : 'en']}</h5> </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6"><h5 className="text-right">{t('products.unit_price')}:</h5></div>
                                                <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(itemSelected.quotationUserStatus.quotation.price, itemSelected.quotationUserStatus.quotation.product.currency)} / {itemSelected.quotationUserStatus.quotation.product.pricePerUnit[langSelected == 'fr' ? 'fr' : 'en']}</h5> </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6"><h5 className="text-right">Total:</h5></div>
                                                <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity, itemSelected.quotationUserStatus.quotation.product.currency)}</h5> </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6"><h5 className="text-right">{sofl} {sof}% :</h5></div>
                                                <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency((itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity * sof) / 100, itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6"><h5 className="text-right">{otfl} {otf}% :</h5></div>
                                                <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency((itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity * otf) / 100, itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6"><h5 className="text-right">{t('documents.already_paid')}:</h5></div>
                                                <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(paid, itemSelected.quotationUserStatus.quotation.product.currency)}</h5> </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6"><h5 className="text-right">{t('documents.balance')}:</h5></div>
                                                <div className="col-6"> <h5 className="text-left text-muted">{formatCurrency(getBalance(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity), itemSelected.quotationUserStatus.quotation.product.currency)}</h5> </div>
                                            </div>

                                            <hr style={{ margin: "0px" }} />
                                            <br />

                                            <div className="row">
                                                <div className="col-6"><h5 className="text-right">Grand Total:</h5></div>
                                                <div className="col-6"><h5 className="text-left text-muted">{formatCurrency(totalPriceAndSookoFees(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity), itemSelected.quotationUserStatus.quotation.product.currency)}</h5></div>
                                            </div>

                                            <input
                                                name="amount"
                                                className="form-control"
                                                type="number"
                                                max={totalPriceAndSookoFees(itemSelected.quotationUserStatus.quotation.price * itemSelected.quotationUserStatus.quotation.quantity).toFixed(2)}
                                                min={0}
                                                placeholder='Enter the amount to pay'
                                                onChange={(e) => { setAmount(e.target.value) }}
                                            />
                                        </div>

                                        <div className="col-sm-6">

                                            <h5>{t('documents.upload_receipt')}</h5>

                                            {errorUploadReceiptFile}
                                            {receiptFile ? <Pdf pdfUrl={receiptFile} className="licence-pdfs" /> : null}

                                            <div className="text-center">
                                                <div style={{ marginTop: 10 }}>
                                                    {!receiptFile ? (
                                                        <label className="">
                                                            <i className="fa fa-upload" aria-hidden="true"></i>
                                                            {t('documents.upload_receipt')}
                                                            <span><input type="file" onChange={handleReceiptChange} /></span>
                                                        </label>) : ''}
                                                </div>

                                                <div className="mt-4">
                                                    {/* {userAuthenticate.userType} */}
                                                    {receiptFile ? <button
                                                        type="button"
                                                        style={{ padding: 12 }}
                                                        className={`btn btnUploadRegistration`}
                                                        onClick={onSendReceipt}
                                                        disabled={loadingReceipt}
                                                    >
                                                        {loadingReceipt ? <span><i className="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> : null}
                                                                        SEND RECEIPT
                                                                    </button> : null}

                                                    {receiptFile ? <button
                                                        type="button"
                                                        className={`btn btnCancelUpload`}
                                                        style={{ padding: 12, color: 'white' }}
                                                        onClick={cancelEditReceipt}
                                                        disabled={loadingReceipt}
                                                    >
                                                        {t('documents.cancel')}
                                                    </button> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div> : null}
                        </div> : null}
                    </div>




                    {/* <div className="text-center"><img src={loginImg} style={{ margin: "0 auto" }} width={200} /></div> */}


                </div>
            </ModalInfo>

            <div className="main">

                <div className="container">

                    <h2>{t('documents.contract_list')}</h2>

                    {isSeller ?
                        <p style={{ marginTop: 10, marginBottom: 50 }}>
                            <strong style={{ marginRight: 10 }}>
                                Note:
                        </strong>
                            {t('notes.document').replace('{button}', 'Documents')}
                        </p>
                        : null
                    }
                    {!isSeller ?
                        <p style={{ marginTop: 10 }}>
                            <strong style={{ marginRight: 10 }}>
                                Note:
                        </strong>
                            {t('notes.pay').replace('{button}', t('documents.proceed_payment'))}
                        </p>
                        : null
                    }
                    {!isSeller ?
                        <p style={{ marginTop: 30, marginBottom: 50 }}>
                            <strong style={{ marginRight: 10 }}>
                                Note:
                        </strong>
                            {t('notes.view_document').replace('{button}', 'Documents')}
                        </p>
                        : null
                    }

                    {data.length > 0 ? <div className="row">
                        <div className={"col-md-5 header-search " + "header-search-inline"} style={{ width: "100% !important" }}>
                            <div className="header-search-wrapper">
                                <input type="search" className="form-control" name="q" placeholder={t('common.search')} style={{ width: "100%" }} required
                                    value={searchText} onChange={(ev) => setSearchText(ev.target.value)} />
                            </div>
                        </div>
                    </div> : null}

                    {loader}
                    {errorDisplay}


                    {data.length >= 0 ? <div className="row" style={{ marginTop: 30, marginBottom: 20 }}>
                        <table className="col-lg-12 col-sm-12">
                            <thead>
                                <th>{t('documents.contract_name')}</th>
                                <th> {t('documents.status')} </th>
                                <th> {t('documents.price')} </th>
                                <th> Total </th>
                                <th> {t('documents.date')} </th>
                                <th>{t('documents.action')}</th>
                            </thead>
                            <br />
                            <tbody>
                                {paginate(data.filter(filterFromSearchText), page, pageSize).map((item, index) => <ContractListItem key={index} item={item} getCountDocsUnread={props.getCountContractsUnread}
                                    setItemSelected={setItemSelected} showDocument={showDocument} setPaymentModalOpen={setPaymentModalOpen} goToDetail={goToDetail} submitFirstContractBuyer={submitFirstContractBuyer} />)}
                            </tbody>
                        </table>
                    </div> : null}
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        count={Math.ceil(data.filter(filterFromSearchText).length / pageSize)}
                        showFirstButton
                        showLastButton
                        onChange={(__, selectedPage) => setPage(selectedPage)}
                    />

                    {!loadingData && data.length <= 0 ?
                        (
                            <div className="mb-5 text-center">
                                <img src="assets/images/nothing-here.png" width="500" style={{ margin: "0 auto" }} />
                                <h2 className="text-muted font-weight-light"> {t('documents.no_documents')} </h2>
                            </div>
                        ) : null
                    }

                </div>

                <Dialog
                    open={showBox}
                    onClose={() => { setShowBox(false) }}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogTitle id="form-dialog-title">
                        <div className="row justify-content-between align-items-center">
                            <h3>{t('notes.details_modal')}</h3>
                            <IconButton
                                color="primary"
                                aria-label="close"
                                className="text-danger"
                                onClick={() => { setShowBox(false) }}>
                                <CancelIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    !isSeller && currentContract ?
                                        <>
                                            <h4 style={{ fontWeight: 'normal' }}>{t('documents.confirm_transaction')}</h4>
                                            <div style={{ marginTop: 10 }}>
                                                {/* <input
                                                    name="password"
                                                    className="form-control"
                                                    type="password"
                                                    placeholder='Enter your password'
                                                    onChange={(e) => { setPassword(e.target.value) }}
                                                /> */}
                                                {!isUpdatingRegistration[0] ? (
                                                    <>
                                                        {!isSeller ?
                                                            <label className={classes.contractbutton}>
                                                                <i className="fa fa-upload" aria-hidden="true"></i>
                                                                {t('documents.upload_proof')}
                                                                <span>
                                                                    <input type="file" onChange={(e) => handleRegistationChange(e, 0)} />
                                                                </span>
                                                            </label> : null}
                                                        {currentContract.getProof ?
                                                            <label className={classes.contractbutton} onClick={() => window.open(`${process.env.BACKEND_BASE_URL}${currentContract.getProof}`, '_blank')}>
                                                                <i className="fa fa-eye" aria-hidden="true"></i>
                                                                {t('documents.view_document')}
                                                            </label> : null}
                                                    </>
                                                ) : (
                                                        ""
                                                    )}
                                            </div>
                                            <div
                                                className="profile-usertitle-job mt-2"
                                                style={{ paddingLeft: "20%" }}
                                            >
                                                {/* {userAuthenticate.userType} */}
                                                {isUpdatingRegistration[0] ? (
                                                    <button
                                                        type="button"
                                                        style={{ padding: 12 }}
                                                        className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                                                        onClick={() => onSendProof()}
                                                        disabled={registrationLoader[0] || password.length < 0}
                                                    >
                                                        {registrationLoader[0] ? (
                                                            <span>
                                                                <i
                                                                    className="fa fa-spinner fa-spin fa-1x"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        ) : null}
                                                        {t('documents.send_document')}
                                                    </button>
                                                ) : null}

                                                {isUpdatingRegistration[0] ? (
                                                    <button
                                                        type="button"
                                                        className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                                                        style={{ padding: 12, color: "white" }}
                                                        onClick={() => cancelEditRegistation(0)}
                                                        disabled={registrationLoader[0] || password.length < 0}
                                                    >
                                                        {t('documents.cancel')}
                                                    </button>
                                                ) : null}
                                            </div>
                                        </>
                                        : null
                                }
                                <h4 style={{ fontWeight: 'normal' }}>{t('documents.title')}</h4>
                                {
                                    currentContract ?

                                        <div style={{ width: '80%', margin: '0 auto' }}>
                                            {
                                                currentContract.check1 ?
                                                    <>
                                                        <h4 style={{ fontWeight: 'normal' }}>{t('documents.commercial_invoice')}</h4>
                                                        <div style={{ marginTop: 10 }}>
                                                            {!isUpdatingRegistration[1] ? (
                                                                <>
                                                                    {isSeller ?
                                                                        <label className={classes.contractbutton}>
                                                                            <i className="fa fa-upload" aria-hidden="true"></i>
                                                                            {!hasDocument('Commercial invoice') ? t('documents.upload_document') : t('documents.update_document')}
                                                                            <span>
                                                                                <input type="file" onChange={(e) => handleRegistationChange(e, 1)} />
                                                                            </span>
                                                                        </label> : null}
                                                                    {hasDocument('Commercial invoice') ?
                                                                        <label className={classes.contractbutton} onClick={() => openDocument('Commercial invoice')}>
                                                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                                                            {t('documents.view_document')}
                                                                        </label> : null}
                                                                </>
                                                            ) : (
                                                                    ""
                                                                )}
                                                        </div>
                                                        <div
                                                            className="profile-usertitle-job mt-2"
                                                            style={{ paddingLeft: "20%" }}
                                                        >
                                                            {/* {userAuthenticate.userType} */}
                                                            {isUpdatingRegistration[1] ? (
                                                                <button
                                                                    type="button"
                                                                    style={{ padding: 12 }}
                                                                    className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                    onClick={() => onUpdateFile(1, 'Commercial invoice')}
                                                                    disabled={registrationLoader[1]}
                                                                >
                                                                    {registrationLoader[1] ? (
                                                                        <span>
                                                                            <i
                                                                                className="fa fa-spinner fa-spin fa-1x"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </span>
                                                                    ) : null}
                                                                    {t('documents.save')}
                                                                </button>
                                                            ) : null}

                                                            {isUpdatingRegistration[1] ? (
                                                                <button
                                                                    type="button"
                                                                    className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                    style={{ padding: 12, color: "white" }}
                                                                    onClick={() => cancelEditRegistation(1)}
                                                                    disabled={registrationLoader[1]}
                                                                >
                                                                    {t('documents.cancel')}
                                                                </button>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                    : null}
                                            {
                                                currentContract.check2 ? <>
                                                    <h4 style={{ fontWeight: 'normal' }}>{t('documents.transport_document')}</h4>
                                                    <div style={{ marginTop: 10 }}>
                                                        {!isUpdatingRegistration[2] ? (
                                                            <>
                                                                {isSeller ?
                                                                    <label className={classes.contractbutton}>
                                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                                        {!hasDocument('Transport documents') ? t('documents.upload_document') : t('documents.update_document')}
                                                                        <span>
                                                                            <input type="file" onChange={(e) => handleRegistationChange(e, 2)} />
                                                                        </span>
                                                                    </label> : null}
                                                                {hasDocument('Transport documents') ?
                                                                    <label className={classes.contractbutton} onClick={() => openDocument('Transport documents')}>
                                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                                        {t('documents.view_document')}
                                                                    </label> : null}
                                                            </>
                                                        ) : (
                                                                ""
                                                            )}
                                                    </div>
                                                    <div
                                                        className="profile-usertitle-job mt-2"
                                                        style={{ paddingLeft: "20%" }}
                                                    >
                                                        {/* {userAuthenticate.userType} */}
                                                        {isUpdatingRegistration[2] ? (
                                                            <button
                                                                type="button"
                                                                style={{ padding: 12 }}
                                                                className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                onClick={() => onUpdateFile(2, 'Transport documents')}
                                                                disabled={registrationLoader[2]}
                                                            >
                                                                {registrationLoader[2] ? (
                                                                    <span>
                                                                        <i
                                                                            className="fa fa-spinner fa-spin fa-1x"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </span>
                                                                ) : null}
                                                                {t('documents.save')}
                                                            </button>
                                                        ) : null}

                                                        {isUpdatingRegistration[2] ? (
                                                            <button
                                                                type="button"
                                                                className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                style={{ padding: 12, color: "white" }}
                                                                onClick={() => cancelEditRegistation(2)}
                                                                disabled={registrationLoader[2]}
                                                            >
                                                                {t('documents.cancel')}
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                </>
                                                    : null}
                                            {
                                                currentContract.check3 ? <>
                                                    <h4 style={{ fontWeight: 'normal' }}>{t('documents.packing_list')}</h4>
                                                    <div style={{ marginTop: 10 }}>
                                                        {!isUpdatingRegistration[3] ? (
                                                            <>
                                                                {isSeller ?
                                                                    <label className={classes.contractbutton}>
                                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                                        {!hasDocument('Packing list') ? t('documents.upload_document') : t('documents.update_document')}
                                                                        <span>
                                                                            <input type="file" onChange={(e) => handleRegistationChange(e, 3)} />
                                                                        </span>
                                                                    </label> : null}
                                                                {hasDocument('Packing list') ?
                                                                    <label className={classes.contractbutton} onClick={() => openDocument('Packing list')}>
                                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                                        {t('documents.view_document')}
                                                                    </label> : null}
                                                            </>
                                                        ) : (
                                                                ""
                                                            )}
                                                    </div>
                                                    <div
                                                        className="profile-usertitle-job mt-2"
                                                        style={{ paddingLeft: "20%" }}
                                                    >
                                                        {/* {userAuthenticate.userType} */}
                                                        {isUpdatingRegistration[3] ? (
                                                            <button
                                                                type="button"
                                                                style={{ padding: 12 }}
                                                                className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                onClick={() => onUpdateFile(3, 'Packing list')}
                                                                disabled={registrationLoader[3]}
                                                            >
                                                                {registrationLoader[3] ? (
                                                                    <span>
                                                                        <i
                                                                            className="fa fa-spinner fa-spin fa-1x"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </span>
                                                                ) : null}
                                                                {t('documents.save')}
                                                            </button>
                                                        ) : null}

                                                        {isUpdatingRegistration[3] ? (
                                                            <button
                                                                type="button"
                                                                className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                style={{ padding: 12, color: "white" }}
                                                                onClick={() => cancelEditRegistation(3)}
                                                                disabled={registrationLoader[3]}
                                                            >
                                                                {t('documents.cancel')}
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                </>
                                                    : null}
                                            {
                                                currentContract.check4 ? <>
                                                    <h4 style={{ fontWeight: 'normal' }}>{t('documents.insurance_document')}</h4>
                                                    <div style={{ marginTop: 10 }}>
                                                        {!isUpdatingRegistration[4] ? (
                                                            <>
                                                                {isSeller ?
                                                                    <label className={classes.contractbutton}>
                                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                                        {!hasDocument('Insurance documents') ? t('documents.upload_document') : t('documents.update_document')}
                                                                        <span>
                                                                            <input type="file" onChange={(e) => handleRegistationChange(e, 4)} />
                                                                        </span>
                                                                    </label> : null}
                                                                {hasDocument('Insurance documents') ?
                                                                    <label className={classes.contractbutton} onClick={() => openDocument('Insurance documents')}>
                                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                                        {t('documents.view_document')}
                                                                    </label> : null}
                                                            </>
                                                        ) : (
                                                                ""
                                                            )}
                                                    </div>
                                                    <div
                                                        className="profile-usertitle-job mt-2"
                                                        style={{ paddingLeft: "20%" }}
                                                    >
                                                        {/* {userAuthenticate.userType} */}
                                                        {isUpdatingRegistration[4] ? (
                                                            <button
                                                                type="button"
                                                                style={{ padding: 12 }}
                                                                className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                onClick={() => onUpdateFile(4, 'Insurance documents')}
                                                                disabled={registrationLoader[4]}
                                                            >
                                                                {registrationLoader[4] ? (
                                                                    <span>
                                                                        <i
                                                                            className="fa fa-spinner fa-spin fa-1x"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </span>
                                                                ) : null}
                                                                {t('documents.save')}
                                                            </button>
                                                        ) : null}

                                                        {isUpdatingRegistration[4] ? (
                                                            <button
                                                                type="button"
                                                                className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                style={{ padding: 12, color: "white" }}
                                                                onClick={() => cancelEditRegistation(4)}
                                                                disabled={registrationLoader[4]}
                                                            >
                                                                {t('documents.cancel')}
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                </>
                                                    : null}
                                            {
                                                currentContract.check5 ? <>
                                                    <h4 style={{ fontWeight: 'normal' }}>{t('documents.certificate_of_origin')}</h4>
                                                    <div style={{ marginTop: 10 }}>
                                                        {!isUpdatingRegistration[5] ? (
                                                            <>
                                                                {isSeller ?
                                                                    <label className={classes.contractbutton}>
                                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                                        {!hasDocument('Certificate of origin') ? t('documents.upload_document') : t('documents.update_document')}
                                                                        <span>
                                                                            <input type="file" onChange={(e) => handleRegistationChange(e, 5)} />
                                                                        </span>
                                                                    </label> : null}
                                                                {hasDocument('Certificate of origin') ?
                                                                    <label className={classes.contractbutton} onClick={() => openDocument('Certificate of origin')}>
                                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                                        {t('documents.view_document')}
                                                                    </label> : null}
                                                            </>
                                                        ) : (
                                                                ""
                                                            )}
                                                    </div>
                                                    <div
                                                        className="profile-usertitle-job mt-2"
                                                        style={{ paddingLeft: "20%" }}
                                                    >
                                                        {/* {userAuthenticate.userType} */}
                                                        {isUpdatingRegistration[5] ? (
                                                            <button
                                                                type="button"
                                                                style={{ padding: 12 }}
                                                                className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                onClick={() => onUpdateFile(5, 'Certificate of origin')}
                                                                disabled={registrationLoader[5]}
                                                            >
                                                                {registrationLoader[5] ? (
                                                                    <span>
                                                                        <i
                                                                            className="fa fa-spinner fa-spin fa-1x"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </span>
                                                                ) : null}
                                                                {t('documents.save')}
                                                            </button>
                                                        ) : null}

                                                        {isUpdatingRegistration[5] ? (
                                                            <button
                                                                type="button"
                                                                className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                style={{ padding: 12, color: "white" }}
                                                                onClick={() => cancelEditRegistation(5)}
                                                                disabled={registrationLoader[5]}
                                                            >
                                                                {t('documents.cancel')}
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                </>
                                                    : null}
                                            {
                                                currentContract.check6 ? <>
                                                    <h4 style={{ fontWeight: 'normal' }}>{t('documents.certificate_of_inspection')}</h4>
                                                    <div style={{ marginTop: 10 }}>
                                                        {!isUpdatingRegistration[6] ? (
                                                            <>
                                                                {isSeller ?
                                                                    <label className={classes.contractbutton}>
                                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                                        {!hasDocument('Certificate of inspection') ? t('documents.upload_document') : t('documents.update_document')}
                                                                        <span>
                                                                            <input type="file" onChange={(e) => handleRegistationChange(e, 6)} />
                                                                        </span>
                                                                    </label> : null}
                                                                {hasDocument('Certificate of inspection') ?
                                                                    <label className={classes.contractbutton} onClick={() => openDocument('Certificate of inspection')}>
                                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                                        {t('documents.view_document')}
                                                                    </label> : null}
                                                            </>
                                                        ) : (
                                                                ""
                                                            )}
                                                    </div>
                                                    <div
                                                        className="profile-usertitle-job mt-2"
                                                        style={{ paddingLeft: "20%" }}
                                                    >
                                                        {/* {userAuthenticate.userType} */}
                                                        {isUpdatingRegistration[6] ? (
                                                            <button
                                                                type="button"
                                                                style={{ padding: 12 }}
                                                                className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                onClick={() => onUpdateFile(6, 'Certificate of inspection')}
                                                                disabled={registrationLoader[6]}
                                                            >
                                                                {registrationLoader[6] ? (
                                                                    <span>
                                                                        <i
                                                                            className="fa fa-spinner fa-spin fa-1x"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </span>
                                                                ) : null}
                                                                {t('documents.save')}
                                                            </button>
                                                        ) : null}

                                                        {isUpdatingRegistration[6] ? (
                                                            <button
                                                                type="button"
                                                                className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                style={{ padding: 12, color: "white" }}
                                                                onClick={() => cancelEditRegistation(6)}
                                                                disabled={registrationLoader[6]}
                                                            >
                                                                {t('documents.cancel')}
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                </>
                                                    : null}
                                            {
                                                currentContract.check7 ? <>
                                                    <h4 style={{ fontWeight: 'normal' }}>{t('documents.custom_document')}</h4>
                                                    <div style={{ marginTop: 10 }}>
                                                        {!isUpdatingRegistration[7] ? (
                                                            <>
                                                                {isSeller ?
                                                                    <label className={classes.contractbutton}>
                                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                                        {!hasDocument('Customs documents') ? t('documents.upload_document') : t('documents.update_document')}
                                                                        <span>
                                                                            <input type="file" onChange={(e) => handleRegistationChange(e, 7)} />
                                                                        </span>
                                                                    </label> : null}
                                                                {hasDocument('Customs documents') ?
                                                                    <label className={classes.contractbutton} onClick={() => openDocument('Customs documents')}>
                                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                                        {t('documents.view_document')}
                                                                    </label> : null}
                                                            </>
                                                        ) : (
                                                                ""
                                                            )}
                                                    </div>
                                                    <div
                                                        className="profile-usertitle-job mt-2"
                                                        style={{ paddingLeft: "20%" }}
                                                    >
                                                        {/* {userAuthenticate.userType} */}
                                                        {isUpdatingRegistration[7] ? (
                                                            <button
                                                                type="button"
                                                                style={{ padding: 12 }}
                                                                className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                onClick={() => onUpdateFile(7, 'Customs documents')}
                                                                disabled={registrationLoader[7]}
                                                            >
                                                                {registrationLoader[7] ? (
                                                                    <span>
                                                                        <i
                                                                            className="fa fa-spinner fa-spin fa-1x"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </span>
                                                                ) : null}
                                                                {t('documents.save')}
                                                            </button>
                                                        ) : null}

                                                        {isUpdatingRegistration[7] ? (
                                                            <button
                                                                type="button"
                                                                className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                style={{ padding: 12, color: "white" }}
                                                                onClick={() => cancelEditRegistation(7)}
                                                                disabled={registrationLoader[7]}
                                                            >
                                                                {t('documents.cancel')}
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                </>
                                                    : null}
                                            {
                                                currentContract.check8 ? <>
                                                    <h4 style={{ fontWeight: 'normal' }}>{t('documents.other_documents')}</h4>
                                                    <div style={{ marginTop: 10 }}>
                                                        {!isUpdatingRegistration[8] ? (
                                                            <>
                                                                {isSeller ?
                                                                    <label className={classes.contractbutton}>
                                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                                        {!hasDocument('Other documents') ? t('documents.upload_document') : t('documents.update_document')}
                                                                        <span>
                                                                            <input type="file" onChange={(e) => handleRegistationChange(e, 8)} />
                                                                        </span>
                                                                    </label> : null}
                                                                {hasDocument('Other documents') ?
                                                                    <label className={classes.contractbutton} onClick={() => openDocument('Other documents')}>
                                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                                        {t('documents.view_document')}
                                                                    </label> : null}
                                                            </>
                                                        ) : (
                                                                ""
                                                            )}
                                                    </div>
                                                    <div
                                                        className="profile-usertitle-job mt-2"
                                                        style={{ paddingLeft: "20%" }}
                                                    >
                                                        {/* {userAuthenticate.userType} */}
                                                        {isUpdatingRegistration[8] ? (
                                                            <button
                                                                type="button"
                                                                style={{ padding: 12 }}
                                                                className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                onClick={() => onUpdateFile(8, 'Other documents')}
                                                                disabled={registrationLoader[8]}
                                                            >
                                                                {registrationLoader[8] ? (
                                                                    <span>
                                                                        <i
                                                                            className="fa fa-spinner fa-spin fa-1x"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </span>
                                                                ) : null}
                                                                {t('documents.save')}
                                                            </button>
                                                        ) : null}

                                                        {isUpdatingRegistration[8] ? (
                                                            <button
                                                                type="button"
                                                                className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                                                                style={{ padding: 12, color: "white" }}
                                                                onClick={() => cancelEditRegistation(8)}
                                                                disabled={registrationLoader[8]}
                                                            >
                                                                {t('documents.cancel')}
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                </>
                                                    : null}
                                        </div>
                                        : null}

                                {currentContract ?
                                    (currentContract.getProof + " ").length > 10 ?
                                        <>
                                            <h4 style={{ fontWeight: 'normal' }}>{t('documents.note')} {isSeller ? 'Buyer' : 'Seller'}</h4>
                                            <div style={{ marginTop: 10 }}>
                                                <label>{t('documents.rating')}</label>
                                                <select className="form-control" onChange={(e) => { setNote(e.target.value) }}>
                                                    <option value="1">
                                                        1
                                                    </option>
                                                    <option value="2">
                                                        2
                                                    </option>
                                                    <option value="3">
                                                        3
                                                    </option>
                                                    <option value="4">
                                                        4
                                                    </option>
                                                    <option value="5">
                                                        5
                                                    </option>
                                                </select>
                                                {/* <input
                                                    name="note"
                                                    className="form-control"
                                                    type="number"
                                                    max={5}
                                                    min={0}
                                                    placeholder={t('documents.rating')}
                                                    onChange={(e) => { setNote(e.target.value) }}
                                                /> */}
                                                <input
                                                    name="comment"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={t('documents.comment')}
                                                    onChange={(e) => { setComment(e.target.value) }}
                                                />
                                                <button
                                                    onClick={() => sendRating()}
                                                    className="btn btn-primary py-4">
                                                    {t('documents.send_rating')}
                                                </button>
                                            </div>
                                        </>
                                        :
                                        null : null
                                }
                            </div>
                        </div>

                    </DialogContent>
                </Dialog>

                <div className="mb-6"></div>
            </div>
        </>
    )
}

export default withTranslation()(React.memo(withRouter(ContractsList)));
