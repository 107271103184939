import React from 'react';


function RfqListItem() {
    const next = ( e ) => {
        document.querySelector( "#shipping-form" ) && document.querySelector( "#shipping-form" ).submit();
    }

    return (
        <>
            <div className="main">
                <div className="rfq-item-container">
                    <div className="row">
                        <div className="col-md-1 rfq-item-cell rfq-item-image">
                            <img className="" src="/assets/images/demo/avatar/cocoa.jpg"/>
                        </div>   
                        <div className="col-md-2">
                            <span style={{paddingRight:"10px"}}><i className="fa fa-circle" style={{color:"#57b793"}} aria-hidden="true"></i></span>
                             Cocoa 
                        </div>
                        <div className="col-md-2 rfq-item-cell"> 100$ </div>
                        <div className="col-md-2 rfq-item-cell"> Sea </div>
                        <div className="col-md-3 rfq-item-cell"> Monday 27 december 2021</div>
                        <div className="col-md-2 rfq-item-cell">
                            <button className="btn btn-primary rfq-item-button">Action
                                {/*<Link to="#" className="card-edit"><UserInformationUserModal userId={userAuthenticate.id} userAuthenticated={userAuthenticate} /></Link>*/}
                            </button>

                            {/*<div className="rfq-item-icons">
                            <Link to="#" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i></Link>
                            <Link to="#" target="_blank"><i class="fa fa-paper-plane" aria-hidden="true"></i></Link>
                            <Link to="#" target="_blank"><i class="fa fa-trash" aria-hidden="true"></i></Link>
                            <Link to="#" target="_blank"><i class="fa fa-comments" aria-hidden="true"></i></Link>
                            </div>*/}
                        </div>
                    </div>
                    {/*<div className="row">
                        
                    </div>*/}
                </div>
            </div>
        </>
    )
}

export default RfqListItem;