import {
    UPDATE_INFORMATIONS_USER_BASE, UPDATE_INFORMATIONS_USER_SUCCESS, UPDATE_INFORMATIONS_USER_ERROR,
    UPDATE_INFORMATIONS_USER_CLEAR
} from '../../../constants/auth-action-types'

const initialState = {

    data: null,

    loading: false,
    error: null,
    userUpdated: false
}

const updateUserInformationReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case UPDATE_INFORMATIONS_USER_BASE:
            return { ...state, loading: true, error: null, userUpdated: false, data: null};

        case UPDATE_INFORMATIONS_USER_SUCCESS:
            return { ...state, error: null, loading: false, userUpdated: true, data: action.payload };

        case UPDATE_INFORMATIONS_USER_ERROR:
            return { ...state, data: null, loading: false, userUpdated: false, error: action.payload };

        
        case UPDATE_INFORMATIONS_USER_CLEAR:
            return initialState

        default:
            return state;
    }
};

/* const persistConfig = {
    keyPrefix: "porto-",
    key: "products",
    storage
}
 */
export default updateUserInformationReducer;