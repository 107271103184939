import Doc from './DocService';
import React, { useEffect } from 'react';

const ContractPdf = (props) => {
    const {generate, setContractPdfBase64} = props;
    
    const createPdf1 = (html) => Doc.createPdf(html, setContractPdfBase64);

    const bodyPurchaseRef = React.createRef();

    useEffect(() => {
        if (generate) {
            createPdf1(bodyPurchaseRef.current);
        }

    }, [props.generate])

    return (
        <>
            <div className="pdfContract">
                
                <section ref={bodyPurchaseRef}>
                    <h2 style={{textAlign: 'center'}}>CONTRACT DOCUMENT</h2>
                </section>
            </div>  
        </>
    )
}
export default ContractPdf;

