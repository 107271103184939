import React from 'react';
import Modal from 'react-modal';
// import { setModalLoginStatus } from '../../action/modal/modalLogin-actions';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'relative',
        maxWidth: '1000px',
        height: '500px',
        marginRight: '2rem',
        padding: '.5rem',
        backgroundColor: '#fff'
    }
};

Modal.setAppElement('#root');

function ModalInfo(props) {

    return (
        <>
            <Modal
                shouldCloseOnOverlayClick={true}
                isOpen={props.show}
                onRequestClose={() => props.closeModal()}
                contentLabel="login Modal"
                className="login-popup"
                id="login-popup-form"
                overlayClassName="cart-modal-overlay"
                style={customStyles}
            >
                <div className="modal-wrapper" style={{ height: "100%" }}>
                    {props.children}
                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => props.closeModal()}>X</button>
                </div>
            </Modal>
        </>
    )

}

export default ModalInfo;