/**
 * find Product by id in array of products
 * @param { Array } products 
 * @param { String } id 
 */
export function findProductById( products, id ) {
    return products.filter( item => isNaN( id ) === false && item.id === parseInt( id ) )[ 0 ]
}


export const getContentInPageContent = (data, page, key, lang) => {

    const val = data.find( item => item.page===page && item.key===key && item.lang===lang)
    //console.log("aaaaaaaaaaaaaaaaaaaaaaaaa", val)

    if (val) {
        return val.value;
    }

    return 'NO TEXT'
}


export const checkIfEmailInString = (text) => { 
    let re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    return re.test(text);
}

export const checkIfPhoneInString = (text) => {
    let phoneExp = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/;
    return phoneExp.test(text);
}
