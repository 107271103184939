// SIGNUP USER
export const SIGNUP_USER_BASE = 'SIGNUP_USER'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNUP_USER_ERROR = 'SIGNUP_USER_ERROR'


// FETCH AHTH USER
export const FETCH_AHTH_USER_BASE = 'FETCH_AHTH_USER'
export const FETCH_AHTH_USER_SUCCESS = 'FETCH_AHTH_USER_SUCCESS'
export const FETCH_AHTH_USER_ERROR = 'FETCH_AHTH_USER_ERROR'


// UPDATE USER INFORMATIONS **************************************************
export const UPDATE_INFORMATIONS_USER_BASE = 'UPDATE_INFORMATIONS_USER';
export const UPDATE_INFORMATIONS_USER_SUCCESS = 'UPDATE_INFORMATIONS_USER_SUCCESS'
export const UPDATE_INFORMATIONS_USER_ERROR = 'UPDATE_INFORMATIONS_USER_ERROR'
export const UPDATE_INFORMATIONS_USER_CLEAR = 'UPDATE_INFORMATIONS_USER_CLEAR'

export const UPDATE_COMPANY_INFORMATIONS_USER_BASE = 'UPDATE_COMPANY_INFORMATIONS_USER';
export const UPDATE_COMPANY_INFORMATIONS_USER_SUCCESS = 'UPDATE_COMPANY_INFORMATIONS_USER_SUCCESS'
export const UPDATE_COMPANY_INFORMATIONS_USER_ERROR = 'UPDATE_COMPANY_INFORMATIONS_USER_ERROR'
export const UPDATE_COMPANY_INFORMATIONS_USER_CLEAR = 'UPDATE_COMPANY_INFORMATIONS_USER_CLEAR'

export const UPDATE_AVATAR_USER_BASE = 'UPDATE_AVATAR_USER';
export const UPDATE_AVATAR_USER_SUCCESS = 'UPDATE_AVATAR_USER_SUCCESS'
export const UPDATE_AVATAR_USER_ERROR = 'UPDATE_AVATAR_USER_ERROR'
export const UPDATE_AVATAR_USER_CLEAR = 'UPDATE_AVATAR_USER_CLEAR'

export const UPDATE_LICENCE_USER_BASE = 'UPDATE_LICENCE_USER';
export const UPDATE_LICENCE_USER_SUCCESS = 'UPDATE_LICENCE_USER_SUCCESS'
export const UPDATE_LICENCE_USER_ERROR = 'UPDATE_LICENCE_USER_ERROR'
export const UPDATE_LICENCE_USER_CLEAR = 'UPDATE_LICENCE_USER_CLEAR'

export const UPDATE_PASSWORD_USER_BASE = 'UPDATE_PASSWORD_USER';
export const UPDATE_PASSWORD_USER_SUCCESS = 'UPDATE_PASSWORD_USER_SUCCESS'
export const UPDATE_PASSWORD_USER_ERROR = 'UPDATE_PASSWORD_USER_ERROR'

export const UPDATE_COMPANY_USER_BASE = 'UPDATE_ICOMPANY_USER';
export const UPDATE_COMPANY_USER_SUCCESS = 'UPDATE_COMPANY_USER_SUCCESS'
export const UPDATE_COMPANY_USER_ERROR = 'UPDATE_COMPANY_USER_ERROR'
// UPDATE USER INFORMATIONS **************************************************


// SIGINUP USER
export const SIGNIN_USER_BASE = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS'
export const SIGNIN_USER_ERROR = 'SIGNIN_USER_ERROR'

export const LOGOUT = 'LOGOUT'