import Pdf from './Pdf';
import { Helmet } from 'react-helmet';
import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { withTranslation, useTranslation } from "react-i18next";

const NotifsList = (props) => {
    const next = (e) => {
        document.querySelector("#shipping-form") && document.querySelector("#shipping-form").submit();

    }

    const { i18n } = useTranslation();

    const [loadingData, setLoadingData] = useState(false);
    const [errorData, setErrorData] = useState(null);


    let loader = null;
    let errorDisplay = null;

    const { t } = props;

    // const filterFromSearchText = (item) => {
    //     // console.log(item)
    //     return item.baseQuotation.product.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
    // }

    if (loadingData) {
        loader = (
            <div className="loader text-center">
                <div className="bounce-loader">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
        );
    }

    if (errorData) {
        errorDisplay = (
            <>
                <br />
                <div className="alert alert-danger">Error: {errorData.message}</div>
                <br />
            </>
        );
    }


    return (
        <>
            <Helmet>
                <title>Sooko - RFQ </title>
            </Helmet>

            <div className="main">

                <div className="container">

                    <div className="row">
                        <div className="col-md-10"><h2>{t('notes.guides')}</h2></div>
                    </div>
                    <div style={{ display: 'inline-block', width: '100% !important', marginRight: "70px" }}>
                        <Pdf className="licence-pdfs2" language={i18n.language} />
                    </div>

                </div>

                <div className="mb-6"></div>
            </div>
        </>
    )
}
export default withTranslation()(React.memo(withRouter(NotifsList)));
