import * as rfqChatActions from '../constants/rfsChat-actions-types';
/* import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'; */

const initialState = {

    activeMessages: [],

    loading: false,
    error: null,
}

const chatReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case rfqChatActions.FETCH_MESSAGES_WITH_USER:
            return { ...state, loading: true, error: null, activeMessages: [] };

        case rfqChatActions.FETCH_MESSAGES_WITH_USER_SUCCESS:
            return { ...state, error: null, loading: false, activeMessages: action.payload };

        case rfqChatActions.FETCH_MESSAGES_WITH_USER_ERROR:
            return { ...state, activeMessages: [], loading: false,  error: action.payload }

        
        case rfqChatActions.APPEND_NEW_MESSAGES:
            const messages = state.activeMessages.concat(action.payload)
            return { ...state, activeMessages: messages }

        
        case rfqChatActions.FETCH_MESSAGES_WITH_USER_CLEAR:
            return initialState


        default:
            return state;
    }
};

/* const persistConfig = {
    keyPrefix: "porto-",
    key: "products",
    storage
}
 */
export default chatReducer;