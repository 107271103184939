import React, { useEffect, useState } from "react";
import FileUploadWithPreview from "file-upload-with-preview";

//import Breadcrumb from '../../../common/breadcrumb';
import { useForm } from "react-hook-form";
import classes from "../rfqCreate.module.scss";
import { useSelector } from "react-redux";

export default function RfqCreateStep3(props) {
  const {
    setDataStep3,
    unitsConfig,
    onPreviousStep,
    loading,
    defaultDataStep3,
  } = props;

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: { ...defaultDataStep3 },
  });

  const authUser = useSelector((state) => state.authUser);

  const [langSelected, setLangSelected] = useState("en");

  useEffect(() => {
    if (authUser.authUser != null) {
      setLangSelected(authUser.authUser.language.toLowerCase());
    }
  }, [authUser])

  const onSubmitForm = (data, publishStatus) => {
    const publishStatusToSend =  publishStatus === 'SELLERS_OF_PRODUCT' ? true : false 

    setDataStep3(data, publishStatusToSend);
  };

  useEffect(() => {}, []);

  return (
    <div className="main">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 order-lg-first">
            <div className="checkout-payment">
              <div id="new-checkout-address" className="show">
                <form name="rfqForm mt-2" className={classes.rfqForm}>
                  <div className="showStepOfStepper">
                    <div className="row">
                      <div className="form-group required-field col-md-6">
                        <label> Quantity </label>
                        <input
                          type="number"
                          className="form-control"
                          name="quantity"
                          ref={register}
                          required
                        />
                      </div>
                      <div className="form-group required-field col-md-6">
                        <label> Per unit </label>
                        <select
                          className="form-control"
                          name="quantityUnitId"
                          ref={register}
                          required
                        >
                          {unitsConfig.map((item, index) => (
                            <option key={index} value={Number(item.id)}>
                              { item[langSelected == 'fr' ? 'fr' : 'en'] }
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group required-field  col-md-6">
                        <label> Quality specification </label>
                        <textarea
                          type="text"
                          className="form-control"
                          spellcheck='false'
                          name="qualitySpecification"
                          ref={register}
                          required
                        ></textarea>
                      </div>

                      <div className="form-group required-field col-md-6">
                        <label> Packaging details </label>
                        <textarea
                          className="form-control"
                          cols="4"
                          spellcheck='false'
                          name="packagingDetails"
                          ref={register}
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="col-md-12">
                  <button
                    onClick={onPreviousStep}
                    className="btn btn-primary float-left px-5"
                  >
                    Previous
                  </button>

                  <button
                    onClick={handleSubmit((d)=>onSubmitForm(d, 'SELLER_ONLY'))}
                    className="btn btn-primary float-right ml-3"
                    disabled={loading}
                  >
                    {loading ? (
                      <span>
                        <i
                          class="fa fa-spinner fa-spin fa-1x"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;
                      </span>
                    ) : null}
                    Send to this seller only 
                  </button>

                  <button
                    onClick={handleSubmit((d)=>onSubmitForm(d, 'SELLERS_OF_PRODUCT'))}
                    className="btn btn-primary float-right"
                    disabled={loading}
                  >
                    {loading ? (
                      <span>
                        <i
                          class="fa fa-spinner fa-spin fa-1x"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;
                      </span>
                    ) : null}
                    Send to all sellers of this product
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6"></div>
    </div>
  );
}
