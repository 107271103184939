import React from 'react';
import { withTranslation } from "react-i18next";

function CheckoutProgessBar( props ) {
    const { active = 1, t } = props;
    return (
        <div>
            <ul className="checkout-progress-bar">
                <li className={ active === 1 ? 'active' : '' }>
                    <span>{t('products.details')}</span>
                </li>
                <li className={ active === 2 ? 'active' : '' }>
                    <span>{t('products.payment')}</span>
                </li>
                <li className={ active === 3 ? 'active' : '' }>
                    <span>{t('products.logistic')}</span>
                </li>
            </ul>
        </div>
    )
}

export default withTranslation()(React.memo( CheckoutProgessBar ));