import store from '../store';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../components/layout';
import FAQ from '../components/pages/faq';
import { initStickyOffset } from '../utils';
import Blog from '../components/pages/blog';
import Login from '../components/pages/login';
import About from '../components/pages/about';
import HomePage from '../components/pages/home';
import { ToastContainer } from 'react-toastify';
import { Route, Switch } from 'react-router-dom';
import Contact from '../components/pages/contact';
import Privacy from '../components/pages/privacy';
import Dashboard from '../components/pages/dashboard';
import RfqList from '../components/pages/rfq/rfqList';
import Terms from '../components/pages/privacy/terms';
import BlogPost from '../components/pages/blog/single';
import Marketplace from '../components/pages/marketplace';
import RfqCreation from '../components/pages/rfq/rfqCreation';
import ErrorPage from '../components/pages/others/404/page-error';
import DiscussionPost from '../components/pages/discussion/single';
import Discussion from '../components/pages/discussion/blog';
import { getAllProductsConfig } from '../action/productsSettings-action';
import DefaultProduct from '../components/pages/products/default-product';
import { HIDE_CART_MODAL, HIDE_QUICKVIEW } from '../constants/action-types';
import ForgetPassword from '../components/pages/others/password/forget-password';
import Guide from '../components/pages/components/account/seller/guide/guide';
import { ABOUT, CHAT, CONTACT, CONTRACT, CONTRACT_FILE, DASHBOARD, DISCUSSION, FORGOT_PASSWORD, 
    HOME, LOGIN, MARKETPLACE, MARKET_INTELLIGENCE, MARKET_INTELLIGENCE_DETAILS, NOT_FOUND, PRIVACY_POLICY, 
    PRODUCT_DETAILS, RFQ_LIST, ROOT, TERM_AND_CONDITIONS, USER_GUIDE, FAQ as FAQ_ROUTE, CREATE_RFQ, UPDATE_RFQ, 
    DISCUSSION_POST, NEW_RFQ, DASHBOARD_RFQ_UPDATE, RFQ_DETAILS } from './urls';

export default function Routes() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllProductsConfig());
    }, [])

    store.dispatch({ type: HIDE_CART_MODAL });
    store.dispatch({ type: HIDE_QUICKVIEW });

    initStickyOffset();

    return (
        <>
            <ToastContainer />
            <Switch>
                <Layout>
                    <Route exact path={ROOT} component={HomePage} />
                    <Route exact path={HOME} component={HomePage} />
                    <Route exact path={LOGIN} component={Login} />
                    <Route exact path={ABOUT} component={About} />
                    <Route exact path={FORGOT_PASSWORD} component={ForgetPassword} />
                    <Route exact path={DISCUSSION} component={Discussion} />
                    <Route exact path={CONTACT} component={Contact} />
                    <Route exact path={MARKET_INTELLIGENCE} component={Blog} />
                    <Route exact path={RFQ_LIST} component={RfqList} />
                    <Route exact path={MARKETPLACE} component={Marketplace} />
                    <Route exact path={CREATE_RFQ} component={RfqCreation} />
                    <Route exact path={UPDATE_RFQ} component={RfqCreation} />
                    <Route exact path={DISCUSSION_POST} component={DiscussionPost} />
                    <Route exact path={NEW_RFQ} component={RfqCreation} />
                    <Route exact path={DASHBOARD_RFQ_UPDATE} component={Dashboard} />
                    <Route exact path={RFQ_DETAILS} component={Dashboard} />
                    <Route exact path={CONTRACT_FILE} component={Dashboard} />
                    <Route exact path={CONTRACT} component={Dashboard} />
                    <Route exact path={CHAT} component={Dashboard} />
                    <Route exact path={DASHBOARD} component={Dashboard} />
                    <Route exact path={PRODUCT_DETAILS} component={DefaultProduct} />
                    <Route exact path={PRIVACY_POLICY} component={Privacy} />
                    <Route exact path={TERM_AND_CONDITIONS} component={Terms} />
                    <Route exact path={USER_GUIDE} component={Guide} />
                    <Route exact path={FAQ_ROUTE} component={FAQ} />
                    <Route exact path={MARKET_INTELLIGENCE_DETAILS} component={BlogPost} />
                    <Route exact path={NOT_FOUND} component={ErrorPage} />
                </Layout>
            </Switch>
        </>
    )
}