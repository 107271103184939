

export const FETCH_PAGE_CONTENT = 'FETCH_PAGE_CONTENT';
export const FETCH_PAGE_CONTENT_SUCCESS = 'FETCH_PAGE_CONTENT_SUCCESS';
export const FETCH_PAGE_CONTENT_ERROR = 'FETCH_PAGE_CONTENT_ERROR';
export const FETCH_PAGE_CONTENT_CLEAR = 'FETCH_PAGE_CONTENT_CLEAR';

export const FETCH_COMMODITY_PRICE = 'FETCH_COMMODITY_PRICE';
export const FETCH_COMMODITY_PRICE_SUCCESS = 'FETCH_COMMODITY_PRICE_SUCCESS';
export const FETCH_COMMODITY_PRICE_ERROR = 'FETCH_COMMODITY_PRICE_ERROR';
export const FETCH_COMMODITY_PRICE_CLEAR = 'FETCH_COMMODITY_PRICE_CLEAR';