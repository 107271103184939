import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import classes from "../productCreate.module.scss";

function ProductCreateStep3(props) {
  const { setDataStep3, setDataFormStep3ToSaveDataStep3, unitsConfigQuantity, unitsConfigTime, portsConfig, onPreviousStep, loading, defaultDataStep3, productToUpdate } = props;

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: { ...defaultDataStep3 }
  });

  //.sort((u1, u2) => u1[langSelected].localeCompare(u2[langSelected]))

  const [countrySelected, setCountrySelected] = useState(productToUpdate ? productToUpdate.port.country : '');

  const onSubmitFormToSaveDataStep3 = (data) => {
    setDataFormStep3ToSaveDataStep3(data);
    onPreviousStep();
  }

  const { authUser } = useSelector(state => state.authUser);
  const [langSelected, setLangSelected] = useState("en");

  useEffect(() => {
    if (authUser != null) {
      setLangSelected(authUser.language.toLowerCase());
    }
  }, [authUser])

  const onSubmitForm = (data) => {
    setDataStep3(data);
  };

  const { t } = props;

  useEffect(() => { }, []);

  return (
    <div className="main">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 order-lg-first">
            <div className="checkout-payment">

              <div id="new-checkout-address" className="show">
                <form name="productForm mt-2" className={classes.productForm}>
                  <div className="showStepOfStepper">
                    <div className="row">
                      <div className="form-group required-field col-md-6">
                        <label> {t("products.processing_time")} </label>
                        <input
                          type="number"
                          className="form-control"
                          name="processingTime"
                          ref={register({ required: true })}
                          required
                        />
                        {errors.processingTime && <div className="text-danger mt-1">{t("form.delayRequired")}</div>}
                      </div>

                      <div className="form-group required-field  col-md-6">
                        <label> {t("products.unit")} </label>
                        <select
                          className="form-control"
                          name="processingTimeUnitId"
                          ref={register({ required: true })}
                          required
                        >
                          <option value=""> </option>
                          {unitsConfigTime.filter(u => u.unitType === 'TIME').map((item, __) => (
                            <option key={item.id} value={Number(item.id)}>
                              {item[langSelected == 'fr' ? 'fr' : 'en']}
                            </option>
                          ))}
                        </select>
                        {errors.processingTimeUnitId && <div className="text-danger mt-1">{t("form.unitRequired")}</div>}
                      </div>
                    </div>


                    <div className="row">
                      <div className="required-field col-lg-12 col-md-12">
                        <label> {t("products.port_of_departure")} </label>
                        <select
                          className="form-control"
                          name="portId"
                          ref={register({ required: true })}
                          required
                        >
                          <option value="">  </option>
                          {portsConfig.map((item, __) => (
                            <option key={item.id} value={Number(item.id)}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.portId && (
                          <div className="text-danger mt-1">
                            {t("form.portRequired")}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group required-field col-md-4">
                        <label> {t("products.production_capacity")} </label>
                        <input
                          type="number"
                          className="form-control"
                          name="productionCapacity"
                          ref={register({ required: true })}
                          required
                        />
                        {errors.productionCapacity && <div className="text-danger mt-1">{t("form.capacityRequired")}</div>}
                      </div>

                      <div className="form-group required-field  col-md-4">
                        <label> {t("products.product_perunit")} </label>
                        <select
                          className="form-control"
                          name="productionCapacityUnitId"
                          ref={register({ required: true })}
                          required
                        >
                          <option value=""> </option>
                          {unitsConfigQuantity.map((item, __) => (
                            <option key={item.id} value={Number(item.id)}>
                              {item[langSelected == 'fr' ? 'fr' : 'en']}
                            </option>
                          ))}
                        </select>
                        {errors.productionCapacityUnitId && <div className="text-danger mt-1">{t("form.unitRequired")}</div>}
                      </div>

                      <div className="form-group required-field  col-md-4">
                        <label> {t("products.by_period")} </label>
                        <select
                          className="form-control"
                          name="productionCapacityPeriodUnitId"
                          ref={register({ required: true })}
                          required
                        >
                          <option value=""> </option>
                          {unitsConfigTime.filter(u => u.unitType === 'TIME').map((item, __) => (
                            <option key={item.id} value={Number(item.id)}>
                              {item[langSelected == 'fr' ? 'fr' : 'en']}
                            </option>
                          ))}
                        </select>
                        {errors.productionCapacityPeriodUnitId && <div className="text-danger mt-1">{t("form.periodRequired")}</div>}
                      </div>
                    </div>

                    <div className="row">
                      <div className="required-field col-md-12">
                        <label> {t("products.packaging_details")} </label>
                        <textarea
                          className="form-control"
                          cols="5"
                          spellcheck='false'
                          name="packagingsDetails"
                          ref={register({ required: true })}
                          required
                        ></textarea>
                        {errors.packagingsDetails && <div className="text-danger mt-1">{t("form.detailsRequired")}</div>}
                      </div>
                    </div>
                  </div>
                </form>

                <div className="col-md-12">
                  <button
                    onClick={handleSubmit(onSubmitFormToSaveDataStep3)}
                    className="btn btn-primary float-left px-5"
                  >
                    {t("products.previous")}
                  </button>

                  <button
                    onClick={handleSubmit(onSubmitForm)}
                    className="btn btn-primary float-right px-5"
                    disabled={loading}
                  >
                    {loading ? (
                      <span>
                        <i
                          class="fa fa-spinner fa-spin fa-1x"
                          aria-hidden="true"
                        ></i>
                          &nbsp;&nbsp;
                      </span>
                    ) : null}
                    {!productToUpdate ? t("products.create") : t("products.update")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6"></div>
    </div>
  );
}
export default withTranslation()(React.memo(ProductCreateStep3));
