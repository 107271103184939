import Pdf from "./Pdf";
import "./contracts.scss";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import classes from "../../../board.module.css";
import React, { useEffect, useState } from "react";
import { getContractAttachedDocuments, updateContractAttachedFile, } from "@action/contracts-actions";

const ContractAttachedFiles = (props) => {
  const [documents, setDocuments] = useState([]);
  const [documentAttached, setDocumentAttached] = useState(null);

  const [registrationFile, setRegistrationFile] = useState([]);
  const [registrationLoader, setRegistrationLoader] = useState([]);
  const [registrationToSend, setRegistrationToSend] = useState([]);
  const [isUpdatingRegistration, setIsUpdatingRegistration] = useState([]);

  const handleRegistationChange = (event) => {
    setRegistrationFile([...registrationFile], window.URL.createObjectURL(event.target.files[0]));
    setRegistrationToSend([...registrationToSend], event.target.files[0]);
    setIsUpdatingRegistration([...isUpdatingRegistration], true);
  };

  const onUpdateRegistration = (index) => {

    if (registrationToSend) {
      updateContractAttachedFile(
        { file: registrationToSend[index], name: registrationToSend[index].name, contractId:props.match.params.contractId})
        .then((result) => {
          setDocumentAttached(result)
          setRegistrationFile([...registrationFile, ...[...{[index]: null}] ]);
          setRegistrationLoader(false);
          setIsUpdatingRegistration(false);
          toast(`Success: Updated registration successfully`, {
            className: "toast-success",
          });
        })
        .catch((err) => {
          toast(`Failed to get testimnials: ${err.message}`, {
            className: "toast-error",
          });
          setRegistrationLoader(false);
          setRegistrationFile(null);
          setIsUpdatingRegistration(false);
        });
    }
  };

  const cancelEditRegistation = () => {
    setRegistrationFile(null);
    setRegistrationToSend(null);
    setIsUpdatingRegistration(false);
  };

  useEffect(() => {
    getContractAttachedDocuments(props.match.params.contractId)
      .then((result) => {
        setDocuments(result);
      })
      .catch((err) => {
        toast(`Error to mark as read: ${err.message}`, {
          className: "toast-error",
        });
      });
  }, []);


  console.log(" All documents attached  result =>", documents);
  
  const goToContract = () => {
    props.history.push(
      `${process.env.PUBLIC_URL}/account/dashboard/contracts/${props.match.params.contractId}/contract-effective`
    );
  };

  return (
    <>
      <div className="main">
        <div className="container">
          <h2> Contracts Attached Files</h2>

          <div className="row">
            {documents.map((item, index) => <div className="col-md-4" key={index}>
              
              {!registrationFile ?<Pdf pdfUrl={`${process.env.BACKEND_BASE_URL}${item.path}`} className="licence-pdfs" /> : null}
              {registrationFile ? <Pdf pdfUrl={registrationFile} className="licence-pdfs" /> : null}
              <div style={{ marginTop: 10 }}>
                {!isUpdatingRegistration ? (
                  <label className={classes.filelicencebutton}>
                    <i className="fa fa-upload" aria-hidden="true"></i>
                    Upload attached file
                    <span>
                      <input type="file" onChange={handleRegistationChange(index)} />
                    </span>
                  </label>
                ) : (
                  ""
                )}
              </div>

              <div
                className="profile-usertitle-job mt-2"
                style={{ paddingLeft: "20%" }}
              >
                {/* {userAuthenticate.userType} */}
                {isUpdatingRegistration ? (
                  <button
                    type="button"
                    style={{ padding: 12 }}
                    className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                    onClick={onUpdateRegistration}
                    disabled={registrationLoader}
                  >
                    {registrationLoader ? (
                      <span>
                        <i
                          className="fa fa-spinner fa-spin fa-1x"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;
                      </span>
                    ) : null}
                    Update
                  </button>
                ) : null}

                {isUpdatingRegistration ? (
                  <button
                    type="button"
                    className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                    style={{ padding: 12, color: "white" }}
                    onClick={cancelEditRegistation}
                    disabled={registrationLoader}
                  >
                    Cancel
                  </button>
                ) : null}
              </div>
            </div>)}

            
          </div>
          <div className="row">
              <div className="col-md-6">
                <button className="btn btn-primary" onClick={() => goToContract()}>
                    Go to contract
                </button>
              </div>
              <div className="col-md-6">
              <div style={{ marginTop: 10 }}>
                {!isUpdatingRegistration ? (
                  <label className={classes.filelicencebutton}>
                    <i className="fa fa-upload" aria-hidden="true"></i>
                    Add new attached file
                    <span>
                      <input type="file" onChange={handleRegistationChange} />
                    </span>
                  </label>
                ) : (
                  ""
                )}
              </div>

              <div
                className="profile-usertitle-job mt-2"
                style={{ paddingLeft: "20%" }}
              >
                {/* {userAuthenticate.userType} */}
                {isUpdatingRegistration ? (
                  <button
                    type="button"
                    style={{ padding: 12 }}
                    className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                    onClick={onUpdateRegistration}
                    disabled={registrationLoader}
                  >
                    {registrationLoader ? (
                      <span>
                        <i
                          className="fa fa-spinner fa-spin fa-1x"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;
                      </span>
                    ) : null}
                    Update
                  </button>
                ) : null}

                {isUpdatingRegistration ? (
                  <button
                    type="button"
                    className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                    style={{ padding: 12, color: "white" }}
                    onClick={cancelEditRegistation}
                    disabled={registrationLoader}
                  >
                    Cancel
                  </button>
                ) : null}
              </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ContractAttachedFiles);
