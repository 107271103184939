import Modal from 'react-modal';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { withTranslation } from "react-i18next";
import { updateRFQSeller } from "@action/rfqs-actions";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'relative',
        maxWidth: '870px',
        marginRight: '2rem',
        padding: '.5rem',
        backgroundColor: '#fff'
    }
};

Modal.setAppElement('#root');

function UpdateRfqModal(props) {

    const { rfq, authUser } = props;

    const { register, handleSubmit, errors, reset } = useForm({
        defaultValues: {
            price: rfq.quotation.price,
            quantity: rfq.quotation.quantity,
            qualitySpecification: rfq.quotation.qualitySpecification,
            condition: rfq.quotation.condition,
            packagingDetails: rfq.quotation.packagingDetails
        }
    });
    
    const { t } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const dispatch = useDispatch();

    let errorUpdate = null;

    const [open, setOpen] = useState(false);

    const closeModal = (fromChild = false) => {

        if (fromChild) {
            setTimeout(() => {
                setOpen(false);
            }, 2000);
        }

        setOpen(false);

    }

    if (error) {
        errorUpdate = (
            <div className="alert alert-danger">
                ERROR: {error.message}
            </div>
        )
    }


    const onSubmitForm = (data) => {
        updateRFQSeller({price: data.price, quantity: data.quantity, condition: data.condition, specification: data.qualitySpecification, packagingDetail: data.packagingDetails, userId: authUser.id, qstId: rfq.id}, rfq.quotation.id).then(data => {
            props.refreshRfq()
        });
        setOpen(false);
    }

    return (
        <>
            <button className='btn btn-primary py-4' onClick={() => setOpen(true)}>{t("rfq.submit_offer")}</button>
            <Modal
                shouldCloseOnOverlayClick={true}
                isOpen={open}
                onRequestClose={closeModal}
                contentLabel="login Modal"
                className="login-popup"
                id="login-popup-form"
                overlayClassName="cart-modal-overlay"
                style={customStyles}
            >
                <div className="modal-wrapper" style={{ height: "100%" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="title mb-2">{t("rfq.update_rfq")}</h2>

                                <form className="mb-1">
                                    <label htmlFor="price"> {t("rfq.price")} <span className="required">*</span></label>
                                    <input type="number" name="price" className="form-input form-wide mb-2" id="price" ref={register} required />

                                    <label htmlFor="quantity"> {t("rfq.quantity")} <span className="required">*</span></label>
                                    <input type="number" name="quantity" className="form-input form-wide mb-2" id="quantity" ref={register} required />

                                    <label htmlFor="Fax">{t("rfq.conditions")}  <span className="required">*</span></label>
                                    <textarea
                                        className="form-control"
                                        style={{
                                            width: '100%'
                                        }}
                                        cols="5"
                                        name="condition"
                                        ref={register({ required: true })}
                                        required
                                    ></textarea>

                                    <label htmlFor="websiteUrl">{t("rfq.quality")} <span className="required">*</span></label>
                                    <textarea
                                        className="form-control"
                                        style={{
                                            width: '100% !important'
                                        }}
                                        cols="5"
                                        name="qualitySpecification"
                                        ref={register({ required: true })}
                                        required
                                    ></textarea>

                                    <label htmlFor="packagingDetails">{t("rfq.packaging")} <span className="required">*</span></label>
                                    <textarea
                                        className="form-control"
                                        style={{
                                            width: '100% !important'
                                        }}
                                        cols="5"
                                        name="packagingDetails"
                                        ref={register({ required: true })}
                                        required
                                    ></textarea>

                                    <div className="form-footer">
                                        <button type="submit" className="btn btn-primary btn-md" disabled={loading} onClick={handleSubmit(onSubmitForm)}>
                                            {loading ? <span><i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> : null}
                                            SUBMIT
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={closeModal}>×</button>
                </div>
            </Modal>
        </>
    )

}

export default withTranslation()(React.memo(UpdateRfqModal));
