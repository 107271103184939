
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { ABOUT, CONTACT, DISCUSSION, LOGIN, MARKET_INTELLIGENCE, ROOT } from '@routes/urls';

function MainMenu(props) {
    const [path, setPath] = useState("");
    const { addClass = "", subClass = '' } = props;

    const { isAuth } = useSelector(state => state.authUser);

    useEffect(() => {
        setPath(window.location.href);
    })
    const {t} = props;

    return (
        <nav className={`main-nav ${addClass}`} style={{ background: '#00c805' }}>
            <ul className={`menu sf-arrows ${subClass}`}>
                <li className={path.indexOf("home") > -1 || path.indexOf('/') == -1 ? 'active' : ''}><Link to={ROOT}> {t("header.home")} </Link></li>
                <li className={path.indexOf("market-intelligence") > -1 ? 'active' : ''}><Link to={MARKET_INTELLIGENCE}>{t("header.marketi")}</Link></li>
                <li className={path.indexOf("marketplace") > -1 ? 'active' : ''}>
                    <Link to={`${process.env.PUBLIC_URL}/marketplace`}><i className="fa fa-store text-white" style={{fontSize: "16px"}} aria-hidden="true"></i> &nbsp; {t("header.market")}</Link>
                </li>
                <li className={path.indexOf("about") > -1 ? 'active' : ''}><Link to={ABOUT}>{t("header.about_us")}</Link></li>                
                <li className={path.indexOf("contact") > -1 ? 'active' : ''}><Link to={CONTACT}>Contact</Link></li>
                <li className={path.indexOf("discussion") > -1 ? 'active' : ''}><Link to={DISCUSSION}>Discussions</Link></li>
                {!isAuth ? <li className={path.indexOf("login") > -1 ? 'active' : ''}><Link to={LOGIN}>{t("header.sign_in")}</Link></li> : null}
            </ul>
        </nav>
    )

}

export default withTranslation()(MainMenu);
