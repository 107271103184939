import './contracts.scss';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import ContractsList from './ContractsList';

class index extends Component {
    
    render() {

        const {getCountContractsUnread} = this.props;

        return (
            <div className="row"> 
                <div className="col-md-12 col-xs-12">
                    <ContractsList getCountContractsUnread={getCountContractsUnread} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({authUser}) => ({
    authUser
})

export default connect(mapStateToProps, null)(index);
