import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from '../../../common/breadcrumb';
import { resetPassword, resetPasswordCode } from '@action/auth';


function ForgetPassword(props) {

    const { authUser } = useSelector(state => state)

    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [displayCodeField, setDisplayCodeField] = useState(false);
    const [displayPasswordField, setDisplayPasswordField] = useState(false);

    const { t } = props;

    const handleChange = (ev) => {
        setEmail(ev.target.value)
    };

    const onSubmitEmail = (ev) => {

        ev.preventDefault();

        if(displayCodeField && !displayPasswordField) {
            setDisplayPasswordField(true);
            return;
        }

        if(!displayCodeField) {
            setLoading(true);
            resetPasswordCode(email).then(() => {
                toast(`Email have been sent successfuly with new password, go and check it.`, { className: "toast-success" });
                setDisplayCodeField(true);
            }).catch((err) => {
                toast(`Failed to send email: ${err.message}`, { className: "toast-error" });
            }).finally(() => {
                setLoading(false);
            });
        } else {
            if(displayPasswordField) {
                if(password != passwordConfirm) {
                    toast(`Passwords are not the same`, { className: "toast-error" });
                    return;
                }
                setLoading(true);
                resetPassword({code, password}).then(() => {
                    toast(`Your password has been changed.`, { className: "toast-success" });
                    setDisplayCodeField(false);
                    setDisplayPasswordField(false);
                    window.location = "/login";
                }).catch((err) => {
                    toast(`Code received is not correct`, { className: "toast-error" });
                    setDisplayCodeField(true);
                    setDisplayPasswordField(false);
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Sooko - Forget Password Page </title>
            </Helmet>

            <h1 className="d-none">Sooko - Forget Password Page</h1>

            <div className="main">
                {/* <div className="page-header page-header-bg" style={{ background: `#EDEFEC no-repeat 60%/cover url('${process.env.PUBLIC_URL}/assets/images/demo/page-header-bg.jpg')` }}>
                    <div className="container">
                        <h1 className="mb-0">{t("reset.title")}</h1>
                    </div>
                </div> */}

                <Breadcrumb current="Forgot Password" />

                <div className="container">
                    <div className="col-lg-6 mx-auto mt-4">
                        <div className="heading mb-4">
                            {!displayCodeField && (
                                <>
                                    <h2 className="title">{t("reset.title")}</h2>
                                    <p>{t("reset.description")}</p>
                                </>
                            )}

                            {displayCodeField && (
                                <>
                                    { !displayPasswordField && (
                                        <>
                                            <h2 className="title">{t("reset.title2")}</h2>
                                            <p>{t("reset.description2")}</p>
                                        </>
                                    )}
                                    { displayPasswordField && (
                                        <>
                                            <h2 className="title">{t("reset.title3")}</h2>
                                            <p>{t("reset.description3")}</p> 
                                        </>
                                    )}
                                </>
                            )}
                        </div>

                        <form onSubmit={onSubmitEmail}>
                            {!displayCodeField && (
                                <div className="form-group required-field">
                                    <input placeholder={t('login.enter_email')} type="email" className="form-control" id="reset-email" name="email" value={email} onChange={handleChange} required
                                        disabled={authUser.authUser} />
                                </div>
                            )}

                            {displayCodeField && (
                                <>
                                    { !displayPasswordField && (
                                        <div className="form-group required-field">
                                            <input type="text" placeholder={t('login.enter_code')} className="form-control" id="reset-code" name="code" value={code} onChange={(e) => setCode(e.target.value)} required />
                                        </div>
                                    )}
                                    { displayPasswordField && (
                                        <>
                                            <div className="password-content">
                                                <input
                                                    required
                                                    name="password"
                                                    value={password}
                                                    className="form-control"
                                                    placeholder={t('board.new_password')}
                                                    type={showPassword ? 'text' : 'password'}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                {showPassword ? <i className="far fa-eye" onClick={() => setShowPassword(false)}></i>
                                                : <i className="far fa-eye-slash" onClick={() => setShowPassword(true)}></i>}
                                            </div>
                                            <input
                                                required
                                                name="passwordConfirm"
                                                className="form-control"
                                                value={passwordConfirm}
                                                placeholder={t('board.new_password_confirm')}
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={(e) => setPasswordConfirm(e.target.value)}
                                            />  
                                        </>
                                    )}
                                </>
                            )}

                            <div className="form-footer">
                                <button type="submit" className="btn btn-primary" disabled={loading || authUser.authUser}>
                                    {loading ? <span><i className="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> : null}
                                    { displayPasswordField ? t("reset.button") : t('common.continue')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withTranslation()(React.memo(withRouter(ForgetPassword)));