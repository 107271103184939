import React from 'react';
import { useSelector } from 'react-redux';
import { getContentInPageContent } from '../../../utils/custom-index';


function FeatureSection() {

    const langApp = useSelector(state => state.language);

    const pagesContent = useSelector(state => state.pagesContent);
    
    return (
        <div className="feature-boxes-container" style={{
            backgroundColor: '#f4f4f4',
            padding: '1%'
        }}>
            <div className="container mt-6 mb-2">
                <h2 style={{ marginBottom: '4%', marginTop: '3%', color: '#00c805'}}>
                    {/* How it works ? */}
                    {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION10', langApp.lang) : ""}
                </h2>
                <div className="row">
                    <div className="col-md-4" key={"feature1"}>
                        <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center">
                            <i className={'icon-user'}></i>

                            <div className="feature-box-content">
                                <h3 className="m-b-10">{/* Register For an Account */} {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION11', langApp.lang) : ""}</h3>
                                {/* <h5 className="m-b-3">{feature.subtitle}</h5> */}
                                <p>
                                    {/* Sign up with Sooko on the website or the app and create your account, and we’ll send you a 
                                    confirmation once we have verified your business. */}
                                    {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION12', langApp.lang) : ""}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4" key={"feature2"}>
                        <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center">
                            <i className={'icon-users'}></i>

                            <div className="feature-box-content">
                                <h3 className="m-b-10">{/* Put Yourself Out There */} {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION13', langApp.lang) : ""}</h3>
                                {/* <h5 className="m-b-3">{feature.subtitle}</h5> */}
                                <p>
                                    {/* Once we’ve verified your account, you can get started on uploading your products or sourcing 
                                    quotes from suppliers. */}
                                    {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION14', langApp.lang) : ""}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4" key={"feature3"}>
                        <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center">
                            <i className={'icon-category-hot-deals'}></i>

                            <div className="feature-box-content">
                                <h3 className="m-b-10">{/* Start Finalizing Deals */} {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION15', langApp.lang) : ""}</h3>
                                {/* <h5 className="m-b-3">{feature.subtitle}</h5> */}
                                <p>
                                    {/* Sooko makes it easy and fast for you to reach out to buyers and sellers in your market, 
                                    allowing you to start making and closing deals immediately. */}
                                    {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION17', langApp.lang) : ""}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeatureSection;