import backendURLs from "../utils/backendURL";
import { joinBaseUrlWithParams, joinBaseUrlWithSearchParams,joinBaseUrlWithRequestParams, makeActionRequest, makeRequest, replaceIdParamInUrlFormat } from "./utils"
import * as rfqActionsType from '../constants/rfqs-actions';


export const getRfqOfUser = (user_id, userType) => {

    let url = '';

    if (userType==='BUYER') 
        url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.GET_RFQ_ONE_BUYER}`, user_id);

    if (userType==='SELLER') 
        url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.GET_RFQ_ONE_SELLER}`, user_id);

    return makeRequest('get', url, null, {});
}


export const getOneRfqOfUser = (quotationId) => {

    const url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.GET_ONE}`, quotationId);

    return makeRequest('get', url, null, {});
}


export const getRfqChild = (quotationId) => {

    const url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.GET_CHILD}`, quotationId);

    return makeRequest('get', url, null, {});
}


export const createRFQ = (data) => {

    const url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.CREATE}`);

    //const url = joinBaseUrlWithSearchParams(`${backendURLs.SELLER.PRODUCTS.CREATE}`,  [{param: 'image', value: data.image}]);

    return makeRequest('post', url, data, {shouldSkipDataParsing: false, multipart: true});
}

export const createContrat = (data) => {
    const url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.CONTRAT}`);
    return makeRequest('post', url, data, {shouldSkipDataParsing: false, multipart: true});
}


export const getContratText = () => {
    const url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.GET_ALL_TEXT_CONTRAT}`);
    return makeRequest ('get', url, null, {});

}


export const getFreehand = (id) => {
    const url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.GET_ONE_CONTRACT}`, id);
    return makeRequest ('get', url, null, {});

}

export const updateRFQBuyer = (data, quotationId) => {

    const url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.UPDATE_RFQ_BY_BUYER}`, quotationId);

    //const url = joinBaseUrlWithSearchParams(`${backendURLs.SELLER.PRODUCTS.CREATE}`,  [{param: 'image', value: data.image}]);

    return makeRequest('post', url, data, {shouldSkipDataParsing: false, multipart: true});
}


export const updateRFQSeller = (data, quotationId) => {

    const url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.UPDATE_RFQ_BY_SELLER}`, quotationId);

    //const url = joinBaseUrlWithSearchParams(`${backendURLs.SELLER.PRODUCTS.CREATE}`,  [{param: 'image', value: data.image}]);

    return makeRequest('post', url, data, {shouldSkipDataParsing: false, multipart: true});
}


export const getCountUnreadedRFQ = (userId, userType) => {

    let url;

    if (userType==='SELLER')
        url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.GET_UNREADED_NUMBER_SELLER}`, userId);
    
    if (userType==='BUYER')
        url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.GET_UNREADED_NUMBER_BUYER}`, userId);

    return makeRequest('get', url, null, {});
}


export const getCountUnreadedDocs = () => {

    let url = `${backendURLs.DOCS.GET_UNREADED_DOCS_USER}`

    return makeRequest('get', url, null, {});
}

export const getRfqDetails = (id) => {

    let url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.GET_DETAILS}`, id);

    return makeRequest('get', url, null, {});
}



export const markRFQasRead = (rfqId, user_id, userType) => {

    let url;

    if (userType == 'SELLER')
        url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.MARK_AS_READ_SELLER}`, rfqId);
    
    if (userType == 'BUYER')
        url = replaceIdParamInUrlFormat(`${backendURLs.RFQ.MARK_AS_READ_BUYER}`, rfqId);

    url = joinBaseUrlWithRequestParams(url, [{param: 'user_id', value: user_id}]);

    //const url = joinBaseUrlWithSearchParams(`${backendURLs.SELLER.PRODUCTS.CREATE}`,  [{param: 'image', value: data.image}]);

    return makeRequest('put', url, {user_id}, {shouldSkipDataParsing: true});
}


export const sendDocBase64 = (qusId, base64File, type) => {

    let url = joinBaseUrlWithRequestParams(`${backendURLs.RFQ.SIGN_RFQ_DOCS}`, [
        {param: 'qus_id', value: qusId},
        {param: 'base64File', value: base64File},
        {param: 'type', value: type}
    ]);

    return makeRequest('post', backendURLs.RFQ.SIGN_RFQ_DOCS, {base64File, type, qus_id: qusId}, {shouldSkipDataParsing: true});
}