import React from 'react';
import { formatDate } from '@helpers/helpers';
import ReactFlagsSelect from 'react-flags-select';
import classes from './productLisItem.module.css';
import { withTranslation, useTranslation } from "react-i18next";

function ProductListItem(props) {

    const { t } = props;
    const { i18n } = useTranslation();

    const { item, openDetailModal, showCreationForm } = props;

    return (
        <tr>
            <td className="rfq-item-image" style={{ display: 'flex', alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>
                <img className="" src={`${process.env.BACKEND_BASE_URL}${item.mainImagePath}`} />
                <span style={{ paddingLeft: '7%' }}>{props.item.name} </span>
            </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}> {item.price} {item.currency} </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}> {item.countryOfOrigin ? <ReactFlagsSelect disabled selected={item.countryOfOrigin} className={classes.countryFlag} /> : 'Undefined'} </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}> {formatDate(item.createdAt, i18n.language)} </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div className="rfq-item-icons">
                    <span className="rfq-item-icons-read input-tooltip" data-toggle="tooltip" title={t('common.view')} data-placement="right" onClick={() => openDetailModal(item)} style={{ cursor: "pointer" }}><i className="fa fa-eye" aria-hidden="true"></i></span>&nbsp;
                                <span className="rfq-item-icons-edit input-tooltip" data-toggle="tooltip" title={t('common.edit')} data-placement="right" onClick={() => showCreationForm(item)} style={{ color: "#e4c318", cursor: "pointer" }}><i className="fa fa-pencil-alt" aria-hidden="true"></i></span>&nbsp;
                </div>
            </td>
        </tr>
    )
}

export default withTranslation()(React.memo(ProductListItem));