import {
    UPDATE_AVATAR_USER_BASE, UPDATE_AVATAR_USER_SUCCESS, UPDATE_AVATAR_USER_ERROR,
    UPDATE_AVATAR_USER_CLEAR
} from '../../../constants/auth-action-types'

const initialState = {

    data: null,

    loading: false,
    error: null,
    userAvatarUpdated: false
}

const updateUserAvatarReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case UPDATE_AVATAR_USER_BASE:
            return { ...state, loading: true, error: null, userAvatarUpdated: false, data: null};

        case UPDATE_AVATAR_USER_SUCCESS:
            return { ...state, error: null, loading: false, userAvatarUpdated: true, data: action.payload };

        case UPDATE_AVATAR_USER_ERROR:
            return { ...state, data: null, loading: false, userAvatarUpdated: false, error: action.payload };

        
        case UPDATE_AVATAR_USER_CLEAR:
            return initialState

        default:
            return state;
    }
};

/* const persistConfig = {
    keyPrefix: "porto-",
    key: "products",
    storage
}
 */
export default updateUserAvatarReducer;