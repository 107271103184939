import Modal from 'react-modal';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from 'react-redux';
import { createTestimonial } from '../../../action/testimonials-actions';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'relative',
        maxWidth: '870px',
        marginRight: '2rem',
        padding: '.5rem',
        backgroundColor: '#fff'
    }
};

Modal.setAppElement('#root');

function CreateTestimonyModal(props) {

    const { authUser } = useSelector(state => state);

    const { register, handleSubmit, errors, reset } = useForm({});

    const [ loading, setLoading ] = useState(false);
    

    const dispatch = useDispatch();

    let errorUpdate = null;

    const { addClass = "header-icon" } = props;
    const [open, setOpen] = useState(false);

    const openModal = (e) => {
        e.preventDefault();
        setOpen(true);
    }

    const closeModal = () => {

        setOpen(false);
    }

    const { t } = props;

    const onSubmitForm = (data) => {

        if (!authUser.authUser) {
            
            toast("You have to login to your account before making a testimony.", { className: "toast-warning" });
            return;
        }

        if (!authUser.authUser.approved) {
            
            toast("Your account have to be approved in oder to make a testimony.", { className: "toast-warning" });
            return;
        }

        setLoading(true);

        createTestimonial({...data}).then((result) => {
            
            toast("Your testimony have been send!", { className: "toast-success" });
            closeModal();
        }).catch((err) => {
            
            toast("Error occured: unable to create testimony", { className: "toast-error" });
        }).finally(() => { setLoading(false); });
    }


    return (
        <>
            <div className="text-center mt-4">
              <button type="button" onClick={openModal} style={{ borderRadius: '5px' }} className="btn btn-primary">{ t('common.create_testimonial') }</button>
            </div>

            <Modal
                shouldCloseOnOverlayClick={false}
                isOpen={open}
                onRequestClose={closeModal}
                contentLabel="login Modal"
                className="login-popup"
                id="login-popup-form"
                overlayClassName="cart-modal-overlay"
                style={customStyles}
            >
                <div className="modal-wrapper" style={{ height: "100%" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="title mb-2">{ t('common.create_testimonial') }</h2>

                                {errorUpdate}

                                <form className="mb-1">

                                    {/* <div>
                                        <label htmlFor="title">Title <span className="required">*</span></label>
                                        <input type="text" name="title" className="form-input form-wide mb-2" id="title" ref={register({ required: true })} />
                                        {errors.title && <div className="text-danger mb-1">Title is required</div>}
                                    </div> */}

                                    <div>
                                        <label htmlFor="content">{ t('common.testimonials') } <span className="required">*</span></label>
                                        <textarea style={{ padding: "20px" }} name="content" type="text" className="form-input form-wide mb-2" id="content" ref={register({ required: true })}
                                        >

                                        </textarea>
                                        {errors.content && <div className="text-danger mb-1">Content is required</div>}
                                    </div>

                                    <div className="form-footer">
                                        <button type="submit" className="btn btn-primary btn-md" disabled={loading} onClick={handleSubmit(onSubmitForm)}>
                                        {loading ? <span><i className="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> : null}
                                            {t('documents.save')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={closeModal}>×</button>
                </div>
            </Modal>
        </>
    )

}

const mapStateToProps = ({ authUser }) => ({
    authUser: authUser
  });
  
  export default withTranslation()(
    connect(mapStateToProps, {})(CreateTestimonyModal)
  );