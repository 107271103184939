import { Helmet } from 'react-helmet';
import DocListItem from './DocListItem';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { paginate } from "@helpers/helpers";
import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { createContract } from '@action/contract';
import ModalInfo from "@components/customs/modalInfo";
import SuccessModal from '@components/pages/succesModal';
import { getDocumentsOfUser } from '@action/docs-actions';

const DocsList = (props) => {

    const authUser = useSelector((state) => state.authUser);

    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [errorData, setErrorData] = useState(null);

    const [searchText, setSearchText] = useState('');
    const [id, setId] = useState(0);
    const [errorContract, setErrorContract] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const filterFromSearchText = (item) => {
        try {
            return item.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
        } catch (err) {
            return false;
        }
    }

    let loader = null;
    let errorDisplay = null;

    const initialiseState = () => {
        setLoadingData(true);
        setErrorData(null);
        setData([]);
    };

    const { t } = props;

    if (loadingData) {
        loader = (
            <div className="loader text-center">
                <div className="bounce-loader">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
        );
    }

    if (errorData) {
        errorDisplay = (
            <>
                <br />
                <div className="alert alert-danger">Error: {errorData.message}</div>
                <br />
            </>
        );
    }

    const getDocsForUser = () => {
        initialiseState();
        getDocumentsOfUser()
            .then(data => {
                setData(data.sort((a, b) => a.id > b.id ? -1 : 1));
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoadingData(false);
            });
    };

    const createContractFromDoc = (docId) => {
        createContract(docId).then((result) => {
            setId(result.id)
            // setSuccessModal(true);
            props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/contracts/${result.id}/contract-effective`);
            getDocsForUser();
            // toast(t('documents.created'), { className: "toast-success" });
        }).catch((err) => {
            console.log(err)
            setErrorContract(true)
            // toast(t('documents.error'), { className: "toast-error" });
        });
    }


    useEffect(() => {
        if (authUser.authUser) {
            getDocsForUser();
        }
    }, [authUser]);

    return (
        <>
            <Helmet>
                <title>Sooko - Checkout Page </title>
            </Helmet>

            <div className="main">

                <div className="container">

                    <h2>{t('documents.title')}</h2>

                    <p style={{ marginTop: 10, marginBottom: 50 }}>
                        <strong style={{ marginRight: 10 }}>
                            Note:
                        </strong>
                        {t('notes.check_mail')}
                    </p>

                    {data.length > 0 ? <div className="row">
                        <div className={"col-md-5 header-search " + "header-search-inline"} style={{ width: "100% !important" }}>
                            <div className="header-search-wrapper">
                                <input type="search" className="form-control" name="q" placeholder={t('common.search')} style={{ width: "100%" }} required
                                    value={searchText} onChange={(ev) => setSearchText(ev.target.value)} />
                            </div>
                        </div>
                    </div> : null}

                    {loader}
                    {errorDisplay}


                    {data.length > 0 ? <div className="row" style={{ marginTop: 30, marginBottom: 20 }}>
                        <table className="col-lg-12 col-sm-12">
                            <thead>
                                <th>{t('documents.name')}</th>
                                <th> {t('documents.status')} </th>
                                <th> {t('documents.price')} </th>
                                <th> {t('documents.date')} </th>
                                <th>{t('documents.action')}</th>
                            </thead>
                            <br />
                            <tbody>
                                {paginate(data.filter(filterFromSearchText), page, pageSize).map((item, index) => <DocListItem key={index} item={item} createContractFromDoc={createContractFromDoc} getCountDocsUnread={props.getCountDocsUnread} />)}
                            </tbody>
                        </table>
                    </div> : null}
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        count={Math.ceil(data.filter(filterFromSearchText).length / pageSize)}
                        showFirstButton
                        showLastButton
                        onChange={(__, selectedPage) => setPage(selectedPage)}
                    />

                    {!loadingData && data.length <= 0 ?
                        (
                            <div className="mb-5 text-center">
                                <img src="assets/images/nothing-here.png" width="500" style={{ margin: "0 auto" }} />
                                <h2 className="text-muted font-weight-light"> {t('documents.no_documents')} </h2>
                            </div>
                        ) : null
                    }

                </div>

                <div className="mb-6"></div>

                <ModalInfo show={errorContract} closeModal={() => setErrorContract(false)}>
                    <div className="text-center py-5">
                        <h2 className="text-danger">
                            {t('notes.contract_error1')}
                            <br />
                        </h2>
                        <h3 className="font-weight-light mt-2 mb-4"> {t('notes.contract_error2')} </h3>
                        <img
                            src={"assets/images/confirmation-illustration.png"}
                            style={{ width: "300px", height: "200px", margin: "0 auto" }}
                            className="img-responsive"
                            alt="Product image"
                        />
                    </div>
                </ModalInfo>

                <SuccessModal
                    shouldShowModal={successModal && id}
                    close={() => setSuccessModal(false)}
                    title={t('notes.document_generated')}
                    subtitle={t('notes.see_contract')}
                    button={t('notes.go_to_contract')}
                    to={id != 0 ? `${process.env.PUBLIC_URL}/account/dashboard/contracts/${id}/contract-effective` : `${process.env.PUBLIC_URL}/account/dashboard/contracts`}
                />
            </div>
        </>
    )
}

export default withTranslation()(React.memo(withRouter(DocsList)));
