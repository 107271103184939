import './style.css'
import { Helmet } from 'react-helmet';
import { setParallax } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from '../../../action/contract';
import { getContentInPageContent } from '../../../utils/custom-index';
import { getPageContentForPage } from '../../../action/pagesContents-actions';

function About(props) {

    const [data, setData] = useState([]);

    const pagesContent = useSelector(state => state.pagesContent);

    const langApp = useSelector(state => state.language);

    const dispatch = useDispatch();

    const { i18n } = useTranslation();

    const getLanguage = () => i18n.language || window.localStorage.i18nextLng

    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        getSettings().then((result) => {
            setData(result);
        }).catch((err) => {
            // toast(`Error to get fees app: ${err.message}`, { className: "toast-error" });
        });
    }, []);


    useEffect(() => {


        if (document.querySelector(".parallax")) {
            document.addEventListener("scroll", setParallax);
        }

        document.querySelector('.menu') && document.querySelector('.menu').firstChild.classList.add('active');
        document.querySelector('.mobile-menu') && document.querySelector('.mobile-menu').firstChild.classList.add('active');
    })


    useEffect(() => {
        dispatch(getPageContentForPage('ABOUT', langApp.lang));
    }, [])

    const { t } = props;

    return (
        <>
            <Helmet>
                <title>{t('title.about')}</title>
            </Helmet>

            <h1 className="d-none">Sooko</h1>

            <div className="main">
                <div className="homeMain row ">
                    <div className="illustrationBlock col-md-5">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/demo/undraw_factory_dy0a.svg`} className='illustration' style={{ maxWidth: '95%' }} alt={t('title.about_alt')} />
                    </div>
                    <div className="presentationBlock col-md-6">
                        <h2>
                            {pagesContent.data ? getContentInPageContent(pagesContent.data, 'ABOUT', 'SECTION0', langApp.lang) : ""}
                        </h2>
                        <p>
                            {pagesContent.data ? getContentInPageContent(pagesContent.data, 'ABOUT', 'SECTION1', langApp.lang) : ""}
                        </p>
                    </div>
                </div>

                <div className=" col-md-8 col-sm-12 pt-5 pt-md-0 offset-md-2">
                    <h2 style={{ marginBottom: '4%', marginTop: '4%' }}>
                        {t('home.about')}
                    </h2>
                </div>

                <div className="serviceSectionBlock">
                    <div className="serviceBlock">
                        <div className="serviceBlock">
                            <h2>
                                <span style={{ color: '#00c805' }}>{pagesContent.data ? getContentInPageContent(pagesContent.data, 'ABOUT', 'SECTION2', langApp.lang) : ""}</span>
                            </h2>
                            <p style={{ textAlign: 'justify' }}>
                                {pagesContent.data ? getContentInPageContent(pagesContent.data, 'ABOUT', 'SECTION3', langApp.lang) : ""}
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                                {pagesContent.data ? getContentInPageContent(pagesContent.data, 'ABOUT', 'SECTION4', langApp.lang) : ""}
                            </p>
                        </div>
                    </div>
                    <div className="serviceBlock">
                        <div className="serviceBlock">
                            <h2>
                                <span style={{ color: '#00c805' }}>{pagesContent.data ? getContentInPageContent(pagesContent.data, 'ABOUT', 'SECTION5', langApp.lang) : ""}</span>
                            </h2>
                            <p style={{ textAlign: 'justify' }}>
                                {pagesContent.data ? getContentInPageContent(pagesContent.data, 'ABOUT', 'SECTION6', langApp.lang) : ""}
                            </p>
                        </div>
                    </div>
                    <div className="serviceBlock">
                        <div className="serviceBlock">
                            <h2>
                                <span style={{ color: '#00c805' }}> {pagesContent.data ? getContentInPageContent(pagesContent.data, 'ABOUT', 'SECTION7', langApp.lang) : ""}</span>
                            </h2>
                            <p style={{ textAlign: 'justify' }}>
                                {pagesContent.data ? getContentInPageContent(pagesContent.data, 'ABOUT', 'SECTION8', langApp.lang) : ""}
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )

}

export default withTranslation()(About);