import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import classes from "../productCreate.module.scss";
import React, { useEffect, useState } from "react";
import currencies from '@mockData/currencies_approved.json';

function ProductCreateStep2(props) {

  const { setDataStep2, unitsConfigQuantity, onPreviousStep, defaultDataStep2 } = props;
  const { authUser } = useSelector(state => state.authUser);
  const [langSelected, setLangSelected] = useState("en");

  useEffect(() => {
    if (authUser != null) {
      setLangSelected(authUser.language.toLowerCase());
    }
  }, [authUser])

  const [currentPrice, setCurrentPrice] = useState(null);

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: { 
      ...defaultDataStep2 }
  });

  const onSubmitForm = (data) => {
    setDataStep2(data);
  };

  const { t } = props;

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    document.getElementById("validityPriceDate").setAttribute('min', today);
  }, []);


  const gettingPrice = (event) => {
    setCurrentPrice(event.target.value);
  }

  return (
    <div className="main">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 order-lg-first">
            <div className="checkout-payment">

              <div id="new-checkout-address" className="show">
                <form name="productForm mt-2" className={classes.productForm}>
                  <div className="showStepOfStepper">
                    <div className="row">
                      <div className="required-field col-md-4">
                        <label> {t("products.product_price")} </label>
                        <input
                          type="text"
                          className="form-control"
                          name="price"
                          onChange={gettingPrice}
                          ref={register({ required: true })}
                          required
                        />
                        {errors.price && <div className="text-danger mt-1">{t("form.priceRequired")}</div>}
                      </div>

                      <div className="required-field col-md-4">
                        <label> {t("products.product_currency")} </label>
                        <select
                          className="form-control"
                          name="currency"
                          ref={register({ required: true })}
                          required
                        >
                          <option value=""> </option>
                          {Object.keys(currencies).map(key => (
                            <option key={currencies[key].code} value={currencies[key].code}>{currencies[key].name}</option>
                          ))}
                        </select>
                        {errors.currency && <div className="text-danger mt-1">{t("form.currencyRequired")}</div>}
                      </div>

                      <div className="required-field col-md-4">
                        <label> {t("products.product_perunit")} </label>
                        <select
                          className="form-control"
                          name="pricePerUnitId"
                          ref={register({ required: true })}
                          required
                          onChange={(e) => console.log(e.target.value)}
                        >
                          <option value=""> </option>
                          {unitsConfigQuantity.map((item, __) => (
                            <option key={item.id} value={item.id}>
                              {langSelected == 'fr' ? item.fr : item.en}
                            </option>
                          ))}
                        </select>
                        {errors.pricePerUnitId && <div className="text-danger mt-1">{t("form.unitRequired")}</div>}
                      </div>
                    </div>

                    <div className="row">
                      <div className="required-field col-md-6">
                        <label> {t("products.product_price_v")} </label>
                        <input
                          id="validityPriceDate"
                          type="date"
                          className="form-control"
                          name="validityOfPrice"
                          ref={register({ required: true })}
                          required
                        />

                        {errors.validityOfPrice && <div className="text-danger mt-1">{t("form.validityRequired")}</div>}
                      </div>

                      <div className="required-field  col-md-6">
                        <label> {t("products.product_payment")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paymentOption"
                          ref={register({ required: true })}
                          required
                        />
                        {errors.paymentOption && <div className="text-danger mt-1">{t("form.optionRequired")}</div>}
                      </div>
                    </div>

                    <div className="row">
                      <div className="required-field col-md-6">
                        <label> {t("products.min_price")} </label>
                        <input
                          type="number"
                          className="form-control"
                          name="minPrice"
                          max={currentPrice}
                          ref={register({ required: true, max: currentPrice })}
                          required
                        />
                        {errors.minPrice && errors.minPrice.type === "required" && <div className="text-danger mt-1">{t("form.minPriceRequired")}</div>}
                        {errors.minPrice && errors.minPrice.type === "max" && <div className="text-danger mt-1">{t("common.min_price")}</div>}
                      </div>

                      <div className="required-field  col-md-6">
                        <label> {t("products.max_price")} </label>
                        <input
                          type="number"
                          className="form-control"
                          name="maxPrice"

                          ref={register({ required: true, min: currentPrice })}
                          required
                        />
                        {errors.maxPrice && errors.maxPrice.type === "required" && <div className="text-danger mt-1">{t("form.maxPriceRequired")}</div>}
                        {errors.maxPrice && errors.maxPrice.type === "min" && <div className="text-danger mt-1">{t("common.min_price")}</div>}
                      </div>
                    </div>

                    <div className="row">
                      <div className="required-field col-md-6">
                        <label> {t("products.mon_order_qty")} </label>
                        <input
                          type="number"
                          className="form-control"
                          name="minOrderQuantity"
                          ref={register({ required: true })}
                          required
                        />
                        {errors.minOrderQuantity && <div className="text-danger mt-1">{t("form.quantityRequired")}</div>}
                      </div>

                      <div className="required-field  col-md-6">
                        <label> {t("products.product_perunit")} </label>
                        <select
                          className="form-control"
                          name="minOrderQuantityUnitId"
                          ref={register({ required: true })}
                          required
                        >
                          <option value=""> </option>
                          {unitsConfigQuantity.map((item, __) => (
                            <option key={item.id} value={item.id}>
                              {item[langSelected == 'fr' ? 'fr' : 'en']}
                            </option>
                          ))}
                        </select>
                        {errors.minOrderQuantityUnitId && <div className="text-danger mt-1">{t("form.unitRequired")}</div>}
                      </div>
                    </div>
                  </div>
                </form>

                <div className="col-md-12">
                  <button
                    onClick={onPreviousStep}
                    className="btn btn-primary float-left px-5"
                  >
                    {t("products.previous")}
                  </button>

                  <button
                    onClick={handleSubmit(onSubmitForm)}
                    className="btn btn-primary float-right px-5"
                  >
                    {t("products.next")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6"></div>
    </div>
  );
}

export default withTranslation()(React.memo(ProductCreateStep2));