import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux'
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import { getNotifs, setNotif } from '@action/auth';

const NotifsList = (props) => {

    const authUser = useSelector((state) => state.authUser);
    const isBuyer = authUser.authUser ? authUser.authUser.userType === 'BUYER' : false;
    const isSeller = authUser.authUser ? authUser.authUser.userType === 'SELLER' : false;

    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [size, setSize] = useState(10);
    const [loadingData, setLoadingData] = useState(false);
    const [errorData, setErrorData] = useState(null);


    let loader = null;
    let errorDisplay = null;

    const initialiseState = () => {
        setLoadingData(true);
        setErrorData(null);
        setPage(1);
        setSize(10);
        setNotifications([]);
    };

    const { t } = props;

    // const filterFromSearchText = (item) => {
    //     // console.log(item)
    //     return item.baseQuotation.product.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
    // }

    if (loadingData) {
        loader = (
            <div className="loader text-center">
                <div className="bounce-loader">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
        );
    }

    const goToDetails = (notif) => {
        setNotif(notif.id)
            .then(data => {
                props.getCountNumberOfUnreadNotif(authUser.authUser.id)
                props.history.push(`${process.env.PUBLIC_URL}/${getUrlFromType(notif)}`);
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }

    if (errorData) {
        errorDisplay = (
            <>
                <br />
                <div className="alert alert-danger">Error: {errorData.message}</div>
                <br />
            </>
        );
    }

    const unixTime = (date) => {

        var u = new Date(date);

        return u.getFullYear() +
            '-' + ('0' + (u.getMonth() + 1)).slice(-2) +
            '-' + ('0' + u.getDate()).slice(-2) +
            ' ' + ('0' + u.getHours()).slice(-2) +
            ':' + ('0' + u.getMinutes()).slice(-2) +
            ':' + ('0' + u.getSeconds()).slice(-2)
    };


    const getTextFromType = (notif) => {
        switch (notif.type) {
            case "ACCOUNT_CREATED":
                return t("notifications.create_account");
            case "ACCOUNT_APPROVED":
                return t("notifications.approve_account");
            case "PRODUCT_CREATED":
                return t("notifications.product_created");
            case "PAYMENT":
                if (isBuyer)
                    return t("notifications.payment_other").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx').replace('{amount}', notif.amount);
                else
                    return t("notifications.payment").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx').replace('{amount}', notif.amount);
            case "RFQ_DELIVER":
                return t("notifications.rfq_deliver");
            case "RFQ_CREATED":
                return t("notifications.create_rfq").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx');
            case "RFQ_ACCEPTED":
                return t("notifications.accept_rfq").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx').replace("{name}", notif.seller ? notif.seller.name : 'xxxx');
            case "FIRST_MESSAGE":
                return t("notifications.message").replace("{rfq}", notif.quotation ? notif.quotation.product.name : 'xxxx').replaceAll("{type}", isSeller ? t('common.buyer') : t('common.seller'));
            // case "ACCEPT_SELLER":
            //     return t("notifications.confirm_chat").replace("{rfq}", notif.quotation ? notif.quotation.product.name : 'xxxx');
            case "CONFIRM_CHAT":
                if (isBuyer)
                    return t("notifications.confirm_chat_buyer").replace("{rfq}", notif.quotation ? notif.quotation.product.name : 'xxxx').replaceAll("{type}", isSeller ? t('common.the_buyer') : t('common.the_seller'));
                if (isSeller)
                    return t("notifications.confirm_chat_seller").replace("{rfq}", notif.quotation ? notif.quotation.product.name : 'xxxx').replaceAll("{type}", isSeller ? t('common.the_buyer') : t('common.the_seller'));
            case "CONFIRM_CHAT_WAIT":
                return t("notifications.confirm_chat_wait").replace("{rfq}", notif.quotation ? notif.quotation.product.name : 'xxxx').replaceAll("{type}", isSeller ? t('common.the_buyer') : t('common.the_seller'));
            case "DOCUMENT_SEND":
                if (isBuyer)
                    return t("notifications.generated_document1").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx');
                if (isSeller)
                    return t("notifications.generated_document2").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx');
            case "SELLER_PAYMENT":
                if (isSeller)
                    return t("notifications.seller_payment").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx').replace('{amount}', notif.amount);
                else
                    return t("notifications.payment_sooko").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx').replace('{amount}', notif.amount);
            case "PAYMENT_WAIT":
                return t("notifications.payment_wait").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx').replace('{amount}', notif.amount);
            case "SIGN_DOCUMENT":
                return t("notifications.document_signed").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx');
            case "MAKE_PAYMENT":
                return t("notifications.make_payment").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx');
            case "CONTRACT_CREATED":
                return t("notifications.create_contract").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx');
            case "CONTRACT_APPROVED":
                return t("notifications.approve_contract").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx').replaceAll("{type}", isSeller ? t('common.buyer') : t('common.seller'));
            case "CONTRACT_SEND":
                return t("notifications.send_contract").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx');
            case "CONTRACT_SIGNED":
                return t("notifications.sign_contract").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx').replaceAll("{type}", isSeller ? t('common.buyer') : t('common.seller'));
            case "DOCUMENT_UPLOADED":
                return t("notifications.upload_document").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx');
            case "SHIP_RECEIVED":
                return t("notifications.send_proof").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx');
            case "RFQ_EDIT":
                return t("notifications.rfq_edit").replace("{rfq}", notif.quotationUserStatus ? notif.quotationUserStatus.baseQuotation.product.name : 'xxxx').replaceAll("{type}", isSeller ? t('common.the_buyer') : t('common.the_seller'));

            default:
                break;
        }
    }

    const getUrlFromType = (notif) => {
        switch (notif.type) {
            case "ACCOUNT_CREATED":
                return `account/dashboard/board`;
            case "ACCOUNT_APPROVED":
                if (isBuyer)
                    return `account/dashboard/rfqs`;
                if (isSeller)
                    return `account/dashboard/seller-products`;
            case "RFQ_CREATED":
                return `account/dashboard/rfqs/${notif.quotationUserStatus.id}/details`;
            case "PAYMENT":
                return `account/dashboard/transactions`;
            case "RFQ_DELIVER":
                return `account/dashboard/rfqs/${notif.quotationUserStatus.id}/details`;
            case "PRODUCT_CREATED":
                return `account/dashboard/seller-products`;
            case "RFQ_ACCEPTED":
                return `account/dashboard/rfqs/${notif.quotationUserStatus.id}/details`;
            case "RFQ_EDIT":
                return `account/dashboard/rfqs/${notif.quotationUserStatus.id}/details`;
            case "FIRST_MESSAGE":
                return `account/dashboard/chats`
            case "SELLER_PAYMENT":
                return `account/dashboard/transactions`
            case "ACCEPT_SELLER":
                return `account/dashboard/chats`
            case "CONFIRM_CHAT":
                return `account/dashboard/chats?id=${notif.referalId}`
            case "CONFIRM_CHAT_WAIT":
                return `account/dashboard/chats?id=${notif.referalId}`
            case "DOCUMENT_SEND":
                return `account/dashboard/documents`
            case "SIGN_DOCUMENT":
                return `account/dashboard/documents`
            case "CONTRACT_CREATED":
                return `account/dashboard/contracts/${notif.quotationUserStatus.contract.id}/contract-effective`
            case "CONTRACT_APPROVED":
                return `account/dashboard/contracts/${notif.quotationUserStatus.contract.id}/contract-effective`
            case "CONTRACT_SEND":
                return `account/dashboard/contracts`
            case "PAYMENT_WAIT":
                return `account/dashboard/transactions`
            case "MAKE_PAYMENT":
                return `account/dashboard/contracts`
            case "CONTRACT_SIGNED":
                return `account/dashboard/contracts`
            case "DOCUMENT_UPLOADED":
                return `account/dashboard/contracts`
            case "SHIP_RECEIVED":
                return `account/dashboard/contracts`

            default:
                break;
        }
    }

    const getNotifications = (id, toPage) => {
        initialiseState();
        getNotifs(id, toPage, 10)
            .then(notifs => {
                setNotifications(notifs.data);
                setTotalPage(notifs.totalPages)
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoadingData(false);
            });
    };

    useEffect(() => {
        if (authUser.authUser) {
            props.getCountNumberOfUnreadNotif(authUser.authUser.id)
            getNotifications(authUser.authUser.id, page);
        }
    }, [authUser]);

    return (
        <>
            <Helmet>
                <title>Sooko - RFQ </title>
            </Helmet>

            <div className="main">

                <div className="container">

                    <div className="row">
                        <div className="col-md-10"><h2>Notifications</h2></div>
                    </div>

                    {/* {notifications.length > 0 ? <div className="row">
                        <div className={"col-md-5 header-search " + "header-search-inline"} style={{ width: "100% !important" }}>
                            <div className="header-search-wrapper">
                                <input type="search" className="form-control" name="q" placeholder={t("common.search")} style={{ width: "100%" }} required
                                    value={searchText} onChange={(ev) => setSearchText(ev.target.value)} />
                            </div>
                        </div>
                    </div> : null} */}

                    {loader}
                    {errorDisplay}


                    {notifications.length > 0 ?
                        <div className="row">
                            <table className="col-lg-12 col-sm-12">
                                <thead>
                                    <th>Status</th>
                                    <th>Content</th>
                                    <th>Date</th>
                                    <th>Actions</th>
                                </thead>
                                <br />
                                <tbody>
                                    {notifications.map((item, __) =>
                                        <tr>
                                            <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                                                {
                                                    item.status == 'UNREAD' ?
                                                        <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: 'green', fontWeight: "bold", padding: 5 }}> {t("common.new")} </div>
                                                        :
                                                        <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: '#ff0000a1', fontWeight: "bold", padding: 5 }}> {t("common.read")} </div>
                                                }
                                            </td>
                                            <td style={{ paddingTop: 10, paddingBottom: 10 }}>{getTextFromType(item)}</td>
                                            <td style={{ paddingTop: 10, paddingBottom: 10 }}>{unixTime(item.createdAt)}</td>
                                            <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                                                <button
                                                    onClick={() => goToDetails(item)}
                                                    className="btn btn-primary py-2"
                                                >
                                                    Details
                                                </button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div> : null}
                    <br />
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        count={totalPage}
                        showFirstButton
                        showLastButton
                        onChange={(__, selectedPage) => getNotifications(authUser.authUser.id, selectedPage)}
                    />
                </div>

                <div className="mb-6"></div>
            </div>
        </>
    )
}
export default withTranslation()(React.memo(withRouter(NotifsList)));
