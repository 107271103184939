import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import ProductsList from './ProductsList';
import ProductCreate from './ProductCreate';
import { withTranslation } from "react-i18next";
import ModalInfo from '@components/customs/modalInfo';
import { createProductsOfSeller, getProductsOfSeller } from '@action/sellerProducts-actions';

class index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addProcess: false,
            showModalForSellNonApproved: false,

            productToUpdate: null,
        };
    }

    setProductToUpdate = (product) => {
        this.setState({ productToUpdate: product });
    }

    componentDidMount() {
    }

    showCreationForm = (productToUpdate = null) => {

        if (!this.props.authUser.authUser.approved) {
            this.setState({ showModalForSellNonApproved: true });
        } else {
            this.setState({ addProcess: true, productToUpdate: productToUpdate });
        }
    }

    cancelCreationForm = () => {
        this.setState({ addProcess: false });
    }


    render() {
        const { t } = this.props;
        return (
            <>
                <div className="row">
                    <div className="col-md-12 col-xs-12 d-flex direction-row justify-content-between">
                        <div>
                            <h2> {t("products.my_products")}</h2>
                            </div>
                        <div style={{ right: "30px" }}>
                            {!this.state.addProcess ? <button onClick={() => this.showCreationForm(null)} className="btn btn-primary" style={{ padding: "10px 10px" }}> {t("products.new_product")} </button> : null}&nbsp;&nbsp;
                        </div>
                    </div>

                    <div className="col-md-12 col-xs-12">
                        {this.state.addProcess === false ? <ProductsList showCreationForm={this.showCreationForm} /> : <ProductCreate cancelCreationForm={this.cancelCreationForm} productToUpdate={this.state.productToUpdate} />}
                    </div>

                </div>

                <ModalInfo
                    show={this.state.showModalForSellNonApproved}
                    closeModal={() => this.setState({ showModalForSellNonApproved: false })}
                >
                    <div className="container">
                        <div className="d-flex flex-column justify-content-center align-items-center">

                            <h3 className="text-center"> <span className="text-success" style={{ fontSize: "18px" }}>
                                {t("single_detail.hey")} {this.props.authUser.authUser ? this.props.authUser.authUser.name : null}!
                                <br />{t("single_detail.seller_approved")}</span></h3>


                            {/* <div className="text-center"><img src={loginImg} style={{ margin: "0 auto" }} width={200} /></div> */}

                            <br /><br />

                            <p>
                                <span style={{ fontSize: "16px" }}>
                                    {t("single_detail.notifie_approved")}
                                    <Link to="#" className="text-success"> <strong>{t("single_detail.thank_you")}.</strong></Link>
                                </span>
                            </p>
                        </div>
                    </div>
                </ModalInfo>
            </>
        )
    }
}

const mapStateToProps = ({ sellerProducts, authUser }) => ({
    sellerProducts, authUser
});

export default withTranslation()(connect(mapStateToProps, { getProductsOfSeller, createProductsOfSeller })(index));
