import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo } from '@action/auth';
import { UPDATE_INFORMATIONS_USER_CLEAR } from '@constants/auth-action-types';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'relative',
        maxWidth: '870px',
        marginRight: '2rem',
        padding: '.5rem',
        backgroundColor: '#fff'
    }
};

Modal.setAppElement('#root');

function UserInformationUserModal(props) {

    const { userUpdated } = useSelector(state => state.updateUserInformation);

    const { userAuthenticated } = props;

    const [phone, setPhone] = useState('');
    const [countrySelected, setCountrySelected] = useState('');

    const { register, handleSubmit, errors, reset } = useForm({
        defaultValues: {
            name: userAuthenticated.name,
            email: userAuthenticated.email,
            phone: userAuthenticated.phone,
            country: userAuthenticated.country,
            language: userAuthenticated.language,
            aboutYou: userAuthenticated.aboutYou
        }
    });
    const { loading, error } = useSelector(state => state.updateUserInformation);


    const dispatch = useDispatch();

    let errorUpdate = null;

    const { addClass = "header-icon" } = props;
    const [open, setOpen] = useState(false);

    const openModal = (e) => {
        e.preventDefault();
        setOpen(true);
    }

    const closeModal = (fromChild = false) => {
        dispatch({ type: UPDATE_INFORMATIONS_USER_CLEAR })

        if (fromChild) {
            setTimeout(() => {
                setOpen(false);
            }, 2000);
        }

        setOpen(false);

    }

    if (error) {
        errorUpdate = (
            <div className="alert alert-danger">
                ERROR: {error.message}
            </div>
        )
    }

    const onSubmitForm = (data) => {

        data['country'] = countrySelected;

        dispatch(updateUserInfo(data, userAuthenticated.id, result => {

            if (result.data && result.data.id) {
                toast("User information have been updated!", { className: "toast-success" });
                props.setUser(result.data);
                setOpen(false);
            } else {
                toast("Error occured: unable to update user informations", { className: "toast-error" });
            }
        }));
    }


    useEffect(() => {
        if (userAuthenticated) {
            reset({ ...userAuthenticated });
            setPhone(userAuthenticated.phone);
        }
    }, [userAuthenticated]);

    const { t } = props;

    return (
        <>
            <Link to="#" className={`login-link ${addClass}`} onClick={openModal} title="Login"><i className="fa fa-pencil-alt" aria-hidden="true" style={{ fontSize: "medium", paddingRight: "5px" }}></i>{t("board.edit")}</Link>
            <Modal
                shouldCloseOnOverlayClick={false}
                isOpen={open}
                onRequestClose={closeModal}
                contentLabel="login Modal"
                className="login-popup"
                id="login-popup-form"
                overlayClassName="cart-modal-overlay"
                style={customStyles}
            >
                <div className="modal-wrapper" style={{ height: "100%" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="title mb-2">{t("board.update_title")}</h2>

                                {errorUpdate}
                                {/*                                 <InfoUpdatedSuccessModal closeEditModal={closeModal} haveBeenSuccessfullyUpdated={userUpdated} clearUpdateStateAfterUpdate={() => dispatch({type: UPDATE_INFORMATIONS_USER_CLEAR})} />
 */}
                                {!userUpdated ? <form className="mb-1">
                                    <label htmlFor="name">{t("board.account_name")} <span className="required">*</span></label>
                                    <input type="text" name="name" className="form-input form-wide mb-2" id="name" ref={register} required />

                                    <label htmlFor="email">{t("board.account_email")} <span className="required">*</span></label>
                                    <input type="email" name="email" className="form-input form-wide mb-2" id="email" ref={register} disabled required />

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group required-field">
                                                <label htmlFor="phone">{t("board.account_phone_number")} <span className="required"></span></label>
                                                <input type="phone" name="phone" className="form-input form-wide mb-2" id="phone" ref={register} required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="language"> {t("board.language")}<span className="required">*</span></label>
                                            <select name="language" className="form-control" id="langue" ref={register}>
                                                <option value='Fr'>{t("login.french")}</option>
                                                <option value="En">{t("login.english")}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <label htmlFor="aboutyou">{t("board.account_about_you")} <span className="required">*</span></label>
                                    <textarea style={{ padding: "20px" }} name="aboutYou" type="text" className="form-input form-wide mb-2" id="aboutyou" required ref={register}>

                                    </textarea>

                                    <div className="form-footer">
                                        <button type="submit" className="btn btn-primary btn-md" disabled={loading} onClick={handleSubmit(onSubmitForm)}>
                                            {loading ? <span><i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> : null}
                                            {t("board.edit")}
                                        </button>
                                    </div>
                                </form> : null}
                            </div>
                        </div>
                    </div>

                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={closeModal}>×</button>
                </div>
            </Modal>
        </>
    )

}

export default withTranslation()(React.memo(UserInformationUserModal));