import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function PageError() {
    return (
        <>
            <Helmet>
                <title>Sooko - 404 Page </title>
            </Helmet>

            <h1 className="d-none">Sooko - 404 Page</h1>

            <div className="main">
                <div className="container">
                    <section className="http-error">
                        <div className="row justify-content-center py-3">
                            <div className="col-md-7 text-center">
                                <div className="http-error-main">
                                    {/* <h2>404<i className="fas fa-file ml-3"></i></h2> */}
                                    <img src={ `${ process.env.PUBLIC_URL }/assets/images/demo/404.png`} width='500' style={{ margin: 'auto' }}/>
                                    <p>We're sorry, but the page you were looking for doesn't exist.</p>
                                    <a href={ `${ process.env.PUBLIC_URL }/marketplace` } className="btn btn-primary">Back to the market place</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default PageError;