import { withRouter } from "react-router-dom";
import { formatDate } from '@helpers/helpers';
import React, { useEffect, useState } from 'react';
import { withTranslation, useTranslation } from "react-i18next";
import { getDocumentFromSinRequest } from '@action/docs-actions';


function DocListItem(props) {

    const { item } = props;

    const [ setDocStatusSignRequest] = useState(null)

    const { t } = props;
    const { i18n } = useTranslation();

    useEffect(() => {

        getDocumentFromSinRequest(item.uuid).then((resultSignRquest) => {
            setDocStatusSignRequest(resultSignRquest.status);
        });
    }, []);

    const goToReceipt = (link) => {
        window.open(item.type == 'STRIPE' ? link : `${process.env.BACKEND_BASE_URL}${link}`, '_blank');
    };

    return (

        <tr>

            <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                {item.type}
            </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: 'rgb(0 139 243 / 66%)', fontWeight: "bold", padding: 5 }}> {item.status} </div>
            </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}>{item.quotationUserStatus.quotation.product.currency} {item.amount} </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}> {formatDate(item.createdAt, i18n.language)}</td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                {item.type == 'STRIPE' && (
                    <div className="row">
                        <div className="rfq-item-icons">
                            <><span><button onClick={() => goToReceipt(item.receipt)} className="btn-pay-contract float-right" style={{ fontWeight: "bold", margin: "0px", marginTop: "-5px" }}> {t('documents.view_receipt')} </button></span></>
                        </div>
                    </div>
                )}
            </td>
        </tr>
    )
}

export default withTranslation()(React.memo(withRouter(DocListItem)));