import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Board from '../components/board';
import Account from '../components/account';
import RfqCreation from '../rfq/rfqCreation';
import { withTranslation } from "react-i18next";
import Breadcrumb from '../../common/breadcrumb';
import React, { useEffect, useState } from 'react';
import Rfqs from '../components/account/seller/rfqs';
import Docs from '../components/account/seller/docs';
import AccountSidebar from '../components/account-sidebar';
import Contracts from '../components/account/seller/contracts';
import RfqChat from '../components/account/seller/rfqs/RfqChat';
import Guide from '../components/account/seller/guide/guide';
import { logout, countNotifs } from '@action/auth';
import SellerProducts from '../components/account/seller/products';
import { countUnreadMessages } from '../../../action/chat-actions';
import SidebarToggle from '../products/common/sidebars/sidebar-toggle';
import ContratPdf from '../components/account/seller/rfqs/ContratPdf';
import RfqDetatils from '../components/account/seller/rfqs/RfqDetails';
import { getCountUnreadedContracts } from '../../../action/contracts-actions';
import NotifsList from '../components/account/seller/notifications/NotifsList';
import RfqProgression from '../components/account/seller/progression/RfqsList';
import TransactionList from '../components/account/seller/transactions/TransactionList';
import { getCountUnreadedRFQ, getCountUnreadedDocs } from '../../../action/rfqs-actions';
import ContractAttachedFiles from '../components/account/seller/contracts/ContractAttachedFiles';

function Dashboard (props){

    const { authUser }  = props;

    const [numberOfUnreadRFQ, setNumberOfUnreadRFQ] = useState(null)
    const [numberOfUnreadDoc, setNumberOfUnreadDoc] = useState(null)
    const [numberOfUnreadMessage, setNumberOfUnreadMessage] = useState(null)
    const [numberOfUnreadContracts, setNumberOfUnreadContracts] = useState(null)
    const [numberOfUnreadNotif, setNumberOfUnreadNotif] = useState(null)

    const getCountRFQUnread = (userId, userType) => {

        getCountUnreadedRFQ(userId, userType).then( res => {  setNumberOfUnreadRFQ(parseInt(res)) })
    }

    const countMessageUnread = () => {
        countUnreadMessages().then( res => {  setNumberOfUnreadMessage(parseInt(res)) })
    }

    const getCountDocsUnread = () => {

        getCountUnreadedDocs().then( res => {  setNumberOfUnreadDoc(parseInt(res)) })
    }

    const getCountContractsUnread = () => {

        getCountUnreadedContracts().then( res => {  setNumberOfUnreadContracts(parseInt(res)) })
    }

    const getNumberOfUnreadNotif = (id) => {
        countNotifs(id).then( res => {  setNumberOfUnreadNotif(parseInt(res)) })
    }


    useEffect(() => {
        if(authUser.authUser){
            getCountRFQUnread(authUser.authUser.id, authUser.authUser.userType);
            getCountDocsUnread();
            countMessageUnread();
            getCountContractsUnread();
            getNumberOfUnreadNotif(authUser.authUser.id);
        }
    }, [authUser])


    const selectBoard = () => {
        switch ( props.match.params.board ) {
            case "board":
                return ( <Board /> );
            case "account":
                return ( <Account /> );
            case "seller-products":
                return ( <SellerProducts /> );
            case "rfqs":
                if (props.match.params.rfqId) {
                    return ( <RfqDetatils getCountRFQUnread={getCountRFQUnread} /> );
                }
                return ( <Rfqs getCountRFQUnread={getCountRFQUnread} /> );
            case "rfqsUpdate":
                return <RfqCreation />
            case "rfqsChat":
                return <RfqChat countMessageUnread={countMessageUnread}/>
            case "chats":
                return <RfqChat countMessageUnread={countMessageUnread}/>
            case "documents":
                    return <Docs getCountDocsUnread={getCountDocsUnread} />
            case "transactions":
                    return <TransactionList />
            case "notifications":
                    return <NotifsList getCountNumberOfUnreadNotif={getNumberOfUnreadNotif} />
            case "progressions":
                    return <RfqProgression getCountRFQUnread={getCountRFQUnread} />
            case "user-guide":
                    return <Guide />
            case "contracts":
                if (props.match.params.contractId) {
                    return ( <ContractAttachedFiles  /> );
                }
                if (props.match.params.contractEffectiveId) {
                    return ( <ContratPdf  /> );
                }
                return <Contracts getCountContractsUnread={getCountContractsUnread}/>
            default:
        }
    }
    

        /* if ( props.match.params.board !== "board" && props.match.params.board !== "account" ) {
            window.location = NOT_FOUND;
        } */

        return (
            <>
                <Helmet>
                    <title>Sooko - Dashboard Page </title>
                </Helmet>
    
                <h1 className="d-none">Sooko - Dashboard Page</h1>
    
                <div className="main">
                    <Breadcrumb current="Dashboard" parent="pages" t={props.t} />
    
                    <div style={{margin: '5%', marginTop: 10}}>
                        <div className="row">
                            <div className="col-lg-9 order-lg-last dashboard-content">
                                {
                                    selectBoard()
                                }
                            </div>
                            <SidebarToggle />
                            <AccountSidebar board={ props.match.params.board } logout={props.logout} numberOfUnreadNotif={numberOfUnreadNotif} authenticatedUser={props.authUser} 
                            getCountRFQUnread={getCountRFQUnread} numberOfUnreadRFQ={numberOfUnreadRFQ} numberOfUnreadMessage={numberOfUnreadMessage} 
                            numberOfUnreadDoc={numberOfUnreadDoc} numberOfUnreadContracts={numberOfUnreadContracts}/>
                        </div>
                    </div>
    
                    <div className="mb-5"></div>
                </div>
            </>
        )

}

const mapStateToProps = ({ authUser }) => ({
    authUser: authUser,
    isAuth: authUser.isAuth
});

export default withTranslation()( connect(mapStateToProps, {logout})(Dashboard));
