import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import RfqListItem from './RfqListItem';
import { useSelector } from 'react-redux'
import RfqProgression from './rfq-progression';
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from "@material-ui/core/Dialog/Dialog";
import Pagination from '@material-ui/lab/Pagination';
import IconButton from "@material-ui/core/IconButton";
import { paginate } from '@helpers/helpers';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import { getRfqOfUser } from '@action/rfqs-actions';
import DialogContent from "@material-ui/core/DialogContent/DialogContent";

const RfqsList = (props) => {

    const authUser = useSelector((state) => state.authUser);

    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [showBox, setShowBox] = useState(false);
    const [errorData, setErrorData] = useState(null);
    const [rfqToProgress, setRfqToProgress] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    let loader = null;
    let errorDisplay = null;

    const initialiseState = () => {
        setLoadingData(true);
        setErrorData(null);
        setData([]);
    };

    const { t } = props;

    const filterFromSearchText = (item) => {
        try {
            return item.baseQuotation.product.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
        } catch (err) {
            return false;
        }
    }

    if (loadingData) {
        loader = (
            <div className="loader text-center">
                <div className="bounce-loader">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
        );
    }

    if (errorData) {
        errorDisplay = (
            <>
                <br />
                <div className="alert alert-danger">Error: {errorData.message}</div>
                <br />
            </>
        );
    }

    const getRfqsForUser = (user_id, userType) => {
        initialiseState();
        getRfqOfUser(user_id, userType)
            .then(data => {
                setData(data);
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoadingData(false);
            });
    };

    useEffect(() => {
        if (authUser.authUser) {
            getRfqsForUser(authUser.authUser.id, authUser.authUser.userType);
        }
    }, [authUser]);

    const goToUpdate = (rfqItemSelectedQuote) => {
        props.history.push(
            `${process.env.PUBLIC_URL}/account/dashboard/rfqsUpdate/products/${rfqItemSelectedQuote.product.id}/rfq/update`,
            {
                rfqToUpdate: rfqItemSelectedQuote,
            }
        );
    };

    const showProgression = (rfqItemSelectedQuote) => {
        setRfqToProgress(rfqItemSelectedQuote);
        setShowBox(true);
    };

    return (
        <>
            <Helmet>
                <title>Sooko - RFQ </title>
            </Helmet>

            <div className="main">

                <div className="container">

                    <div className="row">
                        <div className="col-md-10"><h2>{t("rfq.title")}</h2></div>
                        <div className="col-md-2" style={{ right: "30px" }}>
                            <Link to={`${process.env.PUBLIC_URL}/marketplace`}><button type="button" style={{ borderRadius: '5px', padding: "10px 10px", margin: '0px' }} className="btn btn-primary">{t("rfq.marketplace")}</button></Link>
                        </div>
                    </div>

                    <p style={{ marginTop: 10, marginBottom: 20 }}>
                        <strong style={{ marginRight: 10 }}>
                            Note :
                        </strong>
                        {t('notes.view_button')}
                    </p>
                    <p style={{ marginTop: 10, marginBottom: 20 }}>
                        <strong style={{ marginRight: 10 }}>
                            Note:
                        </strong>
                        {t('notes.chat_button')}
                    </p>
                    <p style={{ marginTop: 10, marginBottom: 40 }}>
                        <strong style={{ marginRight: 10 }}>
                            Note:
                        </strong>
                        {t('notes.progression_button')}
                    </p>

                    {data.length > 0 ? <div className="row">
                        <div className={"col-md-5 header-search " + "header-search-inline"} style={{ width: "100% !important" }}>
                            <div className="header-search-wrapper">
                                <input type="search" className="form-control" name="q" placeholder={t("common.search")} style={{ width: "100%" }} required
                                    value={searchText} onChange={(ev) => setSearchText(ev.target.value)} />
                            </div>
                        </div>
                    </div> : null}

                    {loader}
                    {errorDisplay}


                    {data.length > 0 ? <div className="row" style={{ marginTop: 30, marginBottom: 20 }}>
                        <table className="col-lg-12 col-sm-12">
                            <thead>
                                <th>{t("rfq.name")}</th>
                                <th>{t("rfq.category")}</th>
                                <th> {t("rfq.status")} </th>
                                <th> {t("rfq.rfq_price")} </th>
                                <th> {t("rfq.quantity")} </th>
                                <th> {t("rfq.date")} </th>
                                <th>{t("rfq.action")}</th>
                            </thead>
                            <br />
                            <tbody>
                                {paginate(data.filter(filterFromSearchText), page, pageSize).map((item, index) =>
                                    <RfqListItem key={index} item={item} showDetail={props.showDetail} showProgression={showProgression} goToUpdate={goToUpdate} />
                                )}
                            </tbody>
                        </table>
                    </div> : null}

                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        count={Math.ceil(data.filter(filterFromSearchText).length / pageSize)}
                        showFirstButton
                        showLastButton
                        onChange={(__, selectedPage) => setPage(selectedPage)}
                    />

                    {!loadingData && data.length <= 0 ?
                        (
                            <div className="mb-5 text-center">
                                <img src="assets/images/nothing-here.png" width="500" style={{ margin: "0 auto" }} />
                                <h2 className="text-muted font-weight-light"> {t("rfq.no_rfq")} </h2>

                                <Link to={`${process.env.PUBLIC_URL}/marketplace`}><button type="button" style={{ borderRadius: '5px' }} className="btn btn-primary mt-2">{t("rfq.marketplace")}</button></Link>
                            </div>
                        ) : null
                    }

                </div>

                <div className="mb-6"></div>

                <Dialog
                    open={showBox}
                    onClose={() => { setShowBox(false) }}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={'lg'}
                    fullWidth
                >
                    <DialogTitle id="form-dialog-title">
                        <div className="row justify-content-between align-items-center">
                            <h3>{t("rfq.rfq_progression")}</h3>
                            <IconButton
                                color="primary"
                                aria-label="close"
                                className="text-danger"
                                onClick={() => { setShowBox(false) }}>
                                <CancelIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12">
                                <div style={{ width: '80%', margin: '0 auto' }}>
                                    {
                                        rfqToProgress != null ?
                                            < RfqProgression id={rfqToProgress.id} />
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                    </DialogContent>
                </Dialog>
            </div>
        </>
    )
}

export default withTranslation()(React.memo(RfqsList));
