import React, { useEffect, useState } from "react";
import FileUploadWithPreview from "file-upload-with-preview";

//import Breadcrumb from '../../../common/breadcrumb';
import { useForm } from "react-hook-form";
import classes from "../rfqCreate.module.scss";
import ReactFlagsSelect from "react-flags-select";
import './rfqCreateStep2.css';
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

function RfqCreateStep2(props) {
  const {
    setDataStep2,
    loading,
    portsConfig,
    typesOfShipmentsConfig,
    onPreviousStep,
    defaultDataStep2,
    rfqToUpdate
  } = props;

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: { ...defaultDataStep2 },
  });
  const { t } = props;

  const authUser = useSelector((state) => state.authUser);

  const [langSelected, setLangSelected] = useState("en");

  useEffect(() => {
    if (authUser.authUser != null) {
      setLangSelected(authUser.authUser.language.toLowerCase());
    }
  }, [authUser])

  const [countrySelected, setCountrySelected] = useState(rfqToUpdate ? rfqToUpdate.port ? rfqToUpdate.port.country : '' : '');

  const [methodOfShipmentSelected, setMethodOfShipmentSelected] = useState("");


  const handleChange = ($event) => {
    const name = $event.target.name;
    const value = $event.target.value;

    if (name === "methodOfShipment") {
      setMethodOfShipmentSelected(value);
    }
  };

  const onSubmitForm = (data, publishStatus) => {
    const publishStatusToSend =
      publishStatus === "SELLERS_OF_PRODUCT" ? true : false;

    setDataStep2(data, publishStatusToSend);
  };

  const disableIfSellerWantToUpdate = rfqToUpdate && authUser.authUser && authUser.authUser.userType === 'SELLER';

  useEffect(() => {
    const methodOfShipmentValue = document.getElementById("methodOfShipment").value;
    setMethodOfShipmentSelected(methodOfShipmentValue);
  }, []);

  const methodOfShipmentData = [
    { name: t("common.air"), value: 'AIR' },
    { name: t("common.sea"), value: 'SEA' },
    { name: t("common.road"), value: 'ROAD' },
    { name: t("common.courriel"), value: 'COURRIEL' },
    { name: t("common.rail"), value: 'RAIL' }
  ];

  return (
    <div className="main">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 order-lg-first">
            <div className="checkout-payment">
              <div id="new-checkout-address" className="show">
                <form name="rfqForm mt-2" className={classes.rfqForm}>
                  <div className="showStepOfStepper">
                    <div className="row">
                      <div className="required-field  col-md-12">
                        <label> {t("rfq.method")}</label>
                        {disableIfSellerWantToUpdate ? <>
                          <input type="text" className="form-control" id="methodOfShipment" value={rfqToUpdate.methodOfShipment} disabled />
                        </> :
                          <>
                            <select
                              id="methodOfShipment"
                              className="form-control"
                              name="methodOfShipment"
                              ref={register({ required: true })}
                              onChange={handleChange}
                              required
                            >
                              <option value="">  </option>
                              {methodOfShipmentData.sort((i1, i2) => i1.name.localeCompare(i2.name)).map((item, index) => (
                                <option key={index} value={item.value}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </>
                        }
                        {errors.methodOfShipment && (
                          <div className="text-danger mt-1">
                            Method of Shipment is required
                          </div>
                        )}
                      </div>
                    </div>

                    {methodOfShipmentSelected !== "SEA" &&
                      methodOfShipmentSelected !== "" ? (
                        <div className="row">
                          {/* <div className="form-group required-field  col-md-6">
                              <label> departure </label>
                              <input
                                type="text"
                                className="form-control"
                                name="departure"
                                ref={register({ required: true })}
                                required
                              />
                            </div> */}
                          <div className="required-field  col-md-12">
                            <label> {t("rfq.arrival")} </label>
                            {disableIfSellerWantToUpdate ? <>
                              <input type="text" className="form-control" value={rfqToUpdate.arrival} disabled />
                            </> : <>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="arrival"
                                  ref={register({ required: true })}
                                  required
                                />
                                {errors.arrival && (
                                  <div className="text-danger mt-1">
                                    Arrival is required
                                  </div>
                                )}
                              </>}
                          </div>
                        </div>
                      ) : null}

                    {methodOfShipmentSelected === "SEA" ? (
                      <>
                        <div className="row">
                          <div className="required-field col-md-12">
                            <label> {t("rfq.type")} </label>
                            {disableIfSellerWantToUpdate ? <>
                              <input type="text" className="form-control" value={typesOfShipmentsConfig.find(item => item.id == rfqToUpdate.typeOfShipment.id).name} disabled />
                            </> : <>
                                <select
                                  className="form-control"
                                  name="typeOfShipmentId"
                                  ref={register({ required: true })}
                                  required
                                >
                                  <option value="">  </option>
                                  {typesOfShipmentsConfig.map((item, index) => (
                                    <option key={index} value={Number(item.id)}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                {errors.typeOfShipmentId && (
                                  <div className="text-danger mt-1">
                                    Type of shipment is required
                                  </div>
                                )}
                              </>}
                          </div>
                        </div>

                        <div className="row">
                          <div className="required-field col-md-12">
                            <label> {t("rfq.port")} </label>
                            {disableIfSellerWantToUpdate ? <>
                              <input type="text" className="form-control" value={portsConfig.find(item => item.id == rfqToUpdate.port.id).name} disabled />
                            </> : <>
                                <select
                                  className="form-control"
                                  name="portDestinationId"
                                  ref={register({ required: true })}
                                  required
                                >
                                  <option value="">  </option>
                                  {portsConfig.map((item, index) => (
                                    <option key={index} value={Number(item.id)}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                {errors.portDestinationId && (
                                  <div className="text-danger mt-1">
                                    Port destination is required
                                  </div>
                                )}
                              </>}
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="row">
                      <div className="required-field col-md-12">
                        <label> {t("rfq.packaging")} </label>
                        <textarea
                          style={{ width: "100%", maxWidth: "initial" }}
                          className="form-control"
                          cols="4"
                          spellcheck='false'
                          name="packagingDetails"
                          ref={register({ required: true })}
                          required
                        ></textarea>
                        {errors.packagingDetails && (
                          <div className="text-danger mt-1">
                            Packagings details is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>

                {/* <div className="col-md-12">
                  <button
                    onClick={onPreviousStep}
                    className="btn btn-primary float-left px-5"
                  >
                    Previous
                  </button>

                  <button
                    onClick={handleSubmit(onSubmitForm)}
                    className="btn btn-primary float-right px-5"
                  >
                    Next
                  </button>
                </div> */}
                {rfqToUpdate ? <div className="col-md-12">
                  <button
                    onClick={onPreviousStep}
                    className="btn btn-primary float-left px-5"
                  >
                    {t("rfq.previous")}
                  </button>

                  <button
                    onClick={handleSubmit((d) =>
                      onSubmitForm(d, "SELLER_ONLY")
                    )}
                    className="btn btn-primary float-right ml-3"
                    disabled={loading}
                  >
                    {loading ? (
                      <span>
                        <i
                          className="fa fa-spinner fa-spin fa-1x"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;
                      </span>
                    ) : null}
                    UPDATE
                  </button>
                </div> :
                  <div className="col-md-12">
                    <button
                      onClick={onPreviousStep}
                      className="btn btn-warning float-left px-5"
                    >
                      {t("rfq.previous")}
                    </button>

                    <button
                      onClick={handleSubmit((d) =>
                        onSubmitForm(d, "SELLER_ONLY")
                      )}
                      className="btn btn-primary float-right ml-3"
                      disabled={loading}
                    >
                      {loading ? (
                        <span>
                          <i
                            className="fa fa-spinner fa-spin fa-1x"
                            aria-hidden="true"
                          ></i>
                        &nbsp;&nbsp;
                        </span>
                      ) : null}
                      {t("rfq.one")}
                    </button>

                    <button
                      onClick={handleSubmit((d) =>
                        onSubmitForm(d, "SELLERS_OF_PRODUCT")
                      )}
                      className="btn btn-primary float-right"
                      disabled={loading}
                    >
                      {loading ? (
                        <span>
                          <i
                            class="fa fa-spinner fa-spin fa-1x"
                            aria-hidden="true"
                          ></i>
                        &nbsp;&nbsp;
                        </span>
                      ) : null}
                      {t("rfq.all")}
                    </button>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6"></div>
    </div>
  );
}

export default withTranslation()(React.memo(RfqCreateStep2));
