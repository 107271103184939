import backendURLs from "../utils/backendURL";
import { joinBaseUrlWithParams, joinBaseUrlWithRequestParams, joinBaseUrlWithSearchParams, makeActionRequest, makeRequest, replaceIdParamInUrlFormat } from "./utils"
import * as chatActionsType from '../constants/rfsChat-actions-types';


export const createChat = (data) => {

    const url = `${backendURLs.RFQ_CHAT.CREATE_RFQ_CHAT}`;

    //const url = joinBaseUrlWithSearchParams(`${backendURLs.SELLER.PRODUCTS.CREATE}`,  [{param: 'image', value: data.image}]);

    return makeRequest('post', url, data, { shouldSkipDataParsing: false, multipart: false });
}

export const getAllSellerToChatWith = (quotationId) => {

    let url = '';

    if (quotationId)
        url = replaceIdParamInUrlFormat(`${backendURLs.RFQ_CHAT.GET_CONCERN_SELLERS_FOR_RFQ}`, quotationId);
    else
        url = backendURLs.RFQ_CHAT.GET_CHATS;

    return makeRequest('get', url, null, { shouldSkipDataParsing: false, multipart: false });
}

export const comfirmAndProceedRFQ = (chatId, userId) => {

    const url = joinBaseUrlWithParams(`${backendURLs.RFQ_CHAT.CONFIRM_AND_PROCEED}`, [
        { param: 'chat_id', value: chatId },
        { param: 'user_id', value: userId },
    ]);

    return makeRequest('put', url, null, {});
}


export const getMessagesFromChat = (chatId) => dispatch => {

    const url = replaceIdParamInUrlFormat(`${backendURLs.RFQ_CHAT.FETCH_MESSAGES_FROM_CHAT}`, chatId);

    return makeRequest('get', url, null, { shouldSkipDataParsing: false });
}

export const countUnreadMessages = () => {
    return makeRequest('get', backendURLs.RFQ_CHAT.COUNT_UNREAD_MESSAGES, null, { shouldSkipDataParsing: false });
}


export const getLastMessagesForChat = (chatId) => {

    const url = replaceIdParamInUrlFormat(`${backendURLs.RFQ_CHAT.LAST_MESSAGES}`, chatId);

    return makeRequest('get', url, null, { shouldSkipDataParsing: false });
}


export const createMessage = (chatId, senderId, content) => {

    let preUrl = replaceIdParamInUrlFormat(`${backendURLs.RFQ_CHAT.CREATE_MESSAGE}`, chatId);
    const url = joinBaseUrlWithRequestParams(preUrl, [

        { param: 'sender_id', value: senderId },
        { param: 'content', value: content },
    ]);

    return makeRequest('post', url, null, {});
}
