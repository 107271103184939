import { combineReducers } from 'redux';
import cartReducer from './cart';
import productReducer from './product';
import wishReducer from './wishlist';
import demoReducer from './demo';
import compareReducer from './compare';
import filtersReducer from './filter';

import signInReducer from './auth/signIn';
import signUpReducer from './auth/signUp';
import updateUserInformationReducer from './auth/userUpdate/userUpdateInfo';
import updateUserCompanyInformationReducer from './auth/userUpdate/updateUserCompany';
import updateUserAvatarReducer from './auth/userUpdate/userUpdateAvatar';
import updateUserLicenceReducer from './auth/userUpdate/updateUserLicence';
import sellerProductsReducer from './sellerProducts';
import productsConfigReducer from './ProductsConfig';
import productsReducer from './productsApp';
import ModalToLoginReducer from './modal/modalToLogin';
import languageReducer from './language'
import pagesContentReducer from './pageContent';
import chatReducer from './rfqChat';
import commodityPriceReducer from './commodityPriceReducer';


const rootReducer = combineReducers( {
    cartList: cartReducer,
    data: productReducer,
    wishlist: wishReducer,
    demo: demoReducer,
    compareList: compareReducer,
    filter: filtersReducer,
    modalToLogin: ModalToLoginReducer,
    pagesContent: pagesContentReducer,
    authUser: signInReducer,
    signUpUser: signUpReducer,
    productsConfig: productsConfigReducer,
    updateUserInformation: updateUserInformationReducer,
    updateUserCompanyInformation: updateUserCompanyInformationReducer,
    updateUserAvatar: updateUserAvatarReducer,
    updateUserLicence: updateUserLicenceReducer,
    products: productsReducer,
    sellerProducts: sellerProductsReducer,
    language: languageReducer,
    commodityPrices: commodityPriceReducer,
    chatMessages: chatReducer
} );

export default rootReducer;