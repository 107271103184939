import { useEffect } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { getRfqDetails } from '@action/rfqs-actions';


function RfqProgression(props) {

    const authUser = useSelector((state) => state.authUser);

    const isBuyer = authUser.authUser.userType === 'BUYER';

    const [rfq, setRfq] = useState(null);

    useEffect(() => {
        getRfqDetails(props.id).then(data => setRfq(data)).catch(error => console.log(error))
    }, [props.id]);

    const okStyle = {
        backgroundColor: '#4cc910',
        color: 'white'
    }

    const nonStyle = {
        backgroundColor: 'white',
        color: '#4cc910'
    }

    const goToDetails = (to) => {
        props.history.push(to);
    }

    const { t } = props;

    return (
        <div className="feature-boxes-container" style={{
            padding: '1%'
        }}>
            <p style={{ marginBottom: 50, fontSize: '1.2em' }}>
                <strong>
                    Note:
                </strong>
                {t('notes.completed')}
            </p>

            {
                rfq ?

                    <div className="container mt- mb-2">
                        {
                            isBuyer ?
                                <>
                                    <div className="row">
                                        <div className="col-md-3" key={"feature1"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.quotation.hasChat ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/rfqs/${props.id}/details`) : null}>
                                                <i className={'icon-grid'} style={rfq.quotation.hasChat ? okStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">1. {t("rfq.accept")}</h3>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3" key={"feature2"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.quotation.hasChat ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/chats`) : null}>
                                                <i className={'icon-chat'} style={rfq.quotation.hasChat ? okStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">2. {t("rfq.discussion")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3" key={"feature3"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.quotation.hasChat ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/chats`) : null}>
                                                <i className={'icon-category-hot-deals'} style={rfq.purchase ? okStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">3. {t("rfq.assign")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3" key={"feature3"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.purchase ? rfq.purchase.status == 'si' ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/documents`) : null : null}>
                                                <i className={'icon-pencil'} style={rfq.purchase ? rfq.purchase.status == 'si' ? okStyle : nonStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">4. {t("rfq.sign_purchase")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3" key={"feature1"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.contract ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/contracts`) : null}>
                                                <i className={'icon-doc'} style={rfq.contract ? okStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">5. {t("rfq.contract")}</h3>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3" key={"feature2"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.contractd ? rfq.contractd.status == 'si' ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/contracts`) : null : null}>
                                                <i className={'icon-check'} style={rfq.contractd ? rfq.contractd.status == 'si' ? okStyle : nonStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">6. {t("rfq.sign_contract")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3" key={"feature3"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.contract ? rfq.contract.paid && !rfq.contract.paidPending ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/contracts`) : null : null}>
                                                <i className={'icon-money'} style={rfq.contract ? rfq.contract.paid && !rfq.contract.paidPending ? okStyle : nonStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">7. {t("rfq.payment")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3" key={"feature3"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.contract ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/contracts`) : null}>
                                                <i className={'icon-shipped'} style={rfq.contract ? (rfq.contract.getProof + " ").length > 10 ? okStyle : nonStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">8. {t("rfq.shipment")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="row">
                                        <div className="col-md-4" key={"feature1"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.quotation.hasChat ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/rfqs/${props.id}/details`) : null}>
                                                <i className={'icon-grid'} style={rfq.quotation.hasChat ? okStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">1. {t("rfq.accept")}</h3>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4" key={"feature2"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.quotation.hasChat ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/rfqs/${props.id}/details`) : null}>
                                                <i className={'icon-chat'} style={rfq.quotation.hasChat ? okStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">2. {t("rfq.discussion")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4" key={"feature3"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.proformat ? rfq.proformat.status == 'si' ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/documents`) : null : null}>
                                                <i className={'icon-pencil'} style={rfq.proformat ? rfq.proformat.status == 'si' ? okStyle : nonStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">3. {t("rfq.sign_proformat")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4" key={"feature1"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.contract ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/contracts`) : null}>
                                                <i className={'icon-doc'} style={rfq.contract ? okStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">4. {t("rfq.contract")}</h3>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4" key={"feature2"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.contract ? rfq.contract.status == 'si' ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/contracts`) : null : null}>
                                                <i className={'icon-check'} style={rfq.contract ? rfq.contract.status == 'si' ? okStyle : nonStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">5. {t("rfq.sign_contract")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4" key={"feature3"}>
                                            <div className="feature-box px-md-4 mx-md-3 feature-box-simple text-center" onClick={() => rfq.contract ? rfq.contract.adminPaid ? props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/contracts`) : null : null}>
                                                <i className={'icon-shipped'} style={rfq.contract ? rfq.contract.adminPaid ? okStyle : nonStyle : nonStyle}></i>

                                                <div className="feature-box-content">
                                                    <h3 className="m-b-10">6. {t("rfq.shipment_proof")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                    : null}
        </div>
    )
}

export default withTranslation()(React.memo(withRouter(RfqProgression)));