import * as pagesContentActionsType from '../constants/pagesContent-actions-types';

const initialState = {
    data: [],
    loading: false,
    error: null,
}

const commodityPriceReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case pagesContentActionsType.FETCH_COMMODITY_PRICE:
            return { ...state, loading: true, error: null, data: []};
        case pagesContentActionsType.FETCH_COMMODITY_PRICE_SUCCESS:
            return { ...state, error: null, loading: false, data: action.payload};
        case pagesContentActionsType.FETCH_COMMODITY_PRICE_ERROR:
            return { ...state, data: null, loading: false, error: action.payload };
        case pagesContentActionsType.FETCH_COMMODITY_PRICE_CLEAR:
            return initialState
        default:
            return state;
    }
};
export default commodityPriceReducer;