import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function SearchForm( props ) {

    const search = useLocation().search;
    const q = new URLSearchParams(search).get('q');

    useEffect( () => {
        document.querySelector( "body" ).addEventListener( "click", onBodyClick );
        let headerSearch = document.querySelectorAll( '.header-search' );
        for ( let i = 0; i < headerSearch.length; i++ ) {
            headerSearch[ i ].querySelector( '.header-search-wrapper' ).addEventListener( "click", function ( e ) {
                e.stopPropagation();
            } )
        }

        window.addEventListener( "resize", onWindowResize );

        return () => {
            document.querySelector( "body" ).removeEventListener( "click", onBodyClick );
            window.removeEventListener( "resize", onWindowResize );
        }
    } )

    function onSearchClick( e ) {
        e.preventDefault();

        let headerSearch = document.querySelectorAll( ".header-search" )
        for ( let j = 0; j < headerSearch.length; j++ ) {
            headerSearch[ j ].classList.toggle( "show" );
        }

        let headerSearchWrapper = document.querySelectorAll( ".header-search-wrapper" )
        for ( let j = 0; j < headerSearchWrapper.length; j++ ) {
            headerSearchWrapper[ j ].classList.toggle( "show" );
        }

        if ( window.innerWidth < 576 ) {
            document.querySelector( ".header-search-wrapper" ).style.left = 15 - document.querySelector( ".header-search" ).offsetLeft + "px";
            document.querySelector( ".header-search-wrapper" ).style.right = 15 + document.querySelector( ".header-search" ).offsetLeft + document.querySelector( ".header-search" ).clientWidth - window.innerWidth + "px";
        }
    }

    function onWindowResize() {
        let searchWrapper = document.querySelector( ".header-search-wrapper" );
        let headerSearch = document.querySelector( ".header-search" );

        if ( window.innerWidth < 576 ) {
            searchWrapper.style.left = 15 - headerSearch.offsetLeft + "px";
            searchWrapper.style.right = 15 + headerSearch.offsetLeft + headerSearch.clientWidth - window.innerWidth + "px";
        }
    }

    function onBodyClick( e ) {
        if ( document.querySelector( ".header-search-wrapper" ) ) {
            let headerSearch = document.querySelectorAll( ".header-search" )
            let headerSearchWrapper = document.querySelectorAll( ".header-search-wrapper" )
            for ( let j = 0; j < headerSearchWrapper.length; j++ ) {
                if ( headerSearchWrapper[ j ].classList.contains( "show" ) ) {
                    headerSearchWrapper[ j ].classList.remove( "show" );
                    document.querySelector( "body" ).classList.remove( "is-search-active" );
                }
            }

            for ( let j = 0; j < headerSearch.length; j++ ) {
                if ( headerSearch[ j ].classList.contains( "show" ) ) {
                    headerSearch[ j ].classList.remove( "show" );
                }
            }
        }
    }

    const { addClass, iconClass = "icon-magnifier", placeholder = "Search...", isCat = true, text, btnAClass, searchId } = props;

    return (
        <div className={ "header-search " + addClass }>
            <Link to="marketplace" className="search-toggle" role="button" onClick={ onSearchClick }><i className={ iconClass }></i>{ text }</Link>
            <form action="marketplace" method="get">
                <div className="header-search-wrapper">
                    <input type="search" className="form-control" defaultValue={q} name="q" id={ searchId ? searchId : "q" } placeholder={ placeholder } />
                    <button className={ iconClass + " btn " + btnAClass } type="submit"></button>
                </div>
            </form>
        </div>
    )
}

export default SearchForm;