import './style.css';
import { Helmet } from 'react-helmet';
import Faq from "react-faq-component";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Testimonials from './Testimonials';
import { setParallax } from '@utils';
import { useTranslation } from 'react-i18next';
import FeatureSection from './feature-section';
import { withTranslation } from "react-i18next";
import { getFaqs } from '@action/contact';
import React, { useEffect, useState } from 'react';
import ProductCollection from './product-collection';
import { useDispatch, useSelector } from 'react-redux';
import { getContentInPageContent } from '@utils/custom-index';
import { fetchInterestingProducts } from '@action/products-actions';
import { getPageContentForPage } from '@action/pagesContents-actions';
import { LOGIN, MARKETPLACE } from '@routes/urls';

const styles = {
    titleTextColor: "#00c805",
    rowTitleColor: "#00c805",
    titleTextSize: '30px',
    rowContentTextSize: '18px',
};

const config = {
};

function HomePage(props) {

    const pagesContent = useSelector(state => state.pagesContent);

    const langApp = useSelector(state => state.language);

    const dispatch = useDispatch();

    const [interestingProducts, setInterestingProducts] = useState([]);

    const getInterestingProducts = () => {

        fetchInterestingProducts().then(res => {
            setInterestingProducts(res);
        }).catch((err) => {

            toast("Failed to get interesting products", { className: "toast-error" });
        });
    }

    const { i18n } = useTranslation();

    const getLanguage = () => i18n.language || window.localStorage.i18nextLng

    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        getFaqs().then(data => {
            setFaqs(data)
        }).catch(err => {
            setFaqs([]);
        });
    }, []);


    useEffect(() => {


        if (document.querySelector(".parallax")) {
            document.addEventListener("scroll", setParallax);
        }

        document.querySelector('.menu') && document.querySelector('.menu').firstChild.classList.add('active');
        document.querySelector('.mobile-menu') && document.querySelector('.mobile-menu').firstChild.classList.add('active');
    })


    useEffect(() => {
        dispatch(getPageContentForPage('HOME', langApp.lang));
    }, [])


    useEffect(() => {

        getInterestingProducts();
    }, [])
    const { t } = props;

    return (
        <>
            <Helmet>
                <title>{t('title.home')}</title>
            </Helmet>

            <div className="main">
                <div className="homeMain row ">
                    <div className="illustrationBlock col-md-5">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/demo/undraw_deliveries_131a.svg`} className='illustration' alt={t('title.home_alt')} />
                    </div>
                    <div className="presentationBlock col-md-6">
                        <h2>
                            {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'TITLE1', langApp.lang) : ""}
                        </h2>
                        <p>
                            {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'TITLE2', langApp.lang) : ""}
                        </p>
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-sm-6"><Link to={LOGIN}><button type="button" className="btn btn-primary callToActionHome" style={{ borderRadius: '5px' }}>{pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'BUTTON1', langApp.lang) : ""}</button></Link></div>
                            <div className="col-xl-6 col-md-6 col-sm-6"><Link to={MARKETPLACE}><button type="button" className="btn btn-primary callToActionHome2" style={{ borderRadius: '5px' }}>{pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'BUTTON2', langApp.lang) : ""}</button></Link></div>
                        </div>
                    </div>
                </div>
                <div className='bg-gray' style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <div className=" col-md-8 col-sm-12 pt-5 pt-md-0 offset-md-2">
                        <h2 style={{ marginBottom: '4%', marginTop: '4%' }}>
                            {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'TITLE3', langApp.lang) : ""}
                        </h2>
                    </div>

                    <div className="choosingSectionBlock">
                        <div className="choosingBlock" style={{ flex: 1 }}>
                            <div className="illustrationBlock" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/demo/undraw_Container_ship_ok1c.svg`} className='choosingIllustration2' style={{ maxWidth: '50%' }} />
                            </div>
                            <div className="presentationBlock" style={{ marginTop: '7%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <h2 style={{ fontSize: '2.5em' }}>
                                    <span style={{ color: '#00c805' }}>{pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'TITLE6', langApp.lang) : ""}</span>
                                </h2>
                                <p>
                                    {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'TITLE66', langApp.lang) : ""}
                                </p>
                                <Link to={LOGIN}>
                                    <button type="button" className="btn btn-primary callToActionHome" style={{ borderRadius: '5px' }}>
                                        {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'BUTTON4', langApp.lang) : ""}
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="choosingBlock" style={{ flex: 1 }}>
                            <div className="illustrationBlock" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/demo/undraw_business_deal_cpi9.svg`} className='choosingIllustration' />
                            </div>
                            <div className="presentationBlock" style={{ marginTop: '7%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <h2 style={{ fontSize: '2.5em' }}>
                                    <span style={{ color: '#00c805' }}>{pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'TITLE4', langApp.lang) : ""}</span>
                                </h2>
                                <p>
                                    {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'TITLE5', langApp.lang) : ""}
                                </p>
                                <Link to={LOGIN}>
                                    <button type="button" className="btn btn-primary callToActionHome" style={{ borderRadius: '5px' }}>
                                        {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'BUTTON3', langApp.lang) : ""}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" col-md-8 col-sm-12 pt-5 pt-md-0 offset-md-2">
                    <h2 style={{ marginBottom: '4%', marginTop: '4%' }}>
                        {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'TITLE7', langApp.lang) : ""}
                    </h2>
                </div>

                <div className="serviceSectionBlock">
                    <div className="serviceBlock">
                        <div className="serviceIllustrationBlock">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/demo/undraw_Powerful_re_frhr.svg`} className='serviceIllustration' />
                        </div>
                        <div className="serviceBlock">
                            <h2>
                                <span style={{ color: '#00c805' }}> {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION1', langApp.lang) : ""}</span>
                            </h2>
                            <p style={{ textAlign: 'justify' }}>
                                {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION2', langApp.lang) : ""}
                            </p>
                        </div>
                    </div>
                    <div className="serviceBlock">
                        <div className="serviceBlock">
                            <h2>
                                <span style={{ color: '#00c805' }}>{pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION3', langApp.lang) : ""}</span>
                            </h2>
                            <p style={{ textAlign: 'justify' }}>
                                {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION4', langApp.lang) : ""}
                            </p>
                        </div>
                        <div className="serviceIllustrationBlock">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/demo/undraw_Segmentation_re_gduq.svg`} className='serviceIllustration' />
                        </div>
                    </div>
                    <div className="serviceBlock">
                        <div className="serviceIllustrationBlock">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/demo/undraw_Meeting_re_i53h.svg`} className='serviceIllustration' />
                        </div>
                        <div className="serviceBlock">
                            <h2>
                                <span style={{ color: '#00c805' }}>{pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION5', langApp.lang) : ""}</span>
                            </h2>
                            <p style={{ textAlign: 'justify' }}>
                                {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION6', langApp.lang) : ""}
                            </p>
                        </div>
                    </div>
                    <div className="serviceBlock">
                        <div className="serviceBlock">
                            <h2>
                                <span style={{ color: '#00c805' }}>{pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION7', langApp.lang) : ""}</span>
                            </h2>
                            <p style={{ textAlign: 'justify' }}>
                                {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION8', langApp.lang) : ""}
                            </p>
                        </div>
                        <div className="serviceIllustrationBlock">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/demo/undraw_On_the_way.svg`} className='serviceIllustration' />
                        </div>
                    </div>
                </div>

                <FeatureSection />

                <div className="col-md-8 col-sm-12 pt-5 pt-md-0 offset-md-2">
                    <h2 style={{ marginBottom: '4%', marginTop: '4%' }}>
                        {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'SECTION18', langApp.lang) : ""}
                    </h2>
                </div>

                <ProductCollection interestingProducts={interestingProducts} />

                <div className=" col-md-8 col-sm-12 pt-5 pt-md-0 offset-md-2" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '2%',
                    marginBottom: '4%'
                }}>
                    <Link to={`${process.env.PUBLIC_URL}/marketplace`}><button type="button" style={{ borderRadius: '5px' }} className="btn btn-primary callToActionHome2"> {t("home.explore_all_products")} </button></Link>
                </div>

                <Testimonials />

                <div className="col-md-8 col-sm-12 pt-5 pt-md-0 offset-md-2">
                    <div className="col-md-12" style={{ marginTop: '10%', marginBottom: '5%' }}>
                        <h2 className="light-title"><strong>{t('home.faq')}</strong></h2>
                        <p style={{ textAlign: 'justify' }}>
                            {pagesContent.data ? getContentInPageContent(pagesContent.data, 'HOME', 'FAQ', langApp.lang) : ""}
                        </p>
                        <Faq
                            data={{ rows: faqs.map(faq => { return { title: getLanguage() == 'en' ? faq.titleEn : faq.titleFr, content: getLanguage() == 'en' ? faq.contentEn : faq.contentFr } }) }}
                            styles={styles}
                            config={config}
                        />
                    </div>
                </div>

            </div>
        </>
    )

}

export default withTranslation()(HomePage);