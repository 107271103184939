import axios from '../api';
import { setAuthUser, logout } from './auth';

const BASE = process.env.BACKEND_BASE_URL;


export const makeActionRequest = (verb, url, data, dispatch, ACTION_BASE, customConfig) => {

    const requestConfig = {
        method: verb,
        url: url,
        data: data,

        shouldSkipDataParsing: true,
        ...customConfig
    };

    dispatch({ type: `${ACTION_BASE}`, payload: null });

    axios(requestConfig).then((response) => {

        dispatch({ type: `${ACTION_BASE}_SUCCESS`, payload: response.data });

        if (customConfig.shouldFetchAuthUserAfterSuccess) {
            dispatch(setAuthUser());
        }

        if (customConfig.shouldClearReducerState) {
            dispatch({ type: `${ACTION_BASE}_CLEAR` });
        }

        if (customConfig.callback) {
            customConfig.callback(response);
        }

    }).catch(error => {

        dispatch({ type: `${ACTION_BASE}_ERROR`, payload: error });

        if (error.response) {
            if (error.response.status == 401) {
                dispatch(logout());
            }
        }

        if (customConfig.callback) {
            customConfig.callback(error);
        }
    })
}



export const makeRequest = (verb, url, data = null, customConfig = {}, dispatch = null)  =>{

    const requestConfig = {
        method: verb,
        url: url.replaceAll("+", "%2B"),
        data: data,

        shouldSkipDataParsing: true,
        ...customConfig
    };

    return new Promise((resole, reject) => {

        axios(requestConfig).then((response) => {
            resole(response.data);
        }).catch(error => {
            if (error.response) {
                if (error.response.status == 401) {
                    if(dispatch != null)
                        dispatch(logout());
                }
            }
            reject(error);
        })
    });
}


export const replaceIdParamInUrlFormat = (url, id) => {

    return url.replace("{id}", id);
}


export const joinBaseUrl = to => BASE + to;

export const joinBaseUrlWithParams = (url, params) => {

    params.forEach(param => {
        url = url.replace(`{${param.param}}`, `${encodeURIComponent(param.value)}`);
    });

    return url;
};

export const joinBaseUrlWithSearchParams = (url, params) => {

    params.forEach(param => {
        url = url.replace(`[${param.param}]`, `${encodeURIComponent(param.value)}`);
    });

    return url;
};

export const joinBaseUrlWithParamsId = (to, id) => {
    return joinBaseUrlWithParams(to, [{ param: 'id', value: id }]);
};

export const joinBaseUrlWithRequestParams = (url, params) => {
    //let url = BASE + to;
    let i = 0;
    params.forEach(param => {
        if (i == 0)
            url = url + '?' + param.param + '=' + param.value
        else
            url = url + '&' + param.param + '=' + param.value;
        i++
    });

    return url;
};