import './contract.css';
import Doc from "./DocService";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import CountryManager from '@helpers/CountryManager';
import SuccessModal from '@components/pages/succesModal';
import { getContratText, getFreehand } from "@action/rfqs-actions";
import { sendFirstDocToBuyerBase64, updateContrat } from "@action/contract";


const bodyPurchaseRef = React.createRef();

export const ContratPdf = (props) => {

  const [currentRfq, setCurrentRfq] = useState(null);

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingSendContractDoc, setLoadingSendContractDoc] = useState(false);

  const authUser = useSelector(state => state.authUser);
  const isBuyer = authUser.authUser ? authUser.authUser.userType === 'BUYER' : false;
  const isSeller = authUser.authUser ? authUser.authUser.userType === 'SELLER' : false;

  const [contract, setContract] = useState([]);
  const [freehands, setFreehands] = useState({});
  const [final, setFinal] = useState(false);
  const [nonPayment1, setNonPayment1] = useState(true);
  const [nonPayment2, setNonPayment2] = useState(true);
  const [nonPayment3, setNonPayment3] = useState(true);
  const [nonPayment4, setNonPayment4] = useState(true);
  const [nonPayment5, setNonPayment5] = useState(true);
  const [successModal, setSuccessModal] = useState(false);
  const [successWatingModal, setSuccessWaitingModal] = useState(false);
  const [successDocModal, setSuccessDocModal] = useState(false);
  const [lang, setLang] = useState('en');
  const [changeClassName, setChangeClassName] = useState("contract_input");

  const { register, handleSubmit, errors, reset } = useForm({});
  const { t } = props;

  const onfinalize = () => {
    setFinal(true)
    setChangeClassName("contract1_input");
  }

  useEffect(() => {
    if (authUser != null) {
      if (authUser.authUser != null)
        setLang(authUser.authUser.language.toLowerCase())
    }
  }, [authUser])

  const setBase64Contract2 = (data, base64File, sendBase64) => {

    const contractEffectiveId = Number(props.match.params.contractEffectiveId);
    const base64ToSend = sendBase64 ? base64File : null;

    setLoadingUpdate(true);
    if (base64ToSend) {
      setLoadingSendContractDoc(true);
      sendFirstDocToBuyerBase64(contractEffectiveId, base64ToSend, 'CONTRACTBUYER').then((result) => {
        setFreehands(result);
        if (result.file)
          setSuccessDocModal(true)
        else
          setSuccessWaitingModal(true)
      }).catch((err) => {
        toast(`Error to send documents: ${err.message}`, { className: "toast-error" });
      }).finally(() => {
        setLoadingSendContractDoc(false);
        setChangeClassName('contract_input');
        setLoadingUpdate(false);
      });
    }
    else {
      console.log(data);
      updateContrat(data, base64ToSend, contractEffectiveId)
        .then((data) => {
          setFreehands(data);
          setSuccessModal(true)
        })
        .catch((err) => {
          toast(`Error: ${err.message}`, { className: "toast-error" });
        })
        .finally(() => {
          setLoadingUpdate(false);
        });
    }
  }


  const onSubmitForm = (data, sendBase64 = false) => {

    if (freehands.quotationUserStatus) {
      if (sendBase64) {
        setChangeClassName("contract1_input");
        setNonPayment1(freehands.payment == '1')
        setNonPayment2(freehands.payment == '2')
        setNonPayment3(freehands.payment == '3')
        setNonPayment4(freehands.payment == '4')
        setNonPayment5(freehands.payment == '5')

        if (freehands.quotationUserStatus.baseQuotation.user.language == 'Fr' && freehands.quotationUserStatus.user.language == 'Fr') {
          setLang('fr')
        }

        setTimeout(() => {
          Doc.createPdf(bodyPurchaseRef.current, data, sendBase64, setBase64Contract2);
        }, 2000);
      } else {
        Doc.createPdf(bodyPurchaseRef.current, data, sendBase64, setBase64Contract2);
      }
    }
  };


  useEffect(() => {

    const contractEffectiveId = props.match.params.contractEffectiveId;
    getContratText().then((result) => {
      setContract(result);
    }).catch((err) => {
      toast(`Error to get Contract test: ${err.message}`, { className: "toast-error" });
    });

    getFreehand(contractEffectiveId).then((freehandsResult) => {
      setFreehands(freehandsResult);
      setCurrentRfq(freehandsResult.quotationUserStatus);
      reset({
        freehand_1: freehandsResult.freehand1,
        freehand_2: freehandsResult.freehand2,
        freehand_3: freehandsResult.freehand3,
        freehand_4: freehandsResult.freehand4,
        freehand_5: freehandsResult.freehand5,
        freehand_6: freehandsResult.freehand6,
        freehand_7: freehandsResult.freehand7,
        freehand_8: freehandsResult.freehand8,
        freehand_9: freehandsResult.freehand9,
        freehand_10: freehandsResult.freehand10,
        freehand_11: freehandsResult.freehand11,
        freehand_12: freehandsResult.freehand12,
        freehand_13: freehandsResult.freehand13,
        freehand_14: freehandsResult.freehand14,
        freehand_15: freehandsResult.freehand15,
        freehand_16: freehandsResult.freehand16,
        freehand_17: freehandsResult.freehand17,
        freehand_18: freehandsResult.freehand18,
        freehand_19: freehandsResult.freehand19,
        freehand_20: freehandsResult.freehand20,
        freehand_21: freehandsResult.freehand21,
        freehand_22: freehandsResult.freehand22,
        freehand_23: freehandsResult.freehand23,
        freehand_24: freehandsResult.freehand24,
        freehand_25: freehandsResult.freehand25,
        freehand_26: freehandsResult.freehand26,
        freehand_27: freehandsResult.freehand27,
        freehand_28: freehandsResult.freehand28,
        freehand_29: freehandsResult.freehand29,
        freehand_30: freehandsResult.freehand30,
        freehand_31: freehandsResult.freehand31,
        freehand_32: freehandsResult.freehand32,
        freehand_33: freehandsResult.freehand33,
        freehand_34: freehandsResult.freehand34,
        freehand_35: freehandsResult.freehand35,
        freehand_36: freehandsResult.freehand36,
        freehand_37: freehandsResult.freehand37,
        freehand_38: freehandsResult.freehand38,
        freehand_39: freehandsResult.freehand39,
        freehand_40: freehandsResult.freehand40,
        freehand_41: freehandsResult.freehand41,
        freehand_42: freehandsResult.freehand42,
        freehand_43: freehandsResult.freehand43,
        freehand_44: freehandsResult.freehand44,
        freehand_45: freehandsResult.freehand45,
        freehand_46: freehandsResult.freehand46,
        freehand_47: freehandsResult.freehand47,
        freehand_48: freehandsResult.freehand48,
        freehand_49: freehandsResult.freehand49,
        freehand_50: freehandsResult.freehand50,
        freehand_51: freehandsResult.freehand51,
        freehand_52: freehandsResult.freehand52,
        freehand_53: freehandsResult.freehand53,
        freehand_54: freehandsResult.freehand54,
        freehand_55: freehandsResult.freehand55,
        freehand_56: freehandsResult.freehand56,
        freehand_57: freehandsResult.freehand57,
        freehand_58: freehandsResult.freehand58,
        freehand_59: freehandsResult.freehand59,
        freehand_60: freehandsResult.freehand60,
        freehand_61: freehandsResult.freehand61,
        freehand_62: freehandsResult.freehand62,
        freehand_63: freehandsResult.freehand63,
        freehand_64: freehandsResult.freehand64,
        freehand_65: freehandsResult.freehand65,
        freehand_66: freehandsResult.freehand66,
        freehand_67: freehandsResult.freehand67,
        check1: freehandsResult.check1,
        check2: freehandsResult.check2,
        check3: freehandsResult.check3,
        check4: freehandsResult.check4,
        check5: freehandsResult.check5,
        check6: freehandsResult.check6,
        check7: freehandsResult.check7,
        check8: freehandsResult.check8,
        payment: freehandsResult.payment,
      })
    }).catch((err) => {
      toast(`Error to get Contract test: ${err.message}`, { className: "toast-error" });
    });

  }, [])


  const createPdfTesting = (html) => Doc.createPdfForTest(html);

  //console.log('current_date=>',current_date);
  const createPdfTest = () => {
    createPdfTesting(bodyPurchaseRef.current);
  };

  const findTextByKey = (key) => {
    try {
      return contract.find(c => c.key == key)[lang] + " "
    } catch (err) {
      // console.log(err, key)
    }
  }

  const compareSellerAndBuyerCountry = (qus) => {
    return qus.user.country === qus.quotation.user.country;
  }

  const computeContractTitle = (text, sameCountry, language) => {
    if (language == 'en') {
      if (text.includes('INTERNATIONALE')) {
        text = text.replace('INTERNATIONALE', sameCountry ? 'NATIONAL' : 'INTERNATIONAL');
        return text;
      }
      if (text.includes('NATIONALE')) {
        text = text.replace('NATIONALE', sameCountry ? 'NATIONAL' : 'INTERNATIONAL');
        return text;
      }
      if (text.includes('INTERNATIONAL')) {
        text = text.replace('INTERNATIONAL', sameCountry ? 'NATIONAL' : 'INTERNATIONAL');
        return text;
      }
      if (text.includes('NATIONAL')) {
        text = text.replace('NATIONAL', sameCountry ? 'NATIONAL' : 'INTERNATIONAL');
        return text;
      }
    } else {
      if (text.includes('INTERNATIONALE')) {
        text = text.replace('INTERNATIONALE', sameCountry ? 'NATIONALE' : 'INTERNATIONALE');
        return text;
      }
      if (text.includes('NATIONALE')) {
        text = text.replace('NATIONALE', sameCountry ? 'NATIONALE' : 'INTERNATIONALE');
        return text;
      }
      if (text.includes('INTERNATIONAL')) {
        text = text.replace('INTERNATIONAL', sameCountry ? 'NATIONALE' : 'INTERNATIONALE');
        return text;
      }
      if (text.includes('NATIONAL')) {
        text = text.replace('NATIONAL', sameCountry ? 'NATIONALE' : 'INTERNATIONALE');
        return text;
      }
    }
  }

  const computeCountryText = (text, sameCountry, language) => {
    if (language == 'en') {
      if (text.toLowerCase().includes('internationale')) {
        text = text.toLowerCase().replace('internationale', sameCountry ? 'national' : 'international');
        return text;
      }
      if (text.toLowerCase().includes('nationale')) {
        text = text.toLowerCase().replace('nationale', sameCountry ? 'national' : 'international');
        return text;
      }
      if (text.toLowerCase().includes('international')) {
        text = text.toLowerCase().replace('international', sameCountry ? 'national' : 'international');
        return text;
      }
      if (text.toLowerCase().includes('national')) {
        text = text.toLowerCase().replace('national', sameCountry ? 'national' : 'international');
        return text;
      }
    } else {
      if (text.toLowerCase().includes('internationale')) {
        text = text.toLowerCase().replace('internationale', sameCountry ? 'nationale' : 'internationale');
        return text;
      }
      if (text.toLowerCase().includes('nationale')) {
        text = text.toLowerCase().replace('nationale', sameCountry ? 'nationale' : 'internationale');
        return text;
      }
      if (text.toLowerCase().includes('international')) {
        text = text.toLowerCase().replace('international', sameCountry ? 'nationale' : 'internationale');
        return text;
      }
      if (text.toLowerCase().includes('national')) {
        text = text.toLowerCase().replace('national', sameCountry ? 'nationale' : 'internationale');
        return text;
      }
    }
  }

  return (
    <>
      <section>
        {/* <button onClick={createPdfTest}>Create Contrat Pdf</button> */}
        <p style={{ marginTop: 20, fontSize: '1.2em' }}>
          <strong style={{ marginRight: 20 }}>
            Note:
          </strong>
          {t('notes.save_contract')}
        </p>
      </section>

      {currentRfq ? (contract.length ? (

        <div className="" ref={bodyPurchaseRef} style={{ fontSize: '1.3em', lineHeight: 2 }}>
          <form>
            <section>
              <div
                className="container"
                style={{ fontFamily: "Times New Roman", marginTop: "100px" }}
              >
                <div className="row">
                  <div className="col-md-11">
                    <p
                      style={{ fontStyle: "italic", color: "rgba(0 0 0 0.5)" }}
                    >
                      Sooko Trade Contrat
                    </p>
                  </div>
                  <div className="col-md-1">
                    <span style={{ fontStyle: "italic" }}> 1 </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12" style={{ textAlign: "center" }}>
                    <h2
                      style={{
                        width: "80%",
                        textAlign: "center",
                        marginLeft: "10%",
                        marginTop: "50px",
                        marginBottom: "10px",
                      }}
                    >
                      {computeContractTitle(findTextByKey('CONTRACT_TITLE'), compareSellerAndBuyerCountry(currentRfq), lang)} &nbsp; &nbsp; <strong>{currentRfq.quotation.product.name}</strong>
                    </h2>
                    <hr
                      style={{
                        borderBottom: "solid black 3px",
                        width: "85%",
                        marginTop: "0px",
                        marginBottom: "20px",
                        marginLeft: "15%",
                        marginRight: "0px",
                      }}
                    />
                  </div>
                  <div
                    className="col-md-12"
                    style={{
                      width: "90%",
                      padding: '5%',
                      marginTop: "0px",
                      marginBottom: "20px",
                      marginRight: "0px",
                      color: "black",
                      marginLeft: '3%'
                    }}
                  >
                    <p
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                        marginTop: "40px",
                      }}
                    >
                      PARTIES:
                    </p>
                    <p
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                        marginBottom: "20px",
                      }}
                    >
                      {t('common.seller')}:
                    </p>
                    {/* seller infotmations */}
                    <p>
                      {findTextByKey('COMPANY_NAME')}:</p>
                    <p style={{ fontWeight: 'bold' }}>  {currentRfq.user ? currentRfq.user.companyName : ""}
                    </p>
                    <p>{findTextByKey('LEGAL_FORM')}:</p>
                    <p style={{ fontWeight: 'bold' }}>{currentRfq.user ? currentRfq.user.companyName : ""}</p>
                    <p>
                      {findTextByKey('COUNTRY_OF_INCORPORATION')}:
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                      {currentRfq.user ? CountryManager.getNameFromId(currentRfq.user.country) : ''}
                    </p>
                    <p>
                      {findTextByKey('ADDRESS')}:
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                      {currentRfq.user.streetAddress}<br />
                      {currentRfq.user.city}, {currentRfq.user.province} {currentRfq.user.postal} <br />
                      {CountryManager.getNameFromId(currentRfq.user.country)}<br />
                    </p>

                    <p>
                      {findTextByKey('REPRESENTED_BY')}:
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                      {currentRfq.user.keyContactName}
                    </p>

                    <p>
                      {findTextByKey('EXPORTER_LICENCE')}:
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                      {currentRfq.user.seller.exporterLicenceNumber}
                    </p>

                    <p
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                        marginBottom: "20px",
                      }}
                    >
                      {t('common.buyer')}:
                    </p>
                    <p>
                      {findTextByKey('COMPANY_NAME')}:
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                      {currentRfq.baseQuotation.user.companyName}
                    </p>

                    <p>{findTextByKey('LEGAL_FORM')}:</p>
                    <p style={{ fontWeight: 'bold' }}>{currentRfq.baseQuotation.user.companyName}</p>
                    <p>
                      {findTextByKey('COUNTRY_OF_INCORPORATION')}:
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                      {currentRfq.baseQuotation.user ? CountryManager.getNameFromId(currentRfq.baseQuotation.user.country) : ''}
                    </p>
                    <p>
                      {findTextByKey('ADDRESS')}:
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                      {currentRfq.baseQuotation.user.streetAddress}<br />
                      {currentRfq.baseQuotation.user.city}, {currentRfq.baseQuotation.user.province} {currentRfq.baseQuotation.user.postal} <br />
                      {CountryManager.getNameFromId(currentRfq.baseQuotation.user.country)}<br />
                    </p>

                    <p>
                      {findTextByKey('REPRESENTED_BY')}:
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                      {currentRfq.baseQuotation.user.keyContactName}
                    </p>
                    <p>
                      {findTextByKey('IMPORTER_LICENCE')} :
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                      {currentRfq.baseQuotation.user.buyer.importerLicenceNumber}
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div
                className="container"
                style={{ fontFamily: "Times New Roman" }}
              >
                <div className="row">
                  <div
                    className="col-md-12"
                    style={{
                      width: "90%",
                      padding: '5%',
                      marginTop: "0px",
                      marginBottom: "20px",
                      marginRight: "0px",
                      color: "black",
                      marginLeft: '3%'
                    }}
                  >
                    <h1 className="level1-title-number"> 1. {currentRfq.quotation.product.name}</h1>
                    <div className="level2-title-content">
                      <span className="level2-title-number">1.1</span>
                      {findTextByKey('SECTION1_1.1_1')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION1_1.1_2')}
                    </div>

                    <div className="level2-title-content">

                      <span className="level2-title-number">

                        1.2
                      </span>
                      {findTextByKey('SECTION1_1.2_1')}
                      <strong>{currentRfq.quotation.product.name}</strong>
                      {findTextByKey('SECTION1_1.2_2')}
                      <strong>{currentRfq.quotation.product.name}</strong> &nbsp;
                      {findTextByKey('SECTION1_1.2_3')}
                      <p style={{ fontWeight: 'bold' }}>
                        Description: {currentRfq.quotation.product.description}
                      </p>
                      <p style={{ fontWeight: 'bold' }}>
                        Country of origin: {CountryManager.getNameFromId(currentRfq.quotation.product.countryOfOrigin)}
                      </p>
                    </div>

                    <div className="level2-title-content">

                      <span className="level2-title-number">

                        1.3
                      </span>
                      {findTextByKey('SECTION1_1.3_1')}
                      <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION1_1.3_2')}
                    </div>
                    <div className="level2-title-content" style={{ fontWeight: 'bold' }}>
                      <span className="level2-title-number">
                        1.3.1
                      </span>
                      {findTextByKey('SECTION1_1.3_3')}:
                      {currentRfq.quotation.quantity}
                    </div>
                    <div className="level2-title-content">
                      <span className="level2-title-number">
                        1.3.2
                      </span>
                      {findTextByKey('SECTION1_1.3_4')}
                      <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION1_1.3_5')} <strong>{freehands.freehand1 ? freehands.freehand1 : 'XXX'}</strong>

                    </div>
                    <textarea
                      type="text"
                      name="freehand_1"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    >
                    </textarea>
                    <div className="level2-title-content">
                      <span className="level2-title-number">
                        1.3.3
                      </span>
                      {findTextByKey('SECTION1_1.3_6')} <strong>{freehands.freehand2 ? freehands.freehand2 : 'XXX'}</strong> {findTextByKey('SECTION1_1.3_7')}
                    </div>
                    <input
                      type="number"
                      name="freehand_2"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    />
                    <div className="level2-title-content">
                      <span className="level2-title-number">1.4</span>
                      {findTextByKey('SECTION1_1.4_1')}
                      <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION1_1.4_2')}
                    </div>
                    <div className="level2-title-content">
                      <span className="level2-title-number">1.5</span>
                      {findTextByKey('SECTION1_1.4_3')}:
                      <strong>
                        {currentRfq.quotation.packagingDetails}
                      </strong>
                    </div>
                    <div className="level2-title-content">
                      <span className="level2-title-number">1.6</span>
                      {findTextByKey('SECTION1_1.4_4')}
                      <strong>
                        {currentRfq.quotation.product.qualitySpecification}
                      </strong>
                    </div>

                    <h1 className="level1-title-number">2. {findTextByKey('SECTION2_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">2.1</span>
                      {computeCountryText(findTextByKey('SECTION2_2.1'), compareSellerAndBuyerCountry(currentRfq), lang)}:
                      <strong>
                        {currentRfq.quotation.incoterm.name}
                      </strong>
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">2.2 </span>
                      {findTextByKey('SECTION2_2.2')}: <strong>{currentRfq.quotation.port ? currentRfq.quotation.port.name : currentRfq.quotation.arrival}</strong>
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">2.3</span>
                      {findTextByKey('SECTION2_2.3_1')}
                      {findTextByKey(26)} <strong>{freehands.freehand3 ? freehands.freehand3 : 'XXX'}</strong>
                    </div>
                    <textarea
                      type="date"
                      name="freehand_3"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    ></textarea>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION2_2.3_2')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">2.4 </span>
                      {findTextByKey('SECTION2_2.4')} <strong>{freehands.freehand4 ? freehands.freehand4 : 'XXX'}</strong>
                    </div>
                    <textarea
                      type="text"
                      name="freehand_4"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    ></textarea>


                    <div className="level2-title-content">
                      <span className="level2-title-number">2.5</span>
                      {findTextByKey('SECTION2_2.5')}:
                      <strong>{currentRfq.quotation.typeOfShipment ? currentRfq.quotation.typeOfShipment.name : ''}</strong>
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">2.6 </span>
                      {findTextByKey('SECTION2_2.6')} <strong>{freehands.freehand5 ? freehands.freehand5 : 'XXX'}</strong>
                    </div>
                    <textarea
                      type="text"
                      name="freehand_5"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    ></textarea>

                    <h1 className="level1-title-number">3. {findTextByKey('SECTION3_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">3.1</span>
                      {findTextByKey('SECTION3_3.1')}:
                      <strong>
                        {currentRfq.quotation.quantity * currentRfq.quotation.price}
                      </strong>
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">3.2</span>
                      {findTextByKey('SECTION3_3.2')}:
                      <strong>
                        {currentRfq.quotation.price}
                      </strong>

                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">3.3</span>
                      {findTextByKey('SECTION3_3.3')}:
                      <strong>
                        {currentRfq.quotation.quantity * currentRfq.quotation.price}
                      </strong>
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">3.4 </span>
                      {findTextByKey('SECTION3_3.4')} <strong>{freehands.freehand6 ? freehands.freehand6 : 'XXX'}</strong>
                    </div>
                    <textarea
                      type="text"
                      name="freehand_6"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    ></textarea>


                    <div className="level2-title-content">
                      <span className="level2-title-number">3.5 </span>
                      {findTextByKey('SECTION3_3.5')}:
                      <strong>
                        {currentRfq.quotation.currency}
                      </strong>
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">3.6</span>
                      {findTextByKey('SECTION3_3.6')}.
                    </div>
                    <textarea
                      type="text"
                      name="freehand_7"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    ></textarea>

                    <h1 className="level1-title-number">4. {findTextByKey('SECTION4_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">4.1</span>
                      {findTextByKey('SECTION4_4.1')}:
                      <strong>
                        {currentRfq.quotation.product.payementOption}
                      </strong>

                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">4.2 </span>
                      {findTextByKey('SECTION4_4.2')} <strong>{freehands.freehand8 ? freehands.freehand8 : 'XXX'}</strong>
                    </div>
                    <textarea
                      type="text"
                      name="freehand_8"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    ></textarea>


                    <div className="level2-title-content">
                      <span className="level2-title-number">4.3</span>
                      {findTextByKey('SECTION4_4.3_1')} <strong>{freehands.freehand9 ? freehands.freehand9 : 'XXX'}</strong>
                    </div>
                    <input
                      type="date"
                      name="freehand_9"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    />
                    <br />


                    <div className="level2-title-content">
                      {findTextByKey('SECTION4_4.3_2')}
                    </div>

                    {
                      nonPayment1 ?
                        <>
                          <div className="level2-title-content">

                            <input type="radio" id="1" name="payment" ref={register} value="1" />
                            {findTextByKey('SECTION4_4.3_3')}
                          </div>
                          <div className="level2-title-content"> {findTextByKey('SECTION4_4.3_4')} <strong>{freehands.freehand10 ? freehands.freehand10 : 'XXX'}</strong>
                          </div>
                          <textarea
                            type="text"
                            name="freehand_10"
                            className={changeClassName}
                            ref={register}
                            style={{ width: '90%' }}
                          ></textarea>
                          <div className="level2-title-content">
                            {findTextByKey('SECTION4_4.3_5')} <strong>{freehands.freehand11 ? freehands.freehand11 : 'XXX'}</strong>
                          </div>
                          <input
                            type="date"
                            name="freehand_11"
                            className={changeClassName}
                            ref={register}
                            style={{ width: '90%' }}
                          />
                          <div className="level2-title-content">
                            {findTextByKey('SECTION4_4.3_6')} <strong>{freehands.freehand12 ? freehands.freehand12 : 'XXX'}</strong>
                          </div>
                          <textarea
                            type="text"
                            name="freehand_12"
                            className={changeClassName}
                            ref={register}
                            style={{ width: '90%' }}
                          ></textarea>
                        </>
                        : null}

                    {nonPayment2 ?
                      <>
                        <div className="level2-title-content">

                          <input type="radio" id="2" name="payment" ref={register} value="2" />
                          {findTextByKey('SECTION4_4.3_7')}
                        </div>
                        <div className="level2-title-content">
                          {findTextByKey('SECTION4_4.3_8')} <strong>{freehands.freehand13 ? freehands.freehand13 : 'XXX'}</strong>
                        </div>
                        <textarea
                          type="text"
                          name="freehand_13"
                          className={changeClassName}
                          ref={register}
                          style={{ width: '90%' }}
                        ></textarea>
                        <div className="level2-title-content">
                          {findTextByKey('SECTION4_4.3_9')} <strong>{freehands.freehand14 ? freehands.freehand14 : 'XXX'}</strong>
                        </div>
                        <input
                          type="date"
                          name="freehand_14"
                          className={changeClassName}
                          ref={register}
                          style={{ width: '90%' }}
                        />
                        <div className="level2-title-content">
                          {findTextByKey('SECTION4_4.3_10')}: <strong>{freehands.freehand15 ? freehands.freehand15 : 'XXX'}</strong>
                        </div>
                        <textarea
                          type="text"
                          name="freehand_15"
                          className={changeClassName}
                          ref={register}
                          style={{ width: '90%' }}
                        ></textarea>
                        <div className="level2-title-content">
                          {findTextByKey('SECTION4_4.3_11')}
                        </div>
                        <div className="level2-title-content">
                          {computeCountryText(findTextByKey('SECTION4_4.3_12'), compareSellerAndBuyerCountry(currentRfq), lang)}
                        </div>
                      </>
                      : null}
                    {
                      nonPayment3 ?
                        <>
                          <div className="level2-title-content">
                            <input type="radio" id="3" name="payment" ref={register} value="3" /> {findTextByKey('SECTION4_4.3_13')}
                          </div>
                          <div className="level2-title-content">
                            {computeCountryText(findTextByKey('SECTION4_4.3_14'), compareSellerAndBuyerCountry(currentRfq), lang)}
                          </div>
                          <div className="level2-title-content">
                            {findTextByKey('SECTION4_4.3_15')}: <strong>{freehands.freehand16 ? freehands.freehand16 : 'XXX'}</strong>
                          </div>
                          <input
                            type="date"
                            name="freehand_16"
                            className={changeClassName}
                            ref={register}
                            style={{ width: '90%' }}
                          />
                          <div className="level2-title-content">
                            {findTextByKey('SECTION4_4.3_16')}:  <strong>{freehands.freehand17 ? freehands.freehand17 : 'XXX'}</strong>
                          </div>
                          <textarea
                            type="text"
                            name="freehand_17"
                            className={changeClassName}
                            ref={register}
                            style={{ width: '90%' }}
                          ></textarea>
                          <div className="level2-title-content">
                            {findTextByKey('SECTION4_4.3_17')}:  <strong>{freehands.freehand18 ? freehands.freehand18 : 'XXX'}</strong>
                          </div>
                          <textarea
                            type="text"
                            name="freehand_18"
                            className={changeClassName}
                            ref={register}
                            style={{ width: '90%' }}
                          ></textarea>
                          <div className="level2-title-content">
                            {findTextByKey('SECTION4_4.3_18')}: <strong>{freehands.freehand19 ? freehands.freehand19 : 'XXX'}</strong>
                          </div>
                          <textarea
                            type="text"
                            name="freehand_19"
                            className={changeClassName}
                            ref={register}
                            style={{ width: '90%' }}
                          ></textarea>
                          <div className="level2-title-content">
                            {findTextByKey('SECTION4_4.3_19')}: <strong>{freehands.freehand20 ? freehands.freehand20 : 'XXX'}</strong>
                          </div>
                          <textarea
                            type="text"
                            name="freehand_20"
                            className={changeClassName}
                            ref={register}
                            style={{ width: '90%' }}
                          ></textarea>

                        </>
                        : null}
                    {
                      nonPayment4 ?
                        <>
                          <div className="level2-title-content">
                            <input type="radio" id="4" name="payment" ref={register} value="4" /> {findTextByKey('SECTION4_4.3_20')}
                          </div>
                          <div className="level2-title-content">
                            {findTextByKey('SECTION4_4.3_21')}
                          </div>
                        </>
                        : null}
                    {
                      nonPayment5 ?
                        <>
                          <div className="level2-title-content">
                            <input type="radio" id="5" name="payment" ref={register} value="5" /> {findTextByKey('SECTION4_4.3_22')} <strong>{freehands.freehand21 ? freehands.freehand21 : 'XXX'}</strong>
                          </div>
                          <textarea
                            type="text"
                            name="freehand_21"
                            className={changeClassName}
                            ref={register}
                            style={{ width: '90%' }}
                          ></textarea>

                        </>
                        : null}

                    <h1 className="level1-title-number">5. {findTextByKey('SECTION5_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">5.1</span>
                      {findTextByKey('SECTION5_5.1_1')}
                      <ul style={{ marginLeft: "3%" }}>

                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="check1" name="check1" ref={register} />
                          <label className="custom-control-label" htmlFor="check1" style={{ fontWeight: 'normal', fontSize: '1.1em' }}>{findTextByKey('SECTION5_5.1_2')} <strong>{freehands.freehand22 ? freehands.freehand22 : 'XXX'}</strong></label>
                        </div>
                        <textarea
                          type="text"
                          name="freehand_22"
                          className={changeClassName}
                          ref={register}
                          style={{ width: '90%' }}
                        ></textarea>

                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="check2" name="check2" ref={register} />
                          <label className="custom-control-label" htmlFor="check2" style={{ fontWeight: 'normal', fontSize: '1.1em' }}>{findTextByKey('SECTION5_5.1_3')}.</label>
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="check3" name="check3" ref={register} />
                          <label className="custom-control-label" htmlFor="check3" style={{ fontWeight: 'normal', fontSize: '1.1em' }}>{findTextByKey('SECTION5_5.1_4')} <strong>{freehands.freehand23 ? freehands.freehand23 : 'XXX'}</strong></label>
                        </div>
                        <textarea
                          type="text"
                          name="freehand_23"
                          className={changeClassName}
                          ref={register}
                          style={{ width: '90%' }}
                        ></textarea>

                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="check4" name="check4" ref={register} />
                          <label className="custom-control-label" htmlFor="check4" style={{ fontWeight: 'normal', fontSize: '1.1em' }}>{findTextByKey('SECTION5_5.1_5')} <strong>{freehands.freehand24 ? freehands.freehand24 : 'XXX'}</strong></label>
                        </div>
                        <textarea
                          type="text"
                          name="freehand_24"
                          className={changeClassName}
                          ref={register}
                          style={{ width: '90%' }}
                        ></textarea>

                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="check5" name="check5" ref={register} />
                          <label className="custom-control-label" htmlFor="check5" style={{ fontWeight: 'normal', fontSize: '1.1em' }}>{findTextByKey('SECTION5_5.1_6')} <strong>{freehands.freehand25 ? freehands.freehand25 : 'XXX'}</strong></label>
                        </div>
                        <textarea
                          type="text"
                          name="freehand_25"
                          className={changeClassName}
                          ref={register}
                          style={{ width: '90%' }}
                        ></textarea>


                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="check6" name="check6" ref={register} />
                          <label className="custom-control-label" htmlFor="check6" style={{ fontWeight: 'normal', fontSize: '1.1em' }}>{findTextByKey('SECTION5_5.1_7')} <strong>{freehands.freehand26 ? freehands.freehand26 : 'XXX'}</strong></label>
                        </div>
                        <textarea
                          type="text"
                          name="freehand_26"
                          className={changeClassName}
                          ref={register}
                          style={{ width: '90%' }}
                        ></textarea>


                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="check7" name="check7" ref={register} />
                          <label className="custom-control-label" htmlFor="check7" style={{ fontWeight: 'normal', fontSize: '1.1em' }}>{findTextByKey('SECTION5_5.1_8')} <strong>{freehands.freehand27 ? freehands.freehand27 : 'XXX'}</strong></label>
                        </div>
                        <textarea
                          type="text"
                          name="freehand_27"
                          className={changeClassName}
                          ref={register}
                          style={{ width: '90%' }}
                        ></textarea>


                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="check8" name="check8" ref={register} />
                          <label className="custom-control-label" htmlFor="check8" style={{ fontWeight: 'normal', fontSize: '1.1em' }}>{findTextByKey('SECTION5_5.1_9')} <strong>{freehands.freehand28 ? freehands.freehand28 : 'XXX'}</strong></label>
                        </div>
                        <textarea
                          type="text"
                          name="freehand_28"
                          className={changeClassName}
                          ref={register}
                          style={{ width: '90%' }}
                        ></textarea>

                      </ul>
                    </div>

                    <div className="level2-title-content">
                      <span className="level2-title-number">5.2</span>
                      {findTextByKey('SECTION5_5.2')}
                    </div>

                    <h1 className="level1-title-number">6. {findTextByKey('SECTION6_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">6.1</span>
                      {findTextByKey('SECTION6_6.1_1')} <strong>{freehands.freehand29 ? freehands.freehand29 : 'XXX'}</strong>
                    </div>
                    <textarea
                      type="text"
                      name="freehand_29"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    ></textarea>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION6_6.1_2')}
                    </div>



                    <div className="level2-title-content">
                      <span className="level2-title-number">6.2</span>
                      {findTextByKey('SECTION6_6.2_1')} <strong>{freehands.freehand30 ? freehands.freehand30 : 'XXX'}</strong>  {findTextByKey('SECTION6_6.2_2')}
                    </div>
                    <textarea
                      type="text"
                      name="freehand_30"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    ></textarea>

                    <div className="level2-title-content">
                      {findTextByKey('SECTION6_6.2_3')}
                    </div>

                    <h1 className="level1-title-number">7. {findTextByKey('SECTION7_TITLE_1')} {currentRfq.quotation.product.name}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">7.1</span>
                      {findTextByKey('SECTION7_TITLE_2')}
                      <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION7_7.1_1')} <strong>{freehands.freehand31 ? freehands.freehand31 : 'XXX'}</strong>
                    </div>
                    <textarea
                      type="text"
                      name="freehand_31"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    ></textarea>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION7_7.1_3')} <br />
                      {findTextByKey('SECTION7_7.1_4')}
                    </div>

                    <div className="level2-title-content">
                      <span className="level2-title-number">7.2</span>
                      {findTextByKey('SECTION7_7.2_1')} &nbsp;
                      <strong>{freehands.freehand32 ? freehands.freehand32 : 'XXX'}</strong> &nbsp; {findTextByKey('SECTION7_7.2_2')}
                    </div>
                    <textarea
                      type="text"
                      name="freehand_32"
                      className={changeClassName}
                      ref={register}
                      style={{ width: '90%' }}
                    ></textarea>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION7_7.2_3')} &nbsp; <strong>{freehands.freehand33 ? freehands.freehand33 : 'XXX'}</strong>

                      <textarea
                        type="text"
                        name="freehand_33"
                        className={changeClassName}
                        ref={register}
                        style={{ width: '90%' }}
                      ></textarea>

                      {findTextByKey('SECTION7_7.2_4')} &nbsp; <strong>{freehands.freehand34 ? freehands.freehand34 : 'XXX'}</strong>

                      <textarea
                        type="text"
                        name="freehand_34"
                        className={changeClassName}
                        ref={register}
                        style={{ width: '90%' }}
                      ></textarea>

                      {findTextByKey('SECTION7_7.2_5')} &nbsp; <strong>{freehands.freehand35 ? freehands.freehand35 : 'XXX'}</strong> &nbsp;  {findTextByKey('SECTION7_7.2_6')}

                      <textarea
                        type="text"
                        name="freehand_35"
                        className={changeClassName}
                        ref={register}
                        style={{ width: '90%' }}
                      ></textarea>
                    </div>

                    <h1 className="level1-title-number">8. {findTextByKey('SECTION8_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">8.1 </span>
                      {findTextByKey('SECTION8_1.1_1')}

                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          8.1.1
                        </span>
                        {findTextByKey('SECTION8_1.1_2')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_1.1_3')}
                      </div>


                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          8.1.2
                        </span>
                        <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_1.1_4')}
                      </div>


                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          8.1.3
                        </span>
                        <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_1.1_5')}
                      </div>


                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          8.1.4
                        </span>
                        <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_1.1_6')}
                      </div>


                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          8.1.5
                        </span>
                        <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_1.1_7')}
                      </div>


                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          8.1.6
                        </span>
                        <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_1.1_8')}
                      </div>


                    </div>


                    <div className="level2-title-content">
                      {findTextByKey('SECTION8_1.1_9')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">8.2</span>
                      {findTextByKey('SECTION8_8.2_1')}
                    </div>


                    <div className="level2-title-content">
                      {findTextByKey('SECTION8_8.2_2')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_8.2_3')}
                    </div>


                    <div className="level2-title-content">
                      {findTextByKey('SECTION8_8.2_4')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_8.2_5')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">8.3</span>
                      {findTextByKey('SECTION8_8.3')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">8.4</span>
                      {findTextByKey('SECTION8_8.4_1')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_8.4_2')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_8.4_3')}
                      <strong>{freehands.freehand36 ? freehands.freehand36 : 'XXX'}</strong>
                    </div>

                    <textarea
                      type="text"
                      name="freehand_36"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>
                    {findTextByKey('SECTION8_8.4_4')} <strong>{freehands.freehand37 ? freehands.freehand37 : 'XXX'}</strong> {findTextByKey('SECTION8_8.4_5')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_8.4_6')}
                    <textarea
                      type="text"
                      name="freehand_37"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>


                    <div className="level2-title-content">
                      <span className="level2-title-number">8.5</span>
                      {findTextByKey('SECTION8_8.5_1')}

                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          8.5.1
                        </span>
                        {findTextByKey('SECTION8_8.5_2')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_8.5_3')}
                      </div>


                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          8.5.2
                        </span>
                        {findTextByKey('SECTION8_8.5_4')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_8.5_5')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_8.5_6')}
                      </div>


                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          8.5.3
                        </span>
                        {findTextByKey('SECTION8_8.5_7')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_8.5_8')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_8.5_9')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION8_8.5_10')}
                      </div>


                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          8.5.4
                        </span>
                        {findTextByKey('SECTION8_8.5_11')}
                      </div>


                      {findTextByKey('SECTION8_8.5_12')}
                    </div>

                    <h1 className="level1-title-number">9. {findTextByKey('SECTION9_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">9.1 </span>
                      {findTextByKey('SECTION9_9.1_1')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION9_9.1_2')} <strong>{freehands.freehand38 ? freehands.freehand38 : 'XXX'}</strong> {findTextByKey('SECTION9_9.1_3')} <strong>{currentRfq.quotation.product.name}</strong>
                    </div>

                    <textarea
                      type="text"
                      name="freehand_38"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>


                    <div className="level2-title-content">
                      <span className="level2-title-number">9.2</span>
                      {findTextByKey('SECTION9_9.2_1')} <strong>{freehands.freehand39 ? freehands.freehand39 : 'XXX'}</strong> {findTextByKey('SECTION9_9.2_2')}
                    </div>
                    <textarea
                      type="text"
                      name="freehand_39"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>


                    <div className="level2-title-content">
                      <span className="level2-title-number">9.3</span>
                      {findTextByKey('SECTION9_9.3')} <strong>{currentRfq.quotation.product.name}</strong>.
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">9.4 </span>
                      {findTextByKey('SECTION9_9.4_1')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION9_9.4_2')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">9.5</span>
                      {findTextByKey('SECTION9_9.5_1')} <strong>{freehands.freehand40 ? freehands.freehand40 : 'XXX'}</strong> {findTextByKey('SECTION9_9.5_2')}
                    </div>
                    <textarea
                      type="text"
                      name="freehand_40"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>
                    <div className="level2-title-content">
                      {findTextByKey(331)}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">9.6</span>
                      {findTextByKey('SECTION9_9.6_1')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION9_9.6_2')}
                    </div>

                    <h1 className="level1-title-number">10. {findTextByKey('SECTION10_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">10.1</span>
                      {findTextByKey('SECTION10_10.1_1')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION10_10.1_2')}
                    </div>

                    <div className="level2-title-content">
                      {findTextByKey('SECTION10_10.1_3')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION10_10.1_4')}
                    </div>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION10_10.1_5')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION10_10.1_6')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION10_10.1_7')}
                      <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION10_10.1_8')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION10_10.1_9')}
                    </div>

                    <div className="level2-title-content">
                      <span className="level2-title-number">10.2 </span>
                      <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION10_10.2_1')}
                    </div>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION10_10.2_2')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION10_10.2_3')} <strong>{freehands.freehand41 ? freehands.freehand41 : 'XXX'}</strong> {findTextByKey('SECTION10_10.2_4')}
                    </div>
                    <textarea
                      type="text"
                      name="freehand_41"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>


                    <div className="level2-title-content">
                      <span className="level2-title-number">10.3 </span>
                      {findTextByKey('SECTION10_10.3')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">10.4 </span>
                      {findTextByKey('SECTION10_10.4')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">10.5 </span>
                      {findTextByKey('SECTION10_10.5_1')} <strong>{freehands.freehand42 ? freehands.freehand42 : 'XXX'}</strong> {findTextByKey('SECTION10_10.5_2')}]
                    </div>
                    <textarea
                      type="text"
                      name="freehand_42"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>


                    <div className="level2-title-content">
                      <span className="level2-title-number">10.6 </span>
                      {findTextByKey('SECTION10_10.6_1')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION10_10.6_2')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION10_10.6_3')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">10.7</span>
                      {findTextByKey('SECTION10_10.7_1')} <strong>{freehands.freehand43 ? freehands.freehand43 : 'XXX'}</strong> {findTextByKey('SECTION10_10.7_2')} <strong>{currentRfq.quotation.product.name}</strong>
                    </div>
                    <textarea
                      type="text"
                      name="freehand_43"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>

                    <h1 className="level1-title-number">11. {findTextByKey('SECTION11_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">11.1</span>
                      {findTextByKey('SECTION11_11.1')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">11.2</span>
                      {findTextByKey('SECTION11_11.2_1')}

                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          11.2.1
                        </span>
                        {findTextByKey('SECTION11_11.2_2')}
                      </div>


                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          11.2.2
                        </span>
                        {findTextByKey('SECTION11_11.2_3')}
                      </div>


                      {findTextByKey('SECTION11_11.2_4')}
                    </div>

                    <div className="level2-title-content">
                      {findTextByKey('SECTION11_11.2_5')}
                    </div>

                    <div className="level2-title-content">
                      <span className="level2-title-number">11.3</span>
                      {findTextByKey('SECTION11_11.3_1')} <strong>{freehands.freehand44 ? freehands.freehand44 : 'XXX'}</strong> {findTextByKey('SECTION11_11.3_2')}
                    </div>
                    <textarea
                      type="text"
                      name="freehand_44"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>
                    <div className="level2-title-content">
                      {findTextByKey(130)}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">11.4</span>
                      {findTextByKey('SECTION11_11.4')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">11.5</span>
                      {findTextByKey('SECTION11_11.5')}
                    </div>

                    <h1 className="level1-title-number">12. {findTextByKey('SECTION12_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">12.1</span>
                      {findTextByKey('SECTION12_12.1')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">12.2</span>
                      {findTextByKey('SECTION12_12.2')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">12.3</span>
                      {findTextByKey('SECTION12_12.3')}
                    </div>

                    <h1 className="level1-title-number">13. {findTextByKey('SECTION13_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">13.1</span>
                      {findTextByKey('SECTION13_13.1')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">13.2</span>
                      {findTextByKey('SECTION13_13.2')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">13.3</span>
                      {findTextByKey('SECTION13_13.3')}.
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">13.4</span>
                      {findTextByKey('SECTION13_13.4_1')}

                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          13.4.1
                        </span>
                        {findTextByKey('SECTION13_13.4_2')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey("SECTION13_13.4_3")}
                      </div>


                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          13.4.2
                        </span>
                        {findTextByKey('SECTION13_13.4_4')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION13_13.4_5')}
                      </div>


                    </div>

                    <h1 className="level1-title-number">14. {findTextByKey('SECTION14_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">14.1</span>
                      {findTextByKey('SECTION14_14.1')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">14.2 </span>
                      {findTextByKey('SECTION14_14.2')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">14.3</span>
                      {findTextByKey('SECTION14_14.3_1')}
                      <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION14_14.3_2')}
                      <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION14_14.3_3')}
                      <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION14_14.3_4')}
                      <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION14_14.3_5')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">14.4</span>
                      {findTextByKey('SECTION14_14.4_1')} <strong>{freehands.freehand45 ? freehands.freehand45 : 'XXX'}</strong> {findTextByKey('SECTION14_14.4_2')} <strong>{currentRfq.quotation.product.name}</strong> {findTextByKey('SECTION14_14.4_3')} </div>
                    <textarea
                      type="text"
                      name="freehand_45"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>


                    <div className="level2-title-content">
                      <span className="level2-title-number">14.5</span>
                      {findTextByKey('SECTION14_14.5')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">14.6</span>
                      {findTextByKey('SECTION14_14.6')} <strong>{freehands.freehand46 ? freehands.freehand46 : 'XXX'}</strong></div>
                    <textarea
                      type="text"
                      name="freehand_46"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea> .


                    <div className="level2-title-content">
                      <span className="level2-title-number">14.7</span>
                      {findTextByKey('SECTION14_14.7')} <strong>{freehands.freehand47 ? freehands.freehand47 : 'XXX'}</strong>
                    </div>
                    <textarea
                      type="text"
                      name="freehand_47"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>

                    <h1 className="level1-title-number">15. {findTextByKey('SECTION15_TITLE')}</h1>
                    <div
                      className="level2-title-content"
                      style={{
                        marginLeft: "3%",
                        marginTop: "2%",
                        marginBottom: "2%",
                      }}
                    >
                      {findTextByKey('SECTION15_15.1')}
                    </div>

                    <h1 className="level1-title-number">16. {findTextByKey('SECTION16_TITLE')}</h1>
                    <div
                      className="level2-title-content"
                      style={{
                        marginLeft: "3%",
                        marginTop: "2%",
                        marginBottom: "2%",
                      }}
                    >
                      {findTextByKey('SECTION16_16.1_1')}

                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          16.1
                        </span>
                        {findTextByKey('SECTION16_16.1_2')}
                      </div>


                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          16.2
                        </span>
                        {findTextByKey('SECTION16_16.2_1')}

                        <div className="level2-title-content">
                          <span className="level2-title-number">
                            16.2.1
                          </span>
                          {findTextByKey('SECTION16_16.2_2')}
                        </div>


                        <div className="level2-title-content">
                          <span className="level2-title-number">
                            16.2.2
                          </span>
                          {findTextByKey('SECTION16_16.2_3')}
                        </div>


                        <div className="level2-title-content">
                          <span className="level2-title-number">
                            16.2.3
                          </span>
                          {findTextByKey('SECTION16_16.2_4')}
                        </div>


                        <div className="level2-title-content">
                          <span className="level2-title-number">
                            16.2.4
                          </span>
                          {findTextByKey('SECTION16_16.2_5')}
                        </div>


                        {findTextByKey('SECTION16_16.2_6')}
                      </div>


                      <div className="level2-title-content">
                        <span className="level2-title-number">
                          16.3
                        </span>
                        {findTextByKey('SECTION16_16.3_1')} <strong>{freehands.freehand48 ? freehands.freehand48 : 'XXX'}</strong>
                        <textarea
                          type="text"
                          name="freehand_48"
                          className={changeClassName}
                          ref={register}
                          style={{ width: "90%" }}
                        ></textarea>
                        {findTextByKey('SECTION16_16.3_2')} <strong>{freehands.freehand49 ? freehands.freehand49 : 'XXX'}</strong> {findTextByKey('SECTION16_16.3_3')}
                        <textarea
                          type="text"
                          name="freehand_49"
                          className={changeClassName}
                          ref={register}
                          style={{ width: "90%" }}
                        ></textarea>
                      </div>


                    </div>

                    <h1 className="level1-title-number">17. {findTextByKey('SECTION17_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">17.1</span>
                      {findTextByKey('SECTION17_17.1')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">17.2 </span>
                      {findTextByKey('SECTION17_17.2')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">17.3 </span>
                      {findTextByKey('SECTION17_17.3')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">17.4 </span>
                      {findTextByKey('SECTION17_17.4_1')} <strong>{freehands.freehand50 ? freehands.freehand50 : 'XXX'}</strong> {findTextByKey('SECTION17_17.4_2')}</div>
                    <textarea
                      type="text"
                      name="freehand_50"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION17_17.4_3')} </div>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION17_17.4_4')}
                      {findTextByKey(168)} <strong>{freehands.freehand51 ? freehands.freehand51 : 'XXX'}</strong>
                      <textarea
                        type="text"
                        name="freehand_51"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea>
                      {findTextByKey('SECTION17_17.4_5')} <strong>{freehands.freehand52 ? freehands.freehand52 : 'XXX'}</strong> {findTextByKey('SECTION17_17.4_6')}
                      <textarea
                        type="text"
                        name="freehand_52"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea>
                    </div>

                    <h1 className="level1-title-number">18. {findTextByKey('SECTION18_TITLE.')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">18.1</span>
                      {findTextByKey('SECTION18_18.1')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">18.2</span>
                      {findTextByKey('SECTION18_18.2')}
                    </div>

                    <h1 className="level1-title-number">19. {findTextByKey('SECTION19_TITLE')} </h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">19.1</span>
                      {findTextByKey('SECTION19_19.1')}
                    </div>


                    <div className="level2-title-content">
                      <span className="level2-title-number">19.2</span>
                      {findTextByKey('SECTION19_19.2')} <br />

                      <strong>{freehands.freehand53 ? freehands.freehand53 : 'XXX'}</strong><br />
                      <textarea
                        type="text"
                        name="freehand_53"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea> <br />
                      <strong>{freehands.freehand54 ? freehands.freehand54 : 'XXX'}</strong><br />
                      <textarea
                        type="text"
                        name="freehand_54"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea>
                    </div>

                    <h1 className="level1-title-number">20. {findTextByKey('SECTION20_TITLE')}</h1>

                    <div className="level2-title-content">
                      {findTextByKey('SECTION20.1')}
                    </div>

                    <h1 className="level1-title-number">21. {findTextByKey('SECTION21')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">21.1</span>
                      {findTextByKey('SECTION21.21.1')} <strong>{freehands.freehand55 ? freehands.freehand55 : 'XXX'}</strong>]</div>
                    <textarea
                      type="text"
                      name="freehand_55"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>


                    <div className="level2-title-content">
                      <span className="level2-title-number">21.2</span>
                      {findTextByKey('SECTION21.21.2')}
                    </div>

                    <h1 className="level1-title-number">22. {findTextByKey('SECTION22_TITLE')}</h1>
                    <div
                      className="level2-title-content"
                      style={{
                        marginLeft: "3%",
                        marginTop: "2%",
                        marginBottom: "2%",
                      }}
                    >
                      {findTextByKey('SECTION22.22.1')} <strong>{freehands.freehand56 ? freehands.freehand56 : 'XXX'}</strong>
                      <textarea
                        type="text"
                        name="freehand_56"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea>
                      {findTextByKey('SECTION22.22.2')} <strong>{freehands.freehand57 ? freehands.freehand57 : 'XXX'}</strong>
                      <textarea
                        type="text"
                        name="freehand_57"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea>
                      {findTextByKey('SECTION22.22.3')} <strong>{freehands.freehand58 ? freehands.freehand58 : 'XXX'}</strong>
                      <textarea
                        type="text"
                        name="freehand_58"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea>
                      {findTextByKey('SECTION22.22.4')} <strong>{freehands.freehand59 ? freehands.freehand59 : 'XXX'}</strong>]
                      <textarea
                        type="text"
                        name="freehand_59"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea>
                    </div>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION22.22.5')}
                    </div>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION22.22.6')}
                    </div>
                    <textarea
                      type="text"
                      name="freehand_60"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION22.22.7')} <strong>{freehands.freehand61 ? freehands.freehand61 : 'XXX'}</strong>

                      <textarea
                        type="text"
                        name="freehand_61"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea>
                      {findTextByKey('SECTION22.22.8')} <strong>{freehands.freehand62 ? freehands.freehand62 : 'XXX'}</strong>
                      <textarea
                        type="text"
                        name="freehand_62"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea>
                      {findTextByKey('SECTION22.22.9')} <strong>{freehands.freehand63 ? freehands.freehand63 : 'XXX'}</strong>
                      <textarea
                        type="text"
                        name="freehand_63"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea>
                      {findTextByKey('SECTION22.22.10')} <strong>{freehands.freehand64 ? freehands.freehand64 : 'XXX'}</strong>
                      <textarea
                        type="text"
                        name="freehand_64"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea>
                      {findTextByKey('SECTION22.22.11')} <strong>{freehands.freehand65 ? freehands.freehand65 : 'XXX'}</strong> ]"
                      <textarea
                        type="text"
                        name="freehand_65"
                        className={changeClassName}
                        ref={register}
                        style={{ width: "90%" }}
                      ></textarea>
                    </div>

                    <div className="level2-title-content">
                      {findTextByKey('SECTION22.22.12')}
                    </div>

                    <div className="level2-title-content">
                      {findTextByKey('SECTION22.22.13')} <strong>{freehands.freehand66 ? freehands.freehand66 : 'XXX'}</strong> {findTextByKey('SECTION22.22.14')}
                    </div>

                    <textarea
                      type="text"
                      name="freehand_66"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>

                    <h1 className="level1-title-number">23. {findTextByKey('SECTION23_TITLE')}</h1>

                    <div className="level2-title-content">
                      <span className="level2-title-number">23.1</span>
                      {computeCountryText(findTextByKey('SECTION23.23.1'), compareSellerAndBuyerCountry(currentRfq), lang)}
                    </div>

                    <div className="level2-title-content">
                      {computeCountryText(findTextByKey('SECTION23.23.2'), compareSellerAndBuyerCountry(currentRfq), lang)}
                    </div>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION23.23.3')}
                    </div>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION23.23.4')}
                    </div>
                    <div className="level2-title-content">
                      {findTextByKey('SECTION23.23.5')} <strong>{freehands.freehand67 ? freehands.freehand67 : 'XXX'}</strong>].
                    </div>
                    <textarea
                      type="text"
                      name="freehand_67"
                      className={changeClassName}
                      ref={register}
                      style={{ width: "90%" }}
                    ></textarea>

                    <div className="level2-title-content">
                      <span className="level2-title-number">23.2</span>
                      {findTextByKey('SECTION23.23.6')}
                    </div>

                  </div>
                </div>
              </div>
              <div className="row" style={{ width: "75%", color: "black", marginLeft: "20%" }}>
                <div className="col-md-12">
                  <p style={{ textAlign: "center" }}>{findTextByKey('SIGNATURES')}</p>
                </div>

                <div className="col-md-6">
                  <p style={{ textAlign: "center" }}>Seller</p>
                  <p style={{ textAlign: "center" }}> {(new Date()).toLocaleDateString()}<br />
                    {currentRfq.user.keyContactName}</p>

                  <p style={{ textAlign: "center" }}>Signature</p>
                </div>
                <div className="col-md-6">
                  <p style={{ textAlign: "center" }}>Buyer</p>
                  <p style={{ textAlign: "center" }}>{(new Date()).toLocaleDateString()}<br />
                    {currentRfq.baseQuotation.user.keyContactName}</p>

                  <p style={{ textAlign: "center" }}>Signature</p>
                </div>
              </div>
            </section>
          </form>
        </div>
      ) : null) : null
      }

      <div style={{ display: freehands.file ? 'none' : 'flex', justifyContent: 'space-between' }}>
        <button onClick={handleSubmit((data) => onSubmitForm(data, false))} className="btn btn-primary" disabled={loadingUpdate}>
          {loadingUpdate ? <span><i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> : null}
          {t('documents.save')}
        </button>

        <button onClick={handleSubmit((data) => onSubmitForm(data, true))} className="btn btn-primary" disabled={loadingUpdate || loadingSendContractDoc || (isSeller && freehands.sellerApproved) || (isBuyer && freehands.buyerApproved)}>
          {loadingUpdate || loadingSendContractDoc ? <span><i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> : null}
          {t('documents.save_sign')}
        </button>
      </div>

      <SuccessModal
        shouldShowModal={successModal}
        close={() => setSuccessModal(false)}
        title={t('notes.contract_sign')}
        subtitle={''}
        button={t('notes.go_to_contract')}
        to={`${process.env.PUBLIC_URL}/account/dashboard/contracts`}
      />

      <SuccessModal
        shouldShowModal={successDocModal}
        close={() => setSuccessDocModal(false)}
        title={t('notes.contract_generated')}
        subtitle={t('notes.contract_ok')}
        button={t('notes.go_to_contract')}
        to={`${process.env.PUBLIC_URL}/account/dashboard/contracts`}
      />

      <SuccessModal
        shouldShowModal={successWatingModal}
        close={() => setSuccessWaitingModal(false)}
        title={t('notes.contract_approved')}
        subtitle={''}
        button={t('notes.go_to_contract')}
        to={`${process.env.PUBLIC_URL}/account/dashboard/contracts`}
      />

    </>
  );
};
export default withTranslation()(React.memo(withRouter(ContratPdf)));
