import backendURLs from "../utils/backendURL";
import { makeRequest, joinBaseUrlWithRequestParams } from "./utils";


export const createTestimonial = (data) => {

    const url = joinBaseUrlWithRequestParams(`${backendURLs.TESTIMONIALS.CREATE_TESTIMONY}`, [
        {param: 'content', value: data.content}
    ]);

    return makeRequest('post', url, data, {});
}


export const fetchAllTestimonials = () => {

    const url  = `${backendURLs.TESTIMONIALS.GET_ALL_TESTIMONIALS}`;

    return makeRequest('get', url, null, {})
}


export const fetchAllTestimonialsFavotites = () => {

    const url  = `${backendURLs.TESTIMONIALS.GET_FAVORITES}`;

    return makeRequest('get', url, null, {})
}

