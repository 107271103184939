import './rfq.scss';
import RfqsList  from './RfqsList';
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import React, { Component } from 'react'
import { markRFQasRead } from '@action/rfqs-actions';
import { createProductsOfSeller, getProductsOfSeller } from '@action/sellerProducts-actions';

class index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addProcess : false,
            detailsProcess: false,
            chatProcess: false,

            rfqItemSelected: null
        };
    }

    componentDidMount() {
    }

    showDetail = (item) => {
        
        if (this.props.authUser.authUser.userType==='SELLER' && !item.status) {
            
            markRFQasRead(item.quotation.id, this.props.authUser.authUser.id, this.props.authUser.authUser.userType).then((result) => {
            
                // toast(`Mark as read`, {className: "toast-success"});
                this.props.getCountRFQUnread(this.props.authUser.authUser.id, this.props.authUser.authUser.userType);

            }).catch((err) => {
                
                toast(`Error to mark as read: ${err.message}`, {className: "toast-error"});
            });
        }
    }

    cancelDetail = () => {
        this.setState({detailsProcess : false, rfqItemSelected: null});
    }

    showChatForRfq = (item) => {
        this.setState({chatProcess : true, detailsProcess : false, rfqItemSelected: item});
    }

    cancelChatForRfq = () => {
        this.setState({chatProcess : false, rfqItemSelected: null});
    }

    
    render() {

        const {getCountRFQUnread} = this.props;

        return (
            <div className="row"> 
                <div className="col-md-12 col-xs-12">
                    {!this.state.detailsProcess && !this.state.chatProcess ? <RfqsList showDetail={this.showDetail} /> : null}

                    {/* {this.state.detailsProcess ? <RfqDetatils cancelDetail={this.cancelDetail} showChatForRfq={this.showChatForRfq} rfqItemSelected={this.state.rfqItemSelected}/> : null} */}

                    {/* {this.state.chatProcess ? <RfqChat rfqItemSelected={this.state.rfqItemSelected} /> : null} */}
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = ({sellerProducts, authUser}) => ({
    sellerProducts, authUser
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, { getProductsOfSeller, createProductsOfSeller })(index);
