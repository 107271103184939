import * as productsConfigActionsType from '../constants/config-actions';

const initialState = {

    data: null,

    unitsConfig: [],
    portsConfig: [],
    incoTermsConfig: [],
    productsCatConfig: [],
    productsCatUsedConfig: [],
    typesOfShipmentsConfig: [],

    //portsConfig: [],
    //typesOfShipmentsConfig: [],

    loading: false,
    error: null,
}


const productsConfigReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case productsConfigActionsType.GET_ALL_PRODUCT_CONFIG:
            return { ...state, loading: true, error: null, data: null};

        case productsConfigActionsType.GET_ALL_PRODUCT_CONFIG_SUCCESS:
            return { ...state, error: null, loading: false, data: action.payload, unitsConfig: action.payload[0], portsConfig: action.payload[1], productsCatConfig: action.payload[3],
                typesOfShipmentsConfig: action.payload[5], incoTermsConfig: action.payload[2], productsCatUsedConfig: action.payload[4]};

        case productsConfigActionsType.GET_ALL_PRODUCT_CONFIG_ERROR:
            return { ...state, data: null, loading: false, error: action.payload };
        
        case productsConfigActionsType.GET_ALL_PRODUCT_CONFIG_CLEAR:
            return initialState

        default:
            return state;
    }
};


export default productsConfigReducer;