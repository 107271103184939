import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import "react-app-polyfill/ie11";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import i18n from "./i18n";
import Routes from "./routes";
import { refreshStore } from "./action";
import { setAuthUser, logout } from "./action/auth";
import { getAuthToken } from "./helpers/tokens";
import { I18nextProvider } from "react-i18next";
import { definePolyfills, scrollTop } from "./utils";
import { UNPROTECTED_ROUTES } from "./routes/urls";

export function Root() {

  const { accessToken } = getAuthToken();

  if (accessToken) {
    store.dispatch(setAuthUser());
  } else {
    if(!UNPROTECTED_ROUTES.includes(window.location.pathname)) {
      store.dispatch(logout());
    }
  }
  
  definePolyfills();
  scrollTop();

  useEffect(() => {
    if (store.getState().demo.current !== 9) {
      store.dispatch(refreshStore(9));
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<span></span>}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter basename={"/"}>
            <ScrollContext>
              <Routes />
            </ScrollContext>
          </BrowserRouter>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
