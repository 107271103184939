import Modal from "react-modal";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../common/breadcrumb";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import CheckoutProgessBar from "./checkout-progress-bar";
import { findProductById } from "@utils/custom-index";
import SuccessModal from '@components/pages/succesModal';
import RfqCreateStep1 from "./stepForCreate/RfqCreateStep1";
import RfqCreateStep2 from "./stepForCreate/RfqCreateStep2";
import RfqCreateStep3 from "./stepForCreate/RfqCreateStep3";
import { getProductById } from "@action/products-actions";
import { createRFQ, updateRFQBuyer, updateRFQSeller } from "@action/rfqs-actions";

Modal.setAppElement("#root");

function RfqCreate(props) {

  const [product, setProduct] = useState(null);

  const { authUser, productsConfig, rfqToUpdate } = props;

  const user = useSelector((state) => state.authUser);

  const [langSelected, setLangSelected] = useState("en");

  useEffect(() => {
    if (user.authUser != null) {
      setLangSelected(user.authUser.language.toLowerCase());
    }
  }, [user])

  const unitsConfigQuantity = productsConfig.unitsConfig.filter(item => item.unitType === 'QUANTITY');

  const [dataStep1, setDataStep1] = useState(rfqToUpdate ? {
    price: rfqToUpdate.price, currency: rfqToUpdate.currency, quantityUnitId: rfqToUpdate.quantityUnit.id,
    quantity: rfqToUpdate.quantity, incotermId: rfqToUpdate.incoterm.id,
    condition: rfqToUpdate.condition, qualitySpecification: rfqToUpdate.qualitySpecification
  } : {});

  const [dataStep2, setDataStep2] = useState(rfqToUpdate ? {
    methodOfShipment: rfqToUpdate.methodOfShipment, arrival: rfqToUpdate.arrival,
    typeOfShipmentId: rfqToUpdate.typeOfShipment ? rfqToUpdate.typeOfShipment.id : null,
    portDestinationId: rfqToUpdate.port ? rfqToUpdate.port.id : null, packagingDetails: rfqToUpdate.packagingDetails
  } : {});

  const [dataStep3, setDataStep3] = useState({});
  const { t } = props;

  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isSecondStep, setIsSecondStep] = useState(false);
  const [isThirdStep, setIsThirdStep] = useState(false);

  const [dataCreated, setDataCreated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setErrorCreate] = useState(false);
  const [errorDialogClosed, setErrorDialogClosed] = useState(true);

  const deactivateWhenSellerUpdate = () => {
    return authUser.authUser.userType === 'SELLER' && rfqToUpdate;
  }

  let loader = null;
  let errorDisplay = null;

  const [open, setOpen] = useState(false);

  const setDataFormStep1 = (data) => {
    setDataStep1(data);
    onNextStep();
  };


  const setDataFormStep2 = (data, publishStatus) => {
    /* setDataStep2(data);
    onNextStep(); */
    setDataStep2(data);
    createRfq({ ...dataStep1, ...dataStep2, ...data, publish: publishStatus })
  };

  const setDataFormStep3 = (data, publishStatus) => {
    /* setDataStep3(data, () => {

      createRfq({...dataStep1, ...dataStep2, ...dataStep3});
    });  */

    //createRfq({ ...dataStep1, ...dataStep2, ...data, publish: publishStatus });
  };

  const closeModal = () => {
    //props.clearUpdateStateAfterUpdate();
    setOpen(false);
    props.history.push(`${process.env.PUBLIC_URL}/marketplace`);
  };

  const initialiseState = () => {
    setErrorDialogClosed(true);
    setLoading(true);
    setErrorCreate(null);
    setDataCreated(null);
  };

  const onNextStep = () => {
    if (isFirstStep) {
      setIsFirstStep(false);
      setIsSecondStep(true);
    }
    if (isSecondStep) {
      setIsSecondStep(false);
      setIsThirdStep(true);
    }
  };

  const onPreviousStep = () => {
    if (isSecondStep) {
      setIsFirstStep(true);
      setIsSecondStep(false);
    }
    if (isThirdStep) {
      setIsSecondStep(true);
      setIsThirdStep(false);
    }
  };


  const createRfq = (data) => {

    initialiseState();

    if (props.rfqToUpdate) {
      if (authUser.authUser && authUser.authUser.userType === 'BUYER') {
        if (data.portDestinationId == null)
          delete data.portDestinationId
        if (data.typeOfShipmentId == null)
          delete data.typeOfShipmentId
        updateRFQBuyer({ ...data }, props.rfqToUpdate.id)
          .then(data => {
            // toast("Your Rfq have been Updated!", { className: "toast-success" });
            props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/rfqs`);
            //setDataCreated(data);
          })
          .catch(err => {
            toast("Error to update RFQ!", { className: "toast-error" });
            setErrorCreate(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }

      if (authUser.authUser && authUser.authUser.userType === 'SELLER') {
        updateRFQSeller({ price: data.price, packagingDetails: data.packagingDetails, sellerId: authUser.authUser.id }, props.rfqToUpdate.id)
          .then(data => {
            // toast("The RFQ have been Updated!", { className: "toast-success" });
            props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/rfqs`);
            //setDataCreated(data);
          })
          .catch(err => {
            // console.log('Error to update RFQ', err);
            toast("Error to update RFQ!", { className: "toast-error" });
            setErrorCreate(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      createRFQ(
        authUser.authUser ? {
          ...data,
          user_id: authUser.authUser.id,
          productId: product.id
        } : {
            ...data,
            productId: product.id
          })
        .then((data) => {
          // toast("Your RFQ has been created!", { className: "toast-success" });
          // props.history.push(`${process.env.PUBLIC_URL}/marketplace`);
          setOpen(true);
        })
        .catch((err) => {
          setErrorDialogClosed(false);
          toast("Error to create RFQ!", { className: "toast-error" });
          setErrorCreate(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    //setUploadFirstImage(new FileUploadWithPreview("myFirstUploadId"));
    if (props.products.length <= 0) {
      getProductById(props.productId).then((result) => {

        setProduct(result);
      })
    } else {
      let product = findProductById(props.products, props.productId);
      setProduct(product);
    }

  }, []);

  if (loading) {
    loader = <div>Loading</div>;
  }

  if (error && !errorDialogClosed) {
    errorDisplay = (
      <>
        <br />
        <div className="alert alert-danger">
          Error: {error.message}
          <button
            title="Close (Esc)"
            type="button"
            className="mfp-close"
            onClick={() => setErrorDialogClosed(true)}
          >
            X
          </button>
        </div>
        <br />
      </>
    );
  }

  return (
    <div className="main">
      <Breadcrumb current="Create RFQs" parent="Home" />



      <div className="container">

        <h2 className="text-muted font-weight-light">
          {props.rfqToUpdate ? <h2 style={{ textTransform: 'uppercase' }}>{t('notes.rfq_update')}</h2> : <h2 style={{ textTransform: 'uppercase' }}>{t('notes.rfq')}</h2>}
        </h2>

        {loader}
        {errorDisplay}

        <SuccessModal
          shouldShowModal={open}
          close={closeModal}
          title={t('notes.rfq_created')}
          subtitle={t('notes.seller_will_reply')}
          button={t('notes.go_to_rfq')}
          to={`${process.env.PUBLIC_URL}/account/dashboard/rfqs`}
        />

        <div className="row mt-5">
          <div className="col-lg-12 order-lg-first">
            <div className="checkout-payment">
              <div className="row">
                <div className="col-sm-3">
                  {product ? <div style={{ borderRadius: "5px", border: "1px solid lightgray", marginTop: "10px", }}>
                    <img
                      src={`${process.env.BACKEND_BASE_URL}${product.mainImagePath}`}
                      style={{
                        width: "100%",
                      }}
                    />

                    {/* Info 1 */}
                    <div style={{ borderTop: "1px solid lightgray" }} className="">
                      <h4 className="py-2 text-center mt-2">
                        {product.name}
                      </h4>
                    </div>

                    {/* Info 2 */}
                    <div style={{ borderTop: "1px solid lightgray" }} className="px-2 py-3">
                      <p><strong>{t('rfq.asking_price')}</strong>:</p>
                      <p><span className="">{product.price} {product.currency} / {product.pricePerUnit[langSelected == 'fr' ? 'fr' : 'en']} </span></p>
                      <p><strong>{t('rfq.category')}</strong>:</p>
                      <p><span className="f">{product.productCategory[langSelected == 'fr' ? 'fr' : 'en']}</span></p>
                    </div>
                  </div> : null}
                </div>

                <div className="col-sm-9">
                  <CheckoutProgessBar
                    active={
                      isFirstStep ? 1 : isSecondStep ? 2 : isThirdStep ? 3 : 1
                    }
                  />

                  <h2 className="text-muted font-weight-light mb-2">
                    {isFirstStep ? "Details" : ""}
                    {isSecondStep ? "Logistic" : ""}
                    {isThirdStep ? "Details" : ""}
                  </h2>

                  <div id="new-checkout-address" className="show">
                    {isFirstStep ? (
                      <RfqCreateStep1
                        rfqToUpdate={props.rfqToUpdate}
                        setDataStep1={setDataFormStep1}
                        deactivateWhenSellerUpdate={deactivateWhenSellerUpdate}
                        defaultDataStep1={dataStep1}
                        incoTermsConfig={productsConfig.incoTermsConfig}
                        unitsConfigQuantity={unitsConfigQuantity}
                        productsCatConfig={productsConfig.productsCatConfig}
                        product={product}
                      />
                    ) : null}

                    {isSecondStep ? (
                      <RfqCreateStep2
                        rfqToUpdate={props.rfqToUpdate}
                        deactivateWhenSellerUpdate={deactivateWhenSellerUpdate}
                        loading={loading}
                        onPreviousStep={onPreviousStep}
                        defaultDataStep2={dataStep2}
                        setDataStep2={setDataFormStep2}
                        typesOfShipmentsConfig={productsConfig.typesOfShipmentsConfig}
                        portsConfig={productsConfig.portsConfig}
                        unitsConfig={productsConfig.unitsConfig}
                        productsCatConfig={productsConfig.productsCatConfig}
                      />
                    ) : null}

                    {isThirdStep ? (
                      <RfqCreateStep3
                        loading={loading}
                        onPreviousStep={onPreviousStep}
                        setDataStep3={setDataFormStep3}
                        defaultDataStep3={dataStep3}
                        unitsConfig={productsConfig.unitsConfig}
                        portsConfig={productsConfig.portsConfig}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    products: state.products.data ? state.products.data : [],
    productId: props.match.params.id ? props.match.params.id : 1,
    rfqToUpdate: props.location.state ? props.location.state.rfqToUpdate : null,

    productsConfig: state.productsConfig,
    authUser: state.authUser,
  };
};
export default withTranslation()(React.memo(withRouter(connect(mapStateToProps, {})(RfqCreate))));
