import "./testimonials.css";
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import CreateTestimonyModal from "./CreateTestimonyModal";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { fetchAllTestimonialsFavotites } from "../../../action/testimonials-actions";

class Testimonials extends Component {
  state = {
    data: [],
  };

  componentDidMount() {
    fetchAllTestimonialsFavotites()
      .then((result) => {
        this.setState({ data: result });
      })
      .catch((err) => {
        toast(`Failed to get testimnials: ${err.message}`, {});
      });
  }

  render() {
    return (
      <>
          <div
            className="col-sm-12 pt-5 pt-md-0 feature-boxes-container pb-2"
            style={{ backgroundColor: "#f4f4f4" }}
          >
            <div className="col-md-8 col-sm-12 pt-5 pt-md-0 offset-md-2">
              <h2 style={{ paddingTop: "4%" }}>{ this.props.t('common.testimonials') }</h2>
            </div>

            <Carousel
              showArrows={true}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}
              autoPlay={true}
              interval={4000}
            >
              {this.state.data.map((item, index) => (
                <div key={index}>
                  <img
                    src={item.user.avatar.startsWith('https://encryp') ? item.user.avatar : `${process.env.BACKEND_BASE_URL}${item.user.avatar}`}
                  />
                  <div className="myCarousel">
                    <h3>{item.user.name}</h3>
                    <h4>{item.title}</h4>
                    <p>{item.content}</p>
                  </div>
                </div>
              ))}
            </Carousel>
            {
              this.props.authUser.authUser ?
                <CreateTestimonyModal />
                : null
            }
          </div>
      </>
    );
  }
}

const mapStateToProps = ({ authUser }) => ({
  authUser: authUser
});

export default withTranslation()(
  connect(mapStateToProps, {})(Testimonials)
);
