import './style.css';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../common/breadcrumb';
import React, { useState, useEffect } from 'react';
import { getSettings } from '../../../action/contract';
import { useTranslation, withTranslation } from 'react-i18next';


function Terms() {

    const [data, setData] = useState([]);
    const { i18n } = useTranslation();

    useEffect(() => {
        getSettings().then((result) => {
            setData(result);
        }).catch((err) => {
            // toast(`Error to get fees app: ${err.message}`, { className: "toast-error" });
        });
    }, [])

    return (
        <>
            <Helmet>
                <title>Sooko - Terms and conditions </title>
            </Helmet>

            <h1 className="d-none">Sooko - Terms and conditions</h1>

            <div className="main">
                <Breadcrumb current="Terms and conditions" parent="Page" />

                <div className="container">

                    {i18n.language == 'en' ?
                        <div className="row">
                            <div className="col-md-12" style={{ marginTop: '5%' }}>
                                <h1 className="light-title mb-5"><strong>Terms and conditions of use</strong></h1>
                                <p className="title">Last updated: November 30, 2021</p>
                                <p className="title">Please read these terms and conditions carefully before using Our Service.</p>


                                <h2 className="h2 mt-5">Interpretation and Definitions</h2>
                                <h3 className="mb-3 mt-3">Interpretation</h3>
                                <p className="p">
                                    The words of which the initial letter is capitalized have meanings defined under the following
                                    conditions. The following definitions shall have the same meaning regardless of whether they appear
                                    in singular or in plural.
                                </p>
                                <h3 className="mb-3 mt-3">Definitions</h3>
                                <p className="p">
                                    For the purposes of these Terms and Conditions:
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common
                                        control with a party, where "control" means ownership of 50% or more of the shares, equity interest
                                        or other securities entitled to vote for election of directors or other managing authority.
                                    </li>
                                    <li className="li">
                                        <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
                                    </li>
                                    <li className="li">
                                        <strong>Country</strong> refers to: Delaware, United States
                                    </li>
                                    <li className="li">
                                        <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to
                                        Sooko Group Inc., 108 Lakeland Ave, Dover, DE 19901 C/O Capitol Services Inc.
                                    </li>
                                    <li className="li">
                                        <strong>Content</strong> refers to content such as text, images, or other information that can be posted, uploaded,
                                        linked to or otherwise made available by You, regardless of the form of that content.
                                    </li>
                                    <li className="li">
                                        <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                                    </li>
                                    <li className="li">
                                        <strong>Feedback</strong> means feedback, innovations or suggestions sent by You regarding the attributes, performance
                                        or features of our Service.
                                    </li>
                                    <li className="li">
                                        <strong>Goods</strong> refer to the items offered for sale on the Service.
                                    </li>
                                    <li className="li">
                                        <strong>Orders</strong> mean a request by You to purchase Goods from Us.
                                    </li>
                                    <li className="li">
                                        <strong>Promotions</strong> refer to contests, sweepstakes or other promotions offered through the Service.
                                    </li>
                                    <li className="li">
                                        <strong>Service</strong> refers to the Website.
                                    </li>
                                    <li className="li">
                                        <strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement
                                        between You and the Company regarding the use of the Service.
                                    </li>
                                    <li className="li">
                                        <strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services)
                                        provided by a third-party that may be displayed, included or made available by the Service.
                                    </li>
                                    <li className="li">
                                        <strong>Website</strong> refers to Sooko Trade, accessible from <a href='https://www.sookotrade.com' target="_blank">https://www.sookotrade.com</a>
                                    </li>
                                    <li className="li">
                                        <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which
                                        such individual is accessing or using the Service, as applicable.
                                    </li>
                                </ul>


                                <h2 className="h2 mb-5 mt-5">Acknowledgment</h2>

                                <p className="p">
                                    These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company.
                                    These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
                                </p>
                                <p className="p">
                                    Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions.
                                    These Terms and Conditions apply to all visitors, users and others who access or use the Service.
                                </p>
                                <p className="p">
                                    By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these
                                    Terms and Conditions then You may not access the Service.
                                </p>
                                <p className="p">
                                    You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
                                </p>
                                <p className="p">
                                    Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the
                                    Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal
                                    information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You.
                                    Please read Our Privacy Policy carefully before using Our Service.
                                </p>


                                <h2 className="h2 mb-5 mt-5">Placing Orders for Goods</h2>

                                <p className="p">
                                    By placing an Order for Goods through the Service, You warrant that You are legally capable of entering into binding contracts.
                                </p>
                                <h3 className="mb-3 mt-3">Your Information</h3>
                                <p className="p">
                                    If You wish to place an Order for Goods available on the Service, You may be asked to supply certain information relevant to
                                    Your Order including, without limitation, Your name, Your email, Your phone number, Your credit card number, the expiration
                                    date of Your credit card, Your billing address, and Your shipping information.
                                </p>
                                <p className="p">
                                    You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other payment method(s) in
                                    connection with any Order; and that (ii) the information You supply to us is true, correct and complete.
                                </p>
                                <p className="p">
                                    By submitting such information, You grant us the right to provide the information to payment processing third parties for
                                    purposes of facilitating the completion of Your Order.
                                </p>
                                <h3 className="mb-3 mt-3">Order Cancellation</h3>
                                <p className="p">
                                    We reserve the right to refuse or cancel Your Order at any time for certain reasons including but not limited to:
                                </p>
                                <ul className="ul">
                                    <li className="li">Goods availability</li>
                                    <li className="li">Errors in the description or prices for Goods</li>
                                    <li className="li">Errors in Your Order</li>
                                </ul>
                                <p className="p">
                                    We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal transaction is suspected.
                                </p>
                                <h3 className="mb-3 mt-3">Your Order Cancellation Rights</h3>
                                <p className="p">
                                    Any Goods you purchase can only be returned in accordance with these Terms and Conditions and Our Returns Policy.
                                </p>
                                <p className="p">
                                    Our Returns Policy forms a part of these Terms and Conditions. Please read our Returns Policy to learn more about
                                    your right to cancel Your Order.
                                </p>
                                <p className="p">
                                    Your right to cancel an Order only applies to Goods that are returned in the same condition as You received them.
                                    You should also include all of the products instructions, documents and wrappings. Goods that are damaged or not
                                    in the same condition as You received them or which are worn simply beyond opening the original packaging will not
                                    be refunded. You should therefore take reasonable care of the purchased Goods while they are in Your possession.
                                </p>
                                <p className="p">
                                    We will make arrangement with the other party to reimburse You no later than 90 days from the day on which they receive
                                    the returned Goods. We will use the same means of payment as You used for the Order, and You may incur any fees for such
                                    reimbursement.
                                </p>
                                <p className="p">
                                    You will not have any right to cancel an Order for the supply of any of the following Goods:
                                </p>
                                <ul className="ul">
                                    <li className="li">The supply of Goods made to Your specifications or clearly personalized.</li>
                                    <li className="li">
                                        The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where
                                        the date of expiry is over.
                                    </li>
                                    <li className="li">
                                        The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were unsealed
                                        after delivery.
                                    </li>
                                    <li className="li">
                                        The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items.
                                    </li>
                                    <li className="li">
                                        The supply of digital content which is not supplied on a tangible medium if the performance has begun with Your
                                        prior express consent and You have acknowledged Your loss of cancellation right.
                                    </li>
                                </ul>
                                <h3 className="mb-3 mt-3">Availability, Errors and Inaccuracies</h3>
                                <p className="p">
                                    We are constantly updating Our offerings of Service. The Goods available on Our Service may be mispriced, described
                                    inaccurately, or unavailable, and We may experience delays in updating information regarding Goods on the Service and
                                    in Our advertising on other websites.
                                </p>
                                <p className="p">
                                    We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images,
                                    specifications, availability, and services. We reserve the right to change or update information and to correct errors,
                                    inaccuracies, or omissions at any time without prior notice.
                                </p>
                                <h3 className="mb-3 mt-3">Prices Policy</h3>
                                <p className="p">
                                    The Company reserves the right to revise prices at any time prior to accepting an Order.
                                </p>
                                <p className="p">
                                    The prices quoted may be revised by the Company subsequent to accepting an Order in the event of any occurrence
                                    affecting delivery caused by government action, variation in customs duties, increased shipping charges, higher
                                    foreign exchange costs and any other matter beyond the control of the Company. In that event, You will have the
                                    right to cancel Your Order.
                                </p>
                                <h3 className="mb-3 mt-3">Payments</h3>
                                <p className="p">
                                    All Goods purchased are subject to a one-time payment or as agreed or contracted. Payment can be made through
                                    various payment methods we have available, such as Visa, MasterCard, Affinity Card, American Express cards, mobile
                                    payments or online payment methods (Stripe, PayPal, for example).
                                </p>
                                <p className="p">
                                    Payment cards (credit cards or debit cards) are subject to validation checks and authorization by Your card issuer.
                                    If we do not receive the required authorization, We will not be liable for any delay or non-delivery of Your Order.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Promotions</h2>

                                <p className="p">
                                    Any Promotions made available through the Service may be governed by rules that are separate from these Terms.
                                </p>
                                <p className="p">
                                    If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. If the
                                    rules for a Promotion conflict with these Terms, the Promotion rules will apply.
                                </p>

                                <h2 className="h2 mb-5 mt-5">User Accounts</h2>

                                <p className="p">
                                    When You create an account with Us, You must provide Us information that is accurate, complete, and current at all
                                    times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account
                                    on Our Service.
                                </p>
                                <p className="p">
                                    You are responsible for safeguarding the password that You use to access the Service and for any activities or actions
                                    under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.
                                </p>
                                <p className="p">
                                    You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any
                                    breach of security or unauthorized use of Your account.
                                </p>
                                <p className="p">
                                    You may not use as a username the name of another person or entity or that is not lawfully available for use, a name
                                    or trademark that is subject to any rights of another person or entity other than You without appropriate authorization,
                                    or a name that is otherwise offensive, vulgar or obscene.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Content</h2>

                                <h3 className="mb-3 mt-3">Your Right to Post Content</h3>

                                <p className="p">
                                    Our Service allows You to post Content. You are responsible for the Content that You post to the Service, including its legality,
                                    reliability, and appropriateness.
                                </p>
                                <p className="p">
                                    By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly display,
                                    reproduce, and distribute such Content on and through the Service. You retain any and all of Your rights to any Content You
                                    submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this
                                    license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content
                                    subject to these Terms.
                                </p>
                                <p className="p">
                                    You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights
                                    and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the
                                    privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.
                                </p>
                                <h3 className="mb-3 mt-3">Content Restrictions</h3>
                                <p className="p">
                                    The Company is not responsible for the content of the Service's users. You expressly understand and agree that You are solely
                                    responsible for the Content and for all activity that occurs under your account, whether done so by You or any third person using
                                    Your account.
                                </p>
                                <p className="p">
                                    You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory,
                                    obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:
                                </p>
                                <ul className="ul">
                                    <li className="li">Unlawful or promoting unlawful activity.</li>
                                    <li className="li">
                                        Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual
                                        orientation, gender, national/ethnic origin, or other targeted groups.
                                    </li>
                                    <li className="li">
                                        Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other
                                        form of unauthorized solicitation, or any form of lottery or gambling.
                                    </li>
                                    <li className="li">
                                        Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to
                                        disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or
                                        obtain unauthorized access to any data or other information of a third person.
                                    </li>
                                    <li className="li">
                                        Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or
                                        other rights.
                                    </li>
                                    <li className="li">
                                        Impersonating any person or entity including the Company and its employees or representatives.
                                    </li>
                                    <li className="li">Violating the privacy of any third person.</li>
                                    <li className="li">False information and features.</li>
                                </ul>
                                <p className="p">
                                    The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content
                                    is appropriate and complies with these Terms, refuse or remove this Content. The Company further reserves the right to make
                                    formatting and edits and change the manner any Content is presented. The Company can also limit or revoke the use of the
                                    Service if You post such objectionable Content. As the Company cannot control all content posted by users and/or third parties
                                    on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to
                                    content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the
                                    Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any
                                    kind incurred as a result of your use of any content.
                                </p>
                                <h3 className="mb-3 mt-3">Content Backups</h3>
                                <p className="p">
                                    Although regular backups of Content are performed, the Company do not guarantee there will be no loss or corruption of data.
                                </p>
                                <p className="p">
                                    Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or
                                    that changes during the time a backup is performed.
                                </p>
                                <p className="p">
                                    The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of
                                    Content. But You acknowledge that the Company has no liability related to the integrity of Content or the failure to
                                    successfully restore Content to a usable state.
                                </p>
                                <p className="p">
                                    You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Copyright Policy</h2>
                                <h3 className="mb-3 mt-3">Intellectual Property Infringement</h3>
                                <p className="p">
                                    We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the
                                    Service infringes a copyright or other intellectual property infringement of any person.
                                </p>
                                <p className="p">
                                    If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a
                                    way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing
                                    to the attention of our copyright agent via email at <a href="mailto:info@sookotrade.com" target="_blank">info@sookotrade.com</a>
                                    and include in Your notice a detailed description of
                                    the alleged infringement.
                                </p>
                                <p className="p">
                                    You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is
                                    infringing Your copyright.
                                </p>
                                <h3 className="mb-3 mt-3">DMCA Notice and DMCA Procedure for Copyright Infringement Claims</h3>
                                <p className="p">
                                    You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the
                                    following information in writing (see 17 U.S.C 512(c)(3) for further detail):
                                </p>
                                <ul className="ul">
                                    <li className="li">An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.</li>
                                    <li className="li">
                                        A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of
                                        the location where the copyrighted work exists or a copy of the copyrighted work.
                                    </li>
                                    <li className="li">
                                        Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.
                                    </li>
                                    <li className="li">Your address, telephone number, and email address.</li>
                                    <li className="li">
                                        A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.
                                    </li>
                                    <li className="li">
                                        A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright
                                        owner or authorized to act on the copyright owner's behalf.
                                    </li>
                                </ul>
                                <p className="p">
                                    You can contact our copyright agent via email at <a href="mailto:info@sookotrade.com" target="_blank">info@sookotrade.com</a>. Upon receipt
                                    of a notification, the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged
                                    content from the Service.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Intellectual Property</h2>

                                <p className="p">
                                    The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the
                                    exclusive property of the Company and its licensors.
                                </p>
                                <p className="p">
                                    The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.
                                </p>
                                <p className="p">
                                    Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.
                                </p>


                                <h2 className="h2 mb-5 mt-5">Your Feedback to Us</h2>

                                <p className="p">
                                    You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is
                                    ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and
                                    license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Links to Other Websites</h2>

                                <p className="p">
                                    Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.
                                </p>
                                <p className="p">
                                    The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any
                                    third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable,
                                    directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance
                                    on any such content, goods or services available on or through any such web sites or services.
                                </p>
                                <p className="p">
                                    We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.
                                </p>


                                <h2 className="h2 mb-5 mt-5">Termination</h2>

                                <p className="p">
                                    We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including
                                    without limitation if You breach these Terms and Conditions or our Privacy policy.
                                </p>
                                <p className="p">
                                    Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may
                                    simply discontinue using the Service.
                                </p>


                                <h2 className="h2 mb-5 mt-5">Limitation of Liability</h2>

                                <p className="p">
                                    Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any
                                    provision of these Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount 1 USD for the
                                    Company throughout the Service provided and/or the amount of the goods for the parties transacting.
                                </p>
                                <p className="p">
                                    To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special,
                                    incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss
                                    of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way
                                    related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service,
                                    or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the
                                    possibility of such damages and even if the remedy fails of its essential purpose.
                                </p>
                                <p className="p">
                                    Some states or countries do not allow the exclusion of implied warranties or limitation of liability for incidental or
                                    consequential damages, which means that some of the above limitations may not apply. In these states or countries, each
                                    party's liability will be limited to the greatest extent permitted by law.
                                </p>


                                <h2 className="h2 mb-5 mt-5">"AS IS" and "AS AVAILABLE" Disclaimer</h2>

                                <p className="p">
                                    The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind.
                                    To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates
                                    and its and their respective licensors and service providers, expressly disclaims all warranties, whether express,
                                    implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness
                                    for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of
                                    performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking,
                                    and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be
                                    compatible or work with any other software, applications, systems or services, operate without interruption, meet any
                                    performance or reliability standards or be error free or that any errors or defects can or will be corrected.
                                </p>
                                <p className="p">
                                    Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or
                                    warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information,
                                    content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii)
                                    as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv)
                                    that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses,
                                    scripts, trojan horses, worms, malware, timebombs or other harmful components.
                                </p>
                                <p className="p">
                                    Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory
                                    rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case
                                    the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under
                                    applicable law.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Governing Law</h2>

                                <p className="p">
                                    The laws of the Company’s Country, excluding its conflicts of law rules, shall govern these Terms and Your use of
                                    the Service. Your use of the Application may also be subject to other local, state, national, or international laws.
                                </p>


                                <h2 className="h2 mb-5 mt-5">Disputes Resolution</h2>

                                <p className="p">
                                    If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.
                                </p>

                                <h2 className="h2 mb-5 mt-5">For European Union (EU) Users</h2>

                                <p className="p">
                                    If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.
                                </p>

                                <h2 className="h2 mb-5 mt-5">United States Legal Compliance</h2>

                                <p className="p">
                                    You represent and warrant that (i) You are not located in a country that is subject to the United States government
                                    embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii)
                                    You are not listed on any United States government list of prohibited or restricted parties.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Severability and Waiver</h2>


                                <h3 className="mb-3 mt-3">Severability</h3>
                                <p className="p">
                                    If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted
                                    to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining
                                    provisions will continue in full force and effect.
                                </p>
                                <h3 className="mb-3 mt-3">Waiver</h3>
                                <p className="p">
                                    Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms
                                    shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall
                                    be the waiver of a breach constitute a waiver of any subsequent breach.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Translation Interpretation</h2>

                                <p className="p">
                                    These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that
                                    the original English text shall prevail in the case of a dispute.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Changes to These Terms and Conditions</h2>

                                <p className="p">
                                    We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material
                                    We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What
                                    constitutes a material change will be determined at Our sole discretion.
                                </p>
                                <p className="p">
                                    By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised
                                    terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Contact Us</h2>

                                <p className="p">If you have any questions about these Terms and Conditions, You can contact us:</p>
                                <ul className="ul">
                                    <li className="li">By email: <a href="mailto:info@sookotrade.com" target="_blank">info@sookotrade.com</a></li>
                                    <li className="li">By phone number: <a href="tel:1.888.777.8578" target="_blank">1.888.777.8578</a></li>
                                </ul>
                            </div>
                        </div>





























                        :



















                        <div className="row">
                            <div className="col-md-12" style={{ marginTop: '5%' }}>
                                <h1 className="light-title mb-5"><strong>Termes et conditions d’utilisation</strong></h1>
                                <p className="title">Dernière mise à jour : 30 novembre 2021</p>
                                <p className="title">Veuillez lire attentivement ces termes et conditions avant d’utiliser notre service.</p>


                                <h2 className="h2 mt-5">Interprétation et définitions</h2>
                                <h3 className="mb-3 mt-3">Interprétation</h3>
                                <p className="p">
                                    Les mots dont la lettre initiale est en majuscule ont des significations définies dans les
                                    conditions suivantes. Les définitions suivantes ont le même sens, qu’elles apparaissent au
                                    singulier ou au pluriel.
                                </p>
                                <h3 className="mb-3 mt-3">Définitions</h3>
                                <p className="p">
                                    Aux fins des présentes Conditions générales :
                                </p>
                                <ul className="ul">
                                    <li className="li">
                                        <strong>Société affiliée</strong> désigne une entité qui contrôle, est contrôlée par ou est sous contrôle commun avec une partie, où le « contrôle » désigne la propriété de 50 % ou plus des actions, des participations ou d’autres titres ayant le droit de voter pour l’élection des administrateurs ou d’une autre autorité de gestion.
                                    </li>
                                    <li className="li">
                                        <strong>Compte</strong> désigne un compte unique créé pour que Vous puissiez accéder à notre Service ou à des parties de notre Service.
                                    </li>
                                    <li className="li">
                                        <strong>Pays</strong> fait référence à: Delaware, États-Unis
                                    </li>
                                    <li className="li">
                                        <strong>La Société</strong> (appelée « la Société », « Nous », « Notre » ou « Nos » dans le présent Contrat) fait référence à Sooko Group Inc., 108 Lakeland Ave, Dover, DE 19901 C/O Capitol Services Inc.
                                    </li>
                                    <li className="li">
                                        <strong>Le contenu</strong> fait référence à du contenu tel que du texte, des images ou d’autres informations qui peuvent être publiées, téléchargées, liées ou autrement mises à disposition par vous, quelle que soit la forme de ce contenu.
                                    </li>
                                    <li className="li">
                                        <strong>Appareil</strong> désigne tout appareil pouvant accéder au Service, tel qu’un ordinateur, un téléphone portable ou une tablette numérique.
                                    </li>
                                    <li className="li">
                                        <strong>Feedback</strong> désigne les commentaires, innovations ou suggestions que Vous avez envoyés concernant les attributs, les performances ou les caractéristiques de notre Service.
                                    </li>
                                    <li className="li">
                                        <strong>Les marchandises</strong> désignent les articles proposés à la vente sur le Service.
                                    </li>
                                    <li className="li">
                                        <strong>Les commandes</strong> signifient une demande de votre part d’acheter des marchandises grâce à nos offres de services.
                                    </li>
                                    <li className="li">
                                        <strong>Les promotions</strong> font référence à des concours, des tirages au sort ou d’autres promotions offertes par l’intermédiaire du Service.
                                    </li>
                                    <li className="li">
                                        <strong>Le service</strong> fait référence au site Web.
                                    </li>
                                    <li className="li">
                                        <strong>Termes et Conditions</strong> (également appelés « Conditions ») désignent ces Termes et Conditions qui forment l’intégralité de l’accord entre Vous et la Société concernant l’utilisation du Service.
                                    </li>
                                    <li className="li">
                                        <strong>Service de médias sociaux tiers</strong> désigne tout service ou contenu (y compris les données, informations, produits ou services) fourni par un tiers qui peut être affiché, inclus ou mis à disposition par le Service.
                                    </li>
                                    <li className="li">
                                        <strong>Le site Web</strong> fait référence à Sooko Trade, accessible depuis <a href='https://www.sookotrade.com' target="_blank">https://www.sookotrade.com</a>
                                    </li>
                                    <li className="li">
                                        <strong>Vous</strong> désigne la personne qui accède au Service ou l’utilise, ou la société, ou toute autre entité juridique au nom de laquelle cette personne accède ou utilise le Service, selon le cas.
                                    </li>
                                </ul>


                                <h2 className="h2 mb-5 mt-5">Reconnaissance</h2>

                                <p className="p">
                                    Ce sont les Termes et Conditions régissant l’utilisation de nos Services et l’accord qui régit la relation contractuelle entre Vous et la Société. Les présentes Conditions générales définissent les droits et obligations de tous les utilisateurs concernant l’utilisation du Service.
                                </p>
                                <p className="p">
                                    Votre accès et votre utilisation du Service sont conditionnés par Votre acceptation et votre respect des présentes Conditions générales. Ces Termes et Conditions s’appliquent à tous les visiteurs, utilisateurs et autres personnes qui accèdent ou utilisent le Service.
                                </p>
                                <p className="p">
                                    En accédant ou en utilisant le Service, vous acceptez d’être lié par les présentes Conditions générales. Si vous n’êtes pas d’accord avec une partie de ces termes et conditions, vous ne pouvez pas accéder au service.
                                </p>
                                <p className="p">
                                    Vous déclarez que vous avez plus de 18 ans. La Société n’autorise pas les moins de 18 ans à utiliser le Service.
                                </p>
                                <p className="p">
                                    Votre accès et votre utilisation du Service sont également sujets/conditionnés à Votre acceptation et à votre respect de la Politique de confidentialité de la Société. Notre politique de confidentialité décrit nos politiques et procédures sur la collecte, l’utilisation et la divulgation de vos renseignements personnels lorsque vous utilisez l’application ou le site Web et vous informe de vos droits portant sur la gestion de vos informations privées et de la façon dont la loi vous protège. Veuillez lire attentivement notre politique de confidentialité avant d’utiliser notre service.
                                </p>


                                <h2 className="h2 mb-5 mt-5">Passer des commandes de marchandises</h2>

                                <p className="p">
                                    En passant une Commande de Biens par le biais du Service, Vous garantissez que Vous êtes légalement capable de conclure des contrats contraignants et de respecter tous vos engagements en rapport avec tout contrat et l’utilisation de nos services.
                                </p>
                                <h3 className="mb-3 mt-3">Vos informations</h3>
                                <p className="p">
                                    Si vous souhaitez passer une commande de biens disponibles sur le Service que nous offrons, il peut vous être demandé de fournir certaines informations pertinentes pour votre commande, y compris, sans s’y limiter, votre nom, votre adresse e-mail, votre numéro de téléphone, votre numéro de carte de crédit, la date d’expiration de votre carte de crédit, votre adresse de facturation et vos informations d’expédition, etc.
                                </p>
                                <p className="p">
                                    Vous déclarez et garantissez que : (i) Vous avez le droit légal d’utiliser toute carte de crédit ou de débit ou tout autre mode de paiement dans le cadre de toute Commande ; et que (ii) les informations que Vous nous fournissez sont véridiques, correctes et complètes.
                                </p>
                                <p className="p">
                                    En soumettant ces informations, vous nous accordez le droit de fournir les informations à des tiers traitant les paiements dans le but de faciliter l’exécution de votre commande.
                                </p>
                                <h3 className="mb-3 mt-3">Annulation de commande</h3>
                                <p className="p">
                                    Nous nous réservons le droit de refuser ou d’annuler votre commande à tout moment pour certaines raisons, y compris, mais sans s’y limiter:
                                </p>
                                <ul className="ul">
                                    <li className="li">Disponibilité des marchandises</li>
                                    <li className="li">Erreurs dans la description ou les prix des marchandises</li>
                                    <li className="li">Erreurs dans votre commande</li>
                                </ul>
                                <p className="p">
                                    Nous nous réservons le droit de refuser ou d’annuler votre commande si une fraude ou une transaction non autorisée ou illégale est suspectée.
                                </p>
                                <h3 className="mb-3 mt-3">Vos droits d’annulation de commande</h3>
                                <p className="p">
                                    Toute marchandise que vous achetez ne peut être retournée que conformément aux présentes conditions générales et à notre politique de retour.
                                </p>
                                <p className="p">
                                    Notre politique de retour fait partie de ces termes et conditions. Veuillez lire notre Politique de retour pour en savoir plus sur votre droit d’annuler votre commande.
                                </p>
                                <p className="p">
                                    Votre droit d’annuler une Commande ne s’applique qu’aux Marchandises qui sont retournées dans le même état que Vous les avez reçues. Vous devez également inclure toutes les instructions, documents et emballages des produits. Les marchandises qui sont endommagées ou qui ne sont pas dans le même état que vous les avez reçues ou qui sont portées simplement au-delà de l’ouverture de l’emballage d’origine ne seront pas remboursées. Vous devez donc prendre raisonnablement soin des Marchandises achetées pendant qu’elles sont en Votre possession.
                                </p>
                                <p className="p">
                                    Nous nous occuperons de saisir l’autre partie afin de requérir de vous rembourser au plus tard  90 jours à compter du jour où vous recevrez les marchandises retournées. Nous utiliserons le même moyen de paiement que celui que vous avez utilisé pour la commande, et vous pouvez encourir des frais pour un tel remboursement.
                                </p>
                                <p className="p">
                                    Vous n’aurez aucun droit d’annuler une Commande pour la fourniture de l’une des Marchandises suivantes :
                                </p>
                                <ul className="ul">
                                    <li className="li">La fourniture de Biens fabriqués selon Vos spécifications ou clairement personnalisés.</li>
                                    <li className="li">
                                        La fourniture de Biens qui, selon leur nature, ne sont pas aptes à être retournés, se détériorent rapidement ou lorsque la date d’expiration est dépassée.
                                    </li>
                                    <li className="li">
                                        La fourniture de marchandises qui ne conviennent pas au retour pour des raisons de protection de la santé ou d’hygiène et qui ont été descellées après la livraison.
                                    </li>
                                    <li className="li">
                                        La fourniture de Biens qui sont, après livraison, selon leur nature, indissociablement mélangés à d’autres articles ou produits.
                                    </li>
                                    <li className="li">
                                        La fourniture de contenu numérique qui n’est pas fourni sur un support matériel si l’exécution a commencé avec Votre consentement exprès préalable et que Vous avez reconnu Votre perte de droit de rétractation.
                                    </li>
                                </ul>
                                <h3 className="mb-3 mt-3">Disponibilité, erreurs et inexactitudes</h3>
                                <p className="p">
                                    Nous mettons constamment à jour nos offres de service. Les Biens disponibles sur Notre Service peuvent être mal évalués, décrits de manière inexacte ou indisponibles, et Nous pouvons rencontrer des retards dans la mise à jour des informations concernant les Biens sur le Service et dans Notre publicité sur d’autres sites Web.
                                </p>
                                <p className="p">
                                    Nous ne pouvons pas et ne garantissons pas l’exactitude ou l’exhaustivité de toute information, y compris les prix, les images de produits, les spécifications, la disponibilité et les services. Nous nous réservons le droit de modifier ou de mettre à jour les informations et de corriger les erreurs, inexactitudes ou omissions à tout moment et sans préavis.
                                </p>
                                <h3 className="mb-3 mt-3">Politique des prix</h3>
                                <p className="p">
                                    La Société se réserve le droit de réviser les prix à tout moment avant d’accepter une Commande.
                                </p>
                                <p className="p">
                                    Les prix indiqués peuvent être révisés par la Société après l’acceptation d’une Commande en cas d’événement affectant la livraison causé par une action gouvernementale, une variation des droits de douane, des frais d’expédition accrus, des coûts de change plus élevés et toute autre question indépendante de la volonté de la Société. Dans ce cas, Vous aurez le droit d’annuler Votre Commande.
                                </p>
                                <h3 className="mb-3 mt-3">Paiements</h3>
                                <p className="p">
                                    Toutes les marchandises achetées sont soumises à un paiement unique ou comme convenu ou contracté. Le paiement peut être effectué via différentes méthodes de paiement que nous avons à disposition, telles que Visa, MasterCard, Affinity Card, cartes American Express, paiements mobiles ou méthodes de paiement en ligne (Stripe, PayPal, par exemple).
                                </p>
                                <p className="p">
                                    Les cartes de paiement (cartes de crédit ou de débit) sont soumises à des contrôles de validation et à une autorisation par l’émetteur de votre carte. Si nous ne recevons pas l’autorisation requise, nous ne serons pas responsables de tout retard ou non-livraison de votre commande.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Promotions</h2>

                                <p className="p">
                                    Toutes les Promotions mises à disposition par le biais du Service peuvent être régies par des règles distinctes des présentes Conditions.
                                </p>
                                <p className="p">
                                    Si vous participez à des promotions, veuillez consulter les règles applicables ainsi que notre politique de confidentialité. Si les règles d’une Promotion entrent en conflit avec les présentes Conditions, les règles de la Promotion s’appliqueront.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Comptes d’utilisateurs</h2>

                                <p className="p">
                                    Lorsque vous créez un compte chez nous, vous devez nous fournir des informations exactes, complètes et à jour en tout temps. Ne pas le faire constitue une violation des Conditions, ce qui peut entraîner la résiliation immédiate de Votre compte sur Notre Service.
                                </p>
                                <p className="p">
                                    Vous êtes responsable de la protection du mot de passe que vous utilisez pour accéder au service et de toute activité ou action sous votre mot de passe, que votre mot de passe soit avec notre service ou un service de médias sociaux tiers.
                                </p>
                                <p className="p">
                                    Vous acceptez de ne pas divulguer votre mot de passe à un tiers. Vous devez nous aviser immédiatement dès que vous avez connaissance de toute violation de la sécurité ou de toute utilisation non autorisée de votre compte.
                                </p>
                                <p className="p">
                                    Vous ne pouvez pas utiliser comme nom d’utilisateur le nom d’une autre personne ou entité ou qui n’est pas légalement disponible pour utilisation, un nom ou une marque de commerce qui est soumis à des droits d’une autre personne ou entité autre que vous sans autorisation appropriée, ou un nom qui est autrement offensant, vulgaire ou obscène.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Contenu</h2>

                                <h3 className="mb-3 mt-3">Votre droit de publier du contenu</h3>

                                <p className="p">
                                    Notre Service Vous permet de publier du Contenu. Vous êtes responsable du Contenu que Vous publiez sur le Service, y compris de sa légalité, de sa fiabilité et de sa pertinence.
                                </p>
                                <p className="p">
                                    En publiant du Contenu sur le Service, Vous Nous accordez le droit et la licence d’utiliser, de modifier, d’exécuter publiquement, d’afficher publiquement, de reproduire et de distribuer ce Contenu sur et via le Service. Vous conservez tous vos droits sur tout contenu que vous soumettez, publiez ou affichez sur ou via le service et vous êtes responsable de la protection de ces droits. Vous acceptez que cette licence comprenne le droit pour Nous de mettre Votre Contenu à la disposition d’autres utilisateurs du Service, qui peuvent également utiliser Votre Contenu sous réserve des présentes Conditions.
                                </p>
                                <p className="p">
                                    Vous déclarez et garantissez que : (i) le Contenu vous appartient (vous en êtes propriétaire) ou vous avez le droit de l’utiliser et de nous accorder les droits et licences prévus dans les présentes Conditions, et (ii) la publication de votre contenu sur ou via le Service ne viole pas les droits à la vie privée, les droits de publicité, les droits d’auteur, les droits contractuels ou tout autre droit de toute personne.
                                </p>
                                <h3 className="mb-3 mt-3">Restrictions de contenu</h3>
                                <p className="p">
                                    La Société n’est pas responsable du contenu des utilisateurs du Service. Vous comprenez et acceptez expressément que vous êtes seul responsable du contenu et de toute activité qui se produit sous votre compte, que ce soit par vous ou par tout tiers utilisant votre compte.
                                </p>
                                <p className="p">
                                    Vous ne pouvez transmettre aucun Contenu illégal, offensant, bouleversant, destiné à dégoûter, menaçant, calomnieux, diffamatoire, obscène ou autrement répréhensible. Des exemples de tels contenus répréhensibles incluent, sans toutefois s’y limiter, les éléments suivants :
                                </p>
                                <ul className="ul">
                                    <li className="li">Activité illégale ou faisant la promotion d’activités illégales.</li>
                                    <li className="li">
                                        Contenu diffamatoire, discriminatoire ou mesquin, y compris des références ou des commentaires sur la religion, la race, l’orientation sexuelle, le sexe, l’origine nationale / ethnique ou d’autres groupes ciblés.
                                    </li>
                                    <li className="li">
                                        Spam, généré automatiquement – ou aléatoirement – constituant de la publicité non autorisée ou non sollicitée, des chaînes de lettres, toute autre forme de sollicitation non autorisée ou toute forme de loterie ou de jeu.
                                    </li>
                                    <li className="li">
                                        Contenir ou installer des virus, des vers, des logiciels malveillants, des chevaux de Troie ou tout autre contenu conçu ou destiné à perturber, endommager ou limiter le fonctionnement de tout logiciel, matériel ou équipement de télécommunications ou à endommager ou obtenir un accès non autorisé à toute donnée ou autre information d’un tiers.
                                    </li>
                                    <li className="li">
                                        Enfreindre les droits de propriété d’une partie, y compris les brevets, les marques de commerce, les secrets commerciaux, les droits d’auteur, les droits de publicité ou d’autres droits.
                                    </li>
                                    <li className="li">
                                        Usurper l’identité de toute personne ou entité, y compris la Société et ses employés ou représentants.
                                    </li>
                                    <li className="li">Violer la vie privée de toute tierce personne.</li>
                                    <li className="li">Fausses informations et fonctionnalités.</li>
                                </ul>
                                <p className="p">
                                    La Société se réserve le droit, mais non l’obligation, de déterminer, à sa seule discrétion, si  un Contenu est approprié et conforme aux présentes Conditions, de refuser ou de supprimer ce Contenu. La Société se réserve en outre le droit d’effectuer des mises en forme et des modifications et de modifier la manière dont tout Contenu est présenté. La Société peut également limiter ou révoquer l’utilisation du Service si Vous publiez un tel Contenu répréhensible. Comme la Société ne peut pas contrôler tout le contenu publié par les utilisateurs et / ou des tiers sur le Service, vous acceptez d’utiliser le Service à vos propres risques. Vous comprenez qu’en utilisant le Service, Vous pouvez être exposé à un contenu que Vous pourriez trouver offensant, indécent, incorrect ou répréhensible, et Vous acceptez qu’en aucun cas la Société ne sera responsable de quelque manière que ce soit de tout contenu, y compris toute erreur ou omission dans tout contenu, ou toute perte ou dommage de quelque nature que ce soit encouru à la suite de votre utilisation de tout contenu.
                                </p>
                                <h3 className="mb-3 mt-3">Sauvegardes de contenu</h3>
                                <p className="p">
                                    Bien que des sauvegardes régulières du Contenu soient effectuées, la Société ne garantit pas qu’il n’y aura pas de perte ou de corruption de données.
                                </p>
                                <p className="p">
                                    Les points de sauvegarde corrompus ou non valides peuvent être causés, sans s’y limiter, par le contenu corrompu avant d’être sauvegardé ou qui change au cours de la sauvegarde.
                                </p>
                                <p className="p">
                                    La Société fournira une assistance et tentera de résoudre tout problème connu ou découvert pouvant affecter les sauvegardes du Contenu. Mais Vous reconnaissez que la Société n’a aucune responsabilité liée à l’intégrité du Contenu ou à l’échec de la restauration réussie du Contenu à un état utilisable.
                                </p>
                                <p className="p">
                                    Vous acceptez de conserver une copie complète et exacte de tout Contenu dans un endroit indépendant du Service.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Politique sur les droits d’auteur</h2>
                                <h3 className="mb-3 mt-3">Violation de la propriété intellectuelle</h3>
                                <p className="p">
                                    Nous respectons les droits de propriété intellectuelle d’autrui. Notre politique est de répondre à toute réclamation selon laquelle le Contenu publié sur le Service enfreint une violation du droit d’auteur ou de toute autre violation de la propriété intellectuelle de toute personne.
                                </p>
                                <p className="p">
                                    Si vous êtes titulaire d’un droit d’auteur, ou autorisé au nom d’un tel droit d’auteur, et que vous pensez que l’œuvre protégée par le droit d’auteur a été copiée d’une manière qui constitue une violation du droit d’auteur qui a lieu par le biais du service, vous devez soumettre votre avis par écrit à l’attention de notre agent des droits d’auteur par courrier électronique à <a href="mailto:info@sookotrade.com" target="_blank">info@sookotrade.com</a> et inclure dans votre avis une description détaillée de la violation présumée.
                                </p>
                                <p className="p">
                                    Vous pouvez être tenu responsable des dommages (y compris les coûts et les honoraires d’avocat) pour avoir faussement déclaré qu’un Contenu enfreint Vos droits d’auteur.
                                </p>
                                <h3 className="mb-3 mt-3">Avis DMCA et procédure DMCA pour les réclamations pour violation du droit d’auteur</h3>
                                <p className="p">
                                    Vous pouvez soumettre une notification conformément au Digital Millennium Copyright Act (DMCA) en fournissant à notre agent des droits d’auteur les informations suivantes par écrit (voir 17 U.S.C 512 (c) (3) pour plus de détails):
                                </p>
                                <ul className="ul">
                                    <li className="li">Une signature électronique ou physique de la personne autorisée à agir au nom du titulaire de l’intérêt du droit d’auteur.</li>
                                    <li className="li">
                                        Une description de l’œuvre protégée par le droit d’auteur qui, selon vous, a été violée, y compris l’URL (c.-à-d. l’adresse de la page Web) de l’endroit où l’œuvre protégée par le droit d’auteur existe ou une copie de l’œuvre protégée par le droit d’auteur.
                                    </li>
                                    <li className="li">
                                        Identification de l’URL ou d’un autre emplacement spécifique sur le Service où se trouve le matériel que vous prétendez enfreindre.
                                    </li>
                                    <li className="li">Votre adresse, votre numéro de téléphone et votre adresse e-mail.</li>
                                    <li className="li">
                                        Une déclaration de votre part selon laquelle vous croyez de bonne foi que l’utilisation contestée n’est pas autorisée par le propriétaire du droit d’auteur, son agent ou la loi.
                                    </li>
                                    <li className="li">
                                        Une déclaration de votre part, faite sous peine de parjure, que les informations ci-dessus dans votre avis sont exactes et que vous êtes le propriétaire du droit d’auteur ou autorisé à agir au nom du propriétaire du droit d’auteur.
                                    </li>
                                </ul>
                                <p className="p">
                                    Vous pouvez contacter notre agent des droits d’auteur par e-mail à <a href="mailto:info@sookotrade.com" target="_blank">info@sookotrade.com</a>. Dès réception d’une notification, la Société prendra toute mesure, à sa seule discrétion, qu’elle jugera appropriée, y compris la suppression du contenu contesté du Service.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Propriété intellectuelle</h2>

                                <p className="p">
                                    Le Service et son contenu original (à l’exclusion du Contenu fourni par Vous ou d’autres utilisateurs), les caractéristiques et les fonctionnalités sont et resteront la propriété exclusive de la Société et de ses concédants de licence.
                                </p>
                                <p className="p">
                                    Le Service est protégé par le droit d’auteur, les marques de commerce et d’autres lois du Pays ou la Société a son siège social et des pays étrangers.
                                </p>
                                <p className="p">
                                    Nos marques de commerce et notre logo, et autre droit commercial ne peuvent être utilisés en relation avec aucun produit ou service sans le consentement écrit préalable de la Société.
                                </p>


                                <h2 className="h2 mb-5 mt-5">Vos commentaires</h2>

                                <p className="p">
                                    Vous cédez tous les droits, titres et intérêts sur tout Commentaire que Vous fournissez à la Société. Si, pour une raison quelconque, une telle cession est inefficace, Vous acceptez d’accorder à la Société un droit et une licence non exclusifs, perpétuels, irrévocables, libres de redevances et mondiaux d’utiliser, de reproduire, de divulguer, de sous-licencier, de distribuer, de modifier et d’exploiter ces Commentaires sans restriction.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Liens vers d’autres sites Web</h2>

                                <p className="p">
                                    Notre Service peut contenir des liens vers des sites Web ou des services tiers qui ne sont pas détenus ou contrôlés par la Société.
                                </p>
                                <p className="p">
                                    La Société n’a aucun contrôle sur le contenu, les politiques de confidentialité ou les pratiques de tout site Web ou service tiers et n’assume aucune responsabilité à cet égard. Vous reconnaissez et acceptez en outre que la Société ne sera pas responsable, directement ou indirectement, de tout dommage ou perte causé ou prétendument causé par ou en relation avec l’utilisation ou la confiance accordée à un tel contenu, biens ou services disponibles sur ou via ces sites Web ou services.
                                </p>
                                <p className="p">
                                    Nous vous conseillons fortement de lire les termes et conditions et les politiques de confidentialité de tout site Web ou service tiers que vous visitez.
                                </p>


                                <h2 className="h2 mb-5 mt-5">Rupture de Service</h2>

                                <p className="p">
                                    Nous pouvons résilier ou suspendre votre compte immédiatement, sans préavis ni responsabilité, pour quelque raison que ce soit, y compris, sans limitation, si vous enfreignez les présentes conditions générales ou notre politique de confidentialité.
                                </p>
                                <p className="p">
                                    En cas de résiliation, Votre droit d’utiliser le Service cessera immédiatement. Si Vous souhaitez résilier Votre Compte, Vous pouvez simplement cesser d’utiliser le Service.
                                </p>


                                <h2 className="h2 mb-5 mt-5">Limitation de responsabilité</h2>

                                <p className="p">
                                    Nonobstant tout dommage que Vous pourriez encourir, l’entière responsabilité de la Société et de l’un de ses fournisseurs en vertu de toute disposition des présentes Conditions et de Votre recours exclusif pour tout ce qui précède sera limitée au montant de 1 USD  pour la Société tout au long du Service  fourni  et /ou au montant des marchandises pour les parties effectuant des transactions.
                                </p>
                                <p className="p">
                                    Dans toute la mesure permise par la loi applicable, la Société ou ses fournisseurs ne peuvent en aucun cas être tenus responsables de tout dommage spécial, accessoire, indirect ou consécutif quel qu’il soit (y compris, mais sans s’y limiter, les dommages pour perte de profits, perte de données ou d’autres informations, pour interruption d’activité, pour blessure corporelle, perte d’informations privées découlant de ou liée de quelque manière que ce soit à l’utilisation ou à l’incapacité d’utiliser le Service,  logiciel tiers et/ou matériel tiers utilisé avec le Service, ou autrement en relation avec toute disposition des présentes Conditions), même si la Société ou tout fournisseur a été informé de la possibilité de tels dommages et même si le recours échoue à son objectif essentiel.
                                </p>
                                <p className="p">
                                    Certains États ou pays n’autorisent pas l’exclusion de garanties implicites ou la limitation de responsabilité pour les dommages accessoires ou consécutifs, ce qui signifie que certaines des limitations ci-dessus peuvent ne pas s’appliquer. Dans ces États ou pays, la responsabilité de chaque partie sera limitée dans toute la mesure permise par la loi.
                                </p>


                                <h2 className="h2 mb-5 mt-5">Clause de non-responsabilité « EN L’ÉTAT » et « TEL QUE DISPONIBLE »</h2>

                                <p className="p">
                                    Le Service Vous est fourni « EN L’ÉTAT » et « TEL QUE DISPONIBLE » et avec tous les défauts et défauts sans garantie d’aucune sorte. Dans toute la mesure permise par la loi applicable, la Société, en son propre nom et au nom de ses Sociétés affiliées et de ses concédants de licence et fournisseurs de services respectifs, décline expressément toute garantie, expresse, implicite, légale ou autre, en ce qui concerne le Service, y compris toutes les garanties implicites de qualité marchande, d’adéquation à un usage particulier, de titre et d’absence de contrefaçon,  et les garanties qui peuvent découler du cours des transactions, du cours de l’exécution, de l’utilisation ou de la pratique commerciale. Sans limitation à ce qui précède, la Société ne fournit aucune garantie ou engagement, et ne fait aucune déclaration de quelque nature que ce soit que le Service répondra à Vos exigences, atteindra les résultats escomptés, sera compatible ou fonctionnera avec tout autre logiciel, application, système ou service, fonctionnera sans interruption, répondra à des normes de performance ou de fiabilité ou sera exempt d’erreurs ou que toute erreur ou défaut peut ou sera corrigé.
                                </p>
                                <p className="p">
                                    Sans limiter ce qui précède, ni la Société ni aucun des fournisseurs de la société ne fait aucune déclaration ou garantie de quelque nature que ce soit, expresse ou implicite: (i) quant au fonctionnement ou à la disponibilité du Service, ou aux informations, contenus et matériaux ou produits qui y sont inclus; (ii) que le Service sera ininterrompu ou exempt d’erreurs ; (iii) quant à l’exactitude, la fiabilité ou l’actualité de toute information ou contenu fourni par le biais du Service ; ou (iv) que le Service, ses serveurs, le contenu ou les e-mails envoyés depuis ou au nom de la Société sont exempts de virus, scripts, chevaux de Troie, vers, logiciels malveillants, bombes à retardement ou autres composants nuisibles.
                                </p>
                                <p className="p">
                                    Certaines juridictions n’autorisent pas l’exclusion de certains types de garanties ou de limitations sur les droits statutaires applicables d’un consommateur, de sorte que certaines ou toutes les exclusions et limitations ci-dessus peuvent ne pas s’appliquer à Vous. Mais dans un tel cas, les exclusions et limitations énoncées dans la présente section seront appliquées dans toute la mesure où elles sont applicables en vertu de la loi applicable.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Droit applicable</h2>

                                <p className="p">
                                    Les lois du Pays de la Société, à l’exclusion de ses règles de conflits de lois, régiront les Conditions et Votre utilisation du Service. Votre utilisation de l’Application peut également être soumise à d’autres lois locales, étatiques, nationales ou internationales.
                                </p>


                                <h2 className="h2 mb-5 mt-5">Règlement des différends</h2>

                                <p className="p">
                                    Si vous avez des préoccupations ou des différends au sujet du Service, vous acceptez d’essayer d’abord de résoudre le litige de manière informelle en contactant la Société.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Pour les utilisateurs de l’Union européenne (UE)</h2>

                                <p className="p">
                                    Si vous êtes un consommateur de l’Union européenne, vous bénéficierez de toutes les dispositions impératives de la loi du pays dans lequel vous résidez.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Conformité légale aux États-Unis</h2>

                                <p className="p">
                                    Vous déclarez et garantissez que (i) vous n’êtes pas situé dans un pays soumis à l’embargo du gouvernement des États-Unis, ou qui a été désigné par le gouvernement des États-Unis comme un pays « soutenant le terrorisme », et (ii) vous ne figurez sur aucune liste du gouvernement des États-Unis des parties interdites ou restreintes.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Divisibilité et renonciation</h2>


                                <h3 className="mb-3 mt-3">Divisibilité</h3>
                                <p className="p">
                                    Si une disposition des présentes Conditions est jugée inapplicable ou invalide, cette disposition sera modifiée et interprétée de manière à atteindre les objectifs de cette disposition dans toute la mesure du possible en vertu de la loi applicable et les autres dispositions resteront pleinement en vigueur.
                                </p>
                                <h3 className="mb-3 mt-3">Renonciation</h3>
                                <p className="p">
                                    Sauf dans les cas prévus aux présentes, le défaut d’exercer un droit ou d’exiger l’exécution d’une obligation en vertu des présentes Conditions n’affectera pas la capacité d’une partie à exercer ce droit ou à exiger une telle exécution à tout moment par la suite et la renonciation à une violation ne constituera pas une renonciation à toute violation ultérieure.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Interprétation de la traduction</h2>

                                <p className="p">
                                    Ces Termes et Conditions peuvent avoir été traduits si Nous les avons mis à Votre disposition sur notre Service. Vous acceptez que le texte original en anglais prévale en cas de litige.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Modifications apportées aux présentes conditions générales</h2>

                                <p className="p">
                                    Nous nous réservons le droit, à notre seule discrétion, de modifier ou de remplacer les présentes Conditions à tout moment. Si une révision est importante, nous ferons des efforts raisonnables pour fournir un préavis d’au moins 30 jours avant l’entrée en vigueur de toute nouvelle modalité. Ce qui constitue un changement important sera déterminé à notre seule discrétion.
                                </p>
                                <p className="p">
                                    En continuant à accéder ou à utiliser Notre Service après l’entrée en vigueur de ces révisions, Vous acceptez d’être lié par les conditions révisées. Si vous n’acceptez pas les nouvelles conditions, en tout ou en partie, veuillez cesser d’utiliser le site Web et le service.
                                </p>

                                <h2 className="h2 mb-5 mt-5">Contactez-nous</h2>

                                <p className="p">Si vous avez des questions concernant ces Termes et Conditions, Vous pouvez nous contacter :</p>
                                <ul className="ul">
                                    <li className="li">Par courriel: <a href="mailto:info@sookotrade.com" target="_blank">info@sookotrade.com</a></li>
                                    <li className="li">Par numéro de téléphone: <a href="tel:1.888.777.8578" target="_blank">1.888.777.8578</a></li>
                                </ul>
                            </div>
                        </div>
                    }
                </div>
                <div className="mb-8"></div>
            </div>
        </>
    )
}

export default withTranslation()(React.memo(Terms));