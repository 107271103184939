import './rfq.scss';
import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import PurchasePdf from "./PurchasePdf";
import ProformaPdf from "./ProformaPdf";
import "react-chat-elements/dist/main.css";
import { Popup } from 'react-chat-elements';
import { Input } from "react-chat-elements";
import { Button } from "react-chat-elements";
import { withRouter } from "react-router-dom";
import { paginate } from "@helpers/helpers";
import { ChatList } from "react-chat-elements";
import { withTranslation } from "react-i18next";
import { MessageList } from "react-chat-elements";
import Pagination from '@material-ui/lab/Pagination';
import { sendDocBase64 } from "@action/rfqs-actions";
import ModalInfo from "@components/customs/modalInfo";
import SuccessModal from '@components/pages/succesModal';
import { checkIfEmailInString, checkIfPhoneInString } from "@utils/custom-index";
import { createMessage, getAllSellerToChatWith, getMessagesFromChat, getLastMessagesForChat, comfirmAndProceedRFQ } from "@action/chat-actions";

class RfqChat extends React.Component {

  constructor(props) {
    super(props);
    this.id = new URLSearchParams(this.props.location.search).get('id');
    this.state = {
      showModalRfqDetail: false,
      showPendingProcess: false,
      rfqItemSelected: null,
      activeMessages: [],

      chatItemSelected: null,

      messageContentEmailPopup: false,
      messageContentPhoneNumberPopup: false,

      confirmProcesModal: false,
      generate: false,

      loading: false,
      loadingConfirmAndProceed: false,
      cancelProceed: false,

      chatList: [],
      message: '',
      messageValid: false,
      page: 1,
      pageSize: 10,
      showModalDocumentSucess: false,
    };

  }

  isSeller = () => this.props.authUser.authUser && this.props.authUser.authUser.userType === 'SELLER';
  isBuyer = () => this.props.authUser.authUser && this.props.authUser.authUser.userType === 'BUYER';

  sendBase64Doc = (base64File, isPurchase) => {
    const sendBase64 = sendDocBase64(this.state.chatItemSelected.id, base64File, isPurchase ? 'PURCHASE' : 'PROFORMAT');
    Promise.all([sendBase64]).then((result) => {
      this.setState({ generate: false, showModalRfqDetail: true });
    }).catch((err) => {
      comfirmAndProceedRFQ(this.state.chatItemSelected.chat.id, this.props.authUser.authUser.id).then((result) => {
        const chatItemSelectedUpdated = { ...this.state.chatItemSelected, chat: result };
        this.setState({ chatItemSelected: chatItemSelectedUpdated });
      }).catch((err) => {
        console.log(err)
      });
      this.setState({ generate: false });
    });
  }


  setPurchasePdfBase64 = (base64Value) => {
    this.sendBase64Doc(base64Value, true);
  }

  setProformaPdfBase64 = (base64Value) => {
    //console.log('PROFORMAT => ', base64Value);
    this.sendBase64Doc(base64Value, false);
  }

  invalidMessage = () => {

    let valid = true;

    if (this.state.message.trim() === "")
      valid = valid && false;

    return valid;
  }


  setConfirmProceedModal = (value, toCancelProceed = false) => {
    this.setState({ confirmProcesModal: value, cancelProceed: toCancelProceed });
  };

  setShowModalRfqDetail = (value) => {
    this.setState({ showModalRfqDetail: value });
  };

  setShowPendingProcess = (value) => {
    this.setState({ showPendingProcess: value });
  };

  getRFQ = (quotationId = null) => {

    this.setState({ loading: true });

    if (this.props.authUser.authUser && this.props.authUser.authUser.userType === 'BUYER') {

      getAllSellerToChatWith(quotationId).then((result) => {
        const f = result.filter(item => item.chat != null);
        this.setState({ chatList: [...f] });
        let chat = this.state.chatList.filter(item => item.id == this.id)[0];
        if (chat) {
          this.selectChatItem(chat);
        }
      }).catch((err) => {
        // toast(`Error to update approvement: ${err.message}`, { className: "toast-error" });
      }).finally(() => {
        this.setState({ loading: false });
      })
    }

    if (this.props.authUser.authUser && this.props.authUser.authUser.userType === 'SELLER') {

      getAllSellerToChatWith(quotationId).then((result) => {
        const f = result.filter(item => item.chat != null);
        this.setState({ chatList: [...f] });
        let chat = this.state.chatList.filter(item => item.id == this.id)[0];
        if (chat) {
          this.selectChatItem(chat);
        }
      }).catch((err) => {

        // toast(`Error: ${err.message}`, { className: "toast-error" });
      }).finally(() => {

        this.setState({ loading: false });
      })
    }
  }

  intervalToGetLastMessages = null;

  componentDidUpdate(prevProps) {
    if (prevProps.authUser.authUser != this.props.authUser.authUser) {
      const quotationId = this.props.match.params.quotationId;
      this.getRFQ(quotationId);
    }
  }

  componentDidMount() {
    const quotationId = this.props.match.params.quotationId;

    this.getRFQ(quotationId);

    this.intervalToGetLastMessages = setInterval(() => {
      if (this.state.activeMessages.length > 0 && this.state.chatItemSelected) {
        getLastMessagesForChat(this.state.chatItemSelected.chat.id).then((result) => {

          if (result.length > 0) {
            const firstInLast = result[0];
            const indexWhereWeMerge = this.state.activeMessages.findIndex(item => item.id == firstInLast.id);

            if (indexWhereWeMerge != -1) {

              const activeMessagesUpdate = [...this.state.activeMessages];
              //console.log('activeMessagesUpdateactiveMessagesUpdateactiveMessagesUpdateactiveMessagesUpdateactiveMessagesUpdate',activeMessagesUpdate);
              activeMessagesUpdate.splice(indexWhereWeMerge, this.state.activeMessages.length, ...result);

              this.setState({ activeMessages: activeMessagesUpdate }, () => {
                document.querySelector('.chat-box-custom').scrollTop = document.querySelector('.chat-box-custom').scrollHeight;
              });
            } else {
              this.selectChatItem(this.state.chatItemSelected, true);
            }
          }
        }).catch((err) => {
          // toast(`Failed to get messages: ${err.message}`, { className: "toast-error" });
        });
      }
      this.getRFQ(quotationId)
    }, 10000);


    let messageBody = document.querySelector('.message-list');
    messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
  }

  componentWillUnmount() {
    clearInterval(this.intervalToGetLastMessages);
  }


  handleChange = (ev) => {
    this.setState({ message: ev.target.value })
  }


  selectChatItem = (item, force = false) => {

    const shoulFetchAllChatDataForChatItem = !this.state.chatItemSelected || this.state.chatItemSelected.id != item.id;

    this.setState({ chatItemSelected: item }, () => {

      if (shoulFetchAllChatDataForChatItem || force) {

        if (this.props.authUser.authUser.userType === 'SELLER') {
          this.props.getMessagesFromChat(this.state.chatItemSelected.chat.id).then((result) => {
            this.props.countMessageUnread();
            this.setState({ activeMessages: result }, () => {
              document.querySelector('.chat-box-custom').scrollTop = document.querySelector('.chat-box-custom').scrollHeight;
              this.getRFQ();
            });
          }).catch((err) => {
            // toast(`Failed to get messages: ${err.message}`, { className: "toast-error" });
          });
        }

        if (this.props.authUser.authUser.userType === 'BUYER') {
          this.props.getMessagesFromChat(this.state.chatItemSelected.chat.id).then((result) => {
            this.props.countMessageUnread();
            this.setState({ activeMessages: result }, () => {
              document.querySelector('.chat-box-custom').scrollTop = document.querySelector('.chat-box-custom').scrollHeight;
              this.getRFQ();
            });
          }).catch((err) => {
            // toast(`Failed to get messages: ${err.message}`, { className: "toast-error" });
          });
        }

      }
    });
  }


  sendMessage = () => {

    const content = this.state.message;
    //this.props.chatMessages.activeMessages[0].chat.id

    if (checkIfEmailInString(this.state.message.trim())) {
      this.setState({ messageContentEmailPopup: true });
      return;
    }

    if (checkIfPhoneInString(this.state.message.trim())) {
      this.setState({ messageContentPhoneNumberPopup: true });
      return;
    }

    this.setState({ loading: true });

    createMessage(this.state.chatItemSelected.chat.id, this.props.authUser.authUser.id, content).then((result) => {
      this.props.getMessagesFromChat(this.state.chatItemSelected.chat.id).then((result) => {
        // const activeMessagesUpdate = [...this.state.activeMessages, ...result];
        this.setState({ activeMessages: result, message: '' }, () => {
          document.querySelector('.rce-input').value = '';
          document.querySelector('.chat-box-custom').scrollTop = document.querySelector('.chat-box-custom').scrollHeight;
        });
      }).catch((err) => {
        // toast(`Failed to get messages: ${err.message}`, { className: "toast-error" });
      });

    }).catch((err) => {

      // toast(`Failed to send message: ${err.message}`, { className: "toast-error" });
    }).finally(() => {

      this.setState({ loading: false });
    });
  }

  confirmAndProceed = () => {

    this.setState({ loadingConfirmAndProceed: true });
    comfirmAndProceedRFQ(this.state.chatItemSelected.chat.id, this.props.authUser.authUser.id).then((result) => {

      // toast(`Confirmation successfully`, { className: "toast-success" });
      this.setConfirmProceedModal(false);

      const chatItemSelectedUpdated = { ...this.state.chatItemSelected, chat: result };
      this.setState({ chatItemSelected: chatItemSelectedUpdated }, () => {
        if (result.buyerStatus && result.sellerStatus) {
          this.setState({ generate: true });
        } else {
          this.setShowPendingProcess(true);
        }
      });

    }).catch((err) => {

      toast(`Failed to confirm and proceed: ${err.message}`, { className: "toast-error" });
    }).finally(() => {

      this.setState({ loadingConfirmAndProceed: false });
    });
  }

  disableConfirmProceedSeller = () => {
    return (this.isSeller() && this.state.chatItemSelected && this.state.chatItemSelected.chat.sellerStatus);
  }

  disableConfirmProceedBuyer = () => {
    return (this.isBuyer() && this.state.chatItemSelected && this.state.chatItemSelected.chat.buyerStatus);
  }


  render() {


    return (
      <>
        <ModalInfo show={this.state.confirmProcesModal} closeModal={() => this.setConfirmProceedModal(false)}>
          <div className="text-center py-5">

            {
              this.state.cancelProceed ?
                <h2 className="text-danger">
                  {this.props.t('chat.go_cancel')}
                  <br />
                </h2> :

                <h2 className="text-success">
                  {this.props.t('chat.go_confirm')}
                  <br />
                </h2>
            }



            <h2 className="font-weight-light mt-2 mb-4"> {this.props.t('chat.sure')} </h2>

            <img
              src={"assets/images/confirmation-illustration.png"}
              style={{ width: "300px", height: "200px", margin: "0 auto" }}
              className="img-responsive"
              alt="Product image"
            />

            <div className="mt-5">
              <button className="mr-3 btn btn-warning" onClick={() => this.setConfirmProceedModal(false)}> {this.props.t('common.cancel')} </button>

              <button className="btn btn-success" onClick={() => this.confirmAndProceed()}>
                {this.state.loadingConfirmAndProceed ? (
                  <span>
                    <i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>
                    &nbsp;&nbsp;
                  </span>
                ) : null}
                {this.props.t('common.confirm')}
              </button>
            </div>
          </div>
        </ModalInfo>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h2 className="d-inline-block">RFQ Chat</h2>
          {this.state.chatItemSelected == null ?
            <p style={{ marginTop: 10, marginBottom: 50 }}>
              <strong style={{ marginRight: 10 }}>
                Note:
              </strong>
              {this.props.t('notes.chat_start').replace('{parti}', !this.isSeller() ? this.props.t('common.seller') : this.props.t('common.buyer'))}
            </p> : null}
          {this.state.chatItemSelected ? !this.state.chatItemSelected.baseQuotation.processing && !this.disableConfirmProceedSeller() && !this.disableConfirmProceedBuyer() ?
            <p style={{ marginTop: 10, marginBottom: 50 }}>
              <strong style={{ marginRight: 10 }}>
                Note:
              </strong>
              {this.props.t('notes.chat_confirm').replace('{button}', `${!this.isSeller() ? this.props.t('chat.confirm1_seller') : this.props.t('chat.confirm1_buyer')} ${this.props.t('chat.confirm2')}`)}
            </p> : null : null}
          {this.state.chatItemSelected ? !this.state.chatItemSelected.baseQuotation.processing && (this.disableConfirmProceedSeller() || this.disableConfirmProceedBuyer()) ?
            <p style={{ marginTop: 10, marginBottom: 50 }}>
              <strong style={{ marginRight: 10 }}>
                Note:
              </strong>
              {this.props.t('notes.chat_cancel').replace('{button}', this.props.t('chat.cancel')).replaceAll('{parti}', !this.isSeller() ? this.props.t('common.seller') : this.props.t('common.buyer'))}
            </p> : null : null}
          {this.state.chatItemSelected ? this.state.chatItemSelected.baseQuotation.processing ?
            <p style={{ marginTop: 10, marginBottom: 50 }}>
              <strong style={{ marginRight: 10 }}>
                Note:
              </strong>
              {this.props.t('notes.chat_end')}
            </p> : null : null}
          {
            this.state.chatItemSelected ? !this.state.chatItemSelected.baseQuotation.processing && this.props.authUser.authUser ?
              <div>
                {this.disableConfirmProceedSeller() || this.disableConfirmProceedBuyer() ?
                  <button onClick={() => this.setConfirmProceedModal(true, true)} className="btn-cancel-rfq float-right" style={{ display: this.state.chatItemSelected ? 'block' : 'none', fontWeight: "bold", margin: "0px" }}
                  > {this.props.t('chat.cancel')}
                  </button> :

                  <button onClick={() => this.setConfirmProceedModal(true)} className="btn-confirm-rfq float-right" style={{ display: this.state.chatItemSelected ? 'block' : 'none', fontWeight: "bold", margin: "0px" }}> {this.isBuyer() ? this.props.t('chat.confirm1_seller') : this.props.t('chat.confirm1_buyer')} {this.props.t('chat.confirm2')} </button>
                }
              </div> : null : null
          }

          {
            this.state.generate ?
              <>

                <PurchasePdf
                  generate={this.state.generate}
                  currentRfq={this.state.chatItemSelected}
                  setPurchasePdfBase64={this.setPurchasePdfBase64}
                />
                <ProformaPdf
                  generate={this.state.generate}
                  currentRfq={this.state.chatItemSelected}
                  setProformaPdfBase64={this.setProformaPdfBase64}
                />
              </> : null
          }
        </div>
        <hr style={{ margin: "15px 0px" }} />

        <div style={{ display: "flex" }}>
          <div style={{ flex: 2, borderRight: "", padding: "0px 10px" }}>
            {
              this.props.authUser.authUser && this.props.authUser.authUser.userType == 'SELLER' ?
                <ChatList
                  onClick={(selected) => { this.selectChatItem(this.state.chatList.filter(item => item.id == selected.id)[0]); }}
                  className={`chat-list`}
                  style={{ marginBottom: 30 }}
                  dataSource={paginate(this.state.chatList, this.state.page, this.state.pageSize).map((item, index) => ({
                    avatar: `${item.baseQuotation.user.avatar.startsWith('https://encryp') ? item.baseQuotation.user.avatar : process.env.BACKEND_BASE_URL + item.baseQuotation.user.avatar}`,
                    title: `${item.baseQuotation.user.name}`,
                    id: `${item.id}`,
                    statusColor: `${this.state.chatItemSelected ? this.state.chatItemSelected.id == item.id ? '#00c805' : 'gray' : 'gray'}`,
                    date: null,
                    subtitle: `${item.baseQuotation.product.name}`,
                    active: true,
                    unread: `${item.chat.lastWriter ? item.chat.lastWriter.id == item.baseQuotation.user.id && this.props.authUser.authUser.id != item.chat.lastWriter.id ? item.chat.unreadCount : 0 : 0}`
                  }))
                  }
                /> :
                <ChatList
                  onClick={(selected) => { this.selectChatItem(this.state.chatList.filter(item => item.id == selected.id)[0]); }}
                  className={`chat-list`}
                  style={{ marginBottom: 30 }}
                  dataSource={paginate(this.state.chatList, this.state.page, this.state.pageSize).map((item, index) => ({
                    avatar: `${item.user.avatar.startsWith('https://encryp') ? item.user.avatar : process.env.BACKEND_BASE_URL + item.user.avatar}`,
                    title: `${item.user.name}`,
                    id: `${item.id}`,
                    statusColor: `${this.state.chatItemSelected ? this.state.chatItemSelected.id == item.id ? '#00c805' : 'gray' : 'gray'}`,
                    date: null,
                    subtitle: `${item.baseQuotation.product.name}`,
                    active: true,
                    unread: `${item.chat.lastWriter ? item.chat.lastWriter.id == item.user.id && this.props.authUser.authUser.id != item.chat.lastWriter.id ? item.chat.unreadCount : 0 : 0}`
                  }))
                  }
                />
            }
            <Pagination
              variant="outlined"
              shape="rounded"
              size="large"
              style={{ marginTop: 30 }}
              count={Math.ceil(this.state.chatList.length / this.state.pageSize)}
              showFirstButton
              showLastButton
              onChange={(__, selectedPage) => this.setState({ page: selectedPage })}
            />
          </div>

          <div
            style={{
              flex: 5,
              background: "#f9f9f9",
              position: "relative",
              border: "1px solid gainsboro",
              paddingBottom: "",
            }}
          >
            {
              this.state.chatItemSelected ?
                !this.state.chatItemSelected.baseQuotation.processing ?
                  <div
                    style={{
                      bottom: "0px",
                      left: "0px",
                      right: "0px",
                      padding: "10px",
                    }}
                  >
                    <Input
                      placeholder={this.props.t('common.type_here')}
                      value={this.state.message}
                      onChange={this.handleChange}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          this.sendMessage()
                        }
                      }}
                      multiline={false}
                      rightButtons={
                        <Button color="white" backgroundColor="green" text={this.state.loading ? (
                          <span className="mx-3"><i className="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i></span>
                        ) : <span className="mx-3"><i className="fa fa-paper-plane"></i></span>} onClick={this.sendMessage} disabled={this.state.loading || !this.invalidMessage()} />
                      }
                    />
                  </div>
                  : null : null}
            <div style={{ height: "500px", overflow: "auto" }} className="chat-box-custom">
              <MessageList
                id="messageBody"
                className="message-list"
                lockable={true}
                toBottomHeight='100%'
                dataSource={

                  this.state.activeMessages.map((message, index) => ({
                    position: `${message.user.id != this.props.authUser.authUser.id ? 'right' : 'left'}`,
                    type: "text",
                    padding: "20px",
                    style: { background: "red" },
                    text: `${message.content}`,
                    date: new Date(message.createdAt),
                  }))
                }
              />
            </div>
            <Popup
              show={this.state.messageContentEmailPopup || this.state.messageContentPhoneNumberPopup}
              header='Error in message.'
              headerButtons={[{
                type: 'transparent',
                color: 'black',
                text: 'close',
                onClick: () => { this.setState({ messageContentEmailPopup: false, messageContentPhoneNumberPopup: false }) }
              }]}
              text={`${this.state.messageContentEmailPopup ? this.props.t('chat.mail_error') : this.props.t('chat.phone_error')}`}
              footerButtons={[]} />
          </div>
        </div>

        <SuccessModal
          shouldShowModal={this.state.showModalRfqDetail}
          close={() => this.setShowModalRfqDetail(false)}
          title={this.props.t('notes.document_generated')}
          subtitle={!this.isSeller() ? this.props.t('chat.purchase_sent') : this.props.t('chat.proforma_sent')}
          button={this.props.t('notes.go_to_document')}
          to={`${process.env.PUBLIC_URL}/account/dashboard/documents`}
        />

        <SuccessModal
          shouldShowModal={this.state.showPendingProcess}
          close={() => this.setShowPendingProcess(false)}
          title={this.props.t('notes.document_generated_pending')}
          subtitle={""}
          button={this.props.t('notes.go_to_document')}
          to={`${process.env.PUBLIC_URL}/account/dashboard/documents`}
        />
      </>
    );
  }
}

const mapStateToProps = ({ authUser, chatMessages }) => ({
  authUser, chatMessages
  //quotationId: this.props.match.params.quotationId
});

const mapDispatchToProps = {};

export default withRouter(withTranslation()(connect(mapStateToProps, { getMessagesFromChat })(RfqChat)));
