
const backendURLs = {

    AUTH: {
        SIGNIN: '/oauth/token',
        ME: '/auth/me',
        SIGNUP: '/auth/register',
        CHECK_EMAIL: '/public/api/users/activation/{token}',
        GOOGLE: 'auth/login/google',
        FACEBOOK: '/auth/login/facebook',
        LINKEDIN: '/auth/login/linkedin',
        USER: {
            UPADATE: {
                INFORMATIONS: '/public/api/users/{id}/update/informations',
                AVATAR: '/public/api/users/{id}/update/avatar',
                PASSWORD: '/public/api/users/{id}/update/password',
                COMPANY: '/public/api/users/{id}/update/company',
                LICENCE: '/public/api/users/{id}/update/licence',
                LANGUAGE: '/public/api/users/{id}/language'
            },
            RESET_PASSWORD: '/public/api/users/reset/password',
            GET_NOTIFS: '/public/api/users/{id}/notifications',
            COUNT_NOTIFS: '/public/api/users/{id}/notifications/count',
            SET_NOTIFS: '/public/api/users/notifications/{id}/read'
        }
    },

    SELLER: {
        PRODUCTS: {
            CREATE: '/public/api/products',
            FETCH: '/public/api/products/sellers/{id}',
            UPDATE: '/public/api/products/{id}/update',
            DELETE_ONE: '/public/api/products/{id}'
        }
    },

    PRODUCTS: {
        GET_ALL: '/public/api/products',
        GET_ONE: '/public/api/products/{id}',
        GET_INTERESTING: '/public/api/products/interesting',
        GET_PRODUCT_BY_CATEGORY: '/public/api/products/categories/category/{id}',

        GET_PRODUCTS_FROM_ID_LIMIT: '/public/api/products/next/limit',
        GET_COUNT_ALL_PRODUCTS: '/public/api/products/count',

        GET_PRODUCTS_CATEGORY_FROM_ID_LIMIT: '/public/api/products/categories/{id}/products/next/limit',
        GET_COUNT_ALL_PRODUCTS_IN_CATEGORY: '/public/api/products/categories/{id}/products/count'
    },

    RFQ: {
        CREATE: '/public/api/quotations/request',
        MARK_AS_READ_BUYER: '/public/api/quotations/{id}/read/buyer',
        MARK_AS_READ_SELLER: '/public/api/quotations/{id}/read/seller',
        GET_RFQ_ME: '/public/api/users/{id}/quotations',

        GET_RFQ_ONE_BUYER: '/public/api/users/{id}/quotations/buyer',
        GET_RFQ_ONE_SELLER: '/public/api/users/{id}/quotations/seller',

        GET_UNREADED_NUMBER_BUYER: '/public/api/users/{id}/quotations/count/buyer',
        GET_UNREADED_NUMBER_SELLER: '/public/api/users/{id}/quotations/count/seller',

        GET_ONE: '/public/api/quotations/{id}',
        GET_DETAILS: '/public/api/quotations/{id}/overview',
        GET_CHILD: '/public/api/quotations/{id}/quotations',

        UPDATE_RFQ_BY_BUYER: '/public/api/quotations/{id}/buyer/update',
        UPDATE_RFQ_BY_SELLER: '/public/api/quotations/{id}/seller/update',

        SIGN_RFQ_DOCS: '/api/documents/sign-request',
        CONTRAT: '/public/api/contracts',
        GET_ALL_TEXT_CONTRAT: '/public/api/pages/contents/contract',
        GET_ONE_CONTRACT: '/api/contracts/{id}'

    },

    DOCS: {
        GET_UNREADED_DOCS_USER: '/api/documents/new',
        MARK_AS_READ: '/api/documents/{id}/status',

        GET_ALL_DOCS_FOR_USER: '/api/documents',
        GET_ALL_DOC_FROM_SIGN_REQUEST: '/api/documents/sign-request/document/{uuid}',
    },

    CONFIG: {
        GET_ALL_UNITS: '/public/api/units',
        GET_ALL_PORTS: '/public/api/products/ports',
        GET_ALL_INCO_TERMS: '/public/api/products/incoterms',
        GET_ALL_PRODUCT_CATEGORY: '/public/api/products/categories',
        GET_ALL_PRODUCT_CATEGORY_USED: '/public/api/products/categories/used',
        GET_ALL_TYPE_OF_SHIPMENTS: '/public/api/products/type_shipment',
    },

    GET_ALL_INCO_TERMS: '/public/api/products/incoterms',
    GET_ALL_TRANSACTIONS: '/api/payment/transaction/user/{id}',
    AMOUNT_TO_PAY: '/api/payment/quotation-user-status/{id}/paid',
    SETTINGS: '/public/api/settings',
    FAQS: '/public/api/faqs',

    BLOG: {
        GET_ALL_ARTICLES: '/public/api/articles',
        GET_ONE_ARTICLE: '/public/api/articles/{id}',
        CREATE_ARTICLE: '/public/api/articles',
        CREATE_CATEGORY: '/public/api/article/categories',
        GET_ALL_CATEGORY: '/public/api/article/categories/tyoe',
        CREATE_COMMENT: '/public/api/articles/{id}/comments',
        GET_ALL_COMMENTS: '/public/api/articles/{id}/comments/approved',
        CREATE_CATEGORY_NON_USER: '/public/api/articles/{id}/comments/non/user',
        GET_ALL_ARTICLES_BY_CATEGORY: '/public/api/article/categories/{id}/articles',
        GET_ALL_INCO_TERMS: '/public/api/products/incoterms',
        //GET_ALL_DELIVERY_ENGINE: '/public/api/delivery/engines',
        //GET_ALL_PRODUCT_TYPE: '/public/api/products/types'
    },

    TESTIMONIALS: {
        GET_ALL_TESTIMONIALS: '/public/api/testimonials',
        GET_FAVORITES: '/public/api/testimonials/favorites',

        CREATE_TESTIMONY: '/api/testimonials'
    },

    PAGE_CONTENT: {
        GET_CONTENT_OF_PAGE: '/public/api/pages/contents',
        GET_COMMODITY_PRICES: '/public/api/pages/contents/commodities',
    },

    RFQ_CHAT: {
        CREATE_RFQ_CHAT: '/api/chats',
        GET_CONCERN_SELLERS_FOR_RFQ: '/public/api/quotations/{id}/concerned/seller',
        GET_CHATS: '/api/chats',
        COUNT_UNREAD_MESSAGES: '/api/chats/messages/unread/count',
        FETCH_MESSAGES: '/api/chats/quotation/buyer/seller/messages',
        CREATE_MESSAGE: '/api/chats/{id}/messages',
        FETCH_MESSAGES_FROM_CHAT: '/api/chats/{id}/messages',
        LAST_MESSAGES: '/api/chats/{id}/last/messages',
        CONFIRM_AND_PROCEED: '/api/chats/{chat_id}/users/{user_id}'
    },

    CONTRACT: {

        STRIPE_PAYMENT: '/api/payment/stripe',
        BANK_PAYMENT: '/api/payment/bank',
        PAYPAL_LINK: '/api/payment/{id}/paypal/link',
        PAYPAL_PAYMENT: '/api/payment/{id}/paypal',
        MOBILE_PAYMENT: "/api/payment/mobile/request/{id}",
        //MOBILE_PAYMENT_PAY: "/api/payment/cinetpay/pay/{signature}",

        GET_ALL_CONTRACTS_FOR_USER: '/api/contracts/user',
        UPDATE_CONTRACT: '/api/contracts/update/contract/{id}',
        MARK_AS_READ: '/api/contracts/{id}/read',

        GET_UNREAD_CONTRACTS_USER: '/api/contracts/unread/count',

        CREATE_CONTRACT: '/api/contracts',
        SIGN_CONTRACT_DOCS: '/api/contracts/sign-request/{id}',
        SIGN_CONTRACT_DOCS_URL: '/api/contracts/sign-request/{id}/url',

        GET_ALL_CONTRACTS_DOCUMENTS: '/api/contracts/{id}/documents/all',

        GET_FEES: '/public/api/payment/fees',
        GET_ALL_CONTRACTS_DOCUMENTS: '/api/contracts/{id}/documents',
        CONFIRM_SHOP: '/api/contracts/{id}/close/password/{password}',
        SEND_RATING: '/api/contracts/{id}/ratings',
        UPDATE_ATTACHED_FILE: '/api/contracts/document'
    },

    CONTACT: {

        CONTACT_SOOKO: '/public/api/contacts'
    },
    REFERRAL: {
        GENERATE: '/api/referrals/generate',
        USERS: '/api/referrals/users',
    }
}

export default backendURLs