import React from 'react';
import Modal from 'react-modal';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import greetAndMeetImg from '../../../public/assets/images/handshake-with-it-transprent-background-meet-and-greet.png'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'relative',
        maxWidth: '870px',
        marginRight: '2rem',
        padding: '.5rem',
        backgroundColor: '#fff'
    }
};

Modal.setAppElement('#root');

function SuccessModal(props) {

    return (

        <>
            <Modal
                shouldCloseOnOverlayClick={false}
                isOpen={props.shouldShowModal}
                onRequestClose={() => props.close()}
                contentLabel="login Modal"
                className="login-popup"
                id="login-popup-form"
                overlayClassName="cart-modal-overlay"
                style={customStyles}
            >
                <div className="modal-wrapper" style={{ height: "100%" }}>
                    <div className="container">
                        <div className="d-flex flex-column justify-content-center align-items-center">

                            <h2 className="text-center">
                                <div className="text-center">
                                    <img src={greetAndMeetImg} style={{ margin: "0 auto" }} width={250} />
                                </div>
                                <br />
                                {props.title} </h2>
                            <p>
                                {props.subtitle}
                            </p>

                            <br /><br />

                            <button type="button" className="btn btn-primary float-right px-5" onClick={() => {
                                props.history.push(props.to);
                                props.close();
                            }}>
                                {props.button}
                            </button>
                        </div>
                    </div>

                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => props.close()}>×</button>
                </div>
            </Modal>
        </>
    )

}

export default withTranslation()(React.memo( withRouter(SuccessModal)));