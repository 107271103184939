import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { withTranslation } from "react-i18next";
import ReactFlagsSelect from 'react-flags-select';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoUpdatedSuccessModal from './infoUpdatedSuccessModal';
import { updateUserCompanyInfo } from '@action/auth';
import { UPDATE_COMPANY_INFORMATIONS_USER_CLEAR } from '@constants/auth-action-types';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'relative',
        maxWidth: '870px',
        marginRight: '2rem',
        padding: '.5rem',
        backgroundColor: '#fff'
    }
};

Modal.setAppElement('#root');

function UserCompanyInformationModal(props) {
    const { t } = props;

    const { userCompanyUpdate } = useSelector(state => state.updateUserCompanyInformation);

    const { userAuthenticated } = props;

    const [fileToSend, setFileToSend] = useState(null);
    /*const [phone, setPhone] = useState(''); */
    const [countrySelected, setCountrySelected] = useState('');


    const { register, handleSubmit, errors, reset } = useForm({
        defaultValues: {
            companyName: userAuthenticated.companyName,
            companyCreationDate: userAuthenticated.companyCreationDate,
            fax: userAuthenticated.fax,
            websiteUrl: userAuthenticated.websiteUrl,
            businessRegistrationNumber: userAuthenticated.businessRegistrationNumber,
            streetAddress: userAuthenticated.streetAddress,
            city: userAuthenticated.city,
            province: userAuthenticated.province,
            postal: userAuthenticated.postal,
            keyContactName: userAuthenticated.keyContactName,
            companyBusinessRegistration: userAuthenticated.businessRegistrationNumber
        }
    });

    const { loading, error } = useSelector(state => state.updateUserInformation);

    const handleLicenceChange = (event) => {
        //setLicenceFile(URL.createObjectURL(event.target.files[0]));
        setFileToSend(URL.createObjectURL(event.target.files[0]));
        //console.log('company file =>', URL.createObjectURL(event.target.files[0]));


    }

    const dispatch = useDispatch();

    let errorUpdate = null;

    const { addClass = "header-icon" } = props;
    const [open, setOpen] = useState(false);

    const openModal = (e) => {
        e.preventDefault();
        setOpen(true);
    }
        
    const onCountrySelected = (code) => {
        setCountrySelected(code);
    }

    const closeModal = (fromChild = false) => {
        dispatch({ type: UPDATE_COMPANY_INFORMATIONS_USER_CLEAR })

        if (fromChild) {
            setTimeout(() => {
                setOpen(false);
            }, 2000);
        }

        setOpen(false);

    }

    if (error) {
        errorUpdate = (
            <div className="alert alert-danger">
                ERROR: {error.message}
            </div>
        )
    }


    const onSubmitForm = (data) => {
        /* $event.preventDefault();*/
        /*data['phone'] = phone;*/
        data['country'] = countrySelected;

        dispatch(updateUserCompanyInfo(data, userAuthenticated.id, result => {
            if (result.data && result.data.id)
                props.setUser(result.data);
        }));
        setOpen(false);
    }


    useEffect(() => {
        if (userAuthenticated) {
            reset({ ...userAuthenticated });
            /*setPhone(userAuthenticated.phone);*/
            setCountrySelected(userAuthenticated.country);
        }
    }, [userAuthenticated]);

    return (
        <>
            <Link to="#" className={`login-link ${addClass}`} onClick={openModal} title="Login"><i className="fa fa-pencil-alt" aria-hidden="true" style={{ fontSize: "medium", paddingRight: "5px" }}></i>{t("board.edit")}</Link>
            <Modal
                shouldCloseOnOverlayClick={false}
                isOpen={open}
                onRequestClose={closeModal}
                contentLabel="login Modal"
                className="login-popup"
                id="login-popup-form"
                overlayClassName="cart-modal-overlay"
                style={customStyles}
            >
                <div className="modal-wrapper" style={{ height: "100%" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="title mb-2">{t("board.update_title")}</h2>

                                {errorUpdate}
                                <InfoUpdatedSuccessModal closeEditModal={closeModal} haveBeenSuccessfullyUpdated={userCompanyUpdate} clearUpdateStateAfterUpdate={() => dispatch({ type: UPDATE_COMPANY_INFORMATIONS_USER_CLEAR })} />

                                {!userCompanyUpdate ? <form className="mb-1">
                                    <label htmlFor="company-name"> {t("board.company_name")} <span className="required">*</span></label>
                                    <input type="text" name="companyName" className="form-input form-wide mb-2" id="company-date" ref={register} required />

                                    <label htmlFor="Fax"> {t("board.company_fax")} </label>
                                    <input type="number" name="fax" className="form-input form-wide mb-2" id="fax" ref={register} required />

                                    <label htmlFor="websiteUrl"> {t("board.company_website_url")}</label>
                                    <input type="url" name="websiteUrl" className="form-input form-wide mb-2" id="websiteUrl" ref={register} required />

                                    <label htmlFor="buisiness-registration-number"> {t("board.company_registration_business_number")} <span className="required">*</span></label>
                                    <input type="text" name="businessRegistrationNumber" className="form-input form-wide mb-2" id="businessRegistrationNumber" ref={register} required />

                                    <label htmlFor="companyCreationDate">{t("board.company_creation_date")} <span className="required">*</span></label>
                                    <input type="date" max={new Date().toISOString().split('T')[0]} name="companyCreationDate" className="form-input form-wide mb-2" id="companyCreationDate" ref={register} required />

                                    <label htmlFor="streetAddress"> {t("board.company_street_address")} <span className="required">*</span></label>
                                    <input type="text" name="streetAddress" className="form-input form-wide mb-2" id="streetAddress" ref={register} required />

                                    <label htmlFor="city"> {t("board.company_city")} <span className="required">*</span></label>
                                    <input type="text" name="city" className="form-input form-wide mb-2" id="city" ref={register} required />

                                    <label htmlFor="province"> {t("board.company_state")} <span className="required">*</span></label>
                                    <input type="text" name="province" className="form-input form-wide mb-2" id="province" ref={register} required />

                                    <label htmlFor="postal"> {t("board.company_postal")} <span className="required">*</span></label>
                                    <input type="text" name="postal" className="form-input form-wide mb-2" id="postal" ref={register} required />

                                    <div className="form-group">
                                        <label htmlFor="country">{t("board.account_country")} <span className="required">*</span></label>
                                        <ReactFlagsSelect searchable selected={countrySelected} onSelect={code => onCountrySelected(code)}
                                            className="form-input form-wide mb-2"
                                        />
                                    </div>

                                    <label htmlFor="keyContactName"> {t("board.signer_officer")} <span className="required">*</span></label>
                                    <input type="text" name="keyContactName" className="form-input form-wide mb-2" id="keyContactName" ref={register} required />

                                    {/*<label htmlFor="companyBusinessRegistration"> Company Business Registration <span className="required">*</span></label>
                                    <input type="file" name="companyBusinessRegistration" onChange={handleLicenceChange} className="form-input form-wide mb-2" id="companyBusinessRegistration" ref={register} required />*/}

                                    <div className="form-footer">
                                        <button type="submit" className="btn btn-primary btn-md" disabled={loading} onClick={handleSubmit(onSubmitForm)}>
                                            {loading ? <span><i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> : null}
                                            {t("board.update")}
                                        </button>
                                    </div>
                                </form> : null}
                            </div>
                        </div>
                    </div>

                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={closeModal}>×</button>
                </div>
            </Modal>
        </>
    )

}

export default withTranslation()(React.memo(UserCompanyInformationModal));