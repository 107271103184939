import backendURLs from "../utils/backendURL";
import { joinBaseUrlWithParams, joinBaseUrlWithSearchParams,joinBaseUrlWithRequestParams, makeActionRequest, makeRequest, replaceIdParamInUrlFormat } from "./utils"

export const contactSooko = (data) => {

    let url = joinBaseUrlWithRequestParams(`${backendURLs.CONTACT.CONTACT_SOOKO}`, [
        {param: 'name', value: data.name},
        {param: 'email', value: data.email},
        {param: 'phone', value: data.phone},
        {param: 'message', value: data.message}      
    ]);

    return makeRequest('post', url, null, {});
}

export const getFaqs = () => {
    return makeRequest('get', backendURLs.FAQS, null, {});
}
