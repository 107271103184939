import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';


import { useSelector } from "react-redux";

import { findIndex } from '../../../utils';

import { addToCart, addToWishList, showQuickView } from '../../../action';
import { withTranslation } from "react-i18next";

function ProductTypeFive(props) {
    let { addClass, product, showCat = true, isDisplay = true, showQuickView } = props;
    let isInWishlist = props.product ? (findIndex(props.wishlist, props.product.id) ? true : false) : false;
    function addToCart(e) {
        if (e.currentTarget.parentElement.querySelector(".horizontal-quantity")) {
            let val = parseInt(e.currentTarget.parentElement.querySelector(".horizontal-quantity").getAttribute("value"));
            props.addToCart(product, val);
        }
    }

    const { authUser } = useSelector(state => state.authUser);
    const [langSelected, setLangSelected] = useState("en");
  
    useEffect(() => {
      if (authUser != null) {
        setLangSelected(authUser.language.toLowerCase());
      }
    }, [authUser])

    const { t } = props;

    const onWishlistClick = (e) => {
        if (!isInWishlist) {
            e.preventDefault();
            props.addToWishList(product);
        }
    }

    return (
        <div className={'product-default inner-quickview inner-icon quantity-input ' + addClass}>
            <figure>
                <Link to={`${process.env.PUBLIC_URL}/products/${product.id}/details`}>
                    <div className="lazy-overlay bg-3"></div>

                    <LazyLoadImage
                        alt="product"
                        src={ `${process.env.BACKEND_BASE_URL}${product.mainImagePath}` }
                        threshold={500}
                        effect="blur"
                    />
                    {/* {
                        product.pictures.length >= 2 ?
                            <LazyLoadImage
                                alt="product"
                                src={process.env.PUBLIC_URL + '/' + product.pictures[1]}
                                threshold={500}
                                effect="blur"
                                wrapperClassName="product-image-hover"
                            />
                            : ""
                    } */}
                </Link>
                <div className="label-group">
                    {product.new ? <span className="product-label label-new">NEW</span> : ""}
                </div>

                {/* <div className="btn-icon-group">
                    <Link to={`${process.env.PUBLIC_URL}/pages/wishlist`} className={`btn-icon btn-icon-wish ${isInWishlist ? 'checked' : ''}`} onClick={onWishlistClick}>
                        <i className="icon-heart"></i>
                    </Link>
                </div> */}

                <Link to="#" className="btn-quickview" title="Quick View" onClick={(e) => { e.preventDefault(); showQuickView(product); }}>{t('home.quick')}</Link>
            </figure>

            <div className="product-details">
                {
                    isDisplay === true && showCat === true ?
                        <div className="category-list">
                            {/* {
                                product.category.map((category, index) => (
                                    index === (product.category.length - 1) ?
                                        <Link to={`${process.env.PUBLIC_URL}/categories/full-width`} className="product-category" key={"prod-cat" + index}>{category}</Link>
                                        : <Link to={`${process.env.PUBLIC_URL}/categories/full-width`} className="product-category" key={"prod-cat" + index}>{category}, </Link>
                                ))
                            } */}
                            <Link to={`${process.env.PUBLIC_URL}/categories/full-width`} className="product-category">{ langSelected == 'fr' ? product.productCategory.fr : product.productCategory.en }</Link>
                        </div>
                        : ""
                }

                <h2 className="product-title">
                    <Link to={`${process.env.PUBLIC_URL}/products/${product.id}/details`}>{product.name}</Link>
                </h2>
                {
                    isDisplay === 0 ?
                        <div className="ratings-container">
                            <div className="product-ratings">
                                <span className="ratings" style={{ width: 20 * product.rating + '%' }}></span>
                                <span className="tooltiptext tooltip-top">{product.rating.toFixed(2)}</span>
                            </div>
                        </div>
                        : ""
                }

                <div className="price-box">
                    <span className="product-price">{product.price.toFixed(2)} {product.currency}</span>
                </div>

                <div className="product-action">
                    {/* <Qty stock={ product.stock } /> */}

                    <Link to={`${process.env.PUBLIC_URL}/products/${product.id}/details`}>
                        <button className="btn-icon btn-add-cart">{t('home.details')}</button>
                    </Link>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        wishlist: state.wishlist.list ? state.wishlist.list : []
    }
}

export default withTranslation()(connect(mapStateToProps, { addToCart, addToWishList, showQuickView })(ProductTypeFive));
