import axios from 'axios';
import { objectToFormData, toCamelCase, toSnakeCase, camelToSnakeCase } from '../helpers/helpers';
import { getAuthToken } from '../helpers/tokens';

const axiosInstance = axios.create({
    baseURL: process.env.BACKEND_BASE_URL,
    timeout: 60000,
});


const API_URL = process.env.PUBLIC_URL;

// API to get products from mock server
export const getProducts = function () {
    return axios.get( API_URL + '/mock-server/product-demo9.json' )
        .then( function ( response ) {
            return response.data;
        } )
        .catch( function ( error ) {
            // handle error
            console.log( error );
        } );
}

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
    (config) => {
      //config.headers['Accept'] = 'application/json';
      config.headers['content-type'] = 'application/json';
  
     const { accessToken } = getAuthToken();

     if (accessToken && !config.shouldSkipToken) {
        config.headers['Authorization'] = 'Bearer ' + accessToken;
     }
      
      if (config.multipart) {
        config.headers['content-type'] = 'multipart/form-data';
      }
  
      // Check if post or put to perform some operation
      if (
        (config.method === "post" || config.method === "put") &&
        !config.shouldSkipDataParsing
      ) {
        // Create an object to store file data
        const fileData = {};
  
        // Check if fileData is present
        if (config.fileData) {
          config.fileData.forEach((f) => {
            fileData[f] = config.data[f];
            delete config.data[f];
          });
        }
        // Parse object to snakeCase and Form data
        const data = toSnakeCase(config.data);
        config.data = objectToFormData(data);

  
        // Append files to data to send
        if (config.fileData) {
          Object.entries(fileData).forEach((item) => {
            config.data.append(camelToSnakeCase(item[0]), item[1]);
          });
        }
      }


      // Check if idsToTransformToInt is present
      if (config.idsToTransformToInt) {
        config.idsToTransformToInt.forEach((id) => {
          config.idsToTransformToInt[id] = Number(config.idsToTransformToInt[id]);
        });
      }

  
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  
  axiosInstance.interceptors.response.use((response) => {
    if (response && response.data) {
      if (Array.isArray(response.data)) {
        response.data = response.data.map((item) => toCamelCase(item));
      } else if (response.data.hasOwnProperty("data")) {
        if (Array.isArray(response.data.data)) {
          // @ts-ignore
          response.data.data = response.data.data.map((item) =>
            toCamelCase(item)
          );
        } else response.data.data = toCamelCase(response.data.data);
      } else response.data = toCamelCase(response.data);
    }
  
    return response;
  });

export default axiosInstance;