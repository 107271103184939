exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".rfqDetails_textDetails__1pVuj {\n\n    padding: 10px 5px;\n    background: rgba(243, 243, 243, 0.8);\n    border-radius: 7px;\n}", ""]);

// exports
exports.locals = {
	"textDetails": "rfqDetails_textDetails__1pVuj"
};