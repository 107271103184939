import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { paginate } from "@helpers/helpers";
import ProductListItem from "./ProductListItem";
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import ProductDetatilsModal from "./Product-details";
import Pagination from '@material-ui/lab/Pagination';
import ModalInfo from "@components/customs/modalInfo";
import { getProductsOfSeller, deleteProductsOfSeller } from "@action/sellerProducts-actions";


const ProductsList = (props) => {

  const authUser = useSelector((state) => state.authUser);

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [errorData, setErrorData] = useState(null);

  const [showItemDetail, setShowItemDetail] = useState(false);
  const [itemDetail, setItemDetail] = useState(null);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [productToDelete, setProductToDelete] = useState(null);

  let loader = null;
  let errorDisplay = null;
  const { t } = props;

  const filterFromSearchText = (item) => {
    try {
      return item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
        String(item.price).toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
    } catch (err) {
      return false;
    }
  }

  const initialiseState = () => {
    setLoadingData(true);
    setErrorData(null);
    setData([]);
  };

  if (loadingData) {
    /* loader = <h3 className="text-center">Loading...</h3>; */
    loader = (
      <div className="loader text-center">
        <div className="bounce-loader">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    );
  }

  if (errorData) {
    errorDisplay = (
      <>
        <br />
        <div className="alert alert-danger">Error: {errorData.message}</div>
        <br />
      </>
    );
  }

  const openDetailModal = (item) => {
    setShowItemDetail(true);
    setItemDetail(item);
  };

  const closeDetailModal = () => {
    //props.clearUpdateStateAfterUpdate();
    setShowItemDetail(false);
    setItemDetail(null);
  };

  const getProductsForSeller = (sellerId) => {
    initialiseState();
    getProductsOfSeller(sellerId)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setErrorData(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const deleteProduct = (product) => {

    setLoadingDelete(true);
    deleteProductsOfSeller(authUser.authUser.seller.id, product.id).then((result) => {

      toast("Product deleted successfully!", { className: "toast-success" });
      setProductToDelete(null);
      getProductsForSeller(authUser.authUser.seller.id);
    }).catch((err) => {

      toast(`Error: ${err.message}`, { className: "toast-error" });

    }).finally(() => { setLoadingDelete(false) })
  }


  useEffect(() => {
    if (authUser.authUser) {
      const id = authUser.authUser.seller
        ? authUser.authUser.seller.id
        : authUser.authUser.buyer.id;
      getProductsForSeller(id);
    }
  }, [authUser]);

  return (
    <>
      <Helmet>
        <title>Sooko - Checkout Page </title>
      </Helmet>

      <h1 className="d-none">Sooko - Checkout Page</h1>

      <div className="main">
        <div className="container">

          {data.length > 0 ? <div className="row">
            <div className={"col-md-5 header-search " + "header-search-inline"} style={{ width: "100% !important" }}>
              <div className="header-search-wrapper">
                <input type="search" className="form-control" name="q" placeholder={t('common.search')} style={{ width: "100%" }} required
                  value={searchText} onChange={(ev) => setSearchText(ev.target.value)} />
              </div>
            </div>
          </div> : null}

          {loader}
          {errorDisplay}

          <ProductDetatilsModal
            showItemDetail={showItemDetail}
            closeDetailModal={closeDetailModal}
            item={itemDetail}
          />

          <ModalInfo show={!!productToDelete} closeModal={() => setProductToDelete(null)}>
            <div className="text-center py-5">

              <h2 className="font-weight-light">
                All <strong className="text-success">RFQs</strong> related to this product will be deleted!
                <br />
              </h2>

              <h2 className="text-danger font-weight-light mt-2 mb-4"> A you sure you want to continue ? </h2>

              <img
                src={"assets/images/delete-illustration.png"}
                style={{ width: "300px", height: "200px", margin: "0 auto" }}
                className="img-responsive"
                alt="Product image"
              />

              <div className="mt-5">
                <button className="mr-3 btn btn-warning" onClick={() => setProductToDelete(null)}> Cancel </button>

                <button className="btn btn-danger" onClick={() => deleteProduct(productToDelete)}>
                  {loadingDelete ? (
                    <span>
                      <i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>
                      &nbsp;&nbsp;
                    </span>
                  ) : null}
                  Confirm
                </button>
              </div>
            </div>
          </ModalInfo>



          {data.length > 0 ? (
            <div className="row" style={{ marginTop: 30, marginBottom: 20 }}>
              <table className="col-lg-12 col-sm-12">
                <thead>
                  <th>
                    {t('products.product_name')}
                  </th>
                  <th>
                    {t('products.unit_price')}
                  </th>
                  <th>
                    {t('products.product_country')}
                  </th>
                  <th>
                    {t('common.created_at')}
                  </th>
                  <th>
                    Action
                    </th>
                </thead>
                <br />
                <tbody>
                  {paginate(data.filter(filterFromSearchText), page, pageSize).map((item, index) => (
                    <ProductListItem
                      key={index}
                      item={item}
                      openDetailModal={openDetailModal}
                      showCreationForm={props.showCreationForm}
                      setProductToDelete={setProductToDelete}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
          <Pagination
            variant="outlined"
            shape="rounded"
            size="large"
            count={Math.ceil(data.filter(filterFromSearchText).length / pageSize)}
            showFirstButton
            showLastButton
            onChange={(__, selectedPage) => setPage(selectedPage)}
          />

          {!loadingData && data.length <= 0 ? (
            <div className="mb-5 text-center">
              <img
                src="assets/images/nothing-here.png"
                width="500"
                style={{ margin: "0 auto" }}
              />
              <h2 className="text-muted font-weight-light">
                {" "}
                {t('products.not_found')}{" "}
              </h2>
            </div>
          ) : null}
        </div>

        <div className="mb-6"></div>
      </div>
    </>
  );
};

export default withTranslation()(React.memo(ProductsList));
