import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { formatDate } from '@helpers/helpers';
import React, { useEffect, useState } from 'react';
import { markContractAsRead } from '@action/contract';
import { withTranslation, useTranslation } from "react-i18next";
import { getDocumentFromSinRequest } from '@action/docs-actions';

function ContractListItem(props) {

    const authUser = useSelector(state => state.authUser)
    const isBuyer = authUser.authUser && authUser.authUser.userType === 'BUYER';
    const isSeller = authUser.authUser && authUser.authUser.userType === 'SELLER';
    const { item, setPaymentModalOpen, setItemSelected } = props;

    const [docStatusSignRequest, setDocStatusSignRequest] = useState(null)
    const [docStatusSignRequest2, setDocStatusSignRequest2] = useState(null)
    const [url, setUrl] = useState(null)

    const { t } = props;
    const { i18n } = useTranslation();

    const [langSelected, setLangSelected] = useState("en");

    useEffect(() => {
        if (authUser.authUser) {
            setLangSelected(authUser.authUser.language.toLowerCase());
        }
    }, [authUser]);

    const openDocumentInBrowser = (uuid, docId) => {

        getDocumentFromSinRequest(uuid).then(() => {

            markContractAsRead(docId).then(() => {
                getDocumentFromSinRequest(uuid).then((resultSignRquest) => {
                    setDocStatusSignRequest(resultSignRquest.status);
                    window.open(resultSignRquest.pdf ? resultSignRquest.pdf : resultSignRquest.file, '_blank');
                    props.getCountDocsUnread();
                });

            }).catch((err) => {

                // toast(`Error to mark as read: ${err.message}`, { className: "toast-error" });
            });
        }).catch((err) => {
            // toast(`Error to get documents: ${err.message}`, { className: "toast-error" });
        });
    }

    useEffect(() => {
        if (item.file)
            getDocumentFromSinRequest(item.file).then((resultSignRquest) => {
                setDocStatusSignRequest(resultSignRquest.status);
                setUrl(resultSignRquest.pdf)
            });
        if (item.firstSignature)
            getDocumentFromSinRequest(item.firstSignature).then((resultSignRquest) => {
                setDocStatusSignRequest2(resultSignRquest.status);
            });
    }, []);

    return (
        <tr>

            <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                {t('documents.contract_on')+item.title.split(' ').splice(3, item.title.split(' ').length).join(' ')}
            </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}>

                {
                    item.file ?
                        <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: `${docStatusSignRequest == 'se' ? '#ff0000a1' : docStatusSignRequest == 'si' ? 'green' : '#acb5bba1'}`, fontWeight: "bold", padding: 5 }}>
                            {`${docStatusSignRequest == 'se' ? t('common.new') : docStatusSignRequest == 'si' ? t('common.signed') : t('common.processing')}`} </div>
                        :
                        isSeller && item.sellerStatus ?
                            <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: 'rgb(0 139 243 / 66%)', fontWeight: "bold", padding: 5 }}> {t('common.read')} </div>
                            :
                            isBuyer && item.buyerStatus ?
                                <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: 'rgb(0 139 243 / 66%)', fontWeight: "bold", padding: 5 }}> {t('common.read')} </div>
                                :
                                item.file ?
                                    <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: `${docStatusSignRequest == 'se' ? '#ff0000a1' : docStatusSignRequest == 'si' ? 'green' : '#acb5bba1'}`, fontWeight: "bold", padding: 5 }}>
                                        {`${docStatusSignRequest == 'se' ? t('common.new') : docStatusSignRequest == 'si' ? t('common.signed') : t('common.processing')}`} </div>
                                    :
                                    <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: '#ff0000a1', fontWeight: "bold", padding: 5 }}> {t('common.new')} </div>
                }
            </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}> {item.quotationUserStatus.quotation.price} {item.quotationUserStatus.quotation.currency} / {item.quotationUserStatus.quotation.product.pricePerUnit[langSelected]} </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}> {item.quotationUserStatus.quotation.price * item.quotationUserStatus.quotation.quantity} {item.quotationUserStatus.baseQuotation.product.currency} </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}> {formatDate(item.createdAt, i18n.language)}</td>
            <td style={{ display: 'block', paddingTop: 10, paddingBottom: 10 }}>
                <div className="row">
                    <div className="rfq-item-icons">
                        {item.file ? <span onClick={() => openDocumentInBrowser(item.file, item.id)}><span className="rfq-item-icons-read input-tooltip" data-toggle="tooltip" title={t('common.view')} data-placement="right" style={{ cursor: "pointer" }}><i className="fa fa-eye" aria-hidden="true"></i></span>&nbsp;</span> : null}
                        {!item.file && !item.close ? <Link to={`${process.env.PUBLIC_URL}/account/dashboard/contracts/${item.id}/contract-effective`}><span className="rfq-item-icons-edit input-tooltip" data-toggle="tooltip" title={t('common.edit')} data-placement="right" style={{ color: "#e4c318", cursor: "pointer" }}><i className="fa fa-pencil-alt" aria-hidden="true"></i></span>&nbsp;</Link> : null}
                        {isBuyer && docStatusSignRequest == 'si' && !item.close ? !item.paid ?
                            <>
                                <span>
                                    <button onClick={() => { setItemSelected(item); setPaymentModalOpen(true) }} className="btn-pay-contract float-right" style={{ fontWeight: "bold", margin: "0px", marginTop: "-5px" }}>
                                        {t('documents.proceed_payment')}
                                    </button>
                                </span>
                            </> :
                            isBuyer && item.paid && !item.close ?
                                <span>
                                    <button className="btn-pay-contract float-right" style={{ fontWeight: "bold", margin: "0px", marginTop: "-5px" }} onClick={() => props.showDocument(item)}>
                                        {t('notes.details')}
                                    </button>
                                </span> :
                                !item.close ?
                                    <>
                                        <span>
                                            <button className="btn-pay-contract float-right" style={{ backgroundColor: '#12131340', color: 'white', fontWeight: "bold", margin: "0px", marginTop: "-5px" }}>
                                                {t('common.processing')}
                                            </button>
                                        </span>
                                    </> : null : null}
                        {isSeller && docStatusSignRequest == 'si' && !item.close ?
                            <span>
                                <button className="btn-pay-contract float-right" style={{ fontWeight: "bold", margin: "0px", marginTop: "-5px" }} onClick={() => props.showDocument(item)}> Documents </button></span> : null}
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default withTranslation()(React.memo(ContractListItem));