import { connect } from 'react-redux';
import ProductNav from './common/product-nav';
import { withTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ModalInfo from '../../../../customs/modalInfo'
import { getPrice } from '@utils';
import { setModalLoginStatus } from '@action/modalLogin-actions';
import CountryManager from '@helpers/CountryManager';
import { quickAddToCart, addToWishList } from '@action'
import loginImg from '../../../../../../public/assets/images/loginIllustration.jpg';
import { LOGIN } from '@routes/urls';
import { getProductById } from "@action/products-actions";

function SingleDetail(props) {

    const [product, setProduct] = useState(null);
    const[show, setShow] = useState(false)

    const[showModalForNoBuyerAccount, setShowModalForNoBuyerAccount] = useState(false)
    const[showModalForNoBuyerNonApproved, setShowModalForNoBuyerNonApproved] = useState(false)
    // let isInWishlist = findIndex(wishlist, product.id) ? true : false;
    let maxPrice, minPrice = 0;

    useEffect(() => {
        getProductById(props.match.params.id).then((result) => {
            setProduct(result);
        });
    
      }, []);

    if(product) {
        if (product.variants) {
            maxPrice = getPrice(product.variants);
            minPrice = getPrice(product.variants, 'min');
        }
    }

    const [langSelected, setLangSelected] = useState("en");

    useEffect(() => {
        if (props.authUser.authUser) {
            setLangSelected(props.authUser.authUser.language.toLowerCase());
        }
    }, [props.authUser]);

    const selectGroup = (e) => {
        e.preventDefault();
        if (props.noSelect === undefined)
            document.querySelector(".product-single-gallery .owl-item.active img").setAttribute("src", e.currentTarget.getAttribute("data-src"));

        e.currentTarget.parentElement.parentElement.querySelector(".active") && e.currentTarget.parentElement.parentElement.querySelector(".active").classList.remove("active");
        e.currentTarget.parentElement && e.currentTarget.parentElement.classList.add("active");
    }

    const makeRfq = () => {
        if (!props.authUser.isAuth) {
            setShow(true)

        } else if(props.authUser.isAuth && props.authUser.authUser.userType==="SELLER") {
            setShowModalForNoBuyerAccount(true)

        }
        else if(props.authUser.isAuth && props.authUser.authUser.userType==="BUYER" && !props.authUser.authUser.approved){
            setShowModalForNoBuyerNonApproved(true)
        }
        else {
            props.history.push(`${process.env.PUBLIC_URL}/products/${product.id}/rfq`);
        }
    }

    const {t} = props;

    return (
        <>
            <div className="skel-pro skel-detail"></div>
            { product != null && (
            <div className="product-single-details">
                <ProductNav product={product} link={props.link} />
                <h1 className="product-title">{product.name}</h1>
                <p>{product.productCategory[langSelected == 'fr' ? 'fr' : 'en']}</p>
                <div className="ratings-container">
                    {/* 
                        <div className="product-ratings">
                            <span className="ratings" style={{ width: 20 * product.rating + '%' }}></span>
                        </div>
                        <Link to="#" className="rating-link">{`( ${product.reviews} Reviews )`}</Link> 
                    */}
                </div>

                <hr className="short-divider" />
                <div className="price-box">
                    <span className="product-price"> {product.price.toFixed(2)}&nbsp; {product.currency} / {product.pricePerUnit[langSelected == 'fr' ? 'fr' : 'en']} </span>
                </div>
                <div className="product-desc">
                    <p>{product.description}</p>
                    <ul>
                        <li><i className="icon-ok"></i>&nbsp; <strong>{t("single_detail.country_origin")}:</strong> {CountryManager.getNameFromId(product.countryOfOrigin)}</li>
                        <li><i className="icon-ok"></i>&nbsp; <strong>{t("single_detail.min_price")}:</strong> {product.minPrice} {product.currency}</li>
                        <li><i className="icon-ok"></i>&nbsp; <strong>{t("single_detail.max_price")}:</strong> {product.maxPrice} {product.currency}</li>
                        <li><i className="icon-ok"></i>&nbsp; <strong>{t("single_detail.port_of_departure")}:</strong> {product.port.name}</li>
                    </ul>
                </div>
                {
                    product.variants ?
                        <div className="product-filters-container">
                            {
                                product.variants.map((variant, index) => (
                                    <div className="product-single-filter" key={"varient" + index}>
                                        <label>{variant.name}:</label>
                                        {
                                            variant.name === "size" ?
                                                <ul className="config-size-list">
                                                    {
                                                        variant.type.map((item, i) => (
                                                            <li className={i === 0 ? "active" : ""} key={"size" + i} >
                                                                <Link to="#" onClick={selectGroup} data-src={item.pictures[0]}>{item.size}</Link>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                                : <ul className="config-swatch-list">
                                                    {
                                                        variant.type.map((item, i) => (
                                                            <li className={i === 0 ? "active" : ""} key={"color" + i} >
                                                                {
                                                                    item.color ?
                                                                        <Link to="#" onClick={selectGroup} data-src={item.pictures[0]} style={{ backgroundColor: item.color }}></Link>
                                                                        : <Link to="#" data-src={item.pictures[0]} onClick={selectGroup} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/${item.pictures[0]})` }}></Link>
                                                                }
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        : ""
                }

                <div className="product-action">
                    {/* <Qty stock={ product.stock } /> */}

                    {/* <Link to={`${process.env.PUBLIC_URL}/products/${product.id}/rfq`} className="btn btn-dark add-cart icon-shopping-cart" title="Request quote">
                        Request For Quotes
                    </Link> */}

                    <button onClick={makeRfq} style={{ borderRadius: '5px', backgroundColor:'00c805' }} className="btn btn-primary add-cart icon-shopping-cart" title="Request quote">
                        {t("home.request")}
                    </button>
                </div>

                <hr className="divider mb-1" />

                <div className="product-single-share">
                    <div className="social-icons mr-2">
                        <Link to="#" className="social-icon social-facebook icon-facebook" target="_blank"></Link>
                        <Link to="#" className="social-icon social-twitter icon-twitter" target="_blank"></Link>
                        <Link to="#" className="social-icon social-linkedin fab fa-linkedin-in" target="_blank"></Link>
                        <Link to="#" className="social-icon social-gplus fab fa-google-plus-g" target="_blank"></Link>
                        <Link to="#" className="social-icon social-mail icon-mail-alt" target="_blank"></Link>
                    </div>
                </div>
            </div>
            )}
            
            <ModalInfo
                show={show}
                closeModal={setShow}
            >
                <div className="container">
                        <div className="d-flex flex-column justify-content-center align-items-center">

                            <h3 className="text-center"> <span className="text-success" style={{fontSize: "18px"}}>
                            {t("single_detail.continue_action")}</span></h3>
                            
                            <div className="text-center"><img src={loginImg} style={{ margin: "0 auto" }} width={200} /></div>
                            <p>{t('home.message1')}</p>
                            <p>{t('home.message2')}</p>
                            <br/><br/>
                            <p>
                              <Link to={LOGIN} className="btn btn-primary"> {t("single_detail.login_page")}</Link>  

                            </p>
                            {/* <p>
                              <span style={{fontSize: "16px"}}>
                              {t("single_detail.instant_quote")}
                                  <Link to={ `${process.env.PUBLIC_URL}/products/${product.id}/rfq` } className="text-success"> <strong>{t("single_detail.here")}</strong></Link>  
                              </span>
                            </p> */}
                        </div>
                    </div>
            </ModalInfo>

            <ModalInfo
                show={showModalForNoBuyerAccount}
                closeModal={setShowModalForNoBuyerAccount}
            >
                <div className="container">
                        <div className="d-flex flex-column justify-content-center align-items-center">

                            <h3 className="text-center"> <span className="text-success" style={{fontSize: "18px"}}>
                            {t("single_detail.hey")}{props.authUser.authUser? props.authUser.authUser.name : null}! 
                                <br/>{t("single_detail.seller_authorised")}</span></h3>

                            
                            <div className="text-center"><img src={loginImg} style={{ margin: "0 auto" }} width={200} /></div>

                            <br/><br/>
                            
                            <p>
                              <span style={{fontSize: "16px"}}>
                              {t("single_detail.buyer_account")}
                                  <Link to={LOGIN} className="text-success"> <strong>{t("single_detail.here")}</strong></Link>  
                              </span>
                            </p>
                        </div>
                    </div>
            </ModalInfo>

            <ModalInfo
                show={showModalForNoBuyerNonApproved}
                closeModal={setShowModalForNoBuyerNonApproved}
            >
                <div className="container">
                        <div className="d-flex flex-column justify-content-center align-items-center">

                            <h3 className="text-center"> <span className="text-success" style={{fontSize: "18px"}}>
                            {t("single_detail.hey")} {props.authUser.authUser? props.authUser.authUser.name : null}! 
                                <br/>{t("single_detail.buyer_approved")}</span></h3>

                            
                            <div className="text-center"><img src={loginImg} style={{ margin: "0 auto" }} width={200} /></div>

                            <br/><br/>
                            
                            <p>
                              <span style={{fontSize: "16px"}}>
                              {t("single_detail.notifie_approved")}
                                  <Link to={LOGIN} className="text-success"> <strong>Here.</strong></Link>  
                              </span>
                            </p>
                        </div>
                    </div>
            </ModalInfo>
        </>
    )
}

const mapStateToProps = (state, props) => {
    return {
                authUser: state.authUser,
        wishlist: state.wishlist.list ? state.wishlist.list : []
    };
}

export default withTranslation()(withRouter(connect(mapStateToProps, { quickAddToCart, addToWishList, setModalLoginStatus})(SingleDetail)));