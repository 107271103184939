import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfFileFrench from './sooko_trade_user_guide_french_version.pdf';
import pdfFileEnglish from './sooko_trade_user_guide_english_version.pdf';

export default function Test({ language }) {

	pdfjs.GlobalWorkerOptions.workerSrc =
		`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);


	/*When document gets loaded successfully*/
	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setPageNumber(1);
	}

	return (
		<>
			<div>
				<Document
					className='licence-pdfs2'
					file={language == 'en' ? pdfFileEnglish : pdfFileFrench}
					onLoadSuccess={onDocumentLoadSuccess}
				>
					{
						Array.from(
							new Array(numPages),
							(el, index) => (
								<Page
									width={1000}
									key={`page_${index + 1}`}
									pageNumber={index + 1}
								/>
							),
						)
					}
				</Document>
			</div>
		</>
	);
}
