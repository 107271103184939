import { Link } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserAvatr,
  updateUserLicense,
  updateUserRegistration,
  updateUserPassWord,
  generateReferral,
  getReferrers
} from "@action/auth";
import Pdf from "./Pdf";
import { toast } from "react-toastify";
import classes from "./board.module.css";
import { useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
import { copyToClipboard } from "@helpers/helpers";
import UserInformationUserModal from "./account/userInfo/FormsToUpdateInfo/UserInformationModal";
import UserCompanyInformationModal from "./account/userInfo/FormsToUpdateInfo/UserCompanyInformationModal";

function Board(props) {

  const { authUser } = useSelector((state) => state.authUser);
  const updateUserAvatar = useSelector((state) => state.updateUserAvatar);
  const updateUserLicence = useSelector((state) => state.updateUserLicence);

  const dispatch = useDispatch();

  const [userAuthenticate, setUserAuthenticate] = useState(authUser == null ? {} : authUser);

  const [file, setFile] = useState(null);
  const [users, setUsers] = useState([]);
  const [licenceFile, setLicenceFile] = useState(null);
  const [registrationFile, setRegistrationFile] = useState(null);
  const [registrationLoader, setRegistrationLoader] = useState(false);

  const [fileToSend, setFileToSend] = useState(null);
  const [registrationToSend, setRegistrationToSend] = useState(null);
  const [updateAvatarAlertClosed, setUpdateAvatarAlertClosed] = useState(false);
  const [loadingp, setLoadingp] = useState(false);
  const [updateLicenceAlertClosed, setUpdateLicenceAlertClosed] = useState(
    false
  );

  const [userLisDate, setUserLisDate] = useState(null);
  const [userLisNumber, setUserLisNumber] = useState(null);
  const [isUpdatingLicence, setIsUpdatingLicence] = useState(false);
  const [isUpdatingRegistration, setIsUpdatingRegistration] = useState(false);
  const [isActive, setIsActive] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState('password');

  const [referralCode, setReferralCode] = useState(null);
  const [loadingrc, setLoadingrc] = useState(false);

  const [open, setOpen] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      // oldPassword: userAuthenticate.password
    },
  });

  useEffect(() => {
    getReferralUsers();
  }, []);

  useEffect(() => {
    if(authUser) {
      setUserAuthenticate(authUser);
      if (userAuthenticate) {
        if (authUser.buyer) {
          setUserLisNumber(authUser.buyer.importerLicenceNumber);
          setUserLisDate(authUser.buyer.licenceNumberExpirationDate);
        } else {
          setUserLisNumber(authUser.seller.exporterLicenceNumber);
          setUserLisDate(authUser.seller.licenceNumberExpirationDate);
        }
        setReferralCode(authUser.referralCode);
      }
    }
  }, [userAuthenticate, authUser]);

  const onSubmitForm = (data) => {
    /* $event.preventDefault();*/

    if (data.newPassword != data.confirmNewPassword) {
      toast(t("login.confirm_error"), { className: "toast-error" });
      return;
    }

    setLoadingp(true);
    updateUserPassWord(data, userAuthenticate.id).then(data => {
      toast(t("board.update_password_ok"), {
        className: "toast-success",
      });
      setOpen(false);
      setLoadingp(false);
    }).catch((err) => {
      toast(t("board.update_password_failed"), {
        className: "toast-error",
      });
      setLoadingp(false);
    }).finally(() => setLoadingp(false));
  };
  //---Handle user update password---- End;

  const { t } = props;

  let errorUpdateAvatar = null;
  let errorUpdateLicence = null;

  const handleAvatarChange = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
    setFileToSend(event.target.files[0]);
  };

  const handleLicenceChange = (event) => {
    setLicenceFile(window.URL.createObjectURL(event.target.files[0]));
    setFileToSend(event.target.files[0]);
    setIsUpdatingLicence(true);
  };

  const handleRegistationChange = (event) => {
    setRegistrationFile(window.URL.createObjectURL(event.target.files[0]));
    setRegistrationToSend(event.target.files[0]);
    setIsUpdatingRegistration(true);
  };

  const cancelEditAvatar = () => {
    setFile(null);
    setFileToSend(null);
  };

  const cancelEditLicence = () => {
    setLicenceFile(null);
    setFileToSend(null);
    setIsUpdatingRegistration(false);
  };

  const cancelEditRegistation = () => {
    setRegistrationFile(null);
    setRegistrationToSend(null);
    setIsUpdatingRegistration(false);
  };

  const onUpdateProfile = () => {
    if (fileToSend) {
      setUpdateAvatarAlertClosed(false);
      //console.log(fileToSend, userAuthenticate.id);

      dispatch(
        updateUserAvatr({ avatar: fileToSend }, userAuthenticate.id, (result) => {
          setFile(null);
          setFileToSend(null);
          setUserAuthenticate(result.data)
        })
      );
    }
  };

  const onUpdateLicence = () => {
    if (fileToSend) {
      dispatch(
        updateUserLicense(
          { licenceFile: fileToSend, userId: userAuthenticate.id },
          userAuthenticate.id,
          (result) => {
            setLicenceFile(null);
            setFileToSend(null);
            setUserAuthenticate(result.data)
            setIsUpdatingLicence(false);
            toast(t("board.update_ok"), {
              className: "toast-success",
            });
          }
        )
      );
    } else {
      dispatch(
        updateUserLicense(
          {
            userId: userAuthenticate.id,
            expirationDate: userLisDate,
            licenceNumber: userLisNumber,
          },
          userAuthenticate.id,
          (result) => {
            setLicenceFile(null);
            setFileToSend(null);
            setUserAuthenticate(result.data)
            setIsUpdatingLicence(false);
            toast(t("board.update_ok"), {
              className: "toast-success",
            });
          }
        )
      );
    }
  };

  const onUpdateRegistration = () => {
    if (registrationToSend) {
      updateUserRegistration(
        { businessFile: registrationToSend, userId: userAuthenticate.id },
        userAuthenticate.id
      )
        .then((result) => {
          setRegistrationFile(null);
          setRegistrationLoader(false);
          setIsUpdatingRegistration(false);
          setUserAuthenticate(result)
          toast(t("board.update_ok"), {
            className: "toast-success",
          });
        })
        .catch((err) => {
          toast(t("board.update_failed"), {
            className: "toast-error",
          });
          setRegistrationLoader(false);
          setRegistrationFile(null);
          setIsUpdatingRegistration(false);
        });
    }
  };

  const onGenerateReferral = () => {
    generateReferral(referralCode).then((result) => {
        toast(t("board.update_ok"), {
          className: "toast-success",
        });
      })
      .catch((err) => {
        toast(t("board.update_failed"), {
          className: "toast-error",
        });
      });
  };

  const getReferralUsers = () => {
    getReferrers().then((result) => {
      setUsers(result);
    })
  };

  const onGetLicenceDate = (event) => {
    setUserLisDate(event.target.value);
  };

  const onGetLicenceNumber = (event) => {
    setUserLisNumber(event.target.value);
  };

  useEffect(() => {
    if (showPassword == true)
      setInputType('text');
    else
      setInputType('password');
  }, [showPassword]);

  if (updateUserAvatar.error && !updateAvatarAlertClosed) {
    errorUpdateAvatar = (
      <div className="alert alert-danger">
        <strong>{t("board.update_failed")} </strong>{" "}
        {updateUserAvatar.error.message}
        <button
          title="Close (Esc)"
          type="button"
          className="mfp-close"
          onClick={() => setUpdateAvatarAlertClosed(true)}
        >
          ×
        </button>
      </div>
    );
  }

  if (updateUserAvatar.data && !updateAvatarAlertClosed) {
    errorUpdateAvatar = (
      <div className="alert alert-success">
        <strong>{t("board.update_ok")} </strong>
        <button
          title="Close (Esc)"
          type="button"
          className="mfp-close"
          onClick={() => setUpdateAvatarAlertClosed(true)}
        >
          ×
        </button>
      </div>
    );
  }

  if (updateUserLicence.error && !updateLicenceAlertClosed) {
    errorUpdateLicence = (
      <div className="alert alert-danger">
        <strong>{t("board.update_failed")} </strong>{" "}
        {updateUserLicence.error.message}
        <button
          title="Close (Esc)"
          type="button"
          className="mfp-close"
          onClick={() => setUpdateLicenceAlertClosed(true)}
        >
          ×
        </button>
      </div>
    );
  }

  if (updateUserLicence.data && !updateLicenceAlertClosed) {
    errorUpdateLicence = (
      <div className="alert alert-success">
        <strong>{t("board.update_ok")} </strong>
        <button
          title="Close (Esc)"
          type="button"
          className="mfp-close"
          onClick={() => setUpdateLicenceAlertClosed(true)}
        >
          ×
        </button>
      </div>
    );
  }

  return (
    <div style={{ background: "white", padding: "30px", borderRadius: "10px" }}>
      <h2>{t("board.title")}</h2>
      <div className="row">
        <div className="profile-usertitle  col-md-4">
          {errorUpdateAvatar}

          <div
            className="profile-userpic"
            style={{ position: "relative", display: "inline-block" }}
          >
            <label className={classes.filebutton}>
              {t("board.edit")}
              <span>
                <input type="file" onChange={handleAvatarChange} />
              </span>
            </label>

            {!file ? (
              <img
                src={
                  authUser && userAuthenticate.avatar ?
                    userAuthenticate.avatar.startsWith("https://encryp")
                    ? userAuthenticate.avatar
                    : `${process.env.BACKEND_BASE_URL}${userAuthenticate.avatar}`
                    : `${process.env.BACKEND_BASE_URL}${userAuthenticate.avatar}`
                }
                style={{ width: "200px", height: "200px" }}
                className="img-responsive"
                alt=""
              />
            ) : null}
            {file ? (
              <img
                src={file}
                className="img-responsive"
                style={{ width: "200px", height: "200px" }}
              />
            ) : null}
          </div>
          <div className="profile-usertitle-job mt-2">
            {/* {userAuthenticate.userType} */}
            {file ? (
              <button
                type="button"
                style={{ padding: 12 }}
                className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                onClick={onUpdateProfile}
                disabled={updateUserAvatar.loading}
              >
                {updateUserAvatar.loading ? (
                  <span>
                    <i
                      className="fa fa-spinner fa-spin fa-1x"
                      aria-hidden="true"
                    ></i>
                    &nbsp;&nbsp;
                  </span>
                ) : null}
                {t("board.update")}
              </button>
            ) : null}

            {file ? (
              <button
                type="button"
                className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                style={{ color: "white", padding: 12 }}
                onClick={cancelEditAvatar}
                disabled={updateUserAvatar.loading}
              >
                {t("board.cancel")}
              </button>
            ) : null}
          </div>
        </div>
        <div className="col-md-8">
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <div className="col-md-9">
              <span
                style={{
                  marginRight: "20px",
                  fontSize: "1.7em",
                  fontWeight: "bold",
                }}
              >
                {" "}
                {userAuthenticate
                  ? userAuthenticate.name
                    ? userAuthenticate.name.charAt(0).toUpperCase() +
                    userAuthenticate.name.slice(1)
                    : ""
                  : ""}
              </span>
              <br />
              <span>
                {userAuthenticate ? userAuthenticate.sookoId : "XXXX-XXXX"}
              </span>
            </div>
          </div>
          <br />
          <div
            className="user-button-action-2 d-inline mt-3"
            style={{ color: "green", fontWeight: "bold" }}
          >
            {" "}
            {t(`board.${userAuthenticate ? userAuthenticate.userType : ""}_ACCOUNT`)}
          </div>
          {/* {userAuthenticate && userAuthenticate.approved ? <div className="mt-4"> <strong className="text-success">ACCOUNT APPROVED <i className="fa fa-check-circle"></i></strong> </div> : null} */}
          {userAuthenticate && !userAuthenticate.approved ? (
            <div className="mt-4">
              {" "}
              <strong className="text-danger">
                {t("board.unapproved_account")}{" "}
                <i className="fa fa-exclamation-circle"></i>
              </strong>
              <p style={{ marginTop: 20, fontSize: '1.2em' }}>
                <strong style={{ marginRight: 20 }}>
                  Note:
                </strong>
                {t('notes.approve')}
              </p>
            </div>
          ) : userAuthenticate && userAuthenticate.userType == 'SELLER' ?
              <div className="mt-4">
                <p style={{ marginTop: 20 }}>
                  <strong style={{ marginRight: 20 }}>
                    Note:
                </strong>
                  {t('notes.create_product')} <Link to={`${process.env.PUBLIC_URL}/account/dashboard/seller-products`}>{t('notes.click_here')}</Link>
                </p>
              </div>
              : userAuthenticate && userAuthenticate.userType == 'BUYER' ?
                <div className="mt-4">
                  <p style={{ marginTop: 20 }}>
                    <strong style={{ marginRight: 20 }}>
                      Note:
                </strong>
                    {t('notes.create_rfq')} <Link to={`${process.env.PUBLIC_URL}/marketplace`}>{t('notes.click_here')}</Link>
                  </p>
                </div>
                : null}
          <div style={{ marginBottom: "100px" }}></div>
          <div className="col-md-12 user-tabs-action-row">
            <div
              className={
                `col-md-2 ${ isActive === 1 ? "user-tabs-action" : "user-tabs-action-2" }`
              }
              onClick={() => setIsActive(1)}
            >
              {" "}
              {t("board.personal")}
            </div>
            <div
              className={
                `col-md-2 ${ isActive === 2 ? "user-tabs-action" : "user-tabs-action-2" }`
              }
              onClick={() => setIsActive(2)}
            >
              {" "}
              {t("board.company")}
            </div>
            <div
              className={
                `col-md-2 ${ isActive === 3 ? "user-tabs-action" : "user-tabs-action-2" }`
              }
              onClick={() => setIsActive(3)}
            >
              {" "}
              {t("board.documents")}
            </div>
            <div
              className={
                `col-md-2 ${ isActive === 4 ? "user-tabs-action" : "user-tabs-action-2" }`
              }
              onClick={() => setIsActive(4)}
            >
              {" "}
              {t("board.password")}
            </div>
            <div
              className={
                `col-md-2 ${ isActive === 5 ? "user-tabs-action" : "user-tabs-action-2" }`
              }
              onClick={() => setIsActive(5)}
            >
              {" "}
              {t("board.referral")}
            </div>
          </div>

          {isActive === 1 ? (
            <div className="col-md-12">
              <div className="user-tabs-header">
                {t("board.account_information")}
                <div className="user-tabs-header-edit">
                  <button>
                    <UserInformationUserModal
                      userId={userAuthenticate.id}
                      userAuthenticated={userAuthenticate}
                      setUser={setUserAuthenticate}
                    />
                  </button>
                </div>
              </div>

              <div className="user-tabs-body">
                <table style={{ width: "90%" }}>
                  <tbody>
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "25%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.account_name")}:
                        </p>
                      </td>
                      <td>
                        <p style={{ alignItems: "center", display: "center" }}>
                          {userAuthenticate.name}
                        </p>
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "25%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.account_email")}:
                        </p>
                      </td>
                      <td>
                        <p>{userAuthenticate.email}</p>
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "25%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.account_phone_number")}:
                        </p>
                      </td>
                      <td>
                        <p style={{ marginLeft: "15px" }}>
                          {userAuthenticate.phone ? (
                            userAuthenticate.phone
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "25%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.language")}:
                        </p>
                      </td>
                      <td>
                        <p style={{ marginLeft: "20px" }}>
                          {userAuthenticate.language ? (
                            userAuthenticate.language
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "25%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.account_about_you")}:
                        </p>
                      </td>
                      <td>
                        <p>
                          {userAuthenticate.aboutYou ? (
                            userAuthenticate.aboutYou
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : isActive === 2 ? (
            <div className="col-md-12">
              <div className="user-tabs-header">
                {t("board.company_information")}
                <div className="user-tabs-header-edit">
                  <button>
                    <UserCompanyInformationModal
                      userId={userAuthenticate.id}
                      userAuthenticated={userAuthenticate}
                      setUser={setUserAuthenticate}
                    />
                  </button>
                </div>
              </div>

              <div className="user-tabs-body">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "60%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.company_name")}:
                      </p>
                      </td>
                      <td>
                        <p>
                          {userAuthenticate.companyName ? (
                            userAuthenticate.companyName
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>

                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "60%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.company_fax")}:
                      </p>
                      </td>
                      <td>
                        <p>
                          {userAuthenticate.fax ? (
                            userAuthenticate.fax
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>

                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "60%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          {t("board.company_website_url")}:
                      </p>
                      </td>
                      <td>
                        <p>
                          {userAuthenticate.websiteUrl ? (
                            userAuthenticate.websiteUrl
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>

                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "60%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.company_registration_business_number")}:
                      </p>
                      </td>
                      <td>
                        <p>
                          {userAuthenticate.businessRegistrationNumber ? (
                            userAuthenticate.businessRegistrationNumber
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>

                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "60%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.company_creation_date")}:
                      </p>
                      </td>
                      <td>
                        <p>
                          {userAuthenticate.companyCreationDate ? (
                            userAuthenticate.companyCreationDate
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>

                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "60%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          {t("board.company_street_address")}:
                      </p>
                      </td>
                      <td>
                        <p>
                          {userAuthenticate.streetAddress ? (
                            userAuthenticate.streetAddress
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>

                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "60%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          {t("board.company_city")}:
                      </p>
                      </td>
                      <td>
                        <p>
                          {userAuthenticate.city ? (
                            userAuthenticate.city
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>

                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "60%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.company_state")}
                        </p>
                      </td>
                      <td>
                        <p>
                          {userAuthenticate.province ? (
                            userAuthenticate.province
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>

                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "60%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.company_postal")}:
                      </p>
                      </td>
                      <td>
                        <p>
                          {userAuthenticate.postal ? (
                            userAuthenticate.postal
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>

                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "60%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.account_country")}:
                      </p>
                      </td>
                      <td>
                        <p>
                          {userAuthenticate.country ? (
                            <ReactFlagsSelect
                              disabled
                              selected={userAuthenticate.country}
                              className={classes.countryFlag}
                            />
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>

                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <td style={{ width: "60%" }}>
                        <p
                          style={{
                            alignItems: "center",
                            display: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {t("board.signer_officer")}:
                      </p>
                      </td>
                      <td>
                        <p>
                          {userAuthenticate.keyContactName ? (
                            userAuthenticate.keyContactName
                          ) : (
                              <div
                                className="user-button-action d-inline mt-3"
                                style={{ color: "orange", padding: "6px" }}
                              >
                                {" "}
                                {t("board.not_set")}{" "}
                              </div>
                            )}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : isActive === 3 ? (
            <div className="col-md-12">
              <div className="user-tabs-header">
                {/*SELLER LICENCES
                                                <div  className="user-tabs-header-edit">
                                                    <button>
                                                        <Link to="#"><i class="fa fa-upload" aria-hidden="true"></i> upload</Link>
                                                </button>
                                        </div> */}
              </div>
              {/*-------------START--------------*/}
              <div className="row">
                <div className="col-md-12" style={{ marginBottom: "20px" }}>
                  <label>{t("board.licence_Expiration_date")}</label>
                  <input
                    className="form-control"
                    placeholder="Licence Expiration date"
                    type="date"
                    id="licenceExpireDate"
                    name="licenceExpireDate"
                    min={new Date().toISOString().split('T')[0]}
                    onChange={onGetLicenceDate}
                    defaultValue={
                      userAuthenticate
                        ? userAuthenticate.buyer
                          ? userAuthenticate.buyer.licenceNumberExpirationDate
                          : userAuthenticate.seller.licenceNumberExpirationDate
                        : ""
                    }
                  />
                  <label>{t("board.licence_number")}</label>
                  <input
                    className="form-control"
                    placeholder="Licence Number"
                    type="text"
                    onChange={onGetLicenceNumber}
                    defaultValue={
                      userAuthenticate
                        ? userAuthenticate.buyer
                          ? userAuthenticate.buyer.importerLicenceNumber
                          : userAuthenticate.seller.exporterLicenceNumber
                        : ""
                    }
                  />
                  <button
                    type="submit"
                    className="btn btn-primary btn-md"
                    disabled={updateUserLicence.loading}
                    onClick={onUpdateLicence}
                  >
                    {updateUserLicence.loading ? (
                      <span>
                        <i
                          className="fa fa-spinner fa-spin fa-1x"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;
                      </span>
                    ) : null}
                    {t("board.update_my_licence")}
                  </button>
                </div>
                <div className="col-md-6">
                  {userAuthenticate.userType === "SELLER" ? (
                    <h5 className="licence-document-responsive">{t("board.exporter_licence")}</h5>
                  ) : (
                      <h5 className="licence-document-responsive">{t("board.importer_licence")}</h5>
                    )}
                  {errorUpdateLicence}
                  {!licenceFile ? (
                    <Pdf
                      pdfUrl={
                        authUser
                          ? userAuthenticate.buyer
                            ? `${process.env.BACKEND_BASE_URL}${userAuthenticate.buyer.importerLicencePath}`
                            : `${process.env.BACKEND_BASE_URL}${userAuthenticate.seller.exporterLicencePath}`
                          : ""
                      }
                      className="licence-pdfs"
                    />
                  ) : null}
                  {licenceFile ? (
                    <Pdf pdfUrl={licenceFile} className="licence-pdfs" />
                  ) : null}
                  <div style={{ marginTop: 10 }}>
                    {!isUpdatingLicence ? (
                      <label className={classes.filelicencebutton}>
                        <i className="fa fa-upload" aria-hidden="true"></i>
                        {t("board.update_licence")}
                        <span>
                          <input type="file" onChange={handleLicenceChange} />
                        </span>
                      </label>
                    ) : (
                        ""
                      )}
                  </div>

                  <div
                    className="profile-usertitle-job mt-2"
                    style={{ paddingLeft: "20%" }}
                  >
                    {/* {userAuthenticate.userType} */}
                    {isUpdatingLicence ? (
                      <button
                        type="button"
                        style={{ padding: 12 }}
                        className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                        onClick={onUpdateLicence}
                        disabled={updateUserLicence.loading}
                      >
                        {updateUserLicence.loading ? (
                          <span>
                            <i
                              className="fa fa-spinner fa-spin fa-1x"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp;
                          </span>
                        ) : null}
                        {t("documents.save")}
                      </button>
                    ) : null}

                    {isUpdatingLicence ? (
                      <button
                        type="button"
                        className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                        style={{ padding: 12, color: "white" }}
                        onClick={cancelEditLicence}
                        disabled={updateUserLicence.loading}
                      >
                        {t("documents.cancel")}
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <h5>{t("board.business_registration")}</h5>
                  {errorUpdateLicence}
                  {!registrationFile ? (
                    <Pdf
                      error={t("board.fail_to_load_pdf")}
                      pdfUrl={
                        authUser
                          ? `${process.env.BACKEND_BASE_URL}${userAuthenticate.businessRegistrationFile}`
                          : ""
                      }
                      className="licence-pdfs"
                    />
                  ) : null}
                  {registrationFile ? (
                    <Pdf
                      error={t("board.fail_to_load_pdf")}
                      pdfUrl={registrationFile}
                      className="licence-pdfs"
                    />
                  ) : null}

                  <div style={{ marginTop: 10 }}>
                    {!isUpdatingRegistration ? (
                      <label className={classes.filelicencebutton}>
                        <i className="fa fa-upload" aria-hidden="true"></i>
                        {t("board.update_file")}
                        <span>
                          <input
                            type="file"
                            onChange={handleRegistationChange}
                          />
                        </span>
                      </label>
                    ) : (
                        ""
                      )}
                  </div>

                  <div
                    className="profile-usertitle-job mt-2"
                    style={{ paddingLeft: "20%" }}
                  >
                    {/* {userAuthenticate.userType} */}
                    {isUpdatingRegistration ? (
                      <button
                        type="button"
                        style={{ padding: 12 }}
                        className={`${classes.btnUpdateAvatar} ${classes.btnAvatar} btn btn-link`}
                        onClick={onUpdateRegistration}
                        disabled={registrationLoader}
                      >
                        {registrationLoader ? (
                          <span>
                            <i
                              className="fa fa-spinner fa-spin fa-1x"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp;
                          </span>
                        ) : null}
                        {t("documents.save")}
                      </button>
                    ) : null}

                    {isUpdatingRegistration ? (
                      <button
                        type="button"
                        className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-link`}
                        style={{ padding: 12, color: "white" }}
                        onClick={cancelEditRegistation}
                        disabled={registrationLoader}
                      >
                        {t("documents.cancel")}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : isActive === 4 ? (
            <div className="col-md-12">
              <div className="user-tabs-header">
                {t("board.update_password")}
              </div>
              <div className="row">
                <div className="col-md-12" style={{ marginBottom: "20px" }}>
                  <label htmlFor="oldPassword">{t("board.old_password")}</label>
                  <input
                    name="oldPassword"
                    id="oldPassword"
                    ref={register}
                    className="form-control"
                    type="password"
                    onChange={(e) => { }}
                  />
                  <label htmlFor="newPassword">{t("board.new_password")}</label>
                  <div className="password-content">
                    <input
                      name="newPassword"
                      id="newPassword"
                      ref={register}
                      className="form-control"
                      type={inputType}
                      onChange={(e) => { }}
                    />
                    {showPassword ? <i className="far fa-eye" onClick={() => setShowPassword(false)}></i>
                      : <i className="far fa-eye-slash" onClick={() => setShowPassword(true)}></i>}
                  </div>
                  <label htmlFor="confirmNewPassword">
                    {t("board.new_password_confirm")}
                  </label>
                  <input
                    name="confirmNewPassword"
                    id="confirmNewPassword"
                    ref={register}
                    className="form-control"
                    type="password"
                    onChange={(e) => { }}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary btn-md"
                    disabled={loadingp}
                    onClick={handleSubmit(onSubmitForm)}
                  >
                    {loadingp ? (
                      <span>
                        <i
                          className="fa fa-spinner fa-spin fa-1x"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;
                      </span>
                    ) : null}
                    {t("board.update_my_password")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="row">
                <div style={{ marginBottom: "20px", width: '100%', marginTop: 20 }}>
                  <div className="col-md-12">
                    <label htmlFor="referralCode">{t("board.referral_code")}</label>
                    <input
                      name="referralCode"
                      id="referralCode"
                      ref={register}
                      value={referralCode}
                      className="form-control"
                      type="text"
                      onChange={(e) => { setReferralCode(e.target.value) }}
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="referralCode">{t("board.referral_link")}</label>
                    <input
                      type="text"
                      disabled={true}
                      className="form-control"
                      value={window.location.origin+'/login?referral='+userAuthenticate.referralCode}
                    />
                  </div>
                  <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button
                      type="submit"
                      className="btn btn-primary btn-md"
                      disabled={loadingrc}
                      onClick={() => onGenerateReferral()}
                    >
                      {loadingp ? (
                        <span>
                          <i
                            className="fa fa-spinner fa-spin fa-1x"
                            aria-hidden="true"
                          ></i>
                          &nbsp;&nbsp;
                        </span>
                      ) : null}
                      {t("board.referral_code_change")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary btn-md"
                      disabled={userAuthenticate ? !userAuthenticate.referralCode : true}
                      onClick={() => copyToClipboard(window.location.origin+'/login?referral='+userAuthenticate.referralCode)}
                    >
                      {t("board.copy_referral_link")}
                    </button>
                  </div>
                </div>
                <h4>{t("board.user_referrer")}</h4>
                <table className="table table-stripped" style={{ marginTop: 20 }}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(u => (
                      <tr>
                        <td>{u.name}</td>
                        <td>{u.date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>   
          )}
        </div>
      </div>
    </div>
  );
}


export default withTranslation()(React.memo(Board));
