/**
 * Helpers Functions
 */
import moment from 'moment-timezone';
import { toast } from "react-toastify";

export const getFullAuthorisationRequestConfig = () => {
    const oauth = process.env.OAUTH;
    const headers = {
        'Content-type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: 'Basic ' + btoa(oauth.clientId + ":" + oauth.clientSecret),
    };
    //'Content-type': 'multipart/form-data',

    return { headers, shouldSkipToken: true, withCredentials: true };
};


/**
 * Deep mapping an object
 * @param obj
 * @param callback function to apply to the key
 */
export function deepMapObject(obj, callback) {
    let rtn = obj;
    if (typeof (obj) === 'object') {
        if (obj instanceof Array) {
            rtn = obj.map(item => deepMapObject(item, callback));
        } else {
            rtn = {};
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    // apply the change on the string
                    const newKey = callback(key);

                    // Falsy or primitive value
                    if (!obj[key] || typeof obj[key] !== 'object')
                        rtn[newKey] = obj[key];
                    // nested object
                    else rtn[newKey] = deepMapObject(obj[key], callback);
                }
            }
        }
    }
    return rtn;
}

/**
 * Convert an object to snake case
 * @param obj
 */
export function toSnakeCase(obj) {
    // function to execute on each key
    const callback = key => key.replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_');

    // call a generic method
    return deepMapObject(obj, callback);
}


export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);


export const objectToFormData = (obj) => {
    let formData = new FormData();
    if (obj instanceof FormData) {
        formData = obj;
    } else {
        Object.keys(obj).forEach(key => formData.append(key, obj[key]));
    }
    return formData;
};


/**
 * Convert an object to camelCase
 * @param obj
 */
export function toCamelCase(obj) {
    // function to execute on each key
    const callback = key => key.replace(/(_\w)/g, k => k[1].toUpperCase());

    // call a generic method
    return deepMapObject(obj, callback);
}

export function computeDocumentName(name, type, lang) {
    let base = name.split('_');
    base.shift();
    if (lang === 'fr') {
        switch (type) {
            case 'PURCHASE':
                return 'bon_de_commande_' + base.join('_');
            case 'PROFORMAT':
                return 'facture_proformat_' + base.join('_');
            case 'CONTRACT':
                return 'contrat' + base.join('_');
            default:
                return name;
        }
    } else {
        switch (type) {
            case 'PURCHASE':
                return 'purchase_order_' + base.join('_');
            case 'PROFORMAT':
                return 'proformat_invoice_' + base.join('_');
            case 'CONTRACT':
                return 'contract_' + base.join('_');
            default:
                return name;
        }
    }
}

export function formatDate(date, lang, format = 'DD MMM YYYY') {
    if (lang === 'en') {
        moment.locale('en', {
            monthsShort: 'jan._feb._mar._apr._may._jun._jul._aug._sept._oct._nov._dec.'.split('_'),
            monthsParseExact: true
        });
        return moment(new Date(date)).format(format);
    } else {
        moment.locale('fr', {
            monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
            monthsParseExact: true
        });
        return moment(new Date(date)).format(format);
    }
}

export function formatToUTCDate(date, lang, format = 'DD MMM YYYY') {
    if (lang === 'en') {
        moment.locale('en', {
            monthsShort: 'jan._feb._mar._apr._may._jun._jul._aug._sept._oct._nov._dec.'.split('_'),
            monthsParseExact: true
        });
        return moment(new Date(date)).utc().format(format);
    } else {
        moment.locale('fr', {
            monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
            monthsParseExact: true
        });
        return moment(new Date(date)).utc().format(format);
    }
}

export function paginate(data, currentPage = 1, pageSize = 10, maxPages = 10) {
    // calculate total pages
    
    let totalItems = data.length;
    let totalPages = Math.ceil(data.length / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage; 
    let endPage;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    return data.slice(startIndex, endIndex+1);
}

export const copyToClipboard = (text) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(text);
            } else {
                const textField = document.createElement('textarea');
                textField.innerText = text;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
            }

            toast('Success', {className: "toast-success"});
            resolve(text);
        } catch (e) {
            toast('Error', {className: "toast-error"});
            reject();
        }
    })
};

export const formatCurrency = (amount, currencyCode = 'USD') => {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode,
    });
  }