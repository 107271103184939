import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import ReactScroll from 'react-infinite-scroll-component';

import ProductTypeThree from '../../../features/product/product-type-three';
import ProductTypeTwo from '../../../features/product/product-type-two';

import { shopFilterProducts } from '../../../../utils';
import { fetchAllProducts, fetchAllProductsSameCategory } from '../../../../action/products-actions';
import ModalTellingLogin from '../../../customs/modalInfo';


function InfiniteProductCategory( props ) {
    const products = useSelector(state => state.products);
    const modalToLogin = useSelector(state => state.modalToLogin);

    const {category} = props;


    const [ infiniteTotal, setInfiniteTotal ] = useState( 12 );
    const dispatch = useDispatch();

    const [categoryProducts, setCategoryProducts] = useState([])
    
    /* let products = products ? products : []; */
    //products = shopFilterProducts( products, filter );
    let total = infiniteTotal;

    //console.log("InfiniteProduct", products.data);

    function fetchData() {
        if ( total < products.length ) {
            // a fake async api call
            setTimeout( () => {
                setInfiniteTotal( total + 3 );
            }, 2000 );
        }
    }

   const  getCategoryProducts = (category_id) => {
    fetchAllProductsSameCategory(category_id).then(res => setCategoryProducts(res))
   }

    useEffect( () => {
        
        getCategoryProducts(category.id);
    }, [] )

    return (
        <>
        <ReactScroll
            dataLength={ infiniteTotal } //This is important field to render the next data
            next={ fetchData }
            hasMore={ infiniteTotal >= products.length ? false : true }
            style={ { overflow: 'visible' } }
            loader={ <div className="loader">
                <div className="bounce-loader">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div> }>
            {categoryProducts ? <div className={ `row row-sm position-relative ${ props.type === "list" ? "product-intro" : "" }` }>

                {
                    props.type === "grid" ?
                    categoryProducts.slice( 0, total ).map( ( product, index ) => (
                            <div className={ `col-6 col-md-4 fadeInDownShorter` } style={ { animationDuration: 1 + 's' } } key={ "product" + index }>
                                <ProductTypeTwo addClass="inner-quickview inner-icon" product={ product } />
                            </div>
                        ) )
                        :
                        categoryProducts.slice( 0, total ).map( ( product, index ) => (
                            <div className="col-6 col-sm-12 fadeInDownShorter" style={ { animationDuration: 1 + 's' } } key={ "product" + index } >
                                <ProductTypeThree addClass="left-details product-list mb-4" product={ product } />
                            </div>
                        ) )
                }
            </div> : null}
        </ReactScroll>
        </>
    )
}

const mapStateToProps = ( state, props ) => {
    return {
        filter: state.filter ? state.filter : [],
        products: state.products 
    }
}

export default InfiniteProductCategory;