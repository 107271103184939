import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import 'react-input-range/lib/css/index.css';
import { connect, useDispatch, useSelector } from 'react-redux';
import { SET_CATEGORY_ID_SELECTED, SET_MIN_PRICE_INTERVAL_FILTER } from '../../../../constants/products-actions'
import { filterCategories, filterPrice, filterSize, filterBrand, filterColor, cleanFilter } from '../../../../action';
import _filter from '../../../../mock-data/filter.json';
import { stickyContentHandle, setStickyValues } from '../../../../utils';
import { withTranslation } from "react-i18next";


function ShopSidebar( props ) {
    const [ val, setVal ] = useState( props.filter.value );
    const [ showClean, setShowClean ] = useState( false );
    const { filterCategories, cleanFilter, link = "" } = props;
    
    const { authUser } = useSelector(state => state.authUser);
    const [langSelected, setLangSelected] = useState("en");
  
    useEffect(() => {
      if (authUser != null) {
        setLangSelected(authUser.language.toLowerCase());
      }
    }, [authUser])

    const dispatch = useDispatch();

    useEffect( () => {
        if ( props.filter.category !== "" || props.filter.value.min > 0 || props.filter.value.max < 1000 ) {
            setShowClean( true );
        } else {
            setShowClean( false );
        }
    } )

    useEffect( () => {
        setStickyValues( 120 );
        window.addEventListener( "scroll", stickyContentHandle, { passive: true } );
        window.onresize = stickyContentHandle;

        return () => {
            window.removeEventListener( 'scroll', stickyContentHandle );
        }
    } )

    const getCategory = ( e, item ) => {
        e.preventDefault();
        dispatch({ type: SET_CATEGORY_ID_SELECTED, payload: item });
        filterCategories( item );
    }

    const cleanFilters = ( e ) => {
        e.preventDefault();
        setVal( { max: 1000, min: 0 } );
        cleanFilter();
        dispatch({ type: SET_MIN_PRICE_INTERVAL_FILTER, payload: null });
        dispatch({ type: SET_CATEGORY_ID_SELECTED, payload: null });
    }

    const {t} = props;

    return (
        <StickyBox className="sidebar-wrapper sticky-sidebar" style={{background: "white", borderRadius: "10px"}} offsetTop={ 80 }>
            {
                link !== "filterTwo" ?
                    <div className="widget">
                        <h3 className="widget-title" onClick={ setStickyValues }>
                            <a href="#widget-body-1" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="widget-body-1">{t("shop_sidebar.categories")}</a>
                        </h3>

                        <div className="collapse show" id="widget-body-1">
                            <div className="widget-body">
                                <ul className="cat-list">
                                    {
                                        props.categories.sort((c1, c2) => c1[langSelected].localeCompare(c2[langSelected])).map( ( item, index ) => (
                                            <li key={ "filter" + index } className={ props.filter.category === item ? 'active' : '' }>
                                                <p onClick={ ( e ) => getCategory( e, item ) } style={{ cursor: 'pointer' }}>{ langSelected == 'fr' ? item.fr : item.en }</p>
                                            </li>
                                        ) )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }


            {
                showClean ?
                    <div className="widget widget-clean">
                        <button className="btn btn-primary btn-md" onClick={ cleanFilters }>{t("shop_sidebar.reset_filter")}</button>
                    </div>
                    : ""
            }

        </StickyBox>
    )
}

const mapStateToProps = ( state ) => {
    return {
        baseProducts: state.data.products ? state.data.products : [],
        filter: state.filter ? state.filter : [],
        categories: state.productsConfig.productsCatUsedConfig
    }
}

export default withTranslation()(connect( mapStateToProps, { filterCategories, filterPrice, filterSize, filterBrand, filterColor, cleanFilter } )( ShopSidebar ));
