import * as pagesContentActionsType from '../constants/pagesContent-actions-types';

const initialState = {

    data: null,

    data: [],

    loading: false,
    error: null,
}


const pagesContentReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case pagesContentActionsType.FETCH_PAGE_CONTENT:
            return { ...state, loading: true, error: null, data: null};

        case pagesContentActionsType.FETCH_PAGE_CONTENT_SUCCESS:
            return { ...state, error: null, loading: false, data: action.payload};

        case pagesContentActionsType.FETCH_PAGE_CONTENT_ERROR:
            return { ...state, data: null, loading: false, error: action.payload };
        
        case pagesContentActionsType.FETCH_PAGE_CONTENT_CLEAR:
            return initialState

        default:
            return state;
    }
};


export default pagesContentReducer;