import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
import Breadcrumb from '../../common/breadcrumb';
import { contactSooko } from '../../../action/contact';

function Contact(props) {

    const { register, handleSubmit, errors, reset } = useForm({});
    const [loading, setLoading] = useState(false)

    const sendMessageToSooko = (data) => {

        setLoading(true);
        contactSooko(data).then((result) => {
            toast(`Your message have sent to sooko`, { className: "toast-success" });
            reset();
        }).catch((err) => {
            toast(`Error to send message: ${err.message}`, { className: "toast-error" });
        }).finally(() => {
            setLoading(false);
        });
    }

    const { t } = props;
    return (
        <>
            <Helmet>
                <title>Sooko - Contact Page </title>
            </Helmet>

            <h1 className="d-none">Sooko - Contact Page</h1>

            <div className="main">
                <Breadcrumb current="Contact Us" parent="pages" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="light-title">{t("contact.write")} <strong>{t("contact.us")}</strong></h2>

                            <form onSubmit={handleSubmit(sendMessageToSooko)}>
                                <div className="form-group required-field">
                                    <label htmlFor="contact-name">{t("board.account_name")}</label>
                                    <input type="text" className="form-control" id="contact-name" name="name" ref={register({ required: true })} required />
                                    {errors.name && <div className="text-danger mt-1">{t("contact.name_is_required")}</div>}
                                </div>

                                <div className="form-group required-field">
                                    <label htmlFor="contact-email">{t("board.account_email")}</label>
                                    <input type="email" className="form-control" id="contact-email" name="email" ref={register({ required: true })} required />
                                    {errors.email && <div className="text-danger mt-1">{t("contact.email_is_required")}</div>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="contact-phone">{t("board.account_phone_number")}</label>
                                    <input type="tel" className="form-control" id="contact-phone" ref={register({ required: true })} name="phone" />
                                </div>

                                <div className="form-group required-field">
                                    <label htmlFor="contact-message">{t("contact.your_mind")}</label>
                                    <textarea cols="30" rows="1" id="contact-message" className="form-control boxsizingBorder" name="message" ref={register({ required: true })} required></textarea>
                                    {errors.message && <div className="text-danger mt-1">{t("contact.message_required")}</div>}
                                </div>

                                <div className="form-footer">
                                    <button type="submit" style={{ borderRadius: '5px' }} className="btn btn-primary" disabled={loading}>
                                        {loading ? <span><i className="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> : null}
                                       {t("contact.submit")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="mb-8"></div>
            </div>
        </>
    )
}

export default withTranslation()(Contact);