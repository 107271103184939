import { Helmet } from 'react-helmet';
import posts from '@mockData/blog';
import { Link } from 'react-router-dom';
import imagesLoaded from 'imagesloaded';
import StickyBox from 'react-sticky-box';
import { withTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import Pagination from '../../features/pagination';
import BlogTypeOne from '../../features/blog/blog-type-one';
import { stickyContentHandle, setStickyValues } from '@utils';
import { getAllArticles, getAllCategories } from '@action/blogActions';

function Blog(props) {
    const { productCount = 0 } = props;
    const [curPage, setCurPage] = useState(1);
    const [displayCount, setDisplayCount] = useState(3);
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let imgLoad = imagesLoaded(".skeleton-body");

        if (document.querySelector('.skeleton-body')) {
            document.querySelector('.skeleton-body').classList.remove('loaded');
            imgLoad.on("done", function () {
                document.querySelector('.skeleton-body') && document.querySelector('.skeleton-body').classList.add('loaded');
            })
        }
    })

    useEffect(() => {
        setStickyValues(120);
        window.addEventListener('scroll', stickyContentHandle, { passive: true });

        return () => {
            window.removeEventListener('scroll', stickyContentHandle);
        }
    }, [])

    useEffect(() => {
        getCategories();
        getArticles();
    }, [])

    const getArticles = () => {
        getAllArticles()
            .then(data => {
                setArticles(data.filter(article => article.type != 'BLOG'));
                document.querySelector('.skeleton-body').classList.remove('loaded');
                document.querySelector('.skeleton-body') && document.querySelector('.skeleton-body').classList.remove('loaded');
                console.log('Finish loading')
            })
            .catch(err => {
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getCategories = () => {
        getAllCategories('DISCUSSION')
            .then(data => {
                setCategories(data);
            })
            .catch(err => {
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onChangeCurPage = (curPageParam) => {
        if (curPage !== curPageParam) {
            setCurPage(curPageParam);
            getAllArticles();
        }
    }

    const onChangeDisplayCount = (countParam) => {
        if (displayCount !== countParam) {
            setDisplayCount(countParam);
        }
    }

    const { t } = props;
    return (
        <>
            <Helmet>
                <title>Sooko - Blog Page </title>
            </Helmet>

            <h1 className="d-none">Sooko - Blog Page</h1>

            <div className="main">
                <Breadcrumb current="Blog" path="pages" />

                <div className="container">
                    <div className="row">
                        {articles.length > 0 ? <div className="col-lg-9 skeleton-body skel-shop-products">

                            {
                                articles.slice((curPage - 1) * displayCount, curPage * displayCount).map((blog, index) => (
                                    blog.type == 'DISCUSSION' ?
                                    <BlogTypeOne addClass="" blog={blog} discussion={true} width={1043} height={338} key={"BlogTypeOne" + index} /> : null
                                ))
                            }

                            <Pagination
                                isBlogCount={posts.length}
                                count={productCount}
                                displayCount={displayCount}
                                // getArticles={getArticles}
                                curPage={onChangeCurPage}
                                changeDisplay={onChangeDisplayCount}
                            />
                        </div> : null}

                        {!loading && articles.length <= 0 ? (
                            <div className="mb-5 col-lg-9 text-center">
                            <img
                                src="assets/images/nothing-here.png"
                                width="500"
                                style={{ margin: "0 auto" }}
                            />
                            <h2 className="text-muted font-weight-light">
                                {t("infinitescroll.nothing_here")}
                            </h2>
                            </div>
                        ) : null}

                        <aside className="sidebar col-lg-3 mobile-sidebar">
                            <StickyBox className="sidebar-wrapper sticky-sidebar" offsetTop={80}>
                                <div className="widget widget-search">
                                    <form role="search" method="get" className="search-form" action="#">
                                        <input type="search" className="form-control" placeholder={t("blog.search_post")} name="s" required />
                                        <button type="submit" className="search-submit" title="Search">
                                            <i className="icon-search"></i>
                                            <span className="sr-only">{t("blog.search")}</span>
                                        </button>
                                    </form>
                                </div>

                                <div className="widget widget-categories">
                                    <h4 className="widget-title">{t("blog.all_category")}</h4>

                                    <ul className="list">
                                        {
                                            categories.length <= 0 ?
                                                <li>{t("blog.nothing_to_show")}</li> :
                                                categories.map(category => (
                                                    <li><Link to="#">{category.name}</Link></li>
                                                ))
                                        }
                                    </ul>
                                </div>
                            </StickyBox>
                        </aside>
                    </div>
                </div>

                <div className="mb-6"></div>
            </div>
        </>
    )
}

export default  withTranslation()(Blog);