import React from 'react';
import Modal from 'react-modal';
import { withTranslation } from "react-i18next";
import greetAndMeetImg from '../../../../public/assets/images/handshake-with-it-transprent-background-meet-and-greet.png';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'relative',
        maxWidth: '870px',
        marginRight: '2rem',
        padding: '.5rem',
        backgroundColor: '#fff'
    }
};

Modal.setAppElement('#root');

function successSignUpModal(props) {

    const closeModal = () => {
        //setOpen(false);
        props.setShowModalRegistrationSucess(false);
    }


    return (
        
        <>
            <Modal
                shouldCloseOnOverlayClick={false}
                isOpen={props.shouldShowSuccessModal}
                onRequestClose={closeModal}
                contentLabel="login Modal"
                className="login-popup"
                id="login-popup-form"
                overlayClassName="cart-modal-overlay"
                style={customStyles}
            >
                <div className="modal-wrapper" style={{ height: "100%" }}>
                    <div className="container">
                        <div className="d-flex flex-column justify-content-center align-items-center">

                            <h2 className="text-center"> <span className="text-success">{props.t("login.modal_welcome")}</span> <br /><br />
                            <div className="text-center"><img src={greetAndMeetImg} style={{ margin: "0 auto" }} width={250} /></div>
                            <br />
                            {props.t("login.modal_title")} </h2>
                            <p>
                            {props.t("login.modal_sub_title")}
                            </p>

                            <br /><br/>
                            <p><strong className="text-success">{props.t("login.modal_experience")}</strong></p>
                        </div>
                    </div>

                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={closeModal}>×</button>
                </div>
            </Modal>
        </>
    )

}

export default withTranslation()(successSignUpModal);