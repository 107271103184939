import * as productsActions from '../constants/products-actions';
/* import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'; */

const initialState = {

    totalProducts: 0,
    totalProductInCategorySelected: 0,

    categorySelected: null,
    searchName: null,
    filterPriceInterval: null, //{ min: val1, max: val2 }

    data: [],
    dataInfinite: [],

    loading: false,
    error: null,

    loadingFromIdLimit: false,
    errorFromIdLimit: null
}

const productsReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case productsActions.FETCH_ALL_PRODUCTS:
            return { ...state, loading: true, error: null };

        case productsActions.FETCH_ALL_PRODUCTS_SUCCESS:
            return { ...state, error: null, loading: false, data: action.payload };

        case productsActions.FETCH_ALL_PRODUCTS_ERROR:
            return { ...state, data: null, loading: false,  error: action.payload }
    
        case productsActions.FETCH_ALL_PRODUCTS_CLEAR:
            return {...state, data: [], loading: false, error: null}


        /*********************************************************************************************** */
        /*********************************************************************************************** */

        case productsActions.FETCH_ALL_PRODUCTS_FROM_ID_LIMIT:
            return { ...state, loadingFromIdLimit: true, errorFromIdLimit: null};

        case productsActions.FETCH_ALL_PRODUCTS_FROM_ID_LIMIT_SUCCESS:
            let dataUpdate = state.dataInfinite.concat([...action.payload]);
            //state.dataInfinite.push([...action.payload])
            return { ...state, error: null, loading: false, dataInfinite: dataUpdate };

        case productsActions.FETCH_ALL_PRODUCTS_FROM_ID_LIMIT_ERROR:
            return { ...state, dataInfinite: [...state.dataInfinite], loadingFromIdLimit: false,  errorFromIdLimit: action.payload }

        
        case productsActions.FETCH_ALL_PRODUCTS_FROM_ID_LIMIT_CLEAR:
            return {...state, dataInfinite: [], loadingFromIdLimit: false, errorFromIdLimit: null}

        /*********************************************************************************************** */
        /*********************************************************************************************** */
        case productsActions.COUNT_ALL_PRODUCTS_SUCCESS:
            return {...state,  totalProducts: action.payload }

        
        case productsActions.SET_CATEGORY_ID_SELECTED:
            return {...state, categorySelected: action.payload}

        case productsActions.SET_SEARCH_NAME:
            return {...state, searchName: action.payload}
        
        case productsActions.SET_MIN_PRICE_INTERVAL_FILTER:
            return {...state, filterPriceInterval: action.payload}


        default:
            return state;
    }
};

/* const persistConfig = {
    keyPrefix: "porto-",
    key: "products",
    storage
}
 */
export default productsReducer;