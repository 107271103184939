import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import SuccessModal from '@components/pages/succesModal';
import { monthTypeOne, monthTypeTwo } from '@mockData/data';
import { postComment, getAllArticleComments } from '@action/blogActions';
import { LOGIN } from '@routes/urls';

function BlogSingle(props) {

    const { blog, discussion } = props;
    let date = new Date(blog.createdAt);

  const { t } = props;

    const { authUser } = useSelector(state => state.authUser);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);
    const [email, setEmail] = useState(authUser ? authUser.email : '');
    const [name, setName] = useState(authUser ? authUser.name : '');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        showContent();
        getComments()
    }, [])

    useEffect(() => {
        showContent();
    })

    const getComments = () => {
        getAllArticleComments(blog.id).then(data => {
            setComments(data);
        })
    }

    const onSubmit = () => {

        if (comment.length <= 0) {
            toast("Fill the comment form", { className: "toast-error" });
            return
        }

        let data = {
            content: comment,
            name: name,
            email: email
        }
        if (authUser) {
            data = {
                ...data,
                user: { id: authUser.id }
            }
        }
        setLoading(true);
        console.log(blog)
        postComment(blog.id, data).then(res => {
            toast("Comment created successfully", { className: "toast-success" });
            getComments();
        }).catch((err) => {
            toast("Error while creating", { className: "toast-error" });
        }).finally(() => {
            setLoading(false)
        })
    }

    const closeModal = () => {
        setShowModal(false);
      };

    const showContent = () => {
        document.querySelector(".single .post-content").innerHTML = props.blog.content;
    }

    return (
        <article className="post single">
            <div className="post-media">
                {
                    blog.image &&
                    (
                        <Link to={`${process.env.PUBLIC_URL}/market-intelligence/single/${blog.id}`}>
                            <figure>
                                <img src={`${process.env.BACKEND_BASE_URL}${blog.image}`} width={props.width} height={props.height} alt="product" />
                            </figure>
                        </Link>
                    )
                }
            </div>
            <div className="post-body" style={{ 'borderBottom': '1px solid #fff' }}>
                <div className="post-date">
                    <span className="day">{date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}</span>
                    <span className="month">{monthTypeOne[date.getMonth()]}</span>
                </div>

                <h2 className="post-title">
                    {blog.title}
                </h2>

                <div className="post-meta">
                    <span><i className="icon-calendar"></i>{` ${monthTypeTwo[date.getMonth()]} ${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}, ${date.getYear() + 1900}`}</span>
                    {/* <span><i className="icon-user"></i>By <Link to="#">{ blog.contributor }</Link></span> */}
                    <span><i className="icon-folder-open"></i>
                        {
                            [blog.category].map((category, index) => (
                                index === ([blog.category].length - 1) ?
                                    <Link to="#" key={"category" + index}>{category.name}</Link>
                                    : <Link to="#" key={"category" + index}>{category.name}, </Link>
                            ))
                        }
                    </span>
                </div>

                <div className="post-content">

                </div>
            </div>
            <div className="product-reviews-content">
                <div className="row">

                    {discussion ?
                        <div className={"col-xl-7"}>
                            <h2 className="reviews-title">{t('common.comment')}</h2>
                            <ol className="comment-list" style={{
                                display: 'flex',
                                flexDirection: comments.length <= 0 ? 'row' : 'column',
                                minHeight: comments.length <= 0 ? '100%' : '10px',
                                alignItems: comments.length <= 0 ? 'center' : 'left',
                                justifyContent: comments.length <= 0 ? 'center' : 'left'
                            }}>

                                {
                                    comments.length > 0 ? comments.map((c, index) => (
                                        <li className="comment-container" key={"review" + index} style={{ minWidth: '100%' }}>
                                            <div style={{ width: '20%', marginLeft: '3%' }}>
                                                <img src={c.user && c.user.avatar.startsWith('https://encryp') ? 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8yJmOL8nb6x7NO2xuLB-Cc1qP2MRFdq24qg&usqp=CAU' : `${process.env.BACKEND_BASE_URL}${c.user.avatar}`} width="65" height="65" alt="avatar" />
                                            </div>
                                            <div className="comment-box">
                                                <div className="comment-info mb-1">
                                                    <h4 className="avatar-name">{c.name}</h4> - <span className="comment-date">{` ${monthTypeTwo[(new Date(c.createdAt)).getMonth()]} ${(new Date(c.createdAt)).getDate() < 10 ? '0' + (new Date(c.createdAt)).getDate() : (new Date(c.createdAt)).getDate()}, ${(new Date(c.createdAt)).getYear() + 1900}`}</span>
                                                </div>
                                                <div className="comment-text">
                                                    <p>
                                                        {c.content}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    )) :
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/demo/undraw_personal_opinions_g3kr.svg`} width={300} />
                                }
                            </ol>
                        </div> : null}
                    {discussion && authUser ?
                        <div className={"col-xl-5"}>
                            <div className="add-product-review">
                                <form action="#" className="comment-form m-0">
                                    <h3 className="review-title">{t('common.add_comment')}</h3>
                                    <div className="form-group">
                                        <label>{t('common.your_comment')}</label>
                                        <textarea cols="5" rows="6" type="text" onChange={(e) => setComment(e.target.value)} value={comment} className="form-control form-control-sm" required />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-xl-12">
                                            <div className="form-group">
                                                <label>{t('common.your_name')}</label>
                                                <input type="text" className="form-control form-control-sm" onChange={(e) => setName(e.target.value)} value={name} required disabled />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-12">
                                            <div className="form-group">
                                                <label>{t('common.your_email')}</label>
                                                <input type="text" className="form-control form-control-sm" onChange={(e) => setEmail(e.target.value)} value={email} required disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" disabled={loading} className="btn btn-primary btn-md fullwidth" onClick={onSubmit}>
                                        {loading ? <span><i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> :
                                            t('common.post_comment')}
                                    </button>
                                </form>
                            </div>
                        </div> : null}
                    {discussion && !authUser ?
                        <div className={"col-xl-5"}>
                            <div className="add-product-review">
                                {/* <form action="#" className="comment-form m-0"> */}
                                    <h3 className="review-title">{t('common.add_comment')}</h3>
                                    <div className="form-group">
                                        <label>{t('common.your_comment')}</label>
                                        <textarea cols="5" rows="6" type="text" onChange={(e) => setComment(e.target.value)} className="form-control form-control-sm" required />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-xl-12">
                                            <div className="form-group">
                                                <label>{t('common.your_name')}</label>
                                                <input type="text" className="form-control form-control-sm" onChange={(e) => setName(e.target.value)} required />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-12">
                                            <div className="form-group">
                                                <label>{t('common.your_email')}</label>
                                                <input type="text" className="form-control form-control-sm" onChange={(e) => setEmail(e.target.value)} required />
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" disabled={loading} className="btn btn-primary btn-md fullwidth" onClick={() => setShowModal(true)}>
                                        {loading ? <span><i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;</span> :
                                            t('common.post_comment')}
                                    </button>
                                {/* </form> */}
                            </div>
                        </div> : null}
                </div>
            </div>
            <SuccessModal
                shouldShowModal={showModal}
                close={closeModal}
                title={t('notes.login')}
                subtitle={t('notes.post')}
                button={t('notes.post_button')}
                to={LOGIN}
            />
        </article>
    )
}

export default withTranslation()(React.memo(withRouter(BlogSingle)));