import * as sellerProductsActionsType from '../constants/sellerProducts-action-types';

const initialStateFetch = {

    data: null,

    loading: false,
    error: null,
}

const initialStateCreate = {
    dataCreate: null,
    loadingCreate: false,
    errorCreate: null
}

const initialStateUpdate = {
    dataUpdate: null,
    loadingUpdate: false,
    errorUpdate: null
}

const initialStateDelete = {
    dataDelete: null,
    loadingDelete: false,
    errorDelete: null
} 

const sellerProductsReducer = ( state = {...initialStateFetch , ...initialStateCreate, ...initialStateUpdate, ...initialStateDelete}, action ) => {
    switch ( action.type ) {

        case sellerProductsActionsType.FETCH_SELLER_PRODUCTS_BASE:
            return { ...state, loading: true, error: null, data: null};

        case sellerProductsActionsType.FETCH_SELLER_PRODUCTS_SUCCESS:
            return { ...state, error: null, loading: false, data: action.payload };

        case sellerProductsActionsType.FETCH_SELLER_PRODUCTS_ERROR:
            return { ...state, data: null, loading: false, error: action.payload };
        
        case sellerProductsActionsType.FETCH_SELLER_PRODUCTS_CLEAR:
            return {...state, ...initialStateFetch}
        
        
        /****************************** ********************************************* */
        
        case sellerProductsActionsType.CREATE_SELLER_PRODUCT:
            return { ...state, loadingCreate: true, errorCreate: null, dataCreate: null};

        case sellerProductsActionsType.CREATE_SELLER_PRODUCT_SUCCESS:
            return { ...state, errorCreate: null, loadingCreate: false, dataCreate: action.payload };

        case sellerProductsActionsType.CREATE_SELLER_PRODUCT_ERROR:
            return { ...state, dataCreate: null, loadingCreate: false, errorCreate: action.payload };
    
        case sellerProductsActionsType.CREATE_SELLER_PRODUCT_CLEAR:
            return {...state, ...initialStateCreate}


        default:
            return state;
    }
};

/* const persistConfig = {
    keyPrefix: "porto-",
    key: "products",
    storage
}
 */
export default sellerProductsReducer;