import Modal from "react-modal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classes from "./productCreate.module.scss";
import React, { useEffect, useState } from "react";
import CheckoutProgessBar from "./checkout-progress-bar";
import SuccessModal from '@components/pages/succesModal';
import ProductCreateStep1 from "./stepperForCreate/ProductCreateStep1";
import ProductCreateStep2 from "./stepperForCreate/ProductCreateStep2";
import ProductCreateStep3 from "./stepperForCreate/ProductCreateStep3";
import { createProductsOfSeller, updateProductsOfSeller } from "@action/sellerProducts-actions";

Modal.setAppElement("#root");

function ProductCreate(props) {
  
  const productsConfig = useSelector((state) => state.productsConfig);
  const { authUser } = useSelector(state => state.authUser);
  const [langSelected, setLangSelected] = useState("en");
  const [otherCategoryProduct, setOtherCategoryProduct] = useState(false);

  useEffect(() => {
    if (authUser != null) {
      setLangSelected(authUser.language.toLowerCase());
    }
  }, [authUser])

  const { productToUpdate, cancelCreationForm } = props;

  const unitsConfigQuantity = productsConfig.unitsConfig.filter(item => item.unitType === 'QUANTITY');
  const unitsConfigTime = productsConfig.unitsConfig.filter(item => item.unitType === 'TIME');

  const [dataStep1, setDataStep1] = useState(productToUpdate ? {
    productCategoryId: Number(productToUpdate.productCategory.id), name: productToUpdate.name,
    country: productToUpdate.countryOfOrigin, type: productToUpdate.type, quality: productToUpdate.qualitySpecification,
    description: productToUpdate.description
  } : {});

  const [dataStep2, setDataStep2] = useState(productToUpdate ? {
    price: productToUpdate.price, currency: productToUpdate.currency,
    pricePerUnitId: Number(productToUpdate.pricePerUnit.id), validityOfPrice: productToUpdate.validityOfPrice,
    paymentOption: productToUpdate.payementOption, maxPrice: productToUpdate.maxPrice, minPrice: productToUpdate.minPrice,
    minOrderQuantity: productToUpdate.minOrderQuantity, minOrderQuantityUnitId: Number(productToUpdate.minOrderQuantityUnit.id)
  } : {});

  const [dataStep3, setDataStep3] = useState(productToUpdate ? {
    processingTime: productToUpdate.processingTime, processingTimeUnitId: Number(productToUpdate.processingTimeUnit.id),
    portId: Number(productToUpdate.port.id), productionCapacity: productToUpdate.productionCapacity,
    productionCapacityUnitId: Number(productToUpdate.productionCapacityUnit.id), productionCapacityPeriodUnitId: Number(productToUpdate.productionCapacityPeriodUnit.id),
    packagingsDetails: productToUpdate.packagingDetails
  } : {});

  //const { register, handleSubmit, errors, reset } = useForm();
  const [file, setFile] = useState(productToUpdate ? `${process.env.BACKEND_BASE_URL}${productToUpdate.mainImagePath}` : null);
  const [fileToSend, setFileToSend] = useState(null);

  const handlemainImageChange = (event) => {
    if (event.target.files[0]) {
      setFile(URL.createObjectURL(event.target.files[0]));
      setFileToSend(event.target.files[0]);
    }
  };

  const cancelMainImageUpload = () => {
    setFile(null);
    setFileToSend(null);
  };

  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isSecondStep, setIsSecondStep] = useState(false);
  const [isThirdStep, setIsThirdStep] = useState(false);

  const [dataCreated, setDataCreated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setErrorCreate] = useState(false);
  const [errorDialogClosed, setErrorDialogClosed] = useState(true);

  //const [uploadFirstImage, setUploadFirstImage] = useState(null);
  const [uploadOthersImage, setUploadOthersImage] = useState(null);

  let loader = null;
  let errorDisplay = null;

  const [open, setOpen] = useState(false);

  const setDataFormStep1 = (data) => {
    setDataStep1(data);
    onNextStep();
  }

  const setDataFormStep2 = (data) => {
    setDataStep2(data);
    onNextStep();
  }

  const setDataFormStep3ToSaveDataStep3 = (data) => {

    setDataStep3(data);
  }

  const setDataFormStep3 = (data) => {

    setDataStep3(data);

    createdProduct({ ...dataStep1, ...dataStep2, ...data });

  }


  const closeModal = () => {
    //props.clearUpdateStateAfterUpdate();
    setOpen(false);
    setOtherCategoryProduct(false);
    props.cancelCreationForm();
    // props.history.push(`${process.env.PUBLIC_URL}/account/dashboard/seller-products`);
  };

  const initialiseState = () => {
    setErrorDialogClosed(true);
    setLoading(true);
    setErrorCreate(null);
    setDataCreated(null);
  };

  const onNextStep = () => {
    if (isFirstStep) {
      setIsFirstStep(false);
      setIsSecondStep(true);
    }
    if (isSecondStep) {
      setIsSecondStep(false);
      setIsThirdStep(true);
    }
  };

  const onPreviousStep = () => {
    if (isSecondStep) {
      setIsFirstStep(true);
      setIsSecondStep(false);
    }
    if (isThirdStep) {
      setIsSecondStep(true);
      setIsThirdStep(false);
    }
  };

  const { t } = props;

  const createdProduct = (data) => {

    initialiseState();

    if (props.productToUpdate) {
      updateProductsOfSeller({ ...data, productId: props.productToUpdate.id, image: fileToSend }, props.productToUpdate.id)
        .then(data => {
          // toast("Product updated!", { className: "toast-success" });
          props.cancelCreationForm();
        })
        .catch(err => {
          toast(`Error: ${err.message}`, { className: "toast-error" });
          setErrorCreate(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {

      if(fileToSend == null) {
        toast(t("products.image_required"), {className: "toast-error",});
        setLoading(false);
        return;
      }

      let cat = productsConfig.productsCatConfig.find(pc => pc.id == data.productCategoryId);

      if(cat) {
        setOtherCategoryProduct(cat.isUnknown);
      }

      createProductsOfSeller({
        ...data,
        image: fileToSend,
        sellerId: authUser.seller.id,
      })
        .then((data) => {
          // toast("Product created!", { className: "toast-success" });
          setOpen(true);
          // props.cancelCreationForm();
        })
        .catch((err) => {
          setErrorDialogClosed(false);
          setErrorCreate(err);
          toast(`Error: ${err.message}`, { className: "toast-error" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (loading) {
    loader = <div className="text-center">...Loading</div>;
  }

  if (error && !errorDialogClosed) {
    errorDisplay = (
      <>
        <br />
        <div className="alert alert-danger">
          Error: {error.message}
          <button
            title="Close (Esc)"
            type="button"
            className="mfp-close"
            onClick={() => setErrorDialogClosed(true)}
          >
            X
          </button>
        </div>
        <br />
      </>
    );
  }

  return (
    <div className="main">
      <div>
        <button
          onClick={cancelCreationForm}
          className="btn btn-warning"
          style={{ padding: "10px 10px", borderRadius: "5px" }}
        >
          <i className="fa fa-ban"></i> &nbsp;
            {t('board.cancel')}
          </button>
      </div>

      <div className="container">
        <CheckoutProgessBar
          active={isFirstStep ? 1 : isSecondStep ? 2 : isThirdStep ? 3 : 1}
        />

        <SuccessModal
          shouldShowModal={open}
          close={closeModal}
          subtitle={t('notes.see_products')}
          button={t('products.go_to')}
          to={`${process.env.PUBLIC_URL}/account/dashboard/seller-products`}
          title={otherCategoryProduct ? t('notes.product_created_pending') : t('notes.product_created')}
        />

        <div className="row">
          <div className="col-lg-12 order-lg-first">
            <div className="checkout-payment">
              <h2 className="mb-2">
                {isFirstStep ? t('products.details') : ""}
                {isSecondStep ? t('products.payment') : ""}
                {isThirdStep ? t('products.logistic') : ""}
              </h2>

              {loader}
              {errorDisplay}

              <div className="row">

                <div className="col-sm-9">

                  <div id="new-checkout-address" className="show">
                    {isFirstStep ? <ProductCreateStep1 productToUpdate={props.productToUpdate} setDataStep1={setDataFormStep1} defaultDataStep1={dataStep1} productsCatConfig={productsConfig.productsCatConfig.sort((c1, c2) => c1[langSelected].localeCompare(c2[langSelected]))} /> : null}

                    {isSecondStep ? <ProductCreateStep2 productToUpdate={props.productToUpdate} onPreviousStep={onPreviousStep} defaultDataStep2={dataStep2} setDataStep2={setDataFormStep2} unitsConfigQuantity={unitsConfigQuantity.sort((c1, c2) => c1[langSelected].localeCompare(c2[langSelected]))} /> : null}

                    {isThirdStep ? <ProductCreateStep3 setDataFormStep3ToSaveDataStep3={setDataFormStep3ToSaveDataStep3} productToUpdate={props.productToUpdate} loading={loading} onPreviousStep={onPreviousStep} setDataStep3={setDataFormStep3}
                      defaultDataStep3={dataStep3}
                      unitsConfigQuantity={unitsConfigQuantity.sort((c1, c2) => c1[langSelected].localeCompare(c2[langSelected]))}
                      unitsConfigTime={unitsConfigTime.sort((c1, c2) => c1[langSelected].localeCompare(c2[langSelected]))}
                      portsConfig={productsConfig.portsConfig.sort((p1, p2) => p1.name.localeCompare(p2.name))} /> : null}

                  </div>
                </div>

                <div className="col-md-3">
                  <h5 style={{ margin: "0px" }}>{t('products.image')}</h5>
                  <div className="profile-usertitle">
                    <div
                      className="profile-userpic"
                      style={{
                        position: "relative",
                        height: "200px",
                      }}
                    >
                      <label className={classes.filebutton}>
                        {/* {t('products.image_edit')} */}
                        <span className="rfq-item-icons-edit input-tooltip" style={{ color: "#fff", cursor: "pointer" }}><i className="fa fa-pencil-alt" aria-hidden="true"></i></span>
                        <span>
                          <input
                            type="file"
                            onChange={handlemainImageChange}
                            id="mainImageProduct"
                          />
                        </span>
                      </label>

                      {!file ? (
                        <img
                          src={"assets/images/nothing-here.png"}
                          style={{ width: "200px", height: "200px" }}
                          className="img-responsive"
                          alt="Product image"
                        />
                      ) : null}
                      {file ? (
                        <img
                          src={file}
                          className="img-responsive"
                          style={{ width: "200px", height: "200px" }}
                          alt="Product image"
                        />
                      ) : null}
                    </div>
                    <div className="profile-usertitle-job mt-2">
                      {/* {userAuthenticate.userType} */}

                      {file ? (
                        <button
                          type="button"
                          className={`${classes.btnCancelAvatar} ${classes.btnAvatar} btn btn-warning`}
                          style={{}}
                          onClick={cancelMainImageUpload}
                        >
                          {t('products.cancel')}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6"></div>
    </div>
  );
}

export default withTranslation()(React.memo( withRouter(ProductCreate)));
