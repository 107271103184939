import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { withTranslation, useTranslation } from "react-i18next";
import { computeDocumentName, formatDate } from '@helpers/helpers'
import { getDocumentFromSinRequest, markDocumentAsRead } from '@action/docs-actions';

function DocListItem(props) {

    const authUser = useSelector(state => state.authUser)
    const isBuyer = authUser.authUser && authUser.authUser.userType === 'BUYER';
    const isSeller = authUser.authUser && authUser.authUser.userType === 'SELLER';
    const { item, createContractFromDoc } = props;

    const [docStatusSignRequest, setDocStatusSignRequest] = useState(null)

    const { t } = props;
    const { i18n } = useTranslation();
    const [langSelected, setLangSelected] = useState("en");

    useEffect(() => {
        if (authUser.authUser) {
            setLangSelected(authUser.authUser.language.toLowerCase());
        }
    }, [authUser]);

    const openDocumentInBrowser = (uuid, docId) => {

        getDocumentFromSinRequest(uuid).then((resultSignRquest) => {

            markDocumentAsRead(docId).then((result) => {

                // toast(`Mark as read`, { className: "toast-success" });
                props.getCountDocsUnread();

                getDocumentFromSinRequest(item.uuid).then((resultSignRquest) => {
                    setDocStatusSignRequest(resultSignRquest.status);
                    window.open(resultSignRquest.pdf ? resultSignRquest.pdf : resultSignRquest.file, '_blank');
                });

            }).catch((err) => {

                // toast(`Error to mark as read: ${err.message}`, { className: "toast-error" });
            });

        }).catch((err) => {
            // toast(`Error to get document from sign request: ${err.message}`, { className: "toast-error" });
        });
    }

    useEffect(() => {
        if (item.uuid)
            getDocumentFromSinRequest(item.uuid).then((resultSignRquest) => {
                setDocStatusSignRequest(resultSignRquest.status);
            });
    }, []);

    const goToContract = (id) => {
        props.history.push(
            `${process.env.PUBLIC_URL}/account/dashboard/contracts/${id}/contract-effective`
        );
    };

    return (
        <tr>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                {computeDocumentName(item.title, item.type, i18n.language)}
            </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}>

                {
                    item.uuid && docStatusSignRequest == 'si' ?
                        <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: 'green', fontWeight: "bold", padding: 5 }}>
                            {t('common.signed')} </div>
                        :
                        (isBuyer && !item.buyerStatus) || (isSeller && !item.sellerStatus) ?
                            <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: '#ff0000a1', fontWeight: "bold", padding: 5 }}> {t('common.new')} </div>
                            :
                            isBuyer && item.buyerStatus && item.uuid ?
                                <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: `${docStatusSignRequest == 'se' ? '#0991f1a1' : docStatusSignRequest == 'si' ? 'green' : docStatusSignRequest == 'ec' ? 'red' : '#acb5bba1'}`, fontWeight: "bold", padding: 5 }}>
                                    {`${docStatusSignRequest == 'se' ? t('common.read') : docStatusSignRequest == 'si' ? t('common.signed') : docStatusSignRequest == 'ec' ? 'Err convert' : '...'}`} </div>
                                :
                                isSeller && item.sellerStatus && item.uuid ?
                                    <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: `${docStatusSignRequest == 'se' ? '#0991f1a1' : docStatusSignRequest == 'si' ? 'green' : docStatusSignRequest == 'ec' ? 'red' : '#acb5bba1'}`, fontWeight: "bold", padding: 5 }}>
                                        {`${docStatusSignRequest == 'se' ? t('common.read') : docStatusSignRequest == 'si' ? t('common.signed') : docStatusSignRequest == 'ec' ? 'Err convert' : '...'}`} </div>
                                    :
                                    <div className="user-button-action-2 d-inline mt-3" style={{ color: 'white', backgroundColor: 'rgb(0 139 243 / 66%)', fontWeight: "bold", padding: 5 }}> {t('common.processing')} </div>
                }
            </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}> {item.quotationUserStatus ? <>{item.quotationUserStatus.quotation.price} {item.quotationUserStatus.quotation.currency} / {item.quotationUserStatus.quotation.product.pricePerUnit[langSelected == 'fr' ? 'fr' : 'en']}</> : null} </td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}> {formatDate(item.createdAt, i18n.language)}</td>
            <td style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div className="row">
                    <div className="rfq-item-icons">
                        <span onClick={() => openDocumentInBrowser(item.uuid, item.id)}><span className="rfq-item-icons-read input-tooltip" data-toggle="tooltip" title={t('common.view')} data-placement="right" style={{ cursor: "pointer" }}><i className="fa fa-eye" aria-hidden="true"></i></span>&nbsp;</span>
                        {/* {isBuyer ? <Link to={`${process.env.PUBLIC_URL}/account/dashboard/rfqsUpdate/products/${item.baseQuotation.product.id}/rfq/update`}><span className="rfq-item-icons-edit" style={{ color: "#e4c318", cursor: "pointer" }}><i className="fa fa-pencil-alt" aria-hidden="true"></i></span>&nbsp;</Link> : null} */}
                        {!item.contract && docStatusSignRequest == 'si' ? <><span style={{ display: item.type == 'CONTRACT' ? 'none' : 'visible' }}><button onClick={() => { createContractFromDoc(item.id) }} className="btn-pay-contract float-right" style={{ fontWeight: "bold", margin: "0px", marginTop: "-5px" }}>{t('notes.go_contract')}</button></span></> :
                            item.type != 'CONTRACT' && docStatusSignRequest == 'si' && !item.contract.file ? <><span><button onClick={() => goToContract(item.contract.id)} className="btn-pay-contract float-right" style={{ fontWeight: "bold", margin: "0px", marginTop: "-5px" }}>{t('notes.go_contract')}</button></span></> : null}
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default withTranslation()(React.memo(withRouter(DocListItem)));