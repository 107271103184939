export const ROOT = `${process.env.PUBLIC_URL}/`;
export const FAQ = `${process.env.PUBLIC_URL}/faq`;
export const HOME = `${process.env.PUBLIC_URL}/home`;
export const LOGIN = `${process.env.PUBLIC_URL}/login`;
export const ABOUT = `${process.env.PUBLIC_URL}/about`;
export const NOT_FOUND = `${process.env.PUBLIC_URL}/404`;
export const CONTACT = `${process.env.PUBLIC_URL}/contact`;
export const USER_GUIDE = `${process.env.PUBLIC_URL}/user-guide`;
export const DISCUSSION = `${process.env.PUBLIC_URL}/discussions`;
export const MARKETPLACE = `${process.env.PUBLIC_URL}/marketplace`;
export const RFQ_LIST = `${process.env.PUBLIC_URL}/pages/rfq/rfqList`;
export const CREATE_RFQ = `${process.env.PUBLIC_URL}/products/:id/rfq`;
export const NEW_RFQ = `${process.env.PUBLIC_URL}/pages/rfq/rfqCreation`;
export const PRIVACY_POLICY = `${process.env.PUBLIC_URL}/privacy-policy`;
export const FORGOT_PASSWORD = `${process.env.PUBLIC_URL}/forgot-password`;
export const UPDATE_RFQ = `${process.env.PUBLIC_URL}/products/:id/rfq/update`;
export const DASHBOARD = `${process.env.PUBLIC_URL}/account/dashboard/:board`;
export const DASHBOARD_ROOT = `${process.env.PUBLIC_URL}/account/dashboard`;
export const PRODUCT_DETAILS = `${process.env.PUBLIC_URL}/products/:id/details`;
export const DISCUSSION_POST = `${process.env.PUBLIC_URL}/discussions/single/:id`;
export const MARKET_INTELLIGENCE = `${process.env.PUBLIC_URL}/market-intelligence`;
export const TERM_AND_CONDITIONS = `${process.env.PUBLIC_URL}/terms-and-conditions`;
export const RFQ_DETAILS = `${process.env.PUBLIC_URL}/account/dashboard/:board/:rfqId/details`;
export const CHAT = `${process.env.PUBLIC_URL}/account/dashboard/:board/quote/:quotationId/chat`;
export const MARKET_INTELLIGENCE_DETAILS = `${process.env.PUBLIC_URL}/market-intelligence/single/:id`;
export const DASHBOARD_RFQ_UPDATE = `${process.env.PUBLIC_URL}/account/dashboard/:board/products/:id/rfq/update`;
export const CONTRACT = `${process.env.PUBLIC_URL}/account/dashboard/:board/:contractEffectiveId/contract-effective`;
export const CONTRACT_FILE = `${process.env.PUBLIC_URL}/account/dashboard/:board/:contractId/contract-attached-files`;

export const UNPROTECTED_ROUTES = [
    ROOT, FAQ, HOME, LOGIN, ABOUT, NOT_FOUND, CONTACT, USER_GUIDE, MARKETPLACE, MARKET_INTELLIGENCE, MARKET_INTELLIGENCE_DETAILS,
    PRIVACY_POLICY, FORGOT_PASSWORD, PRODUCT_DETAILS, DISCUSSION, DISCUSSION_POST, TERM_AND_CONDITIONS
]

export const joinParams = (to, params) => {
    let url = to;
    
    params.forEach(param => {
        url = url.replace(`:${param.param}`, `${encodeURIComponent(param.value)}`);
    });

    return url;
};