import { savePDF } from '@progress/kendo-react-pdf';
import { drawDOM, exportPDF } from "@progress/kendo-drawing";

class DocService {
  createPdf = (html, data, sendBase64, callback) => {

    if (!sendBase64) {

      callback(data, null, sendBase64);
    } else {

      drawDOM(html, {
        paperSize: 'A3',
        forcePageBreak: '.page-break',
        fileName: 'form.pdf',
        margin: 50,
        height: '1200px',
        padding: 60,
      }).then((group) => {
        return exportPDF(group);
      }).then((dataUri) => {
        callback(data, dataUri.split(';base64,')[1], sendBase64);
      });

    }
  };

  createPdfForTest = (html) => {
    savePDF(html, {
      paperSize: 'A3',
      forcePageBreak: '.page-break',
      fileName: 'form.pdf',
      margin: 50,
      height: '1200px',
      padding: 60,
    }, () => console.log('Generating over...'))
  }

  createPdfForConfirmPoceed = (html, callback) => {
    drawDOM(html, {
      paperSize: 'A3',
      forcePageBreak: '.page-break',
      fileName: 'form.pdf',
      margin: 50,
      height: '1200px',
      padding: 16,
    }).then((group) => {
      return exportPDF(group);
    }).then((dataUri) => {
      callback(dataUri.split(';base64,')[1]);
    });

  };
}

const Doc = new DocService();
export default Doc;
