import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { DASHBOARD_ROOT, USER_GUIDE } from '@routes/urls';

const boards = [ "board", "account", "order", "address", "billing", "profile" ];

const AccountSidebar = ( props ) => {

    const { authenticatedUser, numberOfUnreadRFQ, numberOfUnreadDoc, numberOfUnreadContracts, numberOfUnreadMessage, numberOfUnreadNotif } = props;

    useEffect( () => {
        const index = boards.indexOf( props.board );
        if ( index >= 0 ) changeActive( index );
    }, [] )

    useEffect( () => {
        const index = boards.indexOf( props.board );
        if ( index >= 0 ) changeActive( index );
    } )

    const changeActive = ( index ) => {
        let list = document.querySelector( ".dashboard-sidebar .list" );
        if ( list.querySelector( ".active" ) )
            list.querySelector( ".active" ).classList.remove( "active" );
        list.childNodes[ index ].classList.add( "active" );
    }
    const {t} = props;
    
    return (
        <aside className="dashboard-sidebar mobile-sidebar col-lg-3" style={{ paddingRight: '5%', background: "white", padding: '30px', borderRadius: '10px', marginBottom: '15px'}}>
            <div className="widget widget-dashboard">
                <h3 className="widget-title">{t("dashboard.side_bar_tilte")} </h3>

                <ul className="list">
                    <li>
                        <Link to={ `${DASHBOARD_ROOT}/board` } ><i className="icon-user-2" style={{ fontSize: '1.2em' }}></i> &nbsp; &nbsp; {t("dashboard.account_dashboard")}</Link>
                    </li>
                    {authenticatedUser.authUser && authenticatedUser.authUser.userType == "SELLER" ? <li><Link to={ `${DASHBOARD_ROOT}/seller-products` }><i className="icon-shopping-cart" style={{ fontSize: '1.2em' }}></i> &nbsp; &nbsp; {t("dashboard.products")}</Link></li> : null}
                    <li><Link to={ `${DASHBOARD_ROOT}/rfqs` }><i className="far fa-clipboard" style={{ fontSize: '1.2em'}}></i> &nbsp; &nbsp; {t("dashboard.rfq")} &nbsp;&nbsp; {numberOfUnreadRFQ > 0 ? <div className="d-inline badge badge-success ml-auto pa-4" style={{fontSize: "12px", width: "10px", height: "10px", padding: "3px" , borderRadius: "50%"}}>&nbsp; {numberOfUnreadRFQ} &nbsp;</div> : null} </Link></li>
                    <li><Link to={ `${DASHBOARD_ROOT}/progressions` }><i className="far fa-clipboard" style={{ fontSize: '1.2em'}}></i> &nbsp; &nbsp; {t("dashboard.rfq_progressions")}</Link></li>
                    {/* <li><Link to={ `${DASHBOARD_ROOT}/intentsQuotes` }><i className="far fa-handshake" style={{ fontSize: '1.2em'}}></i> &nbsp; &nbsp; Instant Quote &nbsp;&nbsp; {numberOfUnreadRFQ > 0 ? <div className="d-inline badge badge-success ml-auto pa-4" style={{fontSize: "12px", width: "10px", height: "10px", padding: "3px" , borderRadius: "50%"}}>&nbsp;&nbsp;</div> : null} </Link></li> */}
                    <li><Link to={ `${DASHBOARD_ROOT}/documents` }><i className="far fa-file-pdf" style={{ fontSize: '1.2em'}}></i> &nbsp; &nbsp; Documents &nbsp;&nbsp; {numberOfUnreadDoc > 0 ? <div className="d-inline badge badge-success ml-auto pa-4" style={{fontSize: "12px", width: "10px", height: "10px", padding: "3px" , borderRadius: "50%"}}>&nbsp; {numberOfUnreadDoc} &nbsp;</div> : null} </Link></li>
                    <li><Link to={ `${DASHBOARD_ROOT}/contracts` }><i className="far fa-folder-open" style={{ fontSize: '1.2em'}}></i> &nbsp; &nbsp; {t("dashboard.contracts")} &nbsp;&nbsp; {numberOfUnreadContracts > 0 ? <div className="d-inline badge badge-success ml-auto pa-4" style={{fontSize: "12px", width: "10px", height: "10px", padding: "3px" , borderRadius: "50%"}}>&nbsp; {numberOfUnreadContracts} &nbsp;</div> : null} </Link></li>
                    <li><Link to={ `${DASHBOARD_ROOT}/transactions` }><i className="far fa-folder-open" style={{ fontSize: '1.2em'}}></i> &nbsp; &nbsp; Transactions &nbsp;&nbsp;</Link></li>
                    <li><Link to={`${DASHBOARD_ROOT}/chats`}><i className="far fa-envelope-open" style={{ fontSize: '1.2em'}}></i> &nbsp; &nbsp; {t("dashboard.chat")} &nbsp;&nbsp; {numberOfUnreadMessage > 0 ? <div className="d-inline badge badge-success ml-auto pa-4" style={{fontSize: "12px", width: "10px", height: "10px", padding: "3px" , borderRadius: "50%"}}>&nbsp; {numberOfUnreadMessage} &nbsp;</div> : null} </Link></li>
                    <li><Link to={`${DASHBOARD_ROOT}/notifications`}><i className="far fa-bell" style={{ fontSize: '1.2em'}}></i> &nbsp; &nbsp; Notifications &nbsp;&nbsp; {numberOfUnreadNotif > 0 ? <div className="d-inline badge badge-success ml-auto pa-4" style={{fontSize: "12px", width: "10px", height: "10px", padding: "3px" , borderRadius: "50%"}}>&nbsp; {numberOfUnreadNotif} &nbsp;</div> : null} </Link></li>
                    <li><Link to={USER_GUIDE}><i className="far fa-file-pdf" style={{ fontSize: '1.2em'}}></i> &nbsp; &nbsp; {t("notes.guides")} </Link></li>
                    <li onClick={props.logout} className="text-danger"><a style={{cursor: "pointer"}}><i className="fa fa-power-off" style={{ fontSize: '1.2em' }}></i> &nbsp; &nbsp; {t("dashboard.logout")}</a></li>
                </ul>
            </div>
        </aside>
    )
}

export default withTranslation()(AccountSidebar);
