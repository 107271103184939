
export const FETCH_ALL_PRODUCTS = 'FETCH_ALL_PRODUCTS';
export const FETCH_ALL_PRODUCTS_SUCCESS = 'FETCH_ALL_PRODUCTS_SUCCESS';
export const FETCH_ALL_PRODUCTS_ERROR = 'FETCH_ALL_PRODUCTS_ERROR';
export const FETCH_ALL_PRODUCTS_CLEAR = 'FETCH_ALL_PRODUCTS_CLEAR';



export const FETCH_ALL_PRODUCTS_FROM_ID_LIMIT = 'FETCH_ALL_PRODUCTS_FROM_ID_LIMIT';
export const FETCH_ALL_PRODUCTS_FROM_ID_LIMIT_SUCCESS = 'FETCH_ALL_PRODUCTS_FROM_ID_LIMIT_SUCCESS';
export const FETCH_ALL_PRODUCTS_FROM_ID_LIMIT_ERROR = 'FETCH_ALL_PRODUCTS_FROM_ID_LIMIT_ERROR';
export const FETCH_ALL_PRODUCTS_FROM_ID_LIMIT_CLEAR = 'FETCH_ALL_PRODUCTS_FROM_ID_LIMIT_CLEAR';


export const COUNT_ALL_PRODUCTS = 'COUNT_ALL_PRODUCTS';
export const COUNT_ALL_PRODUCTS_SUCCESS = 'COUNT_ALL_PRODUCTS_SUCCESS';
export const COUNT_ALL_PRODUCTS_ERROR = 'COUNT_ALL_PRODUCTS_ERROR';
export const COUNT_ALL_PRODUCTS_CLEAR = 'COUNT_ALL_PRODUCTS_CLEAR';

/********************************************************************************************************** */

export const COUNT_ALL_PRODUCTS_IN_CATEGORY = 'COUNT_ALL_PRODUCTS_IN_CATEGORY';
export const COUNT_ALL_PRODUCTS_IN_CATEGORY_SUCCESS = 'COUNT_ALL_PRODUCTS_IN_CATEGORY_SUCCESS';
export const COUNT_ALL_PRODUCTS_IN_CATEGORY_ERROR = 'COUNT_ALL_PRODUCTS_IN_CATEGORY_ERROR';
export const COUNT_ALL_PRODUCTS_IN_CATEGORY_CLEAR = 'COUNT_ALL_PRODUCTS_IN_CATEGORY_CLEAR';



/* export const FETCH_ALL_PRODUCTS_IN_CATEGORY_FROM_ID_LIMIT = 'FETCH_ALL_PRODUCTS_IN_CATEGORY_FROM_ID_LIMIT';
export const FETCH_ALL_PRODUCTS_IN_CATEGORY_FROM_ID_LIMIT_SUCCESS = 'FETCH_ALL_PRODUCTS_IN_CATEGORY_FROM_ID_LIMIT_SUCCESS';
export const FETCH_ALL_PRODUCTS_IN_CATEGORY_FROM_ID_LIMIT_ERROR = 'FETCH_ALL_PRODUCTS_IN_CATEGORY_FROM_ID_LIMIT_ERROR';
export const FETCH_ALL_PRODUCTS_IN_CATEGORY_FROM_ID_LIMIT_CLEAR = 'FETCH_ALL_PRODUCTS_IN_CATEGORY_FROM_ID_LIMIT_CLEAR'; */

export const SET_CATEGORY_ID_SELECTED = 'SET_CATEGORY_ID_SELECTED';
export const SET_SEARCH_NAME = 'SET_SEARCH_NAME';
export const SET_MIN_PRICE_INTERVAL_FILTER = 'SET_MIN_PRICE_INTERVAL_FILTER';
