import {
    SIGNUP_USER_BASE, SIGNUP_USER_SUCCESS, SIGNUP_USER_ERROR
} from '../../constants/auth-action-types'
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { removeAuthToken } from '../../helpers/tokens';

const initialState = {

    data: null,

    loading: false,
    error: null,

    accountCreated: false
}

const signUpReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case SIGNUP_USER_BASE:
            return { ...state, loading: true, error: null, data: null, accountCreated: false };

        case SIGNUP_USER_SUCCESS:
            return { ...state, error: null, loading: false, accountCreated: true, data: action.payload };

        case SIGNUP_USER_ERROR:
            return { ...state, data: null, loading: false, accountCreated: false, error: action.payload };
            

        default:
            return state;
    }
};

/* const persistConfig = {
    keyPrefix: "porto-",
    key: "products",
    storage
}
 */
export default signUpReducer;