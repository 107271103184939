import * as modalLoginActions from '../../constants/modalLogin-actions-type';
/* import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'; */

const initialState = {

    status: false,
}

const ModalToLoginReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case modalLoginActions.SET_STATUS_LOGIN_MODAL:
            return { ...state, status: action.payload };

        default:
            return state;
    }
};

/* const persistConfig = {
    keyPrefix: "porto-",
    key: "products",
    storage
}
 */
export default ModalToLoginReducer;