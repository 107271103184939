import backendURLs from "../utils/backendURL";
import { makeActionRequest, makeRequest,  replaceIdParamInUrlFormat, joinBaseUrlWithRequestParams } from "./utils";
import * as productsActions from '../constants/products-actions';


export const fetchAllProducts = () => dispatch => {

    const url  = `${backendURLs.PRODUCTS.GET_ALL}`;

    makeActionRequest('get', url, null, dispatch, productsActions.FETCH_ALL_PRODUCTS, {});
}

export const fetchAllProductsByName = (name) => dispatch => {
    const url  = `${backendURLs.PRODUCTS.GET_ALL}?name=${name}`;
    makeActionRequest('get', url, null, dispatch, productsActions.FETCH_ALL_PRODUCTS, {});
}


export const getProductsFromIdLimit = (fromId, limit=10, q, isFirstTime=false, callback=null) => dispatch => {

    if (isFirstTime) {
        dispatch({ type: productsActions.FETCH_ALL_PRODUCTS_FROM_ID_LIMIT_CLEAR });
    }

    const url = joinBaseUrlWithRequestParams(`${backendURLs.PRODUCTS.GET_PRODUCTS_FROM_ID_LIMIT}`, [
        {param: 'id', value:fromId },
        {param: 'limit', value:limit },
        {param: 'is_first_time', value:isFirstTime },
        {param: 'q', value: q ? q : '' }
    ]);

    makeActionRequest('get', url, null, dispatch, productsActions.FETCH_ALL_PRODUCTS_FROM_ID_LIMIT, {callback});
}


export const getProductsInCategoryFromIdLimit = (fromId, q='', categoryId, limit=10, isFirstTime=false, callback=null) => dispatch => {

    if (isFirstTime) {
        dispatch({ type: productsActions.FETCH_ALL_PRODUCTS_FROM_ID_LIMIT_CLEAR });
    }
    const url = joinBaseUrlWithRequestParams(replaceIdParamInUrlFormat(`${backendURLs.PRODUCTS.GET_PRODUCTS_CATEGORY_FROM_ID_LIMIT}`,categoryId), [
        {param: 'product_id', value:fromId },
        {param: 'limit', value:limit },
        {param: 'q', value: q ? q : '' },
        {param: 'is_first_time', value:isFirstTime }
    ]);

    makeActionRequest('get', url, null, dispatch, productsActions.FETCH_ALL_PRODUCTS_FROM_ID_LIMIT, {callback});
}


export const fetchInterestingProducts = ()  => {

    const url  = `${backendURLs.PRODUCTS.GET_INTERESTING}`;

    return makeRequest('get', url, null, {});
}

export const getProductById = (productId)  => {

    const url  = replaceIdParamInUrlFormat(`${backendURLs.PRODUCTS.GET_ONE}`,productId);

    return makeRequest('get', url, null, {});
}


export const countAllProducts = (q) => dispatch => {

    const url  = `${backendURLs.PRODUCTS.GET_COUNT_ALL_PRODUCTS}?q=${q ? q : ''}`;

    makeActionRequest('get', url, null, dispatch, productsActions.COUNT_ALL_PRODUCTS, {});
}


export const countAllProductsInCategorySelected = (categoryId, q='') => dispatch => {

    const url  = replaceIdParamInUrlFormat(`${backendURLs.PRODUCTS.GET_COUNT_ALL_PRODUCTS_IN_CATEGORY}?q=${q ? q : ''}`, categoryId);

    makeActionRequest('get', url, null, dispatch, productsActions.COUNT_ALL_PRODUCTS, {});
}


export const fetchAllProductsSameCategory = (categoryId) => {

    const url  = replaceIdParamInUrlFormat(`${backendURLs.PRODUCTS.GET_PRODUCT_BY_CATEGORY}`, categoryId);

    return makeRequest('get', url, null, {})
}
