import {
    UPDATE_LICENCE_USER_BASE, UPDATE_LICENCE_USER_SUCCESS, UPDATE_LICENCE_USER_ERROR,
    UPDATE_LICENCE_USER_CLEAR
} from '../../../constants/auth-action-types'

const initialState = {

    data: null,

    loading: false,
    error: null,
    userLicenceUpdated: false
}

const updateUserLicenceReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case UPDATE_LICENCE_USER_BASE:
            return { ...state, loading: true, error: null, userLicenceUpdated: false, data: null};

        case UPDATE_LICENCE_USER_SUCCESS:
            return { ...state, error: null, loading: false, userLicenceUpdated: true, data: action.payload };

        case UPDATE_LICENCE_USER_ERROR:
            return { ...state, data: null, loading: false, userLicenceUpdated: false, error: action.payload };

        
        case UPDATE_LICENCE_USER_CLEAR:
            return initialState

        default:
            return state;
    }
};

/* const persistConfig = {
    keyPrefix: "porto-",
    key: "products",
    storage
}
 */
export default updateUserLicenceReducer;